import React from 'react';
import { Box } from '@mui/system';
import IconBlock from 'components/WebTools/MainForm/ProjectForm/Forms/IconBlock';

import ProjectName from 'shared/components/Icons/ProjectName';
import Participants from 'shared/components/Icons/Participants';
import MuiSelect from 'shared/components/UI/MuiSelect';
import Region from 'shared/components/Icons/Region';
import Location from 'shared/components/Icons/Location';

import { regions } from 'components/WebTools/MainForm/ProjectForm/dataSelect';
import Picture from 'shared/components/Icons/Picture';
import Binoculars from 'shared/components/Icons/Binoculars';
import DownloadBox from 'components/WebTools/MainForm/ProjectForm/Uploader/DownloadBox';
import { General, Project } from 'shared/store/project/interface';
import FormikField from 'shared/components/UI/FormikField';
import { useFormikContext } from 'formik';
import { useCheckPermissions } from 'shared/hooks/useCheckPermissions';
import { Roles } from 'shared/constants/enum';

import { styles } from '../styles';

export interface Props {
  dataForm: Project;
}

const acceptFile = ['image/jpeg', 'image/png', 'application/pdf'];

const GeneralInformation = (): JSX.Element => {
  const { values, touched, errors, handleChange } = useFormikContext<Project>();
  const stateValues: General = values.general;

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  return (
    <Box sx={styles.General}>
      <Box sx={styles.GeneralWrapper}>
        <Box sx={styles.generalItem}>
          <Box sx={styles.fieldWrapper}>
            <IconBlock title="Projektnamn" icon={<ProjectName />} />
            <FormikField
              type="text"
              maxLength={100}
              name="general.name"
              value={values.general.name}
              onChange={handleChange}
              error={touched.general?.name && !!errors.general?.name}
              helperText={touched.general?.name && errors.general?.name}/>
          </Box>
        </Box>
        <Box sx={styles.generalItem}>
          <IconBlock title="Region" icon={<Region />} />
          <Box sx={styles.fieldWrapper}>
            <MuiSelect
              items={regions}
              name="general.region"
              value={stateValues.region ?? ''}
              onHandleChange={handleChange}
              placeholder="Välj din region"
              isDisabled={isMU}/>
          </Box>
        </Box>
        <Box sx={styles.generalItem}>
          <IconBlock title="Plats" icon={<Location />} />
          <Box sx={styles.fieldWrapper}>
            <FormikField
              type="text"
              maxLength={100}
              name="general.location"
              placeholder="Din plats"
              value={stateValues.location}
              onChange={handleChange}/>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.wrapperWorkshopParticipants}>
        <Box sx={styles.workshopParticipants}>
          <IconBlock title="Workshopdeltagare" icon={<Participants />} />
          <Box sx={styles.workshopParticipantsLeft}>
            <FormikField
              type="text"
              maxLength={400}
              name="general.workshopParticipants"
              placeholder="Deltagare"
              value={stateValues.workshopParticipants}
              onChange={handleChange}/>
          </Box>

          <Box sx={styles.projectVision}>
            <IconBlock title="Projektets vision" icon={<Binoculars />} />

            <FormikField
              rows={4.5}
              multiline={true}
              type="text"
              maxLength={500}
              placeholder="Vision"
              name="general.projectVision"
              value={stateValues.projectVision}
              onChange={handleChange}/>
          </Box>
        </Box>
        <DownloadBox name="files.general" accept={acceptFile} nameField="general" title="Tomtkartor, bilder" icon={<Picture />} />
      </Box>
    </Box>
  );
};

export default GeneralInformation;

import {SystemCssProperties} from '@mui/system';

export const styles = {
  stepOneContainer: {
    marginTop: '24px',
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
  } as SystemCssProperties,
};

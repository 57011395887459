import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styles } from './styles';

interface Props {
  [key: string]: any;
  Title: React.ComponentType;
  Subtitle?: React.ComponentType;
}

const TitleAndSubTitle = (props: Props): JSX.Element => {
  const { Title, Subtitle } = props;
  return (
    <Box sx={styles.boxText}>
      <Typography variant="subtitle1" color="inherit" sx={styles.title}>
        {Title && <Title />}
      </Typography>
      <Typography variant="caption">{Subtitle && <Subtitle />}</Typography>
    </Box>
  );
};

export { TitleAndSubTitle };

import React from 'react';
import MuiButton from 'shared/components/UI/MuiButton';
import {RoutesPage} from 'shared/routes';
import {Box, Typography} from '@mui/material';
import {useHistory} from 'react-router-dom';
import Loader from 'shared/components/Loader';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_6/Instruction/styles';
import {useGetProject} from 'shared/hooks/useGetProjects';

const clearProjectStorage = () => {
  localStorage.removeItem('plotNew');
  localStorage.removeItem('plotInitial');
  localStorage.removeItem('projectId');
};

const InstructionStep6 = (): JSX.Element => {
  const { push } = useHistory();
  const { projectData } = useGetProject();
  const reportUrl = projectData?.reportUrl;
  clearProjectStorage();

  const downloadReport = () => {
    window.open(reportUrl);
  };

  const copyReportLink = async () => {
    await navigator.clipboard.writeText(reportUrl || '');
  };

  if (projectData === null) {
    return (
      <Box sx={styles.Loader}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.instructionStep}>
        <ThumbUpIcon sx={styles.Icon} />
        <Typography variant="subtitle1" component="h2" color="inherit" sx={styles.TypoText}>
          Gratulerar! Ni klarade resan! <br />
          Nu kan ni spara ner workshopens underlag och resultat
        </Typography>
        <Box sx={styles.BtnSection}>
          <MuiButton
            type="button"
            variant="contained"
            onClick={downloadReport}
            sx={{
              ...styles.NavLink,
              ...styles.BtnWhite,
            }}
          >
            Ladda ner fil
          </MuiButton>
          <MuiButton
            onClick={copyReportLink}
            type="button"
            variant="contained"
            sx={{
              ...styles.NavLink,
              ...styles.BtnWhite,
            }}
          >
            Kopera länk
          </MuiButton>
          <MuiButton onClick={() => push(RoutesPage.home)} type="button" variant="contained" sx={styles.NavLink}>
            Huvudsida
          </MuiButton>
        </Box>
      </Box>
    </Box>
  );
};

export default InstructionStep6;

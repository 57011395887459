import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  ellipse: {
    width: '48px',
    height: '48px',
    border: `2px solid ${colors.white}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    fontSize: '18px',
    flex: 'none',
  } as SystemCssProperties,
};

import React from 'react';

const Schedule = (): JSX.Element => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_236_11230)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.1873 11.2492V28.1254C6.1873 28.5729 6.36507 29.0021 6.6815 29.3185C6.99793
          29.6349 7.4271 29.8127 7.8746 29.8127H28.1286C28.5761 29.8127 29.0052 29.6349 29.3217 29.3185C29.6381 29.0021 29.8159 28.5729
          29.8159 28.1254V11.2492H6.1873ZM7.8746 6.18729C6.9796 6.18729 6.12126 6.54282 5.4884 7.17568C4.85554 7.80855 4.5 8.66689 4.5
          9.56189L4.5 28.1254C4.5 29.0204 4.85554 29.8787 5.4884 30.5116C6.12126 31.1444 6.9796 31.5 7.8746 31.5H28.1286C29.0236 31.5
          29.8819 31.1444 30.5148 30.5116C31.1476 29.8787 31.5032 29.0204 31.5032 28.1254V9.56189C31.5032 8.66689 31.1476 7.80855
          30.5148 7.17568C29.8819 6.54282 29.0236 6.18729 28.1286 6.18729H7.8746Z"
          fill="#F54F8F"/>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4057 4.5C10.6294 4.5 10.844 4.58888 11.0022 4.7471C11.1604 4.90531
          11.2493 5.1199 11.2493 5.34365V6.1873C11.2493 6.41105 11.1604 6.62564 11.0022 6.78385C10.844 6.94207 10.6294 7.03095 10.4057
          7.03095C10.1819 7.03095 9.96733 6.94207 9.80911 6.78385C9.6509 6.62564 9.56201 6.41105 9.56201 6.1873V5.34365C9.56201 5.1199
          9.6509 4.90531 9.80911 4.7471C9.96733 4.58888 10.1819 4.5 10.4057 4.5V4.5ZM25.593 4.5C25.8167 4.5 26.0313 4.58888 26.1895
          4.7471C26.3477 4.90531 26.4366 5.1199 26.4366 5.34365V6.1873C26.4366 6.29809 26.4148 6.4078 26.3724 6.51015C26.33 6.61251
          26.2679 6.70551 26.1895 6.78385C26.1112 6.86219 26.0182 6.92434 25.9158 6.96673C25.8135 7.00913 25.7038 7.03095 25.593
          7.03095C25.4822 7.03095 25.3725 7.00913 25.2701 6.96673C25.1678 6.92434 25.0748 6.86219 24.9964 6.78385C24.9181 6.70551
          24.8559 6.61251 24.8135 6.51015C24.7711 6.4078 24.7493 6.29809 24.7493 6.1873V5.34365C24.7493 5.1199 24.8382 4.90531
          24.9964 4.7471C25.1546 4.58888 25.3692 4.5 25.593 4.5V4.5Z"
          fill="#F54F8F"/>
        <path
          d="M19.6873 16.3127C19.6873 15.8652 19.865 15.436 20.1815 15.1196C20.4979 14.8032 20.9271 14.6254 21.3746
          14.6254H29.8127V18H21.3746C20.9271 18 20.4979 17.8222 20.1815 17.5058C19.865 17.1894 19.6873 16.7602 19.6873
          16.3127ZM6.18726 19.6873H12.938C13.3855 19.6873 13.8147 19.8651 14.1312 20.1815C14.4476 20.4979 14.6254 20.9271 14.6254
          21.3746C14.6254 21.8221 14.4476 22.2513 14.1312 22.5677C13.8147 22.8841 13.3855 23.0619 12.938
          23.0619H6.18726V19.6873Z"
          fill="#F54F8F"/>
      </g>
      <defs>
        <clipPath id="clip0_236_11230">
          <rect width="27" height="27" fill="white" transform="translate(4.5 4.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Schedule;

import {
  NewCompetitionParametersToTableI,
  OldCompetitionParametersToTableI,
} from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/initialStateData';

export const renderEmptyTable = (rowsLength: number): any => {
  return Array.from({ length: rowsLength }, () => ({
    id: '',
    calculateValue: 0,
    description: '',
    name: '',
    overriddenValue: 0,
    quadrant: null,
    values: [],
  }));
};

export const renderTable = (rowsLen: OldCompetitionParametersToTableI[] | NewCompetitionParametersToTableI[]) => {
  const lengthRows = 9;
  if (rowsLen.length === 0) {
    return renderEmptyTable(lengthRows);
  } else {
    const emptyFill = renderEmptyTable(lengthRows - rowsLen.length);
    return [...rowsLen, ...emptyFill];
  }
};

import colors from 'styles/_Variables';

export const MuiPaper = {
  styleOverrides: {
    root: {
      '& .MuiCalendarPicker-root': {
        '.PrivatePickersSlideTransition-root': {
          '.Mui-selected': {
            backgroundColor: colors.accentBlue,
            border: 'none',
            color: colors.white,
          },
        },
        '.PrivatePickersYear-root': {
          button: {
            '&:hover': {
              backgroundColor: colors.hoverDatePicker,
              color: colors.accentBlue,
              borderRadius: '8px',
            },
          },
          'button.Mui-selected': {
            backgroundColor: colors.accentBlue,
            borderRadius: '8px',
            color: colors.white,
          },
        },
      },
    },
  },
};

import colors from 'styles/_Variables';
import { SystemCssProperties } from '@mui/system';

export const styles = {
  accordion: {
    '& .MuiButtonBase-root': {
      padding: '32px 0 32px 0',
    },
    background: colors.accentBlue,
    borderBottom: '1px solid #354B71',
    '&:before': {
      display: 'none',
    },
  } as SystemCssProperties,

  stylesTitleAccordion: {
    fontWeight: 600,
    color: colors.white,
  } as SystemCssProperties,

  button: {
    mt: 5,
    mb: 10,
    background: colors.white,
    '&:hover': {
      background: colors.white,
    },
  } as SystemCssProperties,

  text: {
    textTransform: 'capitalize',
    color: colors.accentBlue,
  } as SystemCssProperties,

  accordionSummary: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    pr: 2,
  } as SystemCssProperties,

  iconBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as SystemCssProperties,

  textForBtn: {
    color: '#fff',
  } as SystemCssProperties,

  removePadding: {
    padding: 0,
  } as SystemCssProperties,

  accordionInner: {
    '& .MuiButtonBase-root': {
      padding: '32px 0 32px 0',
    },
    background: colors.accentBlue,
    borderBottom: '1px solid #354B71',
    '&:before': {
      display: 'none',
    },
  } as SystemCssProperties,

  stylesHeaderItems: {
    padding: 0,
    position: 'absolute',
    top: '26px',
    color: colors.white,
    zIndex: 9,
    '&:hover': {
      background: 'none',
    },
  } as SystemCssProperties,

  arrowIcon: {
    width: '17px',
    height: '20px',
    marginRight: '16px',
  } as SystemCssProperties,

};

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  modal: {
    width: '680px',
    height: '482px',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    p: 4,
  } as SystemCssProperties,

  muiButton: {
    color: colors.accentBlue,
    background: colors.white,
    '&:hover': {
      background: 'none',
    },
  } as SystemCssProperties,

  Loader: {
    width: '45px',
    display: 'flex',
    position: 'relative',
    left: '50%',
  } as SystemCssProperties,

  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  } as SystemCssProperties,

  wrapperText: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '24px',
  } as SystemCssProperties,

  modalTextArea: {
    width: '100%',
    marginBottom: '24px',
    fontFamily: 'Poppins Regular, sans-serif',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 400,
    '.MuiOutlinedInput-root': {
      height: '100%',
    },
  } as SystemCssProperties,

  modalHeader: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'space-between',
  } as SystemCssProperties,
};

import {DiagramStep5} from 'shared/constants/enum';

export const transformDataDiagram = (allCompetitors, oldListsCompetitionParameters, newListsCompetitionParameters) => {
  const namesTransformCompetitors = allCompetitors.map((item) => {
    const {doings, priorities, ...rest} = item;
    return {...rest};
  });

  const oldListsCompetition = oldListsCompetitionParameters.reduce((total: any, item, index: number) => {
    total[index] = item.values.reduce((accum, value, indexVal) => {
      accum[namesTransformCompetitors[indexVal].name] = value;
      return accum;
    }, {});
    total[index].name = item.name;
    if (total[index][DiagramStep5.RIKSBYGGEN]) {
      total[index][DiagramStep5.GAMMALSTRATEGI] = item.values[0];
      total[index][DiagramStep5.RIKSBYGGEN] = item.overriddenValue;
    } else {
      total[index][DiagramStep5.GAMMALSTRATEGI] = item.overriddenValue;
    }

    return total;
  }, []);
  const newListsCompetition = newListsCompetitionParameters.map((item) => ({
    name: item.name,
    Riksbyggen: item.overriddenValue,
  }));

  return {
    dataDiagramTemp: [...oldListsCompetition, ...newListsCompetition] ?? [],
    competitorsTemp:
      [
        ...namesTransformCompetitors,
        {
          name: 'Gammal strategi',
          hasAllOldCompetitionValues: true,
        },
      ] ?? [],
  };
};

export const colorsCompetitors = ['#D51C29', '#0063AA', '#00B8F1', '#123967', '#05A64F', '#274C26', '#F2BBBF'];

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';
import { CompareParametersId } from 'shared/constants/enum';

export const styles = {
  compareValuesContainer: {
    width: '100%',
    maxWidth: '562px',
    height: '100%',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'center',
    background: colors.accentBlue,
    borderRadius: '8px',
  } as SystemCssProperties,

  board: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '280px',
    borderRight: `$1px solid ${colors.white}`,
    borderBottom: `$1px solid ${colors.white}`,
  } as SystemCssProperties,

  boardTitle: {
    color: colors.white,
    fontSize: '14px',
    textAlign: 'left',
    padding: '16px 8px 16px 16px',
  } as SystemCssProperties,

  innerBoard: {
    width: '280px',
    height: '151px',
    overflowY: 'auto',
    padding: 0,
  } as SystemCssProperties,

  itemBox: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '8px',
    textOverflow: 'ellipsis',
    maxWidth: '180px',
    overflow: 'hidden',
  } as SystemCssProperties,

  iconInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
  } as SystemCssProperties,

  iconInfo: {
    fill: colors.white,
    fontSize: '20px',
    marginBottom: '2px',
  } as SystemCssProperties,
};

export const stylesProps = (snapshotDraggable, draggableProvided) => {
  return {
    block: {
      position: 'relative',
      userSelect: 'none',
      minHeight: '48px',
      padding: '8px',
      backgroundColor: snapshotDraggable.isDragging ? colors.darkBlue : colors.accentBlue,
      color: colors.white,
      borderRadius: snapshotDraggable.isDragging ? '4px' : '',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...draggableProvided.draggableProps.style,
      overflowWrap: 'break-word',
      '&:hover': {
        '& .dragIcon': {
          display: 'flex',
        },
        '& .itemBoxName': {
          display: 'none',
        },
      },
      '& .dragIcon': {
        position: 'absolute',
        right: '8px',
        top: '12px',
        display: snapshotDraggable.isDragging ? 'flex' : 'none',
      },
      '& .itemBoxName': {
        display: snapshotDraggable.isDragging ? 'none' : 'flex',
      },
    },
    board: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '280px',
      borderRight: `1px solid ${colors.white}`,
      borderBottom: `1px solid ${colors.white}`,
    } as SystemCssProperties,
  };
};

export const stylesPropsBoard = (boardId) => {
  return {
    board: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '280px',
      borderRight:
        boardId === CompareParametersId.Reducera || boardId === CompareParametersId.Reduce ? `1px solid ${colors.white}` : 'none',
      borderBottom:
        boardId === CompareParametersId.Reducera || boardId === CompareParametersId.Eliminera ? `1px solid ${colors.white}` : 'none',
    } as SystemCssProperties,
  };
};

export const stylesPropsInnerBoard = (boardId, snapshot) => {
  return {
    innerBoard: {
      width: '248px',
      height: '144px',
      margin: '0 auto 8px',
      borderRadius: '4px',
      background: snapshot.isDraggingOver ? colors.lighterBlue : 'none',
      transition: 'all 1s',
      overflow: 'hidden',
      overflowY: 'auto',
      padding: 0,
    } as SystemCssProperties,
  };
};

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const stylesProps = (isActive: boolean) => {
  return {
    additionalInfoLeftBtnItem: {
      width: '164px',
      height: '56px',
      marginRight: '16px',
      color: colors.accentBlue,
      background: isActive ? colors.grey : colors.white,
      textTransform: 'none',
      boxShadow: 'none',
      padding: 0,
      '&:hover': {
        background: isActive ? colors.grey : colors.white,
        boxShadow: 'none',
      },
    } as SystemCssProperties,
  };
};

export const styles = {
  additionalInfoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    gap: '40px',
  } as SystemCssProperties,

  additionalInfoLeft: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '50%',
    gap: '40px',
  } as SystemCssProperties,

  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '40px',
  } as SystemCssProperties,

  NavLink: {
    width: 'auto',
    marginTop: '40px',

    '&.MuiButton-root': {
      padding: '0px',
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.white,
      textTransform: 'none',
      width: '100%',
      height: '100%',
      padding: '10px 16px',
    },
  } as SystemCssProperties,

  additionalInfoRight: {
    height: '100%',
    marginBottom: {
      xl: '0',
      lg: '90px',
    },
  } as SystemCssProperties,
};

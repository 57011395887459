import colors from 'styles/_Variables';
import { SystemCssProperties } from '@mui/system';

export const styles = {
  typoHeader: {
    marginBottom: '32px',
    color: colors.accentBlue,
    textAlign: 'center',
  } as SystemCssProperties,
  typoTxt: {
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '16px',
    margin: '24px 0',
    lineHeight: '24px',
    textAlign: 'center',
  } as SystemCssProperties,

  fields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as SystemCssProperties,

  styleFiled: {
    width: '100%',
    marginBottom: '32px',
    maxWidth: '296px',
    position: 'relative',
  } as SystemCssProperties,

  btn: {
    width: '100%',
    height: '48px',
    maxWidth: '196px',
  } as SystemCssProperties,

  margin: {
    margin: '16px 0 40px 0',
  } as SystemCssProperties,

  btnTxt: {
    color: colors.white,
  } as SystemCssProperties,

  linkReset: {
    marginBottom: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
    color: colors.accentBlue,
    fontWeight: 500,
  } as SystemCssProperties,

  linkResetTxt: {
    color: colors.accentBlue,
  } as SystemCssProperties,
};

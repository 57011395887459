import {createSlice} from '@reduxjs/toolkit';
import {User} from 'shared/store/admin/interface';
import {StateInterface} from 'shared/interfaces';

const initialState: StateInterface = {
  isLoading: false,
  error: {
    message: [],
    stackErrors: '',
  },
};

const sendInvite = createSlice({
  name: 'sendInvite',
  initialState,
  reducers: {
    start: {
      reducer(state) {
        state.isLoading = true;
        state.error = initialState.error;
      },
      prepare(payload: Pick<User, 'email'>) {
        return {payload};
      },
    },
    end(state) {
      state.isLoading = false;
    },

    resendInviteStart: {
      reducer(state) {
        state.isLoading = true;
        state.error = initialState.error;
      },
      prepare(payload: { id: number }) {
        return {payload};
      },
    },

    resendInviteEnd(state) {
      state.isLoading = false;
    },

    error(state, action: any) {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
    clear() {
      return initialState;
    },
  },
});

export const sendInviteActions = sendInvite.actions;
export default sendInvite.reducer;

import {SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';
import {Properties} from 'csstype';
import {StylesCSS} from 'shared/interfaces';

export const styles: StylesCSS = {
  wrapperPopup: {
    position: 'relative',
    cursor: 'pointer',
    wordWrap: 'break-word',
    '&:hover': {
      '& > .showPopupKey': {
        display: 'block',
      },
    },
  } as SystemCssProperties,

  wrapperColorsCompetitors: {
    display: 'flex',
    alignItems: 'center',
  },

  closeSvg: {
    fill: colors.accentBlue,
    marginRight: '13px',
  },

  closeButton: {
    fontSize: '16px',
    color: colors.accentBlue,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px',
  },
};

export const stylesProps = (index: number,
                            func: (name: string, index: number) => string, name: string, isFullSize?: boolean): StylesCSS => {
  return {
    stylesCircle: {
      width: isFullSize ? '16px' : '8px',
      height: isFullSize ? '16px' : '8px',
      background: func(name, index),
      borderRadius: '50%',
      marginRight: '8px',
      flexShrink: 0,
    },
  };
};

export const stylesDiagram = (isFullWidth: boolean): StylesCSS | any => {
  return {
    wrapperDiagram: {
      width: '100%',
      maxWidth: isFullWidth ? '90%' : '897px',
      maxHeight: isFullWidth ? '100%' : '478px',
      height: '100%',
      position: 'relative',
      top: isFullWidth ? '-60px' : '0',
      padding: '24px 24px',
      paddingTop: isFullWidth ? '24px' : '64px',
      borderRadius: '8px',
      background: colors.white,
      marginBottom: {
        xl: '0',
      },
      '& .recharts-dot': {
        strokeDasharray: 'none',
      },
      '& .recharts-cartesian-axis-ticks line': {
        opacity: 0,
      },
      '& svg': {
        overflow: 'visible',
      },
    } as SystemCssProperties,

    popupCompetitor: {
      display: 'none',
      width: isFullWidth ? '150px' : '275px',
      position: 'absolute',
      top: '25px',
      left: '-15px',
      fontSize: '14px',
      padding: '8px 12px',
      lineHeight: '14px',
      zIndex: 100,
      color: colors.darkMoreBlue,
      background: colors.lightGrey,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
    },

    colorText: {
      width: '128px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: colors.accentBlue,
      fontSize: isFullWidth ? '16px' : '14px',
    },

    titleDiagram: {
      fontSize: isFullWidth ? '24px' : '16px',
      fontWeight: isFullWidth ? 600 : 500,
      textAlign: 'center',
      marginBottom: isFullWidth ? '55px' : '',
      color: colors.accentBlue,
    },

    wrapperStyleLegend: {
      position: 'absolute',
      top: 0,
      right: '-150px',
    } as Properties<string | number, string & {}>,


    iconFullWidth: {
      cursor: 'pointer',
      position: isFullWidth ? 'fixed' : 'absolute',
      zIndex: '999',
      top: isFullWidth && '27px',
      bottom: isFullWidth ? '' : '30px',
      right: isFullWidth ? '46px' : '48px',
    },
  };
};


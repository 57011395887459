import React, {useEffect, useState} from 'react';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';
import { Box, Typography } from '@mui/material';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_5/TableContainer/styles';
import Loader from 'shared/components/Loader';
import TableSection from 'components/WebTools/ProjectProcess/Steps/Step_5/TableContainer/Table';
import {useGetProject} from 'shared/hooks/useGetProjects';
import {applyQuadrantToOldParameterValue} from 'shared/helpers';
import {TitleAndSubTitle} from 'components/WebTools/ProjectProcess/Common/TittleAndSubTitile';
import NavigationBar from 'shared/components/NavigationBar';
import { instructions } from './instructions';
import colors from 'styles/_Variables';
import MuiButton from 'shared/components/UI/MuiButton';
import { RoutesPage } from 'shared/routes';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

const dataProcessing = (joinedData, isUpdateForStep4) => {
  return joinedData.reduce((accum, item) => {
    if (item.values) {
      const {values, quadrant, overriddenValue} = item;
      const calc = applyQuadrantToOldParameterValue(values[0], quadrant);
      accum.push({
        ...item,
        riksbyggen: values[0],
        overriddenValue: isUpdateForStep4 ? calc : overriddenValue,
        isEdited: false,
      });
      return accum;
    }
    accum.push({
      ...item,
      riksbyggen: '',
      overriddenValue: item.overriddenValue,
      isEdited: false,
    });
    return accum;
  }, []);
};

const TableContainer = (): JSX.Element => {
  const {projectData, isLoadingGetForm: isLoading} = useGetProject();
  const history = useHistory();
  const id = useParams<{ id: string }>().id ?? localStorage.getItem('projectId');
  const isUpdateForStep4Value = localStorage.getItem('isUpdateForStep4');
  const isUpdateForStep4 = JSON.parse(isUpdateForStep4Value as string);
  const [combineData, setCombineData] = useState([]);
  const [nextStepEvent, setNextStepEvent] = useState<null | React.MouseEvent<HTMLInputElement>>(null);

  let instruction: any = null;

  useEffect(() => {
    if (projectData !== null) {
      setCombineData(
        dataProcessing(
          [...projectData.data.oldCompetitionParameters, ...projectData.data.newCompetitionParameters],
          isUpdateForStep4,
        ),
      );
    }
  }, [isUpdateForStep4]);

  if (projectData === null || isLoading) {
    return (
      <Box sx={styles.Loader}>
        <Loader/>
      </Box>
    );
  }

  const data: any = dataProcessing(
    [...projectData.data.oldCompetitionParameters, ...projectData.data.newCompetitionParameters],
    isUpdateForStep4,
  );

  instruction = projectData.data.stepsNotes['5'];

  const navigateBack = (): void => {
    const previousPath = `${RoutesPage.project.page}${id}${RoutesPage.steps.step5}${RoutesPage.steps.video}${location.search}`;

    history.push(previousPath);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.Title}>
        <TitleAndSubTitle
          Title={() => <>Ny strategiduk</>}
          Subtitle={() => <>Justera nivåerna på dina konkurrensparametrar.(1 - låg, 10 - hög, 0 - eliminera parameter)</>}/>
        <Instruction stepNote={instruction} data={projectData.data} noteKey={5}/>
      </Box>

      <Box sx={styles.content}>
        <Box sx={styles.instructions}>
          {
            instructions.map((item, index) =>
              <Typography variant="subtitle2" component="p" color={colors.white}>{ index + 1 }. { item }</Typography>)
          }

          <Typography sx={styles.time} variant="subtitle2" component="p">Tidsåtgång: ca 20 minuter</Typography>

          <MuiButton onClick={(event) => setNextStepEvent(event)} type="button" variant="contained" sx={styles.NavLink}>
            Fortsätt
          </MuiButton>
        </Box>

        <TableSection combineData={combineData.length > 0 ? combineData : data} data={projectData.data} nextStepEvent={nextStepEvent}/>
      </Box>

      <NavigationBar
        navigateBack={() => navigateBack()}
        navigateForward={(event) => setNextStepEvent(event)}
      />
    </Box>
  );
};

export default TableContainer;

import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import { useGetProject } from 'shared/hooks/useGetProjects';
import Loader from 'shared/components/Loader';

import { transformDataDiagram } from 'components/WebTools/ProjectProcess/Steps/Step_2/DiagramContainer/utils';
import DiagramCompetitors from 'components/WebTools/ProjectProcess/Steps/Step_2/DiagramContainer/DiagramCompetitors';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';
import { styles, stylesDiagramContainer } from 'components/WebTools/ProjectProcess/Steps/Step_2/DiagramContainer/styles';
import { RoutesPage } from 'shared/routes';
import { useHistory, useParams } from 'react-router-dom';
import MuiButton from 'shared/components/UI/MuiButton';
import { useCurrentPng } from 'recharts-to-png';
import NavigationBar from 'shared/components/NavigationBar';

export interface DiagramData {
  [key: string]: any;
}

const DiagramContainer = (): JSX.Element => {
  const { location, push } = useHistory();
  const { projectData, isLoadingGetForm } = useGetProject();
  const [getPng, { ref }] = useCurrentPng();
  let { id } = useParams<{ id: string }>();
  id = id ?? localStorage.getItem('projectId');
  const fromStep4 = location.state?.from.includes(RoutesPage.steps.step4);
  const [isFullSize, setFillSize] = useState<boolean>(fromStep4 && true);
  const nextStep: string = fromStep4
    ? `${RoutesPage.project.page}${id}${RoutesPage.steps.step4}${RoutesPage.steps.instruction}${location.search}`
    : `${RoutesPage.project.page}${id}${RoutesPage.steps.step3}${RoutesPage.steps.transition}${location.search}`;
  let instruction: string = '';

  const handleComposedDownload = useCallback(async () => {
    const png = await getPng();
    if (png) {
      localStorage.setItem('plotInitial', png);
    }
    push(nextStep);
  }, [getPng]);

  const dataDiagram = useMemo(() => {
    if (projectData !== null) {
      return transformDataDiagram(projectData.data.competitors, projectData.data.oldCompetitionParameters);
    }
  }, [projectData]);

  if (projectData === null || isLoadingGetForm) {
    return <Loader />;
  }

  instruction = projectData.data.stepsNotes['2'];

  const navigateBack = (): void => {
    const previousPath = `${RoutesPage.project.page}${id}${RoutesPage.steps.step2}${RoutesPage.steps.table}${location.search}`;

    push(previousPath);
  };

  return (
    <Box sx={isFullSize ? stylesDiagramContainer().root : styles.container}>
      <Box sx={styles.diagramInnerContainer}>
        <Typography sx={styles.diagramInnerContainerText}>Nuvarande konkurrensdiagram</Typography>
        <Instruction stepNote={instruction} data={projectData.data} noteKey={2} />
      </Box>
      <DiagramCompetitors
        isFullSize={isFullSize}
        isStep4={fromStep4}
        competitors={projectData.data.competitors}
        dataDiagram={dataDiagram}
        setFillSize={setFillSize}
        ref={ref}/>
      {!isFullSize && (
        <>
          <MuiButton sx={styles.buttonDiagram} onClick={handleComposedDownload} type="submit" variant="outlined">
            <Typography sx={styles.buttonTextDiagram} variant="subtitle2">
              Fortsätt
            </Typography>
          </MuiButton>
        </>
      )}

      <NavigationBar  navigateBack={() => navigateBack()} navigateForward={() => handleComposedDownload()} />
    </Box>
  );
};

export default React.memo(DiagramContainer);

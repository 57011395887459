const Communicate = (): JSX.Element => {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56.0005 16H40.0005V32H56.0005V16Z" fill="white"/>
      <path d="M32 64H16V80H32V64Z" fill="white"/>
      <path d="M32 40H16V56H32V40Z" fill="white"/>
      <path d="M32 16H16V32H32V16Z" fill="white"/>
      <path d="M56.0005 49.68V40H40.0005V56H49.6805L56.0005 49.68Z" fill="white"/>
      <path
        d="M83.5195 45.16L78.8395 40.48C78.1995 39.84 77.1595 39.84 76.5195 40.48L72.9995 44L79.9995 51L83.5195
             47.48C84.1595 46.84 84.1595 45.8 83.5195 45.16Z"
        fill="white"/>
      <path d="M44.0005 73V80H51.0005L77.6805 53.32L70.6805 46.32L44.0005 73Z" fill="white"/>
      <path d="M79.9995 16H63.9995V32H79.9995V16Z" fill="white"/>
    </svg>
  );
};

export default Communicate;

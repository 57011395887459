import * as React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { Competitors } from 'shared/store/project/interface';
import { Box } from '@mui/system';
import { stylesHeaderTable } from 'components/WebTools/ProjectProcess/Steps/styles';
import InfoPopup from 'components/WebTools/ProjectProcess/Common/InfoPopup';

import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_3/Table/header/styles';

interface Props {
  dataHeader: Competitors[];
}

const TableHeader = (props: Props): JSX.Element => {
  const { dataHeader } = props;
  return (
    <TableRow sx={stylesHeaderTable.clearBorder}>
      <TableCell sx={stylesHeaderTable.tableCellHeader}>
        <Box sx={stylesHeaderTable.TableCellInner}>
          <Typography sx={stylesHeaderTable.tableCellHeader}>Parameter</Typography>
          <InfoPopup maxLength={25} stylePopup={styles.stylePopupFirstRow} />
        </Box>
      </TableCell>
      <TableCell sx={stylesHeaderTable.tableCellHeader}>
        <Box sx={stylesHeaderTable.TableCellInner}>
          <Typography sx={stylesHeaderTable.tableCellHeader}>Beskrivning (valfritt)</Typography>
          <InfoPopup maxLength={200} stylePopup={styles.stylePopupSecondRow} />
        </Box>
      </TableCell>
      {dataHeader &&
        dataHeader.map((column: any, index: number) => (
          <TableCell sx={{ ...stylesHeaderTable.tableCellHeader }} key={index} id={column.id}>
            <Typography sx={stylesHeaderTable.tableCellHeader}> {column.name}</Typography>
          </TableCell>
        ))}
    </TableRow>
  );
};

export default TableHeader;

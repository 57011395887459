import * as React from 'react';
import { Box } from '@mui/system';
import { styles } from 'components/WebTools/MainForm/ProjectForm/Forms/styles';
import { styles as stylesDetails } from 'components/WebTools/MainForm/ProjectForm/Forms/Details/styles';
import IconBlock from 'components/WebTools/MainForm/ProjectForm/Forms/IconBlock';
import { FormGroup, Typography } from '@mui/material';
import MuiCheckbox from 'shared/components/UI/MuiChecbox';
import DownloadBox from 'components/WebTools/MainForm/ProjectForm/Uploader/DownloadBox';
import DateI from 'shared/components/Icons/DateI';
import DateBox from 'components/WebTools/MainForm/ProjectForm/DateBox';
import Survays from 'shared/components/Icons/Survays';
import SimilarProject from 'shared/components/Icons/SimilarProject';

import { styles as stylesPlans } from './styles';
import Schedule from 'shared/components/Icons/Schedule';
import { Project, RbPlans } from 'shared/store/project/interface';
import { useFormikContext } from 'formik';
import FormikField from 'shared/components/UI/FormikField';

export interface Props {
  dataForm: Project;
}

const acceptFile = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

const InformationPlans = () => {
  const { values, touched, errors, handleChange } = useFormikContext<Project>();
  const stateValues: RbPlans = values.rbPlans;
  return (
    <Box sx={styles.General}>
      <Box sx={styles.GeneralWrapper}>
        <Box sx={stylesDetails.formItem}>
          <IconBlock title="Fyll i de sju datumen enligt RB-plan" icon={<DateI />} />
          <DateBox
            title="Köp av mark"
            name="rbPlans.dates.purchase"
            value={stateValues?.dates?.purchase ?? ''}
            isError={touched.rbPlans?.dates?.purchase && !!errors.rbPlans?.dates?.purchase}/>
          <DateBox
            title="Projektering"
            name="rbPlans.dates.design"
            value={stateValues?.dates?.design ?? ''}
            isError={touched.rbPlans?.dates?.design && !!errors.rbPlans?.dates?.design}/>
          <DateBox
            title="Säljstart"
            name="rbPlans.dates.salesStart"
            value={stateValues?.dates?.salesStart ?? ''}
            isError={touched.rbPlans?.dates?.salesStart && !!errors.rbPlans?.dates?.salesStart}/>
          <DateBox
            title="Inredning & tillval"
            name="rbPlans.dates.preselection"
            value={stateValues?.dates?.preselection ?? ''}
            isError={touched.rbPlans?.dates?.preselection && !!errors.rbPlans?.dates?.preselection}/>
          <DateBox
            title="Byggstart"
            name="rbPlans.dates.constructionStart"
            value={stateValues?.dates?.constructionStart ?? ''}
            isError={touched.rbPlans?.dates?.constructionStart && !!errors.rbPlans?.dates?.constructionStart}/>
          <DateBox
            title="Inflytt"
            name="rbPlans.dates.movedIn"
            value={stateValues?.dates?.movedIn ?? ''}
            isError={touched.rbPlans?.dates?.movedIn && !!errors.rbPlans?.dates?.movedIn}/>
          <Typography sx={{ color: '#9BA1AB' }} variant="subtitle2">
            *Alla datumfält måste fyllas i eller lämnas tomma
          </Typography>
        </Box>
        <Box sx={styles.General}>
          <Box sx={stylesDetails.formItem}>
            <IconBlock title="Tillgängliga undersökningar" icon={<Survays />} />
            <FormGroup sx={stylesDetails.formControl}>
              <Box sx={stylesPlans.WrapperBox}>
                <Box sx={stylesPlans.marginBox}>
                  <MuiCheckbox
                    name="rbPlans.availableSurveys.environment"
                    onChange={handleChange}
                    isChecked={stateValues.availableSurveys.environment}
                    label="Större omvärldsbevakning"/>
                </Box>
                <Box sx={stylesPlans.marginBox}>
                  <MuiCheckbox
                    name="rbPlans.availableSurveys.location"
                    onChange={handleChange}
                    isChecked={stateValues.availableSurveys.location}
                    label="Orts- och projektanalys"/>
                </Box>
                <Box sx={stylesPlans.marginBox}>
                  <MuiCheckbox
                    name="rbPlans.availableSurveys.quarterlyAnalysis"
                    onChange={handleChange}
                    isChecked={stateValues.availableSurveys.quarterlyAnalysis}
                    label="Kvartalsanalys"/>
                </Box>
                <Box sx={stylesPlans.marginBox}>
                  <MuiCheckbox
                    name="rbPlans.availableSurveys.targetGroup"
                    onChange={handleChange}
                    isChecked={stateValues.availableSurveys.targetGroup}
                    label="Preliminär målgruppsanalys"/>
                </Box>
              </Box>
            </FormGroup>
          </Box>

          <Box sx={stylesDetails.formItem}>
            <IconBlock title="Tidigare liknande projekt" icon={<SimilarProject />} />
            <Box sx={stylesPlans.marginBox}>
              <FormikField
                maxLength={100}
                type="text"
                name="rbPlans.previousSimilarProjects[0]"
                value={stateValues.previousSimilarProjects[0]}
                onChange={handleChange}
                placeholder="Projekt 1"/>
            </Box>
            <Box sx={stylesPlans.marginBox}>
              <FormikField
                maxLength={100}
                type="text"
                name="rbPlans.previousSimilarProjects[1]"
                value={stateValues.previousSimilarProjects[1]}
                onChange={handleChange}
                placeholder="Projekt 2"/>
            </Box>
            <Box sx={stylesPlans.marginBox}>
              <FormikField
                maxLength={100}
                type="text"
                name="rbPlans.previousSimilarProjects[2]"
                value={stateValues.previousSimilarProjects[2]}
                onChange={handleChange}
                placeholder="Projekt 3"/>
            </Box>
            <Box sx={stylesPlans.marginBox}>
              <FormikField
                maxLength={100}
                type="text"
                name="rbPlans.previousSimilarProjects[3]"
                value={stateValues.previousSimilarProjects[3]}
                onChange={handleChange}
                placeholder="Projekt 4"/>
            </Box>
          </Box>
        </Box>
        <Box sx={stylesDetails.formItem}>
          <DownloadBox accept={acceptFile} name="files.schedule" nameField="schedule" title="Tidsplan" icon={<Schedule />} />
        </Box>
      </Box>
    </Box>
  );
};

export default InformationPlans;

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: colors.white,
    gap: '120px',
    mb: 3,
  } as SystemCssProperties,

  Icon: {
    marginRight: '12px',
  } as SystemCssProperties,

  modalButtonsItem: {
    '&:hover': {
      background: 'none',
    },

    justifyContent: 'space-between',
    gap: '11px',
    alignItems: 'center',
  } as SystemCssProperties,

  modalFirstBtn: {
    marginRight: '40px',
  } as SystemCssProperties,
};

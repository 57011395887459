import {SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  tableContainer: {
    boxShadow: 'none',
    height: '720px',
    maxHeight: '720px',
    marginBottom: '20px',
    background: colors.lightBlueDark,
    position: 'relative',
  } as SystemCssProperties,

  tableRow: {
    display: 'flex',
    background: colors.lightBlue,
  } as SystemCssProperties,

  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 9999,
  } as SystemCssProperties,

  tableBody: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  } as SystemCssProperties,

  td: {
    display: 'flex',
    width: '25%',
    color: colors.white,
    fontWeight: 500,
    fontSize: 16,
    border: 'none',
    paddingLeft: '24px',
  } as SystemCssProperties,

  tdBody: {
    display: 'flex',
    width: '25%',
    color: colors.white,
    fontWeight: 400,
    fontSize: 16,
    borderBottom: 'none',
    paddingLeft: '24px',
  } as SystemCssProperties,
};

export const stylesRow = (isOpenCollapse: number | boolean, id: number) => {
  return {
    tableBodyRow: {
      position: 'relative',
      display: 'flex',
      background: isOpenCollapse === id ? colors.selectedLink : colors.lightBlueDark,
      cursor: 'pointer',
      '& > td:nth-of-type(3)': {
        width: '90px',
      },
      '& > td:nth-of-type(4)': {
        width: '382px',
        margin: '16px 40px 16px 16px',
        padding: '0',
      },
      '&:hover': {
        background: colors.selectedLink,
        '.deleteIcon': {
          display: 'flex',
        },
      },
      '&::after': {
        content: '" "',
        position: 'absolute',
        display: 'block',
        width: '98%',
        left: '50%',
        marginRight: '-50% ',
        transform: 'translate(-50%, -50%)',
        height: '1px',
        background: colors.selectedLink,
        borderRadius: '4px',
      },
    } as SystemCssProperties,
  };
};

import { SystemCssProperties } from '@mui/system';

export const styles = {
  formItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '416px',
    mr: '56px',
    '&:last-child': {
      mr: 0,
    },
  } as SystemCssProperties,

  textAreaItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '416px',
    mr: '56px',
    '&:last-child': {
      mr: 0,
      mt: '60px',
    },
  } as SystemCssProperties,

  formControl: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as SystemCssProperties,

  marginBox: {
    marginBottom: '22px',
  } as SystemCssProperties,
};

import * as React from 'react';
import { ClapSpinner } from 'react-spinners-kit';
import { Box } from '@mui/system';
import { styles } from './styles';

import colors from 'styles/_Variables';

const Loader = (): JSX.Element => {
  return (
    <Box sx={styles.root}>
      <ClapSpinner frontColor={colors.white} size={40} />
    </Box>
  );
};

export default Loader;

import React from 'react';

const Location = (): JSX.Element => {
  return (
    <svg width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.58466 26.9552C1.91618 15.8472 0.499667 14.7075 0.499667 10.6254C0.484564
            9.28619 0.735295 7.95728 1.23734 6.71563C1.73939 5.47398 2.48277 4.34427 3.42445
            3.39191C4.36613 2.43956 5.48739 1.68348 6.72329 1.16746C7.9592 0.651444 9.2852
            0.385742 10.6245 0.385742C11.9638 0.385742 13.2898 0.651444 14.5257 1.16746C15.7616
             1.68348 16.8829 2.43956 17.8246 3.39191C18.7662 4.34427 19.5096 5.47398 20.0117 6.71563C20.5137
             7.95728 20.7645 9.28619 20.7493 10.6254C20.7493 14.7075 19.3328 15.8472 11.6644 26.9552C11.5478
             27.1231 11.3924 27.2603 11.2113 27.355C11.0302 27.4497 10.8289 27.4992 10.6245 27.4992C10.4201
             27.4992 10.2188 27.4497 10.0377 27.355C9.85665 27.2603 9.7012 27.1231 9.58466 26.9552ZM10.6252
             14.844C11.4595 14.8438 12.275 14.5962 12.9685 14.1324C13.6621 13.6687 14.2026 13.0098 14.5217
             12.2389C14.8408 11.468 14.9242 10.6199 14.7613 9.80161C14.5984 8.98336 14.1965 8.23179 13.6064
             7.64194C13.0164 7.05208 12.2647 6.65042 11.4464 6.48775C10.6281 6.32508 9.77995 6.40869 9.00917
              6.72803C8.23839 7.04737 7.5796 7.58808 7.11611 8.2818C6.65262 8.97552 6.40523 9.7911 6.40523
              10.6254C6.40523 11.1795 6.51439 11.7282 6.72648 12.2401C6.93857 12.752 7.24942 13.2171 7.6413
               13.6089C8.03317 14.0006 8.49838 14.3114 9.01036 14.5233C9.52233 14.7352 10.071 14.8442 10.6252 14.844Z"
        fill="#F54F8F"/>
    </svg>
  );
};

export default Location;

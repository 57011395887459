import React from 'react';

const ProjectName = (): JSX.Element => {
  return (
    <svg width="27" height="27" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 14C0.5 14.8284 1.17157 15.5 2 15.5H11C11.8284 15.5 12.5 14.8284 12.5 14V2C12.5 1.17157
            11.8284 0.5 11 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V14ZM0.5 26C0.5 26.8284 1.17157 27.5 2
            27.5H11C11.8284 27.5 12.5 26.8284 12.5 26V20C12.5 19.1716 11.8284 18.5 11 18.5H2C1.1715718.5 0.5 19.1716
            0.5 20V26ZM15.5 26C15.5 26.8284 16.1716 27.5 17 27.5H26C26.8284 27.5 27.5 26.8284 27.5 26V14C27.5 13.1716
             26.8284 12.5 26 12.5H17C16.1716 12.5 15.5 13.1716 15.5 14V26ZM17 0.5C16.1716 0.5 15.5 1.17157 15.5
              2V8C15.5 8.82843 16.1716 9.5 17 9.5H26C26.8284 9.5 27.5 8.82843 27.5 8V2C27.5 1.17157 26.8284 0.5 26 0.5H17Z"
        fill="#46B9EB"/>
    </svg>
  );
};

export default ProjectName;

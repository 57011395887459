import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Settings, SettingsState } from '../../interface';

const initialState: SettingsState = {
  isLoading: false,
  settings: {
    timers: {},
  },
  error: {
    message: [],
    stackErrors: '',
  },
};

const getSettings = createSlice({
  name: 'getSettings',
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
      state.error = initialState.error;
    },
    end(state, action: PayloadAction<Settings>) {
      state.isLoading = false;
      state.settings = action.payload;
    },
    error(state, action: any) {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
    clear() {
      return initialState;
    },
  },
});

export const getSettingsActions = getSettings.actions;
export default getSettings.reducer;

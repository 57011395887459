import * as React from 'react';
import { Typography } from '@mui/material';
import MuiDatePicker from 'shared/components/UI/MuiDatePicker';
import { Box } from '@mui/system';

import { styles } from './styles';
import { useFormikContext } from 'formik';
import { Project } from 'shared/store/project/interface';

export interface Props {
  stateErrors?: any;
  title: string;
  name: string;
  value: string | Date;
  isError?: boolean | undefined;
}

const DateBox = (props: Props): JSX.Element => {
  const { setFieldValue } = useFormikContext<Project>();
  const { title, name, value, isError } = props;

  return (
    <Box sx={styles.root}>
      <Typography variant="subtitle2" sx={styles.textTitle}>
        {title}
      </Typography>
      <MuiDatePicker value={value} isError={isError} handleDateChange={setFieldValue} name={name} label="Start" />
    </Box>
  );
};

export default DateBox;

import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import LinkIcon from '@mui/icons-material/Link';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { Archive } from 'shared/store/archive/interface';
import { saveAs } from 'file-saver';

import { getActionsStyles, styles, stylesButton } from './styles';
import { stylesArchive } from 'components/WebTools/Archive/styles';

interface Props {
  onHandleOpenPopup: Dispatch<SetStateAction<boolean>>;
  onHandleCopyProject: () => void;
  onHandleCopyLinkPdf: (sharingLink: string) => Promise<void>;
  archive: Archive;
}

const Actions = (props: Props): JSX.Element => {
  const { onHandleOpenPopup, onHandleCopyProject, onHandleCopyLinkPdf, archive } = props;
  const reportUrl = archive?.reportUrl;

  const onDownload = () => {
    saveAs(reportUrl);
  };

  return (
    <Box sx={getActionsStyles(!!reportUrl)}>
      {reportUrl && (
        <>
          <Box sx={stylesButton(254)} onClick={onDownload}>
            <DownloadIcon sx={styles.icon} />
            <Typography sx={stylesArchive.icon}>Ladda ner projektrapport</Typography>
          </Box>
          <Box sx={stylesButton(214)} onClick={() => onHandleCopyLinkPdf(reportUrl ?? '')}>
            <LinkIcon sx={styles.icon} />
            <Typography sx={stylesArchive.icon}>Kopiera projektlänk</Typography>
          </Box>
        </>
      )}
      <Box sx={stylesButton(196)} onClick={onHandleCopyProject}>
        <ContentCopyIcon sx={styles.icon} />
        <Typography sx={stylesArchive.icon}>Kopiera projekt</Typography>
      </Box>
      <Box sx={stylesButton(196)} onClick={() => onHandleOpenPopup(true)}>
        <DeleteIcon sx={styles.icon} />
        <Typography sx={stylesArchive.icon}>Ta bort projekt</Typography>
      </Box>
    </Box>
  );
};

export default Actions;

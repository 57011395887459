import React from 'react';
import MuiButton from 'shared/components/UI/MuiButton';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';
import {RoutesPage} from 'shared/routes';
import {Box, Typography} from '@mui/material';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_5/Instruction/styles';
import Loader from 'shared/components/Loader';
import RadarIcon from '@mui/icons-material/Radar';
import {useGetProject} from 'shared/hooks/useGetProjects';
import NavigationBar from 'shared/components/NavigationBar';

const InstructionStep5 = (): JSX.Element => {
  let {id} = useParams<{ id: string }>();
  id = id ?? localStorage.getItem('projectId');
  const currentRoute = useRouteMatch();
  const {push} = useHistory();
  const pathUrl = `${RoutesPage.project.page}${id}${RoutesPage.steps.step6}${RoutesPage.steps.transition}${location.search}`;
  const pathPreviousStepUrl = `${RoutesPage.project.page}${id}${RoutesPage.steps.step5}${RoutesPage.steps.table}${location.search}`;
  const pathUrlDiagram = `${RoutesPage.project.page}${id}${RoutesPage.steps.step5}${RoutesPage.steps.diagram}${location.search}`;
  const {projectData} = useGetProject();
  let instruction: any = null;

  if (projectData === null) {
    return (
      <Box sx={styles.Loader}>
        <Loader/>
      </Box>
    );
  }
  instruction = projectData.data.stepsNotes['5'];

  const previousStep = () => {
    push(pathPreviousStepUrl);
  };

  const nextStep = () => {
    push(pathUrl);
  };

  return (
    <Box sx={styles.container}>
      <Instruction stepNote={instruction} data={projectData.data} noteKey={5}/>
      <Box sx={styles.instructionStep}>
        <RadarIcon sx={styles.Icon}/>
        <Typography variant="subtitle1" component="h2" color="inherit" sx={styles.instructionTypo}>
          Utvärdera arbetet. Kanske vill ni gå tillbaka och <br/>
          komplettera eller göra om tidigare faser?
        </Typography>
        <Box>
          <MuiButton
            onClick={() => push(pathUrlDiagram, {from: currentRoute.path})}
            type="button"
            variant="contained"
            sx={{
              ...styles.NavLink,
              ...styles.BtnWhite,
            }}
          >
            Granska ert resultat
          </MuiButton>
          <MuiButton onClick={previousStep} type="button" variant="contained" sx={styles.btnParameter}>
            Justera parametervärden
          </MuiButton>
          <MuiButton onClick={nextStep} type="button" variant="contained" sx={styles.NavLink}>
            Fortsätt
          </MuiButton>
        </Box>
      </Box>

      <NavigationBar navigateBack={() => previousStep()} navigateForward={() => nextStep()} />
    </Box>
  );
};

export default InstructionStep5;

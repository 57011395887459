import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { archiveService } from 'core/services/archive';
import { DeleteArchivePayload } from 'shared/store/archive/interface';

interface Props {
  payload: DeleteArchivePayload;
}

export function* deleteArchive(props: Props): SagaIterator {
  const { id } = props.payload;
  try {
    yield call(archiveService.deleteArchive, `projects/${id}`);
    yield put(actions.getArchiveActions.start());
    yield put(actions.deleteArchiveActions.end());
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.getArchiveActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* deleteArchiveSaga(): SagaIterator {
  yield takeLatest(actions.deleteArchiveActions.start, deleteArchive);
}

import React from 'react';
import {Box} from '@mui/system';
import {styles} from './styles';

interface Props {
  counter: number;
}

const Ellipse = (props: Props): JSX.Element => {
  const {counter} = props;
  return <Box sx={styles.ellipse}>{counter}</Box>;
};

export default Ellipse;

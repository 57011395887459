import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {DragDropContext} from 'react-beautiful-dnd';
import {v4 as uuidv4} from 'uuid';
import {Box, Typography} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {useGetProject} from 'shared/hooks/useGetProjects';
import Loader from 'shared/components/Loader';
import Sea from 'shared/components/Icons/Sea/hot';
import MuiButton from 'shared/components/UI/MuiButton';

import TableMyCompany from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/Table';
import CompareValuesContainer from 'components/WebTools/ProjectProcess/Steps/Step_4/CompareValuesContainer';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/styles';

import {CompareParametersId, Roles} from 'shared/constants/enum';
import {
  ColumnI,
  initialData,
  InitialDataI,
  ItemI,
} from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/initialStateData';

import {RoutesPage} from 'shared/routes';
import {actions} from 'shared/store/project';
import {actions as actionsSnackbar} from 'shared/store/snackbar';

import {onDragEnd, onDragStart} from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/dragOnDrop';
import {OldCompetitionParameters, ProjectWithoutFiles} from 'shared/store/project/interface';
import {actions as actionsPopup} from 'shared/store/popup';
import {useCheckPermissions} from 'shared/hooks/useCheckPermissions';
import NavigationBar from 'shared/components/NavigationBar';
import {instructions} from './instructions';

const TableSection = (): JSX.Element => {
  const {push} = useHistory();
  const currentRoute = useRouteMatch();
  const {projectData, isLoadingGetForm} = useGetProject();
  let {id} = useParams<{ id: string }>();
  id = id ?? localStorage.getItem('projectId');
  const dispatch = useDispatch();

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const oldCompetitionParameters = projectData!.data.oldCompetitionParameters ?? [];
  const newCompetitionParameters = projectData!.data.newCompetitionParameters ?? [];

  useEffect(() => {
    if (projectData!.data.oldCompetitionParameters.length === 0 || projectData!.data.newCompetitionParameters.length === 0) {
      dispatch(
        actionsSnackbar.snackbarActions.add({
          type: 'error',
          text: 'För att fortsätta med dra-och-släpp-åtgärder måste parametrarna i steg 2 och 3 fyllas i',
        }),
      );
    }
  }, []);

  const oldCompetitionParametersWithId = oldCompetitionParameters.map((oldParam) => ({
    ...oldParam,
    id: uuidv4(),
    calculateValue: oldParam.values[0] ?? 0,
  }));

  const [boards, setBoards] = useState<InitialDataI[]>(initialData(oldCompetitionParametersWithId, newCompetitionParameters));

  const [currentBoard, setCurrentBoard] = useState<null | ColumnI>(null);
  const [currentItem, setCurrentItem] = useState<null | ItemI>(null);

  const pathUrl = `${RoutesPage.project.page}${id}${RoutesPage.steps.step5}${RoutesPage.steps.transition}${location.search}`;
  const pathUrlDiagram = `${RoutesPage.project.page}${id}${RoutesPage.steps.step4}${RoutesPage.steps.diagram}${location.search}`;

  const updateData = () => {
    const updateBoards = boards.filter((board: InitialDataI) => board.id !== CompareParametersId.Kraeera);
    const newUpdateBoards = updateBoards.reduce((accum: any, prevBoard) => {
      accum.push(...prevBoard.items);
      return accum;
    }, []);

    const filteredBoardWithoutId = newUpdateBoards.map((board) => {
      const {id: idBoard, calculateValue, ...rest} = board;
      return {
        ...rest,
      };
    });

    const newData: ProjectWithoutFiles = {...projectData!.data};
    delete newData.files;

    const currentOldCompetitionParameters = newData.oldCompetitionParameters.map((item: OldCompetitionParameters) => {
      const found = filteredBoardWithoutId.find((c) => c.name === item.name);
      if (item.name === found.name) {
        return {
          ...item,
          quadrant: found.quadrant,
        };
      }
      return item;
    });

    const updateProjectWithoutFiles = {
      ...newData,
      oldCompetitionParameters: currentOldCompetitionParameters.map((item: OldCompetitionParameters) => {
        if (item.quadrant === 'ROOT') {
          return {
            ...item,
            quadrant: null,
          };
        }
        return item;
      }),
    };

    dispatch(actions.updateProjectActions.start({id, updateProjectWithoutFiles}));
    dispatch(actionsPopup.popupActions.toggle(false));
    localStorage.setItem('isUpdateForStep4', 'true');
    push(pathUrl);
  };

  const navigateBack = (): void => {
    const previousPath = `${RoutesPage.project.page}${id}${RoutesPage.steps.step4}${RoutesPage.steps.video}${location.search}`;

    push(previousPath);
  };

  if (projectData === null || isLoadingGetForm) {
    return <Loader/>;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.additionalInfoContainer}>
        <DragDropContext
          onDragStart={(result) => onDragStart(result, boards, setCurrentItem, setCurrentBoard)}
          onDragEnd={(result) => onDragEnd(result, boards, setBoards, currentBoard, currentItem, dispatch)}
        >
          <Box sx={{...styles.additionalInfoLeft}} style={{pointerEvents: isMU ? 'none' : undefined}}>
            <TableMyCompany boards={boards} rowsLength={9}/>
          </Box>
          <Box sx={styles.center}>
            <ArrowForwardIcon sx={styles.centerIcon}/>
            <Typography variant="subtitle1" color="inherit">
              Drag & drop
            </Typography>
          </Box>
          <Box style={{pointerEvents: isMU ? 'none' : undefined}}>
            <CompareValuesContainer boards={boards}/>
          </Box>
          <Box sx={styles.footer}>
            <Box>
              <Box sx={ styles.instructions }>
                {
                  instructions.map((item, index) =>
                    <Typography variant="subtitle2" component="p" color="inherit">{ index + 1 }. { item }</Typography>)
                }
              </Box>
              <Typography variant="subtitle1" component="p" sx={ styles.stepTime }>
                Tidsåtgång: ca 45 minuter
              </Typography>
              <MuiButton type="button" onClick={updateData} variant="contained" sx={styles.navLink}>
                Fortsätt
              </MuiButton>
            </Box>
            <Box sx={styles.footerRight}>
              <Typography sx={styles.footerRightTypo}>
                *Kreera kvadranten kan inte innehålla konkurrensparametrar från tabellen
              </Typography>
            </Box>
          </Box>
        </DragDropContext>
      </Box>

      <NavigationBar navigateBack={() => navigateBack()} navigateForward={() => updateData()} />
    </Box>
  );
};

export default TableSection;

import colors from 'styles/_Variables';

export const stylesDraggable = (snapshot) => {
  return {
    draggableElement: {
      background: snapshot.isDragging ? colors.grey : '',
      border: snapshot.isDragging ? `1px solid ${colors.darkGrey}` : colors.darkGrey,
    },
  };
};

import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { formService } from 'core/services/form';
import { GetSharedProjectPayload } from 'shared/store/project/interface';

interface Props {
  payload: GetSharedProjectPayload;
}

export function* getSharedProject(props: Props): SagaIterator {
  const { token } = props.payload;

  if (location.pathname === '/reset-password') {
    return;
  }

  try {
    const response = yield call(formService.getSharedProject, `projects/shared/${token}`);
    yield put(actions.getSharedProjectActions.end(response));
    yield put(actions.getSharedProjectActions.finish());
    yield put(actions.getProjectActions.end(response));
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.getSharedProjectActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* getSharedProjectSaga(): SagaIterator {
  yield takeLatest(actions.getSharedProjectActions.start, getSharedProject);
}

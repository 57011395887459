import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  lists: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    mt: 3,
  } as SystemCssProperties,

  listsUl: {
    padding: 0,
  } as SystemCssProperties,

  listItem: {
    background: '#CCDEFF',
    borderRadius: '4px',
    maxWidth: '310px',
    mb: 2,
  } as SystemCssProperties,

  listItemText: {
    width: '309px',
    '& .MuiTypography-root': {
      color: colors.accentBlue,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  } as SystemCssProperties,

  closeIcon: {
    cursor: 'pointer',
    ml: 3,
  } as SystemCssProperties,

  listItemIcon: {
    minWidth: '18px',
    marginRight: '10px',
    color: '#152E5B',
  } as SystemCssProperties,
};

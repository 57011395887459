import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const stylesProps = (maxWidth: number) => {
  return {
    TableMuiField: {
      width: maxWidth,
      height: '100%',
      '&:focus': {
        outline: 'none',
        border: 'none',
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '9px 16px',
      },
      '& .MuiFormHelperText-root': {
        position: 'initial',
        margin: '0',
        wordWrap: 'break-word',
      },
    } as SystemCssProperties,

    TableCellStateOutFocus: {
      padding: '10px 16px',
      wordWrap: 'break-word',
      maxWidth,
      minWidth: maxWidth,
    } as SystemCssProperties,

    TableCellFocus: {
      padding: '2px',
      width: maxWidth,
    } as SystemCssProperties,
  };
};

export const styles = {
  NavLink: {
    '&  a': {
      background: 'inherit',
      width: '100%',
      textDecoration: 'none',
      color: colors.white,
      textTransform: 'none',
    },
  } as SystemCssProperties,

  TableBoxContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  } as SystemCssProperties,

  Loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as SystemCssProperties,

  Container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  } as SystemCssProperties,

  Title: {
    display: 'flex',
    justifyContent: 'space-between',
  } as SystemCssProperties,

  TableRow: {
    '&:last-child td, &:last-child th': { border: 0 },
    height: '40px',
  } as SystemCssProperties,

  CellBorder: {
    border: '1px solid #D6DAE1',
  } as SystemCssProperties,

  Content: {
    display: 'flex',
    gap: '66px',
  } as SystemCssProperties,

  Instructions: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginTop: '24px',
  } as SystemCssProperties,

  Instruction: {
    color: colors.white,
    fontWeight: 400,
    fontSize: '16px',
  } as SystemCssProperties,

  StepTime: {
    fontFamily: 'Poppins',
    marginTop: '24px',
    marginBottom: '40px',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '16px',
    color: colors.white,
  } as SystemCssProperties,
};

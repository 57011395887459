import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';
import { CSSProperties } from 'react';

export const styles = {
  wrapperColorsCompetitors: {
    display: 'flex',
    alignItems: 'center',
    mb: 2,
  } as SystemCssProperties,

  buttonTextDiagram: {
    color: colors.white,
  } as SystemCssProperties,

  diagramInnerContainerText: {
    fontSize: '24px',
    color: colors.white,
    fontWeight: 600,
  } as SystemCssProperties,

  wrapperPopup: {
    position: 'relative',
    cursor: 'pointer',
    wordWrap: 'break-word',
    '&:hover': {
      '& > .showPopupKey': {
        display: 'block',
      },
    },
  } as SystemCssProperties,

  closeSvg: {
    fill: colors.accentBlue,
    marginRight: '13px',
  } as SystemCssProperties,

  closeButton: {
    fontSize: '16px',
    color: colors.accentBlue,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px',
  } as SystemCssProperties,
};

export const stylesProps = (colorsCompetitors: string[], index: number, isFullSize?: boolean) => {
  return {
    stylesCircle: {
      flexShrink: 0,
      width: isFullSize ? '16px' : '8px',
      height: isFullSize ? '16px' : '8px',
      background: colorsCompetitors[index],
      borderRadius: '50%',
      marginRight: '8px',
    } as SystemCssProperties,
  };
};

export const stylesDiagram = (isFullWidth: boolean) => {
  return {
    wrapperDiagram: {
      width: '100%',
      maxWidth: isFullWidth ? '85%' : '897px',
      maxHeight: isFullWidth ? '100%' : '478px',
      height: '100%',
      minHeight: '478px',
      position: 'relative',
      top: isFullWidth ? '-60px' : '0',
      zIndex: 100,
      padding: '24px 24px',
      paddingTop: '64px',
      borderRadius: '8px',
      background: colors.white,
      marginBottom: {
        xl: '0',
      },
      '& .recharts-cartesian-axis-ticks line': {
        opacity: 0,
      },
      '& svg': {
        overflow: 'visible',
      },
    } as SystemCssProperties,

    popupCompetitor: {
      display: 'none',
      width: isFullWidth ? '150px' : '275px',
      position: 'absolute',
      top: '25px',
      left: '-15px',
      fontSize: '14px',
      padding: '8px 12px',
      lineHeight: '14px',
      zIndex: 100,
      color: colors.darkMoreBlue,
      background: colors.lightGrey,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
    } as SystemCssProperties,

    wrapperStyleLegend: {
      position: 'absolute',
      top: 0,
      right: '-150px',
    } as CSSProperties,

    colorText: {
      width: '128px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: colors.accentBlue,
      fontSize: isFullWidth ? '16px' : '14px',
    } as SystemCssProperties,

    titleDiagram: {
      fontSize: '24px',
      fontWeight: 600,
      textAlign: 'center',
      marginBottom: '55px',
      color: colors.accentBlue,
    } as SystemCssProperties,

    iconFullWidth: {
      cursor: 'pointer',
      position: isFullWidth ? 'fixed' : 'absolute',
      zIndex: '999',
      top: isFullWidth && '27px',
      bottom: isFullWidth ? '' : '30px',
      right: isFullWidth ? '46px' : '48px',
    } as SystemCssProperties,
  };
};

import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {actions as actionAuth} from 'shared/store/auth';
import {clearTokens} from 'shared/helpers';
import {RoutesPage} from 'shared/routes';
import jwt_decode from 'jwt-decode';
import {Roles} from 'shared/constants/enum';

type ReturnedUseLogout = () => void;
export const useLogout = (): ReturnedUseLogout => {
  const history = useHistory();
  const dispatch = useDispatch();

  return () => {
    dispatch(actionAuth.loginActions.reset());
    if (localStorage.getItem('accessToken')) {
      const decoded: any = jwt_decode(localStorage.getItem('accessToken') as string);
      if (decoded.roles[0] === Roles.ADMIN) {
        history.push(`${RoutesPage.admin}${RoutesPage.login}`);
      } else {
        history.push(RoutesPage.login);
      }
    }
    clearTokens();
  };
};

export const videoInfo: Array<{ headline: string; paragraphs: string[] }> = [
  {
    headline: 'Trender',
    paragraphs: [
      `Vilka irreversabla trender finns på marknaden som kommer påverka branschen.
      Trender är drivkrafter i branschen. Kan vara såväl nya som gamla, lokala som globala.
      Exempel på trender är digitalisering och plattformsekonomi.`,
    ],
  },
  {
    headline: 'Kundresan',
    paragraphs: [
      'Är kundresan en bra upplevelse?',
      'Kan vi skapa konkurrensfördelar och bli mer relevanta?',
      'Vilka faser/touch points går kunden igenom?',
      'Är kundresan samma för alla målgrupper eller konsumentsegment?',
    ],
  },
  {
    headline: 'Pinor & plågor',
    paragraphs: [
      'Vad är det som brukar gå fel? Vad kan vi avhjälpa?',
      `Hur kan vi hjälpa att realisera de fördelar och vinster som kunden söker i den produkt som ni eller era
      konkurrenter tillhandahåller. Kundvärdet är i fokus här.`,
    ],
  },
  {
    headline: 'Utöka marknaden',
    paragraphs: [
      'Hur kan vi locka in kunder från närliggande branscher?',
      'Exempel: Lågprisflyg, mobilkameror, vinbolag, golfklubbor.',
    ],
  },
];

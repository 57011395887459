import * as React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { stylesArchive } from './styles';
import HeaderWeb from 'shared/components/HeaderWeb';
import colors from 'styles/_Variables';
import LayoutContainer from 'components/WebTools/MainForm/LayoutContainer';
import TableVideos from './TableVideos';

const ListVideos = (): JSX.Element => {
  return (
    <LayoutContainer>
      <HeaderWeb background={colors.accentBlue} isStartScreen={false} />
      <Box sx={stylesArchive.titleArchive}>
        <Typography variant="h1">Lista över videor</Typography>
      </Box>
      <TableVideos />
    </LayoutContainer>
  );
};

export default ListVideos;

import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { archiveService } from 'core/services/archive';

export function* getArchive(): SagaIterator {
  try {
    const response = yield call(archiveService.getArchive, 'projects', {
      params: {
        limit: 999,
        offset: 0,
      },
    });
    yield put(actions.getArchiveActions.end(response));
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.getArchiveActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* getArchiveSaga(): SagaIterator {
  yield takeLatest(actions.getArchiveActions.start, getArchive);
}

import React from 'react';
import { TextField } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

export interface Props {
  formik?: any;
  type?: string;
  name?: string;
  label?: string;
  value?: string | number;
  placeholder?: string;
  onChange?: (arg) => void;
  onFocus?: (arg) => void;
  error?: boolean;
  errorText?: string;
  rows?: number;
  sx?: SxProps;
  maxLength?: number;
  disabled?: boolean;
}

const MuiTextarea = (props: Props): JSX.Element => {
  const { maxLength } = props;
  return (
    <TextField
      {...props}
      InputLabelProps={{
        style: { color: '#000' },
      }}
      inputProps={{ maxLength: maxLength ?? 200 }}
      multiline/>
  );
};

export default MuiTextarea;

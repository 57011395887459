import React from 'react';
import MuiButton from 'shared/components/UI/MuiButton';
import Communicate from 'shared/components/Icons/Communicate';
import {RoutesPage} from 'shared/routes';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';
import {Box, Typography} from '@mui/material';
import { Link, useHistory, useParams } from 'react-router-dom';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_1/Instruction/styles';
import Loader from 'shared/components/Loader';
import {useGetProject} from 'shared/hooks/useGetProjects';
import NavigationBar from 'shared/components/NavigationBar';

const InstructionStep1 = (): JSX.Element => {
  let { id } = useParams<{ id: string }>();
  id = id ?? localStorage.getItem('projectId');
  const pathUrl = `${RoutesPage.project.page}${id}${RoutesPage.steps.step2}${RoutesPage.steps.transition}${location.search}`;
  const { projectData } = useGetProject();
  const history = useHistory();
  let instruction: any = null;

  if (projectData === null) {
    return (
      <Box sx={styles.Loader}>
        <Loader />
      </Box>
    );
  }
  instruction = projectData.data.stepsNotes['1'];

  const navigateBack = (): void => {
    const previousPath = `${RoutesPage.project.page}${id}${RoutesPage.steps.step1}${RoutesPage.steps.video}${location.search}`;

    history.push(previousPath);
  };

  return (
    <Box sx={styles.container}>
      <Instruction stepNote={instruction} data={projectData.data} noteKey={1} />
      <Box sx={styles.instructionStep}>
        <Communicate />
        <Typography sx={styles.instructionStepTypo} variant="subtitle1" component="h2" color="inherit">
          Nu kan ni ta fram spelplan, hattar och pennor etc.
        </Typography>
        <MuiButton type="button" variant="contained" sx={styles.NavLink}>
          <Link to={pathUrl}>Fortsätt</Link>
        </MuiButton>
      </Box>

      <NavigationBar navigateBack={() => navigateBack()} navigateForward={() => history.push(pathUrl)} />
    </Box>
  );
};

export default InstructionStep1;

import { combineReducers } from '@reduxjs/toolkit';
import getUsers from 'shared/store/admin/ducks/reducers/getUsers';
import deleteUser from 'shared/store/admin/ducks/reducers/deleteArchive';
import getSettings from 'shared/store/admin/ducks/reducers/getSettings';
import updateSettings from 'shared/store/admin/ducks/reducers/updateSettings';
import sendInvite from './sendInvite';
import getInvites from './getInvites';

export default combineReducers({
  getUsers,
  deleteUser,
  getSettings,
  updateSettings,
  sendInvite,
  getInvites,
});

export const paragraphs: Array<{ headline: string; text: string }> = [
  {
    headline: 'Spara projekt.',
    text: 'Ni sparar all information och har möjlighet att göra ändringar och kompletteringar i ett senare skede.',
  },
  {
    headline: 'Avsluta & Lås projekt.',
    text: `Ni sparar och stänger projektet som inte längre blir redigeringsbart.
    En projektrapport skapas som finns nedladdningsbar i nästa vy och i arkivet.`,
  },
];

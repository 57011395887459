import React, { useState } from 'react';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';
import { Box, Typography } from '@mui/material';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/styles';
import Loader from 'shared/components/Loader';
import TableSection from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/Table';
import { useGetProject } from 'shared/hooks/useGetProjects';
import { TitleAndSubTitle } from 'components/WebTools/ProjectProcess/Common/TittleAndSubTitile';
import { paragraphs } from './TableCompetitors/paragraphs';
import NavigationBar from 'shared/components/NavigationBar';
import { RoutesPage } from 'shared/routes';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

const TableContainer = (): JSX.Element => {
  const { projectData, isLoadingGetForm } = useGetProject();
  const id = useParams<{ id: string }>().id ?? localStorage.getItem('projectId');
  const history = useHistory();
  const [isUpdateTable, updateTable] = useState(false);

  if (projectData === null || isLoadingGetForm) {
    return <Loader />;
  }

  const instruction = projectData.data.stepsNotes['2'];

  const navigateBack = (): void => {
    const previousPath = `${RoutesPage.project.page}${id}${RoutesPage.steps.step2}${RoutesPage.steps.instruction}${location.search}`;

    history.push(previousPath);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.Title}>
        <TitleAndSubTitle Title={() => <>Nivåsätt konkurrensparametrarna 1-10</>} Subtitle={() => <>(1 är lågt och 10 är högt)</>} />
        <Instruction stepNote={instruction} data={projectData.data} noteKey={2} />
      </Box>
      <Box sx={styles.content}>
        <Box sx={styles.paragraphs}>
          {
            paragraphs.map((paragraph, index) =>
              <Typography
                variant="subtitle2"
                component="p"
                color="inherit"
                sx={{ whiteSpace: paragraph.preLine ? 'pre-line': null }}
              >
                {index + 1}. {paragraph.text}
              </Typography>,
            )
          }
          <Typography variant="subtitle2" component="p" color="inherit">Tidsåtgång: ca 20 minuter</Typography>
        </Box>

        <Box sx={{ width: '50%' }}>
          <TableSection projectData={projectData} isUpdateTable={isUpdateTable} />
        </Box>
      </Box>

      <NavigationBar  navigateBack={() => navigateBack()} navigateForward={() => updateTable(true)} />
    </Box>
  );
};

export default TableContainer;

import * as React from 'react';
import { Box, TableCell } from '@mui/material';
import { Field } from 'formik';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_5/Table/WrapperField/styles';
import { stylesProps } from 'components/WebTools/ProjectProcess/Steps/Step_3/Table/styles';

export interface Props {
  onBlur: (event: any) => void;
  onFocus: (event: any) => void;
  onChange: (event: any) => void;
  name: number | string;
}

const WrapperField = (props: Props): JSX.Element => {
  const { name, onBlur, onFocus, onChange } = props;

  return (
    <TableCell sx={stylesProps().td}>
      <Box sx={styles.wrapperField}>
        <Field maxLength={2} autoFocus onChange={onChange} name={name} onBlur={onBlur} onFocus={onFocus} />
      </Box>
    </TableCell>
  );
};

export default WrapperField;

import React from 'react';

const DragIcon = (): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 9H4V11H20V9ZM4 15H20V13H4V15Z" fill="#9BA1AB" />
    </svg>
  );
};

export default DragIcon;

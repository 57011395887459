import HTTP from 'core/services/api';
import { ArchivePayload } from 'shared/store/archive/interface';

export const archiveService = {
  getArchive: async (url: string, config?: any) => {
    const response = await HTTP.get<ArchivePayload>(url, config);
    return response.data;
  },
  deleteArchive: async (url: string) => {
    const response = await HTTP.delete(url);
    return response.data;
  },
  copyArchive: async (url: string) => {
    const response = await HTTP.post(url);
    return response.data;
  },
};

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    padding: '24px',
    paddingBottom: '40px',
    color: colors.accentBlue,
  } as SystemCssProperties,

  headline: {
    color: colors.darkGrey,
    margin: '24px 0 8px 0',
  } as SystemCssProperties,

  muiButton: {
    color: colors.accentBlue,
    background: colors.white,
    '&:hover': {
      background: 'none',
    },
  } as SystemCssProperties,

  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  } as SystemCssProperties,

  modalTextArea: {
    width: '100%',
    marginBottom: '24px',
    height: '378px',
    '.MuiOutlinedInput-root': {
      height: '100%',
    },
  } as SystemCssProperties,

  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  } as SystemCssProperties,
};

import React, {
  useEffect,
  useContext,
  useRef,
  useState,
} from 'react';
import {
  NewCompetitionParameters,
  Project,
  ProjectWithoutFiles,
} from 'shared/store/project/interface';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TableHeader from 'components/WebTools/ProjectProcess/Steps/Step_3/Table/header';
import { FormikProvider, useFormik } from 'formik';
import { actions } from 'shared/store/project';
import { useDispatch } from 'react-redux';
import { setRowsTable } from 'components/WebTools/ProjectProcess/Steps/Step_3/Table/helpers';
import WrapperTableCell from 'components/WebTools/ProjectProcess/Steps/Step_3/Table/WrapperTableCell';
import WrapperField from 'components/WebTools/ProjectProcess/Steps/Step_3/Table/WrapperField';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_3/Table/styles';
import { checkChangeValue } from 'components/WebTools/ProjectProcess/Steps/helpers';
import { actions as actionsPopup } from 'shared/store/popup';
import { useCheckPermissions } from '../../../../../../shared/hooks/useCheckPermissions';
import { Roles } from '../../../../../../shared/constants/enum';
import { TableContext } from '../TableContainer';

interface Props {
  newCompetitionParameters: NewCompetitionParameters[];
  rowsLength: number;
  data: Project;
  pathUrlNextStep: string;
  navigateForwardEvent: React.MouseEvent<HTMLInputElement> | null;
}

export interface Header {
  name: string;
}

const dataHeader: Header[] = [{ name: 'Parameter' }, { name: 'Beskrivning (valfritt)' }];

const TableMyCompany = (props: Props): JSX.Element => {
  const { newCompetitionParameters, rowsLength, data, pathUrlNextStep, navigateForwardEvent } = props;
  let { id } = useParams<{ id: string }>();
  id = id ?? localStorage.getItem('projectId');
  const dispatch = useDispatch();
  const { push } = useHistory();
  const previousValueRef = useRef<string | number>('');
  const [isEdit, setEditMode] = useState<boolean>(false);
  const [indexColumn, setIndexCall] = useState<number>(0);
  const [indexRow, setIndexRow] = useState<number>(0);
  const rows = setRowsTable(rowsLength, newCompetitionParameters);
  const { tableEvent } = useContext(TableContext);

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const formik = useFormik({
    initialValues: { newCompetitionParameters: rows },
    onSubmit: (values: any) => {
      const filteredValues = values.newCompetitionParameters.filter((item) => item.name !== '');
      const newData: ProjectWithoutFiles = { ...data };
      delete newData.files;
      const updateProjectWithoutFiles = {
        ...newData,
        newCompetitionParameters: [...filteredValues],
      };
      dispatch(actions.updateProjectActions.start({ id, updateProjectWithoutFiles }));
    },
  });

  const activateEditMode = (indexR: number, indexCol: number) => {
    if (isMU) {
      return;
    }
    setEditMode(true);
    setIndexCall(indexCol);
    setIndexRow(indexR);
  };

  const deactivateEditMode = () => {
    setEditMode(false);
  };

  const changeHandleBlur = (event) => {
    checkChangeValue(previousValueRef.current, event.target.value, dispatch);
    deactivateEditMode();
    formik.handleBlur(event);
  };

  const renderMuiField = (indexR: number, indexC: number, prop: string, cellValue: string) => {
    return (
      <WrapperField
        maxLength={prop === 'name' ? 25 : 200}
        name={`newCompetitionParameters.${indexR}.${prop}`}
        onFocus={(event) => (previousValueRef.current = event.target.value)}
        onBlur={changeHandleBlur}
        value={cellValue}
        formik={formik}
        keyRow={prop}
      />
    );
  };

  const renderTableCell = (indexR: number, indexC: number, prop: string, value: string) => {
    return <WrapperTableCell
      value={value}
      activateEditMode={() => activateEditMode(indexR, indexC)}
      indexR={indexR}
      prop={prop}
      key={indexC}
    />;
  };

  const handleNextStep = (event) => {
    formik.handleSubmit(event);
    dispatch(actionsPopup.popupActions.toggle(false));
    push(pathUrlNextStep);
  };

  const forbiddenKeys = ['value', 'overriddenValue'];

  useEffect(() => {
    if (tableEvent) {
      handleNextStep(tableEvent);
    }

    if (navigateForwardEvent) {
      handleNextStep(navigateForwardEvent);
    }
  }, [tableEvent, navigateForwardEvent]);

  return (
    <FormikProvider value={formik}>
      <TableContainer component={Paper} sx={{ ...styles.tableCont, ...styles.tableHidden }}>
        <Table sx={{ ...styles.tableW, ...styles.tableCont }} aria-label="simple table">
          <TableHead>
            <TableHeader dataHeader={dataHeader} />
          </TableHead>
          <TableBody>
            {rows.map((row, indexR: number) => {
              return (
                <TableRow key={indexR} sx={styles.rows}>
                  {Object.entries(row).map(([key, value], inxC) => {
                    if (!forbiddenKeys.includes(key)) {
                      const cellValue = formik?.values?.newCompetitionParameters?.[indexR]?.[key] as string;

                      return isEdit && inxC === indexColumn && indexR === indexRow
                        ? renderMuiField(indexR, inxC, key, cellValue)
                        : renderTableCell(indexR, inxC, key, cellValue);
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </FormikProvider>
  );
};

export default TableMyCompany;

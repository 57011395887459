import * as React from 'react';
import Loader from 'shared/components/Loader';
import Accordions from 'components/WebTools/MainForm/ProjectForm/Accordion';
import { useGetProject } from 'shared/hooks/useGetProjects';

const ContainerAccordion = (): JSX.Element => {
  const { projectData, isLoadingGetForm } = useGetProject();

  if (isLoadingGetForm || projectData === null) {
    return <Loader />;
  }

  return <Accordions dataForm={projectData.data} />;
};

export default ContainerAccordion;

import { Steps } from 'components/WebTools/ProjectProcess/NavProgressMap/interface';
import ContainerSubStepperRoutes from 'components/WebTools/ProjectProcess/Common/ContainerSubStepperRoutes';

export const StepsTab: Steps[] = [
  { id: 0, name: 'step1', label: 'Inledning & teori', path: 'step=1', component: ContainerSubStepperRoutes },
  { id: 1, name: 'step2', label: 'Nuvarande position', path: 'step=2', component: ContainerSubStepperRoutes },
  { id: 2, name: 'step3', label: 'Skapa nytt', path: 'step=3', component: ContainerSubStepperRoutes },
  { id: 3, name: 'step4', label: 'Utvärdera och välj', path: 'step=4', component: ContainerSubStepperRoutes },
  { id: 4, name: 'step5', label: 'Den nya positionen', path: 'step=5', component: ContainerSubStepperRoutes },
  { id: 5, name: 'step6', label: 'Eftertanke och avslut', path: 'step=6', component: ContainerSubStepperRoutes },
];

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  General: {
    display: 'flex',
    flexDirection: 'column',
  } as SystemCssProperties,

  GeneralWrapper: {
    display: 'flex',
    mb: 5,
  } as SystemCssProperties,

  generalItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: '416px',
    mr: '56px',
    '&:last-child': {
      mr: 0,
    },
  } as SystemCssProperties,

  generalBox: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '416px',
    color: colors.white,
    mb: 3,
    '.MuiTypography-root': {
      color: colors.white,
    },
  } as SystemCssProperties,

  fieldWrapper: {
    width: '100%',
    maxWidth: '416px',
  } as SystemCssProperties,

  workshopParticipants: {
    width: '100%',
    maxWidth: '890px',
    mr: '56px',
  } as SystemCssProperties,

  wrapperWorkshopParticipants: {
    display: 'flex',
    mb: 5,
  } as SystemCssProperties,

  workshopParticipantsLeft: {
    width: '100%',
    maxWidth: '900px',
  } as SystemCssProperties,

  projectVision: {
    width: '100%',
    maxWidth: '900px',
    marginTop: '40px',
  } as SystemCssProperties,
};

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  container: {
    display: 'flex',
    gap: '24px',
    justifyContent: 'center',
    marginBottom: '24px',
    marginTop: 'auto',
    position: 'sticky',
    bottom: '148px',
    width: '120px',
    left: '50%',
    transform: 'translateX(-50%)',
  } as SystemCssProperties,

  arrow: {
    color: colors.white,
    width: '20px',
  } as SystemCssProperties,

  button: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    minWidth: '48px',
    zIndex: 99,
    '&.Mui-disabled': {
      backgroundColor: colors.lightGrey,
    },
  } as SystemCssProperties,
};

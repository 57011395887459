import * as React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { stylesHeaderTable } from 'components/WebTools/ProjectProcess/Steps/styles';
import { Box } from '@mui/system';
import InfoPopup from 'components/WebTools/ProjectProcess/Common/InfoPopup';
import { styles } from './styles';

interface Props {
  dataHeader: { name: string }[];
}

const TableHeader = (props: Props): JSX.Element => {
  const { dataHeader } = props;
  return (
    <TableRow sx={stylesHeaderTable.clearBorder}>
      {dataHeader &&
        dataHeader.map((column: any, index: number) => (
          <TableCell sx={stylesHeaderTable.tableCellHeader} key={index}>
            <Box sx={stylesHeaderTable.TableCellInner}>
              <Typography sx={stylesHeaderTable.tableCellHeader}>{column.name}</Typography>
              <InfoPopup
                maxLength={column.name === 'Parameter' ? 25 : 200}
                stylePopup={column.name === 'Parameter' ? styles.stylePopupFirstRow : styles.stylePopupSecondRow}/>
            </Box>
          </TableCell>
        ))}
    </TableRow>
  );
};

export default TableHeader;

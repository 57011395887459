import React from 'react';

const Anteckningar = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.49 2C20.49 0.9 19.6 0 18.5 0H2.5C1.4 0 0.5 0.9 0.5 2V14C0.5 15.1 1.4 16 2.5 16H16.5L20.5
           20L20.49 2ZM16.5 12H4.5V10H16.5V12ZM16.5 9H4.5V7H16.5V9ZM16.5 6H4.5V4H16.5V6Z"
        fill="white"/>
    </svg>
  );
};

export default Anteckningar;

import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { RequestUser } from 'shared/store/auth/interface';
import { authService } from 'core/services/auth';
import { RoutesPage } from 'shared/routes';

interface Props {
  payload: Pick<RequestUser, 'history' | 'email'>;
}

export function* getSendResetPassword(props: Props): SagaIterator {
  const {
    payload: { email, history },
  } = props;
  const isAdminReset = history.location.pathname.includes(RoutesPage.admin);

  try {
    if (isAdminReset) {
      yield call(authService.sendPasswordResetAdmin, 'auth/send-password-reset/admin', { email });
    } else {
      yield call(authService.sendPasswordReset, 'auth/send-password-reset/user', { email });
    }
    yield put(actions.resetPasswordActions.end());
    history.push({
      pathname: isAdminReset ? `${RoutesPage.admin}${RoutesPage.reset}` : RoutesPage.reset,
      search: `?email=${email}`,
    });
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.resetPasswordActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* getSendResetPasswordSaga(): SagaIterator {
  yield takeLatest(actions.resetPasswordActions.start, getSendResetPassword);
}

import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { adminService } from 'core/services/admin';
import { Settings } from '../../interface';

interface Props {
  payload: Settings;
}

export function* updateSettings(props: Props): SagaIterator {
  try {
    yield call(adminService.putSettings, props.payload);
    yield put(actions.updateSettingsActions.end(props.payload));
    yield put(actions.getSettingsActions.end(props.payload));
    yield put(
      actionSnackbar.snackbarActions.add({
        type: 'success',
        text: 'Changes are successfully saved',
      }),
    );
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.updateSettingsActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* updateSettingsSaga(): SagaIterator {
  yield takeLatest(actions.updateSettingsActions.start, updateSettings);
}

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  Loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as SystemCssProperties,

  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  } as SystemCssProperties,

  typography: {
    fontSize: '24px',
    textAlign: 'center',
    lineHeight: '32px',
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
  } as SystemCssProperties,

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: colors.white,
  } as SystemCssProperties,

  headerTitle: {
    display: 'grid',
    alignItems: 'center',
    gridColumnGap: '15px',
    gridTemplateColumns: '48px auto',
  } as SystemCssProperties,

  name: {
    fontSize: '24px',
    fontFamily: 'Poppins',
    fontWeight: 600,
  } as SystemCssProperties,

  instructions: {
    color: colors.white,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '24px',
    whiteSpace: 'pre-line',
  } as SystemCssProperties,

  wrapper: {
    width: '50%',
  } as SystemCssProperties,

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '66px',
  } as SystemCssProperties,

  stepTime: {
    fontFamily: 'Poppins',
    marginTop: '24px',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '16px',
    color: colors.white,
    marginBottom: '40px',
  } as SystemCssProperties,

  typoText: {
    fontSize: '24px',
    textAlign: 'center',
  } as SystemCssProperties,

  AdditionalPagesBtn: {
    marginTop: '40px',
    display: 'grid',
    gridTemplateColumns: '210px 210px',
    gridColumnGap: '24px',
  } as SystemCssProperties,

  AdditionalPagesItem: {
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: '24px',
  } as SystemCssProperties,

  AdditionalPagesTypography: {
    fontFamily: 'inherit',
    color: '#152E5B',
    textTransform: 'none',
    fontWeight: '500',
    textAlign: 'start',
    paddingLeft: '20px',
  } as SystemCssProperties,

  AdditionalPagesIcon: {
    color: '#152E5B',
    transform: 'scale(1.4)',
    marginLeft: '18px',
    marginBottom: '12px',
  } as SystemCssProperties,

  instructionStep: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'center',
    fontSize: '24px',
    color: colors.white,
    height: {
      lg: 'calc(100% - 65px)',
      xl: '100%',
    },
    '& .MuiTypography-root': {
      margin: '40px 0',
    },
  } as SystemCssProperties,

  NavLink: {
    width: '196px',

    '&.MuiButton-root': {
      padding: '0px',
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.white,
      textTransform: 'none',
      width: '100%',
      height: '100%',
      padding: '10px 16px',
    },
  } as SystemCssProperties,

  NavLinkInstruct: {
    background: colors.white,
    '&.MuiButton-root': {
      padding: '0px',
    },
    height: '72px',
    width: '210px',

    '&:hover': {
      background: colors.white,
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.accentBlue,
      textTransform: 'none',
      width: '100%',
      height: '100%',
      padding: '10px 16px',
    },
  } as SystemCssProperties,
};

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  root: {
    color: colors.white,
    margin: 0,
    '& .MuiCheckbox-root': {
      color: `${colors.white}!important`,
      marginRight: '11px',
      padding: 0,
    },
    '& .MuiTypography-root': {
      fontSize: '16px',
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: `${colors.white}!important`,
    },
  } as SystemCssProperties,
};

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const stylesProps = (isError?: boolean, isTouched?: boolean) => {
  return {
    td: {
      height: '42px',
      wordBreak: 'break-word',
      position: 'relative',
      color: colors.accentBlue,
      borderBottom: 'none',
      border: isError && isTouched ? '1.1px solid #D51C29' : '1px solid #D6DAE1',
      padding: '10px 16px',
      lineHeight: 'inherit',
    } as SystemCssProperties,
  };
};

export const styles = {
  rows: {
    boxShadow: 'none',
  } as SystemCssProperties,

  tableCont: {
    height: '100%',
    boxShadow: 'none',
    borderRadius: '8px',
  } as SystemCssProperties,

  tableHidden: {
    overflowX: 'hidden',
  } as SystemCssProperties,

  tableW: {
    minWidth: 680,
    borderRadius: '8px',
  } as SystemCssProperties,

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  tableRow: {
    border: 'none',
  } as SystemCssProperties,

  tableContainer: {
    borderRadius: '8px',
    boxShadow: 'none',
  } as SystemCssProperties,

  buttonForm: {
    mt: 3,
    background: colors.lightGreyDisable,
  } as SystemCssProperties,

  textButtonForm: {
    color: colors.white,
  } as SystemCssProperties,

  tableTd: {
    background: 'inherit',
    width: '100%',
    textDecoration: 'none',
    color: colors.white,
    textTransform: 'none',
  } as SystemCssProperties,

  errorWrapper: {
    width: '100%',
    minWidth: '309px',
    padding: '4px 8px',
    background: colors.error,
    position: 'absolute',
    bottom: '-37px',
    left: 0,
    zIndex: 1000,
  } as SystemCssProperties,

  errorText: {
    color: colors.white,
    fontSize: '12px',
    lineHeight: '22px',
  } as SystemCssProperties,
};

export const stylesForm = {
  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'center',
  'align-items': 'center',
  width: '100%',
  'max-width': '1360px',
  margin: '0 auto',
};

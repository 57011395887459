import { createSlice } from '@reduxjs/toolkit';
import { AcceptInvite, RequestUser } from 'shared/store/auth/interface';
import { StateInterface } from 'shared/interfaces';

const initialState: StateInterface = {
  isLoading: false,
  error: {
    message: [],
    stackErrors: '',
  },
};

const updatePassword = createSlice({
  name: 'updatePassword',
  initialState,
  reducers: {
    start: {
      reducer(state) {
        state.isLoading = true;
      },
      prepare(payload: Pick<AcceptInvite, 'token' | 'password'> & Pick<RequestUser, 'history'>) {
        return { payload };
      },
    },

    end(state) {
      state.isLoading = false;
    },
    error(state, action: any) {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
    reset() {
      return initialState;
    },
  },
});

export const updatePasswordActions = updatePassword.actions;
export default updatePassword.reducer;

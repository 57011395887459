import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import { styles } from 'shared/components/UI/Info/styles';

export interface Props {
  onSetIsShowInfo: Dispatch<SetStateAction<boolean>>;
  sx?: any;
}

const InfoComponent = ({ onSetIsShowInfo, sx }: Props): JSX.Element => {
  return (
    <Box onMouseOver={() => onSetIsShowInfo(true)} onMouseLeave={() => onSetIsShowInfo(false)} sx={{ ...styles.info, ...sx }}>
      <InfoIcon sx={styles.infoIcon} />
    </Box>
  );
};

export default InfoComponent;

import React, { ReactNode } from 'react';
import { Button } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

type MuiButtonType = 'button' | 'submit' | 'reset' | undefined;
type MuiButtonVariantType = 'text' | 'outlined' | 'contained' | undefined;

export interface Props {
  type: MuiButtonType;
  variant: MuiButtonVariantType;
  children: ReactNode;
  onSubmit?: any;
  disabled?: boolean;
  onClick?: (...arg: any) => any;
  sx?: SxProps;
}

const MuiButton = (props: Props): JSX.Element => {
  return (
    <Button sx={{ ...props.sx }} {...props}>
      {props.children}
    </Button>
  );
};

export default MuiButton;

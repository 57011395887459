import * as React from 'react';
import { Box, TableCell, TextareaAutosize } from '@mui/material';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors/TableRowField/styles';
import { stylesProps } from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors/styles';
import { FocusEvent } from 'shared/interfaces';
import { useEffect, useRef } from 'react';
import { Field } from 'formik';

export interface Props {
  onBlur: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onFocus: (event: FocusEvent<HTMLTextAreaElement>) => void;
  name: string;
  widthTableTd: string | number;
  index?: number;
  handleChange: (value: string) => void;
  valueRow: string;
  maxLength: number;
  keyRow: string;
}

const FieldTd = (props: Props): JSX.Element => {
  const { name, onBlur, onFocus, widthTableTd, maxLength, handleChange, valueRow, keyRow } = props;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.value = '';
    }
  }, []);

  return (
    <TableCell sx={stylesProps(widthTableTd).td}>
      <Box sx={styles.wrapperField}>
        <TextareaAutosize
          ref={textAreaRef}
          style={{...keyRow !== 'description' && {height: '100%'}}}
          value={valueRow}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={(event) => handleChange(event.target.value)}
        />

        <Field style={styles.autoFocus} autoFocus />
      </Box>
    </TableCell>
  );
};

export default FieldTd;

import React from 'react';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { sv } from 'date-fns/locale';

import { Box } from '@mui/system';

import { styles } from './styles';
import { useCheckPermissions } from 'shared/hooks/useCheckPermissions';
import { Roles } from 'shared/constants/enum';

interface Props {
  value: Date | string;
  label: string;
  handleDateChange: (field: string, value: string | Date, shouldValidate?: boolean | undefined) => void;
  name: string;
  isError: boolean | undefined;
}

const MuiDatePicker = (props: Props): JSX.Element => {
  const { value, name, handleDateChange, isError } = props;

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={sv}>
      <DatePicker
        inputFormat="yyyy/MM/dd"
        mask="____/__/__"
        value={value}
        disabled={isMU}
        onChange={(val) => {
          let v = '';
          if (val) {
            v = moment(val, 'MM-DD-YYYY').format('YYYY-MM-DD');
          }

          handleDateChange(name, v);
        }}
        renderInput={(params) => (
          <Box sx={styles.root}>
            <TextField {...params} sx={styles.styleInput} error={isError} />
          </Box>
        )}/>
    </LocalizationProvider>
  );
};

export default MuiDatePicker;

import React, {useRef} from 'react';
import {Box, Typography} from '@mui/material';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_6/CommentContainer/CommentSection/Comment/styles';
import MuiTextarea from 'shared/components/UI/MuiTextarea';
import InfoPopup from 'components/WebTools/ProjectProcess/Common/InfoPopup';
import {stylesHeaderTable} from 'components/WebTools/ProjectProcess/Steps/styles';
import {checkChangeValue} from 'components/WebTools/ProjectProcess/Steps/helpers';
import {useDispatch} from 'react-redux';
import {EventType} from 'shared/interfaces';
import {useCheckPermissions} from 'shared/hooks/useCheckPermissions';
import {Roles} from 'shared/constants/enum';

interface Props {
  [key: string]: any;

  data: any;
}

const Comment = (props: Props): JSX.Element => {
  const {formik} = props;
  const dispatch = useDispatch();
  const previousValueRef = useRef<string | number>('');

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const handleChangeForm = (event) => {
    checkChangeValue(previousValueRef.current, event.target.value, dispatch);
    formik.handleChange(event);
  };

  return (
    <Box sx={styles.modal}>
      <Box sx={styles.wrapperText}>
        <Typography component="p" sx={styles.modalHeader}>
          Dina kommentarer
        </Typography>
        <InfoPopup maxLength={500} stylePopup={stylesHeaderTable.popupInfo}/>
      </Box>

      <MuiTextarea
        disabled={isMU}
        maxLength={500}
        placeholder="Kommentarer & Synpunkter"
        rows={15}
        name="comment"
        value={formik.values.comment}
        onFocus={(event: EventType) => (previousValueRef.current = event.target.value)}
        onChange={handleChangeForm}
        sx={styles.modalTextArea}/>
    </Box>
  );
};

export default Comment;

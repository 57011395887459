import React, {useEffect} from 'react';
import AppRouter from 'shared/components/AppRouter';
import MuiSnackbar from 'shared/components/SnackBar';
import {useTypedSelector} from 'shared/hooks/useTypedSelector';
import {useDispatch} from 'react-redux';
import {actions} from 'shared/store/auth';
import {actions as projectActions} from 'shared/store/project';
import Loader from 'shared/components/Loader';
import {useHistory} from 'react-router-dom';
import {useCheckPermissions} from '../shared/hooks/useCheckPermissions';
import {Roles} from '../shared/constants/enum';
import {History} from 'history';
import './App.scss';

const getHistoryReact = (history) => (url: string) => history.push(url);
export let reactHistory;

const App = (): JSX.Element => {
  const {isSnackbar} = useTypedSelector((state) => state.snackbar.snackbar);
  const {isLoading: isGettingMe} = useTypedSelector((state) => state.auth.getMe);
  const {user} = useTypedSelector((state) => state.auth.login);
  const dispatch = useDispatch();
  const history: History = useHistory();
  reactHistory = getHistoryReact(history);

  const role = useCheckPermissions();

  useEffect(() => {
    if (user === null && !location.search.substring(1)) {
      dispatch(actions.getMeActions.start({history}));
    }
  }, [user]);

  useEffect(() => {
    let timer;
    if (role === Roles.MU) {
      setInterval(() => {
        dispatch(projectActions.getSharedProjectActions.start({token: location.search.substring(1)}));
      }, 5000);
    }
    return () => clearInterval(timer);
  }, [role]);

  if (isGettingMe) {
    return <Loader/>;
  }

  return (
    <div className="App">
      {isSnackbar && <MuiSnackbar/>}
      <AppRouter/>
    </div>
  );
};

export default App;

import * as React from 'react';
import {Box, TableCell, Typography} from '@mui/material';
import {styles, stylesPropsTd} from 'components/WebTools/ProjectProcess/Steps/Step_5/Table/WrapperTableCell/styles';

export interface Props {
  indexR: string | number;
  activateEditMode: any;
  value: any;
  errors: any;
  touched: any;
  prop: string;
  isDisabled?: boolean;
  width: string;
}

const WrapperTableCell = (props: Props): JSX.Element => {
  const { value, indexR, activateEditMode, errors, touched, prop, isDisabled, width } = props;
  const isError = errors?.newCompetitionParameters?.[indexR]?.[prop];
  const isTouched = touched?.newCompetitionParameters?.[indexR]?.[prop];
  return (
    <TableCell
      sx={
        prop === 'name'
          ? stylesPropsTd(width, isError, isTouched, isDisabled).stickyColumn()
          : stylesPropsTd(width, isError, isTouched, isDisabled).td
      }
      onClick={activateEditMode}
    >
      {value}
      {isError && isTouched && (
        <Box sx={styles.errorWrapper}>
          <Typography sx={styles.errorText}>{isError}</Typography>
        </Box>
      )}
    </TableCell>
  );
};

export default WrapperTableCell;

import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';

import TableHeader from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors/header';
import validationSchema
  from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors/validationSchema';
import TdWithoutValues
  from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors/TdWithoutValues';
import {
  getMaxLengthByRow,
  setWidthTd,
} from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors/helpers';
import FieldTd
  from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors/TableRowField/TableRowField';
import TdWithValues from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors/TdWithValues';
import {
  styles,
  stylesButtonForm,
  stylesForm,
} from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors/styles';

import MuiButton from 'shared/components/UI/MuiButton';

import {
  Competitors,
  OldCompetitionParameters,
  Project,
  ProjectWithoutFiles,
} from 'shared/store/project/interface';
import { RoutesPage } from 'shared/routes';
import { actions } from 'shared/store/project';
import { actions as actionsPopup } from 'shared/store/popup';
import { setRowsTable } from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/helpers';
import { filteredEmptyStepTwoDataFromTable } from 'shared/helpers';
import { checkChangeValue } from 'components/WebTools/ProjectProcess/Steps/helpers';
import { useCheckPermissions } from '../../../../../../../shared/hooks/useCheckPermissions';
import { Roles } from '../../../../../../../shared/constants/enum';

export interface DataTable {
  name: string;
  doings?: string;
  priorities?: string;
  quadrant?: null;
  values: number[] | string[];
  overriddenValue: number | string;
}

interface Props {
  dataHeader: Competitors[];
  data: Project;
  oldCompetitionParameters: OldCompetitionParameters[];
  isUpdateTable: boolean;
}

type EventType = React.ChangeEvent<HTMLTextAreaElement>;

const keysWithoutValues = ['name', 'description', 'quadrant', 'overriddenValue'];
const keysWithValues = ['values', 'quadrant', 'overriddenValue'];
const rowsLength = 9;

const TableCompetitors = (props: Props): JSX.Element => {
  const { oldCompetitionParameters, dataHeader, data, isUpdateTable } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  let { id } = useParams<{ id: string }>();
  id = id ?? localStorage.getItem('projectId');
  const pathUrl = `${RoutesPage.project.page}${id}${RoutesPage.steps.step2}${RoutesPage.steps.diagram}${location.search}`;
  const previousValueRef = useRef<string | number>('');

  const [isEdit, setEditMode] = useState<boolean>(false);
  const [isEditValues, setEditValuesMode] = useState<boolean>(false);
  const [indexColumn, setIndexCall] = useState<number>(0);
  const [indexRow, setIndexRow] = useState<number>(0);
  const [valueIdx, setValueIdx] = useState<number>(0);
  const [formikValues, setFormikValues] = useState<{oldCompetitionParameters: OldCompetitionParameters[]} | null>(null);
  const [isValidForm, setValidForm] = useState(true);

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  useEffect(() => {
    if (isUpdateTable) {
      updatedTable(formikValues, isValidForm);
    }
  }, [isUpdateTable]);

  const activateEditMode = (indexR: number, indexCol: number) => {
    if (isMU) {
      return;
    }
    setEditMode(true);
    setEditValuesMode(false);
    setIndexCall(indexCol);
    setIndexRow(indexR);
  };

  const activateValuesEditMode = (indexR: number, idxValue: number) => {
    if (isMU) {
      return;
    }
    setEditValuesMode(true);
    setEditMode(false);
    setIndexRow(indexR);
    setValueIdx(idxValue);
  };

  const deactivateEditMode = () => {
    setEditMode(false);
    setEditValuesMode(false);
  };

  const rowsTable = () =>
    useMemo(() => setRowsTable(dataHeader, rowsLength, oldCompetitionParameters), [dataHeader, oldCompetitionParameters]);

  const updatedTable = (values, isValid: boolean) => {
    if (isValid) {
      const filteredValues = values.oldCompetitionParameters.filter(
        (row) => row.values[0] !== null && row.values[0] !== '' && row.name !== '',
      );
      const filteredEmptyRows = filteredEmptyStepTwoDataFromTable(filteredValues);
      const newData: ProjectWithoutFiles = { ...data };
      delete newData.files;
      const updateProjectWithoutFiles = {
        ...newData,
        oldCompetitionParameters: filteredEmptyRows,
      };
      dispatch(actions.updateProjectActions.start({ id, updateProjectWithoutFiles }));
      dispatch(actionsPopup.popupActions.toggle(false));

      history.push(pathUrl);
    }
  };

  const changeHandleBlur = (event: EventType, handleChange) => {
    checkChangeValue(previousValueRef.current, event.target.value, dispatch);
    handleChange(event);
    deactivateEditMode();
  };

  const getUpdatedFieldValues = (fieldValues: string[], fieldIndex: number, newValue: string): string[] => {
    return fieldValues.map((currentValue, index) => index === fieldIndex ? newValue : currentValue);
  };

  const changeFieldValue = (
    value: string,
    indexR: number,
    indexC: number,
    keyRow: string,
    setFieldValue: FormikHelpers<string>['setFieldValue'],
  ) => {
    const updatedFormikValue = formikValues?.oldCompetitionParameters.map((item, index) => {
        if (index === indexR) {
          return {
            ...item,
            [keyRow]: keyRow === 'values' ? getUpdatedFieldValues(item.values, indexC, value): value,
          };
        }

        return item;
      }) as OldCompetitionParameters[];

    setFieldValue('oldCompetitionParameters', updatedFormikValue);
  };

  const renderWithValues = (
    oldCompetitionParametersRow,
    indexR,
    handleBlur,
    errors,
    touched,
    setFieldValue: FormikHelpers<string>['setFieldValue'],
  ) => {
    const rows = Object.entries(oldCompetitionParametersRow);
    const filteredRows = rows.filter(([key, value]: any) => !keysWithoutValues.includes(key));
    return filteredRows.map(([keyRow, valueM]: any) => {
      const widthTableTd = setWidthTd(keyRow);
      const maxLength = getMaxLengthByRow(keyRow);
      return valueM.map((item, index: number) => {
        if (isEditValues && !isEdit && indexRow === indexR && valueIdx === index) {
          return (
            <FieldTd
              valueRow={item}
              widthTableTd={widthTableTd}
              name={`oldCompetitionParameters.${indexR}.values[${index}]`}
              onFocus={(event: EventType) => (previousValueRef.current = event.target.value)}
              onBlur={(event: EventType) => changeHandleBlur(event, handleBlur)}
              key={index}
              keyRow={keyRow}
              maxLength={maxLength}
              handleChange={(value) => changeFieldValue(value, indexR, index, keyRow, setFieldValue)}
            />
          );
        } else {
          return (
            <TdWithValues
              value={item}
              activateEditMode={() => activateValuesEditMode(indexR, index)}
              keyRow={keyRow}
              indexR={indexR}
              index={index}
              errors={errors}
              touched={touched}
              key={index}
            />
          );
        }
      });
    });
  };

  const renderWithoutValues = (
    oldCompetitionParametersRow,
    indexR,
    handleBlur,
    errors,
    touched,
    setFieldValue: FormikHelpers<string>['setFieldValue'],
  ) => {
    const rows = Object.entries(oldCompetitionParametersRow);
    const filteredRows = rows.filter(([key, value]: any) => !keysWithValues.includes(key));
    return filteredRows.map(([keyRow, valueRow]: any, indexC: number): any => {
      const widthTableTd = setWidthTd(keyRow);
      const maxLength = getMaxLengthByRow(keyRow);
      if (isEdit && !isEditValues && indexRow === indexR && indexColumn === indexC) {
        return (
          <FieldTd
            key={keyRow}
            maxLength={maxLength}
            widthTableTd={widthTableTd}
            name={`oldCompetitionParameters.${indexR}.${keyRow}`}
            onFocus={(event) => (previousValueRef.current = event.target.value)}
            onBlur={(event) => changeHandleBlur(event, handleBlur)}
            index={indexC}
            keyRow={keyRow}
            handleChange={(value) => changeFieldValue(value, indexR, indexC, keyRow, setFieldValue)}
            valueRow={valueRow}
          />
        );
      } else {
        return (
          <TdWithoutValues
            value={valueRow}
            activateEditMode={() => activateEditMode(indexR, indexC)}
            indexR={indexR}
            keyRow={keyRow}
            errors={errors}
            touched={touched}
            indexC={indexC}
            key={keyRow}
          />
        );
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        oldCompetitionParameters: rowsTable(),
      }}
      validationSchema={validationSchema}
      onSubmit={() => {
      }}
      render={({values, errors, handleBlur, touched, isValid, setFieldValue}) => {
        setFormikValues(values);
        setValidForm(isValid);

        return (
          <Form style={stylesForm}>
            <FieldArray
              name="oldCompetitionParameters"
              render={() => (
                <TableContainer sx={styles.tableContainer} component={Paper}>
                  <Table>
                    <TableHead>
                      <TableHeader dataHeader={dataHeader}/>
                    </TableHead>
                    <TableBody>
                      {values.oldCompetitionParameters &&
                        values.oldCompetitionParameters.map((oldCompetitionParametersRow, indexR) => {
                          return (
                            <TableRow sx={styles.tableRow} key={indexR}>
                              {renderWithoutValues(oldCompetitionParametersRow, indexR, handleBlur, errors, touched, setFieldValue)}
                              {renderWithValues(oldCompetitionParametersRow, indexR, handleBlur, errors, touched, setFieldValue)}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}/>

            <MuiButton
              onClick={() => updatedTable(values, isValid)}
              sx={stylesButtonForm(isValid && !isMU).buttonForm}
              disabled={!isValid || isMU}
              type="submit"
              variant="outlined"
            >
              <Typography sx={styles.textButtonForm} variant="subtitle2">
                Generera diagram
              </Typography>
            </MuiButton>
          </Form>
        );
      }}/>
  );
};

export default TableCompetitors;

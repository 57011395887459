import React from 'react';

const Binoculars = (): JSX.Element => {
  return (
    <svg width="36" height="22" viewBox="0 0 36 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.9818 9.95508C26.0918 9.95508 24.5635 11.4908 24.5635 13.3734C24.5635 13.8238 24.9326 14.193 25.383
       14.193C25.8334 14.193 26.2025 13.8238 26.2025 13.3734C26.2025 12.3915 27.0073 11.5867 27.9892 11.5867C28.4396
       11.5867 28.8088 11.2176 28.8088 10.7672C28.8014 10.3168 28.4322 9.95508 27.9818 9.95508Z" fill="#F54F8F"
      />
      <path d="M33.5561 7.59987C33.3568 7.40791 33.1427 7.22333 32.9212 7.04614L29.8941 4.47682C28.7276 3.64992 27.3396
       3.12572 25.8334 3.02236L25.9958 3.18478L24.1575 1.33901H24.1427C23.6333 0.873878 22.9466 0.585938 22.2009
       0.585938C20.9163 0.585938 19.831 1.42023 19.4618 2.57937C19.0927 2.34311 18.6644 2.20283 18.1993 2.1733C18.1255
       2.16592 18.0443 2.16592 17.9704 2.16592C17.4462 2.16592 16.9589 2.32096 16.5455 2.57937C16.169 1.42761 15.0836
       0.59332 13.8064 0.59332C13.0607 0.59332 12.3814 0.873878 11.8646 1.3464H11.8499L10.0115 3.19217L10.1739
       3.02974C8.66774 3.1331 7.27234 3.66468 6.1132 4.48421L3.08613 7.05352C2.86464 7.22333 2.65053 7.40791 2.45118
       7.60725C0.937652 9.0691 0 11.1142 0 13.3808C0 17.8107 3.60295 21.4136 8.0328 21.4136C12.123 21.4136 15.5119
       18.3349 15.9991 14.3702C16.5898 14.7098 17.2764 14.9017 18 14.9017C18.7309 14.9017 19.4101 14.7098 20.0008
       14.3702C20.4954 18.3275 23.8769 21.4062 27.9671 21.4062C32.397 21.4062 35.9999 17.8033 35.9999 13.3734C36.0073
       11.1068 35.0697 9.06172 33.5561 7.59987ZM8.0328 18.8591C5.00573 18.8591 2.55455 16.4079 2.55455
       13.3808C2.55455 10.3538 5.00573 7.90258 8.0328 7.90258C11.0599 7.90258 13.511 10.3538
       13.511 13.3808C13.5184 16.4005 11.0599 18.8591 8.0328 18.8591ZM18.0073 12.6277C17.0254 12.6277 16.228
       11.8304 16.228 10.8484C16.228 9.86648 17.0254 9.0691 18.0073 9.0691C18.9893 9.0691 19.7867 9.86648
       19.7867 10.8484C19.7867 11.8304 18.9893 12.6277 18.0073 12.6277ZM27.9819 18.8591C24.9548 18.8591 22.5036
       16.4079 22.5036 13.3808C22.5036 10.3538 24.9548 7.90258 27.9819 7.90258C31.009 7.90258 33.4601 10.3538
       33.4601 13.3808C33.4601 16.4005 31.009 18.8591 27.9819 18.8591Z" fill="#F54F8F"
      />
      <path d="M8.03287 9.95508C6.1428 9.95508 4.6145 11.4908 4.6145 13.3734C4.6145 13.8238 4.98366 14.193 5.43402
       14.193C5.88439 14.193 6.25355 13.8238 6.25355 13.3734C6.25355 12.3915 7.0583 11.5867 8.04025 11.5867C8.49062
        11.5867 8.85978 11.2176 8.85978 10.7672C8.85239 10.3168 8.48324 9.95508 8.03287 9.95508Z" fill="#F54F8F"
      />
    </svg>
  );
};

export default Binoculars;

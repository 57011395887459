import * as React from 'react';
import { TableCell } from '@mui/material';
import { stylesPropsTd } from 'components/WebTools/ProjectProcess/Steps/Step_3/Table/WrapperTableCell/styles';

export interface Props {
  indexR: string | number;
  activateEditMode: any;
  value: any;
  prop: string;
  disabled?: boolean;
}

const WrapperTableCell = (props: Props): JSX.Element => {
  const { value, activateEditMode, prop } = props;
  const width = prop === 'name' ? 416 : 569;

  return (
    <TableCell sx={stylesPropsTd(width).td} onClick={activateEditMode}>
      {value}
    </TableCell>
  );
};

export default WrapperTableCell;

import * as React from 'react';
import { Box, TableCell, TextareaAutosize } from '@mui/material';
import { FormikContextType } from 'formik';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_3/Table/WrapperField/styles';
import { stylesProps } from 'components/WebTools/ProjectProcess/Steps/Step_3/Table/styles';
import { FocusEvent } from 'shared/interfaces';

export interface Props {
  onBlur: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onFocus: (event: FocusEvent<HTMLTextAreaElement>) => void;
  name: string;
  maxLength: number;
  value: string;
  formik: FormikContextType<any>;
  keyRow: string;
}

const WrapperField = (props: Props): JSX.Element => {
  const { name, onBlur, onFocus, maxLength, value, formik, keyRow } = props;

  const handleFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    event.target.value = '';

    return onFocus(event);
  };

  return (
    <TableCell sx={stylesProps().td}>
      <Box sx={styles.wrapperField}>
        <TextareaAutosize
          style={{...keyRow === 'name' && {height: '100%'}}}
          maxLength={maxLength}
          autoFocus
          onFocus={handleFocus}
          value={value}
          onBlur={onBlur}
          name={name}
          onChange={formik.handleChange}
        />
      </Box>
    </TableCell>
  );
};

export default WrapperField;

import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useMemo, useState} from 'react';
import {Roles} from '../constants/enum';
import jwt_decode from 'jwt-decode';
import {actions} from '../store/project';
import {RootState} from '../store/rootReducer';

export const useCheckPermissions = (): Roles | null => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isFinished, projectData } = useSelector((state: RootState) => state.project.getSharedProject);
  const [role, setRole] = useState<Roles | null>(null);
  const sharedToken = location.search.substring(1);

  useMemo(() => {
    const token = localStorage.getItem('accessToken');
    if (sharedToken && !projectData) {
      dispatch(actions.getSharedProjectActions.start({ token: sharedToken }));
    } else if (token) {
      const userRole: Roles = (jwt_decode(token) as any).roles[0];
      setRole(userRole);
    }
  }, [sharedToken]);

  useEffect(() => {
    if (!projectData) {
      return;
    }

    setRole(Roles.MU);
  }, [isFinished]);

  return role;
};

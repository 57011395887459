import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { formService } from 'core/services/form';
import { GetProjectPayload } from 'shared/store/project/interface';

interface Props {
  payload: GetProjectPayload;
}

export function* getProject(props: Props): SagaIterator {
  const { id } = props.payload;
  try {
    const response = yield call(formService.getProject, `projects/${id}`);
    yield put(actions.getProjectActions.end(response));
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.getProjectActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* getFormSaga(): SagaIterator {
  yield takeLatest(actions.getProjectActions.start, getProject);
}

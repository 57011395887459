import { all, fork } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { saga as auth } from './auth';
import { saga as project } from './project';
import { saga as files } from './files';
import { saga as archive } from './archive';
import { saga as admin } from './admin';
import { saga as listVideos } from './ListVideos';

const sagas = [auth, project, files, archive, admin, listVideos];

export default function* rootSaga(): SagaIterator {
  yield all(sagas.flat().map((saga) => fork(saga)));
}

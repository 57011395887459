import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import colors from 'styles/_Variables';
import { styles } from 'shared/components/UI/MuiSelect/styles';
import { SelectType } from 'components/WebTools/MainForm/ProjectForm/interface';
import PlaceholderSelect from 'shared/components/UI/MuiSelect/PlaceholderSelect';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
  items: SelectType[];
  value: any;
  onHandleChange: any;
  onBlur?: (...arg: any) => any;
  name: string;
  isDisabled?: boolean;
  placeholder?: string;
}

const MuiSelect = (props: Props): JSX.Element => {
  const { items, value, onHandleChange, name, placeholder, onBlur, isDisabled = false } = props;
  return (
    <Box sx={styles.select}>
      <FormControl fullWidth>
        <Select
          value={value}
          name={name}
          onChange={onHandleChange}
          onBlur={onBlur}
          IconComponent={KeyboardArrowDownIcon}
          displayEmpty
          disabled={isDisabled}
          renderValue={(selected: string) => {
            if (selected.length === 0) {
              return <PlaceholderSelect placeholderText={placeholder} />;
            }
            return selected;
          }}
          inputProps={{
            style: {
              fontFamily: 'Poppins, sans-serif',
              color: colors.accentBlue,
              fontSize: 16,
            },
          }}
        >
          {items.map((item: SelectType) => (
            <MenuItem key={item.id} value={item.value}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MuiSelect;

import React from 'react';
import {Table, TableBody, TableHead, TableRow} from '@mui/material';
import TableHeader from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/Table/header';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/Table/styles';
import {Droppable} from 'react-beautiful-dnd';
import {InitialDataI, ItemBoard} from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/initialStateData';
import {CompareParametersId} from 'shared/constants/enum';
import {renderTable} from './helpers';
import ListDraggableTable from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/Table/ListDraggableTable';

interface Props {
  boards: InitialDataI[];
  rowsLength: number;
}

export interface Header {
  name: string;
}

const dataHeader: Header[] = [{name: 'Parameter'}, {name: 'Nivå'}];

const TableMyCompany = (props: Props): JSX.Element => {
  const {boards} = props;

  return (
    <Table sx={{...styles.tableW, ...styles.tableCont}}>
      <TableHead>
        <TableHeader dataHeader={dataHeader}/>
      </TableHead>
      <TableBody>
        <Droppable droppableId={CompareParametersId.Root}>
          {(providedRows) => (
            <TableRow sx={styles.droppableWrapper} ref={providedRows.innerRef} {...providedRows.droppableProps}>
              {boards.map((board: InitialDataI) => {
                if (board.id === CompareParametersId.Root) {
                  return renderTable(board.items).map((item: ItemBoard, index: number) => {
                    return <ListDraggableTable item={item} index={index} board={board} key={index}/>;
                  });
                }
              })}
            </TableRow>
          )}
        </Droppable>
      </TableBody>
    </Table>
  );
};

export default TableMyCompany;

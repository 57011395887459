import {SystemCssProperties} from '@mui/system';
import {StylesCSS} from 'shared/interfaces';
import colors from 'styles/_Variables';

export const getActionsStyles = (isProjectFinished: boolean): SystemCssProperties => {
  return {
    width: '100%',
    display: 'flex',
    marginTop: isProjectFinished ? 0 : '37px',
  };
};

export const styles: StylesCSS = {
  download: {
    width: '100%',
    maxWidth: '254px',
    display: 'flex',
    cursor: 'pointer',
  },
  icon: {
    color: colors.white,
    marginRight: 1,
  },
};

export const stylesButton = (maxWidth: number): SystemCssProperties => {
  return {
    width: '100%',
    marginRight: '50px',
    maxWidth: `${maxWidth}px`,
    display: 'flex',
    cursor: 'pointer',
    flexShrink: 0,
  };
};

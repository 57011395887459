import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  Loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as SystemCssProperties,

  instructionStep: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
    color: colors.white,
    height: '100%',
    position: 'relative',
    '& .MuiTypography-root': {
      margin: '40px 0',
    },
  } as SystemCssProperties,

  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  } as SystemCssProperties,

  typography: {
    fontSize: '24px',
    textAlign: 'center',
    lineHeight: '32px',
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
  } as SystemCssProperties,

  Icon: {
    width: '96px',
    height: '96px',
  } as SystemCssProperties,

  instructionTypo: {
    fontSize: '24px',
    textAlign: 'center',
  } as SystemCssProperties,

  Btn: {
    height: '48px',
  } as SystemCssProperties,

  BtnWhite: {
    background: colors.white,
    color: colors.accentBlue,
    marginRight: '24px',
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  } as SystemCssProperties,

  NavLink: {
    width: '196px',
    textDecoration: 'none',
    color: colors.white,
    textTransform: 'none',
    height: '100%',
    padding: '10px 16px',
    boxShadow: 'none',
    '&:hover': {
      background: colors.accentBlue,
      boxShadow: 'none',
    },
    '&.MuiButton-root': {
      padding: '0px',
    },
  } as SystemCssProperties,

  btnParameter: {
    width: '246px',
    textDecoration: 'none',
    mr: 3,
    background: colors.white,
    color: colors.accentBlue,
    textTransform: 'none',
    padding: '10px 16px',
    boxShadow: 'none',
    '&:hover': {
      background: colors.white,
      boxShadow: 'none',
    },
    '&.MuiButton-root': {
      padding: '0px',
    },
  } as SystemCssProperties,
};

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  additionalInfoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    marginBottom: {
      xl: '0',
    },
  } as SystemCssProperties,

  paragraph: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  } as SystemCssProperties,

  content: {
    display: 'flex',
    justifyContent: 'space-between',
  } as SystemCssProperties,

  additionalInfoLeft: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    color: colors.white,
  } as SystemCssProperties,

  additionalInfoRight: {
    display: 'flex',
  } as SystemCssProperties,

  additionalInfoLeftTypo: {
    fontSize: '24px',
    fontWeight: 600,
    position: 'relative',
    textAlign: 'left',
    top: '-38px',
    color: colors.white,
  } as SystemCssProperties,

  additionalInfoLeftBtnRow: {
    marginBottom: '16px',
  } as SystemCssProperties,

  Left: {
    width: '620px',
    fontFamily: 'Poppins Regular, sans-serif',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  } as SystemCssProperties,

  additionalInfoLeftBtnItem: {
    width: '164px',
    height: '56px',
    marginRight: '16px',
    color: colors.accentBlue,
    background: 'white',
    textTransform: 'none',
    '&:hover': {
      background: 'white',
    },
  } as SystemCssProperties,

  NavLink: {
    marginTop: '40px',
    textDecoration: 'none',
    color: colors.white,
    textTransform: 'none',
    padding: '10px 16px',

    '&.MuiButton-root': {
      padding: '0px',
    },
  } as SystemCssProperties,

  save: {
    '& > a': {
      textDecoration: 'none',
    },
  } as SystemCssProperties,

  NavLinkInstruct: {
    background: colors.white,
    '&.MuiButton-root': {
      padding: '0px',
    },
    height: '72px',
    width: '210px',

    '&:hover': {
      background: colors.white,
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.accentBlue,
      textTransform: 'none',
      width: '100%',
      height: '100%',
      padding: '10px 16px',
    },
  } as SystemCssProperties,

  hidden: {
    position: 'absolute',
    width: '1000px',
    zIndex: -2,
    top: -9999,
  } as SystemCssProperties,
};

import { SystemCssProperties } from '@mui/system';

export const styles = {
  marginBox: {
    mb: 2,
  } as SystemCssProperties,

  WrapperBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
  } as SystemCssProperties,
};

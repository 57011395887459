import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const stylesHeaderTable = {
  clearBorder: {
    border: 'none',
  } as SystemCssProperties,

  tableRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  } as SystemCssProperties,

  tableCell: {
    display: 'flex',
    width: '100%',
    maxWidth: '291px',
  } as SystemCssProperties,

  info: {
    position: 'static',
    marginLeft: 1,
  } as SystemCssProperties,

  TableCellInner: {
    display: 'flex',
    alignItems: 'center',
  } as SystemCssProperties,

  popupInfo: {
    maxWidth: '157px',
    left: '168px',
    bottom: '-37px',
  },

  tableCellHeader: {
    background: colors.white,
    zIndex: '99',
    fontWeight: '500',
    fontSize: '14px',
    color: colors.darkGrey,
    border: 'none',
    '&:last-child': {
      borderRadius: '0 8px 0 0',
    },
    '&:first-of-type': {
      borderRadius: '8px 0 0 0',
    },
    position: 'sticky',
    top: 0,
    '& > p': {
      textOverflow: 'ellipsis',
      whiteSpace: 'pre-line',
      width: 'fix-content',
      minWidth: '200px',
    },
  } as SystemCssProperties,

  borderRight: {
    borderRadius: '0 8px 0 0',
  } as SystemCssProperties,

  borderLeft: {
    borderRadius: '8px 0 0 0',
  } as SystemCssProperties,
};

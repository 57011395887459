import React from 'react';
import { Box } from '@mui/system';
import { styles } from './styles';
import { Typography } from '@mui/material';
import { descriptions, steps } from './descriptions';
import { useHistory, useParams } from 'react-router-dom';
import MuiButton from 'shared/components/UI/MuiButton';
import { RoutesPage } from 'shared/routes';
import NavigationBar from 'shared/components/NavigationBar';

const WelcomeScreen = (): JSX.Element => {
  const history = useHistory();
  const id = useParams<{ id: string }>().id ?? localStorage.getItem('projectId');

  const navigateToFirstStep = (): void => {
    history.push(`${RoutesPage.project.page}${id}${RoutesPage.steps.step1}${RoutesPage.steps.transition}${history.location.search}`);
  };

  return (
    <Box sx={ styles.container }>
      <Box sx={ styles.content }>
        <Typography variant="h1">Välkommen!</Typography>

        <Box sx={ styles.descriptions }>
          {
            descriptions.map((description) =>
              <Typography variant="subtitle1" component="p" color="inherit" key={description}>
                { description }
              </Typography>)
          }
        </Box>

        <Box sx={ styles.steps }>
          {
            steps.map((step, index) =>
              <Box sx={styles.step} key={step.name}>
                <span>{ index + 1 }. { step.name }</span>

                <span>[CA { step.time } MINUTER]</span>
              </Box>,
            )
          }
          <Box sx={{ ...styles.step, marginTop: '8px' }}>
            <span>Total tid</span>

            <span style={{ marginRight: '20px' }}>[CA 4 TIMMAR]</span>
          </Box>
        </Box>

        <MuiButton type="button" variant="contained" sx={ styles.NavLink } onClick={() => navigateToFirstStep()}>
          Fortsätt
        </MuiButton>

        <Box sx={styles.navigationBar}>
          <NavigationBar isLeftButtonDisabled={true} navigateBack={() => {}} navigateForward={() => navigateToFirstStep()} />
        </Box>
      </Box>
    </Box>
  );
};

export default WelcomeScreen;

import { ItemI } from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/initialStateData';
import { applyQuadrantToOldParameterValue } from 'shared/helpers';
import { actions as actionsPopup } from 'shared/store/popup';

export const onDragEnd = (result, boards, setBoards, currentBoard, currentItem, dispatch) => {
  const { destination, source } = result;
  if (!destination) {
    return;
  }
  if (destination.droppableId === source.droppableId && destination.index === source.index) {
    return;
  }

  const currentIndex = currentBoard!.items.indexOf(currentItem as ItemI);
  if (currentIndex !== -1) {
    currentBoard?.items.splice(currentIndex, 1);
  }
  const dropBoard = boards.find((item) => item.id === destination.droppableId);
  const dropOverCard = dropBoard.items.find((item, index) => index === destination.index);
  const dropIndex = dropBoard.items.indexOf(dropOverCard);

  dropBoard.items.splice(dropIndex, 0, currentItem);

  const calculateItemsBoard = dropBoard.items.map((item) => {
    return {
      ...item,
      quadrant: dropBoard.id,
      calculateValue: applyQuadrantToOldParameterValue(item.values[0], dropBoard.id),
    };
  });

  dispatch(actionsPopup.popupActions.toggle(true));

  setBoards(
    boards.map((board) => {
      if (board.id === dropBoard.id) {
        return {
          ...dropBoard,
          items: calculateItemsBoard,
        };
      }
      if (board.id === currentBoard?.id) {
        return currentBoard;
      }
      return board;
    }),
  );
};

export const onDragStart = (result, boards, setCurrentItem, setCurrentBoard) => {
  const { source } = result;
  const currentSource = boards?.find((item: any) => item.id === source.droppableId);
  const currentSourceItem = currentSource?.items.find((item: any, index) => index === source.index);

  if (currentSource && currentSourceItem) {
    setCurrentItem(currentSourceItem);
    setCurrentBoard(currentSource);
  }
};

import colors from 'styles/_Variables';
import {StylesCSS} from 'shared/interfaces';

export const stylesDiagramContainer = (): StylesCSS => {
  return {
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      border: 0,
      zIndex: '9999',
      background: colors.white,
      overflow: 'hidden',
      width: '100%',
      height: '100vh',
      margin: 0,
    },
  };
};

export const styles: StylesCSS = {
  container: {
    width: '100%',
  },

  diagramInnerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },

  diagramInnerContainerText: {
    fontSize: '24px',
    color: colors.white,
    fontWeight: 600,
  },

  buttonDiagram: {
    display: 'flex',
    marginTop: '24px',
    marginBottom: '120px',
  },

  buttonTextDiagram: {
    color: colors.white,
  },
};

import React, {
  useRef,
  useState,
} from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TableHeader from 'components/WebTools/ProjectProcess/Steps/Step_5/Table/header';
import { FormikProvider } from 'formik';
import WrapperTableCell from 'components/WebTools/ProjectProcess/Steps/Step_5/Table/WrapperTableCell';
import WrapperField from 'components/WebTools/ProjectProcess/Steps/Step_5/Table/WrapperField';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_5/Table/styles';
import { checkChangeValue } from 'components/WebTools/ProjectProcess/Steps/helpers';
import { useDispatch } from 'react-redux';
import { EventType } from 'shared/interfaces';
import { useCheckPermissions } from 'shared/hooks/useCheckPermissions';
import { Roles } from 'shared/constants/enum';
import {getWidthByColumnName} from '../TableContainer/helpers';

interface Props {
  [key: string]: any;

  onHandleUpdate: (event: EventType) => void;
  formik: any;
  rowsLength: number;
  rows: any;
}

export interface Header {
  name: string;
}

const dataHeader: Header[] = [
  {name: 'Riksbyggens parametrar'},
  {name: 'Värde'},
  {name: 'Nytt värde'},
  {name: 'Beskrivning (valfritt)'},
];

enum TableKeys {
  Name = 'name',
  Description = 'description',
  Riksbyggen = 'riksbyggen',
  OverriddenValue = 'overriddenValue',
}

export const correctKeysOfTable = [TableKeys.Name, TableKeys.Riksbyggen, TableKeys.OverriddenValue, TableKeys.Description];

type Row = {
  name: string;
  description: string;
  riksbyggen: string;
  overriddenValue: string;
  quadrant?: string | null;
};

const checkKeySequence = (data: any) => {
  return correctKeysOfTable.map((item: string) => {
    return data.find((arr) => arr[0] === item);
  });
};

const TableStep5 = (props: Props): JSX.Element => {
  const {formik, rows, onHandleUpdate} = props;
  const dispatch = useDispatch();
  const [isEdit, setEditMode] = useState<boolean>(false);
  const [indexColumn, setIndexCall] = useState<number>(0);
  const [indexRow, setIndexRow] = useState<number>(0);
  const previousValueRef = useRef<string | number>('');

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const activateEditMode = (indexR: number, indexCol: number) => {
    if (isMU) {
      return;
    }
    setEditMode(true);
    setIndexCall(indexCol);
    setIndexRow(indexR);
  };

  const deactivateEditMode = () => {
    setEditMode(false);
  };

  const changeHandleBlur = (event) => {
    checkChangeValue(previousValueRef.current, event.target.value, dispatch);
    deactivateEditMode();
    formik.handleBlur(event);
    if (previousValueRef.current !== event.target.value) {
      onHandleUpdate(event);
    }
  };

  const onFocusField = (event: EventType) => {
    return (previousValueRef.current = event.target.value);
  };

  const onChangeField = (event: EventType, indexR: number) => {
    formik.setFieldValue(`newCompetitionParameters.${indexR}.isEdited`, true);
    formik.setFieldValue(`newCompetitionParameters.${indexR}.overriddenValue`, event.target.value);
    formik.handleChange(event);
  };

  const renderMuiField = (indexR: number, indexC: number, prop: string) => {
    return (
      <WrapperField
        name={`newCompetitionParameters.${indexR}.${prop}`}
        onChange={(event) => onChangeField(event, indexR)}
        onFocus={onFocusField}
        onBlur={changeHandleBlur}
        key={indexC}
      />
    );
  };

  const renderTableCell = (indexR: number, indexC: number, prop: string, value: string) => {
    return (
      <WrapperTableCell
        width={getWidthByColumnName(prop)}
        isDisabled={prop !== 'overriddenValue'}
        value={value}
        activateEditMode={prop === 'overriddenValue' ? () => activateEditMode(indexR, indexC) : null}
        indexR={indexR}
        errors={formik.errors}
        touched={formik.touched}
        prop={prop}
        key={indexC}
      />
    );
  };

  const renderTable = (rowsLen: Row[]) => {
    if (rowsLen.length === 0) {
      return renderEmptyTable(18);
    }
    return [...rowsLen, ...renderEmptyTable(18 - rowsLen.length)];
  };

  const renderEmptyTable = (rowsLength: number): Row[] => {
    return Array.from({length: rowsLength}, () => ({
      name: '',
      description: '',
      riksbyggen: '',
      overriddenValue: '',
    }));
  };

  return (
    <FormikProvider value={formik}>
      <TableContainer component={Paper} sx={{...styles.tableCont, ...styles.tableHidden}}>
        <Table sx={{...styles.tableW, ...styles.tableCont}} aria-label="simple table">
          <TableHead>
            <TableHeader dataHeader={dataHeader}/>
          </TableHead>
          <TableBody>
            {renderTable(rows).map((row, indexR) => {
              return (
                <TableRow key={indexR}>
                  {checkKeySequence(Object.entries(row)).map(([key, value], inxC) => {
                    if (correctKeysOfTable.includes(key)) {
                      return isEdit && inxC === indexColumn && indexR === indexRow
                        ? renderMuiField(indexR, inxC, key)
                        : renderTableCell(indexR, inxC, key, formik?.values?.newCompetitionParameters?.[indexR]?.[key]);
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </FormikProvider>
  );
};

export default TableStep5;

import * as React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {styles} from './styles';
import MuiButton from 'shared/components/UI/MuiButton';
import Box from '@mui/material/Box';

export interface Props {
  isOpenPopup: boolean;
  closePopup: () => void;
  onHandleAction: (url: string) => void;
}

const ModalRedirect = (props: Props): JSX.Element => {
  const {isOpenPopup, closePopup, onHandleAction} = props;
  return (
    <Box sx={styles.wrapperDialog}>
      <Dialog sx={styles.dialog} open={isOpenPopup} onClose={closePopup}>
        <DialogTitle sx={styles.dialogTitle}>
          <Typography variant="subtitle2" component="span">
            Se projektinfo
          </Typography>
          <CloseIcon sx={styles.closeIcon} fontSize="small" onClick={closePopup}/>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={styles.dialogContentText}>
            <Typography variant="subtitle2" component="span">
              Om du lämnar nu kommer du att förlora all osparad data. Fortsätt?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <MuiButton sx={styles.dialogFirstButtonAction} onClick={closePopup} variant="contained" type="button">
            Avboka
          </MuiButton>
          <MuiButton sx={styles.dialogSecondButtonAction} onClick={onHandleAction} variant="contained" type="button">
            OK
          </MuiButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ModalRedirect;

import colors from 'styles/_Variables';
import { SystemCssProperties } from '@mui/system';

export const styles = {
  info: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    cursor: 'pointer',
    right: '15px',
    top: '13px',
  } as SystemCssProperties,
  infoIcon: {
    fill: colors.accentBlue,
  } as SystemCssProperties,
};

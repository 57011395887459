import * as React from 'react';
import {useEffect} from 'react';
import LayoutBackgroundGame from 'shared/components/Layout/LayoutBackgroundGame';
import HeaderProjectProcess from 'shared/components/HeaderProjectProcess';
import NavProgressMap from 'components/WebTools/ProjectProcess/NavProgressMap';
import Steps from './NavProgressMap/Steps';
import {Route, useHistory, useParams} from 'react-router-dom';
import {StepsTab} from 'components/WebTools/ProjectProcess/NavProgressMap/_mock_';
import {useTypedSelector} from 'shared/hooks/useTypedSelector';
import {useDispatch} from 'react-redux';
import {actions} from 'shared/store/project';
import {useCheckPermissions} from '../../../shared/hooks/useCheckPermissions';
import {Roles} from '../../../shared/constants/enum';
import {useCurrentStep} from 'shared/hooks/useCurrentStep';
import { Box } from '@mui/system';

const ProjectProcess = (): JSX.Element => {
  const {projectData} = useTypedSelector((state) => state.project.getProject);
  const {location} = useHistory();
  const {id} = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const step = useCurrentStep(false);
  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const isFinished = location.pathname.includes('finish');
  const isWelcome = location.pathname.includes('welcome');
  const isTransition = location.pathname.includes('transition');

  useEffect(() => {
    if (projectData && !isMU && !isFinished) {
      dispatch(
        actions.updateProjectActions.start({
          id,
          updateProjectWithoutFiles: {
            currentStep: step,
          },
        }),
      );
    }
  }, [location]);

  const isShowStepper = (): boolean => {
    if (isWelcome) {
      return false;
    }

    return !projectData?.reportUrl;
  };

  const isShowHeader = (): boolean => {
    if (isTransition) {
      return false;
    }

    return !projectData?.reportUrl;
  };

  return (
    <LayoutBackgroundGame>
      {
        isShowHeader() && <HeaderProjectProcess/>
      }
      <Box sx={{ display: 'flex', flex: 1 }}>
        <Steps>
          <Route
            render={(prop) =>
              StepsTab.map((tab) => {
                return <Route path={`${prop.match.path}${tab.path}`} component={tab.component} key={tab.path} exact={false}/>;
              })
            }/>
        </Steps>
      </Box>
      { isShowStepper() && <NavProgressMap/>}
    </LayoutBackgroundGame>
  );
};

export default ProjectProcess;

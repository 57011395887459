import * as React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/Table/header/styles';

interface Props {
  dataHeader: { name: string }[];
}

const TableHeader = (props: Props): JSX.Element => {
  const { dataHeader } = props;

  return (
    <TableRow sx={styles.clearBorder}>
      {dataHeader &&
        dataHeader.map((column: any, index: number) => (
          <TableCell sx={styles.tableCellHeader} key={index}>
            <Typography sx={styles.cellHeader}>{column.name}</Typography>
          </TableCell>
        ))}
    </TableRow>
  );
};

export default TableHeader;

import * as React from 'react';
import { FormGroup } from '@mui/material';
import { Box } from '@mui/system';

import IconBlock from 'components/WebTools/MainForm/ProjectForm/Forms/IconBlock';
import MuiSelect from 'shared/components/UI/MuiSelect';
import MuiCheckbox from 'shared/components/UI/MuiChecbox';
import { productsTypes } from 'components/WebTools/MainForm/ProjectForm/dataSelect';

import ProductLine from 'shared/components/Icons/ProductLine';
import TargetSolid from 'shared/components/Icons/TargetSolid';
import { styles } from 'components/WebTools/MainForm/ProjectForm/Forms/styles';
import { styles as stylesDetails } from './styles';

import { DetailsType, Project } from 'shared/store/project/interface';
import { useFormikContext } from 'formik';
import FormikField from 'shared/components/UI/FormikField';
import { useCheckPermissions } from 'shared/hooks/useCheckPermissions';
import { Roles } from 'shared/constants/enum';

const Details = () => {
  const { values, handleChange } = useFormikContext<Project>();
  const stateValues: DetailsType = values.details;

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  return (
    <Box sx={styles.General}>
      <Box sx={styles.GeneralWrapper}>
        <Box sx={stylesDetails.formItem}>
          <IconBlock title="Produktionslinje" icon={<ProductLine />} />
          <Box sx={styles.fieldWrapper}>
            <MuiSelect
              items={productsTypes}
              name="details.production"
              value={stateValues.production ?? ''}
              onHandleChange={handleChange}
              placeholder="Välj typ"
              isDisabled={isMU}/>
          </Box>
        </Box>
        <Box sx={stylesDetails.formItem}>
          <Box sx={{ mt: '5px' }}>
            <IconBlock title="Tilltänkt målgrupp/målgrupper" icon={<TargetSolid />} />
          </Box>

          <FormGroup sx={stylesDetails.formControl}>
            <Box sx={styles.General}>
              <Box sx={stylesDetails.marginBox}>
                <MuiCheckbox
                  name="details.targetGroup.young"
                  onChange={handleChange}
                  isChecked={stateValues.targetGroup.young}
                  label="Unga"/>
              </Box>
              <Box sx={stylesDetails.marginBox}>
                <MuiCheckbox
                  name="details.targetGroup.middle"
                  onChange={handleChange}
                  isChecked={stateValues.targetGroup.middle}
                  label="Mitt i livet"/>
              </Box>
              <Box sx={stylesDetails.marginBox}>
                <MuiCheckbox
                  name="details.targetGroup.elderly"
                  onChange={handleChange}
                  isChecked={stateValues.targetGroup.elderly}
                  label="Seniorer"/>
              </Box>
            </Box>

            <Box sx={styles.General}>
              <Box sx={stylesDetails.marginBox}>
                <MuiCheckbox
                  name="details.targetGroup.adultsWithoutChildren"
                  onChange={handleChange}
                  isChecked={stateValues.targetGroup.adultsWithoutChildren}
                  label="Vuxna utan barn"/>
              </Box>
              <Box sx={stylesDetails.marginBox}>
                <MuiCheckbox
                  name="details.targetGroup.families"
                  onChange={handleChange}
                  isChecked={stateValues.targetGroup.families}
                  label="Familjer"/>
              </Box>
            </Box>
          </FormGroup>
        </Box>
        <Box sx={stylesDetails.textAreaItem}>
          <FormikField
            type="text"
            multiline
            maxLength={500}
            rows={4.5}
            name="details.comments"
            placeholder="Kommentarer"
            value={stateValues.comments}
            onChange={handleChange}/>
        </Box>
      </Box>
    </Box>
  );
};

export default Details;

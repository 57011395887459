import React from 'react';
import {Box} from '@mui/material';

import LayoutBackgroundAuth from 'shared/components/Layout/LayoutBackgroundAuth';
import {authRoutes, RoutesPage} from 'shared/routes';
import {Route, useLocation} from 'react-router-dom';
import {styles} from './styles';

import logo from 'assets/images/logo.png';
import logoSmall from 'assets/images/logoSmall.png';

const AuthContainer = (): JSX.Element => {
  const location = useLocation();
  const currentLogo = location.pathname.includes(RoutesPage.registration) ? logoSmall : logo;

  return (
    <>
      <LayoutBackgroundAuth isStartScreen={false}>
        <Box sx={styles.container}>
          <Box sx={styles.logo}>
            <img src={currentLogo} alt="not found"/>
          </Box>
          <Box sx={styles.form}>
            <Route
              render={() =>
                authRoutes.map((route) => <Route path={route.path} component={route.component} exact={route.exact} key={route.path}/>)
              }/>
          </Box>
        </Box>
      </LayoutBackgroundAuth>
    </>
  );
};

export default AuthContainer;

import React from 'react';

const TargetSolid = (): JSX.Element => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1009_264)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.236 18.1668C21.0601 16.9334 21.5 15.4834 21.5 14C21.5 12.0109 20.7098 10.1032 19.3033 8.6967C17.8968
                7.29018 15.9891 6.5 14 6.5C12.5166 6.5 11.0666 6.93987 9.83323 7.76398C8.59986 8.58809 7.63856 9.75943 7.07091
                11.1299C6.50325 12.5003 6.35472 14.0083 6.64411 15.4632C6.9335 16.918 7.64781 18.2544 8.6967 19.3033C9.7456
                20.3522 11.082 21.0665 12.5368 21.3559C13.9917 21.6453 15.4997 21.4968 16.8701 20.9291C18.2406 20.3614 19.4119
                19.4001 20.236 18.1668ZM17.7416 16.5001C18.2361 15.76 18.5 14.89 18.5 14C18.5 12.8065 18.0259 11.6619 17.182
                10.818C16.3381 9.97411 15.1935 9.5 14 9.5C13.11 9.5 12.24 9.76392 11.4999 10.2584C10.7599 10.7529 10.1831
                11.4557 9.84254 12.2779C9.50195 13.1002 9.41283 14.005 9.58647 14.8779C9.7601 15.7508 10.1887 16.5526
                10.818 17.182C11.4474 17.8113 12.2492 18.2399 13.1221 18.4135C13.995 18.5872 14.8998 18.4981 15.7221
                18.1575C16.5443 17.8169 17.2471 17.2401 17.7416 16.5001Z"
          fill="#F54F8F"/>
        <path
          d="M15.5 14C15.5 14.2967 15.412 14.5867 15.2472 14.8334C15.0824 15.08 14.8481 15.2723 14.574 15.3858C14.2999
              15.4994 13.9983 15.5291 13.7074 15.4712C13.4164 15.4133 13.1491 15.2704 12.9393 15.0607C12.7296 14.8509
              12.5867 14.5836 12.5288 14.2926C12.4709 14.0017 12.5007 13.7001 12.6142 13.426C12.7277 13.1519 12.92
              12.9176 13.1666 12.7528C13.4133 12.588 13.7033 12.5 14 12.5C14.3978 12.5 14.7794 12.658 15.0607
              12.9393C15.342 13.2206 15.5 13.6022 15.5 14Z"
          fill="#F54F8F"/>
        <path
          d="M14.0008 0.5C11.3306 0.499769 8.72025 1.29137 6.49994 2.77469C4.27963 4.25801 2.54904 6.36644 1.52704 8.83332C0.505029
              11.3002 0.237506 14.0148 0.758297 16.6337C1.27909 19.2526 2.5648 21.6583 4.45285 23.5465C6.34089 25.4347 8.74646 26.7207
              11.3654 27.2417C13.9842 27.7627 16.6988 27.4954 19.1658 26.4736C21.6328 25.4518 23.7413 23.7214 25.2249 21.5012C26.7084
              19.281 27.5002 16.6708 27.5002 14.0006C27.5004 12.2277 27.1513 10.4722 26.473 8.83422C25.7946 7.19626 24.8003 5.70796
              23.5467 4.4543C22.2932 3.20064 20.805 2.20618 19.1671 1.52769C17.5292 0.849212 15.7736 0.5 14.0008 0.5ZM14.0008
              23.9821C12.0266 23.9821 10.0968 23.3967 8.45534 22.2999C6.81388 21.2031 5.53452 19.6442 4.77905 17.8204C4.02357
               15.9965 3.8259 13.9895 4.21104 12.0533C4.59618 10.1171 5.54683 8.33852 6.94277 6.94257C8.33871 5.54663 10.1173
                4.59598 12.0535 4.21084C13.9897 3.8257 15.9967 4.02337 17.8205 4.77885C19.6444 5.53433 21.2033 6.81368 22.3001
                 8.45514C23.3969 10.0966 23.9823 12.0264 23.9823 14.0006C23.9823 16.6479 22.9307 19.1867 21.0588 21.0586C19.1869
                  22.9305 16.648 23.9821 14.0008 23.9821Z"
          fill="#F54F8F"/>
      </g>
      <defs>
        <clipPath id="clip0_1009_264">
          <rect width="27" height="27" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TargetSolid;

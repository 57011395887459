import React from 'react';
import StepTransition from 'shared/components/StepTransition';
import styles from './styles';

const TransitionStep5 = (): JSX.Element => {
  return (
    <StepTransition
      svg={
        <svg id="svg5" viewBox="0 0 408 77" style={styles.path}>
          <defs>
            <mask id="msk5">
              <rect width="120%" height="150%" fill="white" />
              <path
                stroke="black"
                strokeWidth="11"
                fill="none"
                strokeDashoffset="700"
                strokeDasharray="700"
                strokeLinecap="round"
                d="M 1.746 82.654 C 1.746 82.654 43.659 29.082 101.862 18.044 C 147.098 9.465 195.087 45.659 240.977 41.909
           C 276.412 39.013 298.499 4.793 332.945 4.657 C 381.526 4.466 410.361 43.073 410.361 43.073"
              >
                <animate attributeName="stroke-dashoffset" dur="4s" begin="0s" values="0;700;" fill="freeze" />
              </path>
            </mask>
          </defs>
          <path
            className="dashed"
            mask="url(#msk5)"
            d="M 1.746 82.654 C 1.746 82.654 43.659 29.082 101.862 18.044 C 147.098 9.465 195.087 45.659 240.977 41.909
           C 276.412 39.013 298.499 4.793 332.945 4.657 C 381.526 4.466 410.361 43.073 410.361 43.073"/>
        </svg>
      }
      stepName="Den nya positionen"
      stepNumber={5}/>
  );
};

export default TransitionStep5;

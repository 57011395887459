import React from 'react';

const Picture = (): JSX.Element => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_240_3407)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.7526 11.0627H7.88059C7.43312 11.0627 7.00397 11.2405
           6.68756 11.5569C6.37115 11.8733 6.19339 12.3024 6.19339 12.7499V26.2475C6.19339 26.695 6.37115 27.1241
           6.68756 27.4405C7.00397 27.7569 7.43312 27.9347 7.88059 27.9347H24.7526C25.2001 27.9347 25.6292 27.7569
           25.9456 27.4405C26.262 27.1241 26.4398 26.695 26.4398 26.2475V12.7511C26.4399 12.5294 26.3964 12.3099
           26.3117 12.1051C26.227 11.9002 26.1027 11.7141 25.946 11.5573C25.7893 11.4005 25.6033 11.2761 25.3985
           11.1913C25.1937 11.1064 24.9742 11.0627 24.7526 11.0627ZM7.88059 9.37551C7.43634 9.37392 6.99616
           9.46007 6.58528 9.62902C6.17441 9.79796 5.80094 10.0464 5.48631 10.36C5.17167 10.6736 4.92206
           11.0463 4.7518 11.4566C4.58154 11.867 4.49398 12.3069 4.49414 12.7511V26.2487C4.49414 26.6918
           4.58142 27.1306 4.751 27.54C4.92058 27.9494 5.16914 28.3214 5.48248 28.6348C5.79582
           28.9481 6.16781 29.1967 6.57721 29.3662C6.98661 29.5358 7.42541 29.6231 7.86854
           29.6231H24.7405C25.1837 29.6231 25.6225 29.5358 26.0319 29.3662C26.4413
           29.1967 26.8132 28.9481 27.1266 28.6348C27.4399 28.3214 27.6885 27.9494
           27.8581 27.54C28.0276 27.1306 28.1149 26.6918 28.1149
           26.2487V12.7511C28.1149 11.8562 27.7594 10.9979 27.1266 10.3651C26.4938 9.73223 25.6355 9.37671
           24.7405 9.37671L7.88059 9.37551Z"
          fill="#40DBE4"/>
        <path
          d="M22.4675 18.9044C22.5929 18.7794 22.7544 18.697 22.9291 18.6689C23.1039 18.6408 23.283 18.6684
          23.4413 18.7477L26.4397 22.0341V27.9393H6.18726V26.2521L10.6535 22.2752C10.7914 22.1377 10.9725 22.0522
           11.1663 22.0331C11.36 22.014 11.5544 22.0625 11.7164 22.1703L16.2056 25.1627L22.4723 18.8959L22.4675 18.9044Z"
          fill="#40DBE4"/>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0901 19.5022C12.5906 19.5022 13.0799 19.3538
          13.4961 19.0757C13.9123 18.7976 14.2367 18.4023 14.4282 17.9399C14.6198 17.4774 14.6699 16.9686
          14.5722 16.4776C14.4746 15.9867 14.2336 15.5358 13.8796 15.1818C13.5257
          14.8279 13.0747 14.5869 12.5838 14.4892C12.0929 14.3916 11.584 14.4417 11.1216
          14.6332C10.6591 14.8248 10.2639 15.1492 9.98579 15.5653C9.7077 15.9815 9.55927
          16.4708 9.55927 16.9714C9.55927 17.6426 9.82591 18.2863 10.3005 18.7609C10.7751
          19.2355 11.4189 19.5022 12.0901 19.5022ZM11.2465 7.69179H28.1185C28.5659 7.69179 28.9951 7.86955 29.3115
           8.18596C29.6279 8.50237 29.8057 8.93152 29.8057 9.37899V22.8766C29.8057 23.3241 29.6279 23.7532 29.3115 24.0696C28.9951 24.386
           28.5659 24.5638 28.1185 24.5638V26.251C29.0134 26.251 29.8717 25.8955 30.5045 25.2626C31.1373 24.6298 31.4929 23.7715 31.4929
           22.8766V9.37537C31.4929 8.48043 31.1373 7.62214 30.5045 6.98931C29.8717 6.35649 29.0134 6.00098 28.1185 6.00098H11.2465C10.3515
            6.00098 9.49323 6.35649 8.86041 6.98931C8.22759 7.62214 7.87207 8.48043 7.87207 9.37537H9.55927C9.55911 9.15371 9.60263 8.93418
             9.68735 8.72934C9.77207 8.5245 9.89633 8.33836 10.053 8.18157C10.2097 8.02477 10.3957 7.90038 10.6005 7.81552C10.8053 7.73065
             11.0248 7.68697 11.2465 7.68697V7.69179Z"
          fill="#40DBE4"/>
      </g>
      <defs>
        <clipPath id="clip0_240_3407">
          <rect width="27" height="23.628" fill="white" transform="translate(4.5 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Picture;

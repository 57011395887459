import * as React from 'react';
import { memo } from 'react';
import { Box } from '@mui/system';

import IconBlock from 'components/WebTools/MainForm/ProjectForm/Forms/IconBlock';
import Download from 'components/WebTools/MainForm/ProjectForm/Uploader/Download';
import Upload from 'components/WebTools/MainForm/ProjectForm/Uploader/Upload';

import { styles } from './styles';

export interface Props {
  title: string;
  icon: JSX.Element;
  name: string;
  nameField: string;
  accept: string[];
}

const DownloadBox = (props: Props): JSX.Element => {
  const { title, icon, name, nameField, accept } = props;

  return (
    <Box sx={styles.upload}>
      <Box sx={styles.uploadWrapper}>
        <IconBlock title={title} icon={icon} />
        <Box sx={styles.fileUploaderWrapper}>
          <Box sx={styles.fileUploader}>
            <Download name={name} accept={accept} nameField={nameField} />
          </Box>
        </Box>
      </Box>
      <Upload name={name} nameField={nameField} />
    </Box>
  );
};

export default memo(DownloadBox);

import { SystemCssProperties } from '@mui/system';

export const styles = {
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: 'white',
  } as SystemCssProperties,

  Loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as SystemCssProperties,

  Icon: {
    marginRight: '12px',
  } as SystemCssProperties,

  modalButtonsItem: {
    '&:hover': {
      background: 'none',
    },

    width: '85px',
    justifyContent: 'space-between',
  } as SystemCssProperties,

  modalFirstBtn: {
    marginRight: '40px',
  } as SystemCssProperties,
};

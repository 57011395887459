import axios from 'axios';
import { actions } from 'shared/store/auth';
import { createBrowserHistory } from 'history';
import { RoutesPage } from 'shared/routes';
import { forbiddenRoutes } from 'shared/components/AppRouter';
import { reactHistory } from 'core/App';

let storeInject;

export const injectStore = (store) => {
  storeInject = store;
};

export const history = createBrowserHistory();

export const API_URL = process.env.REACT_APP_API;

const HTTP = axios.create({
  baseURL: API_URL,
});

HTTP.interceptors.request.use(async (config) => {
  if (localStorage.getItem('accessToken')) {
    config.headers!.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  }
  return config;
});

HTTP.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('accessToken');
      storeInject.dispatch(actions.loginActions.error({}));
      const [, ...routes] = forbiddenRoutes;
      const isForbiddenRoute = routes.some((route) => history.location.pathname.includes(route));
      if (!isForbiddenRoute) {
        reactHistory(RoutesPage.login);
      }
    }
    return Promise.reject(error);
  },
);

export default HTTP;

import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { AcceptInvite, RequestUser } from 'shared/store/auth/interface';
import { authService } from 'core/services/auth';
import { RoutesPage } from 'shared/routes';
import jwt_decode from 'jwt-decode';
import { Roles } from 'shared/constants/enum';

interface Props {
  payload: Pick<AcceptInvite, 'token' | 'password'> & Pick<RequestUser, 'history'>;
}

export function* getUpdatePassword(props: Props): SagaIterator {
  const {
    payload: { token, password, history },
  } = props;
  const isAdminReset = history.location.pathname.includes(RoutesPage.admin);

  try {
    let response;
    if (isAdminReset) {
      response = yield call(authService.resetPasswordAdmin, 'auth/reset-password/admin', { token, password });
    } else {
      response = yield call(authService.resetPassword, 'auth/reset-password/user', { token, password });
    }
    localStorage.setItem('accessToken', response.accessToken);
    const tokenLocal = localStorage.getItem('accessToken');
    yield put(actions.updatePasswordActions.end());
    yield put(actions.loginActions.end(response));
    if (tokenLocal) {
      const decoded: any = jwt_decode(tokenLocal as string);
      if (decoded.roles[0] === Roles.ADMIN) {
        history.push(RoutesPage.adminPanel.activeUsers);
      } else {
        history.push(RoutesPage.home);
      }
    }
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.updatePasswordActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* getUpdatePasswordSaga(): SagaIterator {
  yield takeLatest(actions.updatePasswordActions.start, getUpdatePassword);
}

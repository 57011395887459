import React, { useMemo } from 'react';
import SubStepperRoutes from './SubStepperRoutes';
import { useLocation } from 'react-router-dom';
import { Router, SubRoutes, notFound } from 'shared/routes';

function initRoute(neededPath: string) {
  return Object.entries(SubRoutes).reduce((accum: any, [key, item]) => {
    if (key === neededPath) {
      accum = item;
    }
    return accum;
  }, []);
}

const ContainerSubStepperRoutes = (): JSX.Element => {
  const neededPath = useLocation()
    .pathname.split('/')
    .find((item) => item.includes('step='));
  const routes: Router[] = useMemo(() => initRoute(neededPath ?? ''), [neededPath]);
  return <SubStepperRoutes stepRoutes={routes ?? notFound} />;
};

export default ContainerSubStepperRoutes;

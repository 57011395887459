import React, { ReactNode } from 'react';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Minus from 'shared/components/Icons/Collapse/Minus';
import Plus from 'shared/components/Icons/Collapse/Plus';

import { style } from './styles';

interface Props {
  expanded: string | boolean;
  isOpenExpanded: string;
  children: ReactNode;
}

const AccordionSummary = (props: Props): JSX.Element => {
  const { expanded, isOpenExpanded, children, ...rest } = props;
  return (
    <MuiAccordionSummary sx={style.accordionSummary} expandIcon={expanded === isOpenExpanded ? <Minus /> : <Plus />} {...rest}>
      {children}
    </MuiAccordionSummary>
  );
};

export default AccordionSummary;

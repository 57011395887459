import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { styles } from './styles';
import MuiButton from 'shared/components/UI/MuiButton';
import Box from '@mui/material/Box';

export interface Props {
  isOpenPopup: boolean;
  closePopup: () => void;
  deleteProject: () => void;
}

const DeleteModal = (props: Props): JSX.Element => {
  const { isOpenPopup, closePopup, deleteProject } = props;
  return (
    <Box sx={styles.wrapperDialog}>
      <Dialog sx={styles.dialog} open={isOpenPopup} onClose={closePopup}>
        <DialogTitle sx={styles.dialogTitle}>
          <Typography variant="subtitle2" component="p">
            Ta bort projekt
          </Typography>
          <CloseIcon sx={styles.closeIcon} fontSize="small" onClick={closePopup} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={styles.dialogContentText}>
            <Typography variant="subtitle2" component="p">
              Är du säker på att du vill ta bort det här projektet?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <MuiButton sx={styles.dialogFirstButtonAction} onClick={closePopup} variant="contained" type="button">
            Avbryt
          </MuiButton>
          <MuiButton sx={styles.dialogSecondButtonAction} onClick={deleteProject} variant="contained" type="button">
            Radera
          </MuiButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeleteModal;

import { SystemCssProperties } from '@mui/system';
import icLogout from 'assets/images/ic_logout.svg';
import logoSmall from 'assets/images/logo-small.svg';

export const stylesProps = (background: string, isTop?: boolean): { header: SystemCssProperties } => {
  return {
    header: {
      display: 'flex',
      justifyContent: 'center',
      height: '80px',
      background: background as string,
      boxShadow: 'none',
      top: isTop ? '0' : 'unset',
      position: isTop ? 'absolute' : 'relative',
      padding: isTop ? '0 40px 0 40px' : '0',
      zIndex: 9,
    } as SystemCssProperties,
  };
};

export const styles = {
  toolbar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: {
      md: '0',
    },
  } as SystemCssProperties,

  arrowIcon: {
    width: '17px',
    height: '20px',
    marginRight: '16px',
  } as SystemCssProperties,

  wrapperLogo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  } as SystemCssProperties,

  logoutBgc: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
    background: `url(${icLogout}) no-repeat center/cover`,
  } as SystemCssProperties,

  logoSmall: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '48px',
    height: '44px',
    background: `url(${logoSmall}) no-repeat center/cover`,
  } as SystemCssProperties,

  stylesHeaderItems: {
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  } as SystemCssProperties,
};

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  boxText: {} as SystemCssProperties,

  title: {
    fontSize: '24px',
    fontWeight: 600,
    fontStyle: 'normal',
    position: 'relative',
    textAlign: 'left',
    lineHeight: '32px',
    color: colors.white,
  } as SystemCssProperties,

  subTitle: {
    fontSize: '24px',
    fontWeight: 600,
    fontStyle: 'normal',
    position: 'relative',
    textAlign: 'left',
    lineHeight: '32px',
    color: colors.white,
  } as SystemCssProperties,
};

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  root: {
    width: '100%',
    maxWidth: '202px',
    '.MuiOutlinedInput-root': {
      padding: 0,
      background: colors.white,
      color: colors.accentBlue,
      position: 'relative',
      '&.Mui-focused fieldset': {
        borderColor: colors.focusFiled,
      },
      '&.Mui-error fieldset': {
        borderWidth: '2px',
      },
      '& .MuiInputBase-input': {
        padding: '12.5px 13px',
      },
      '& .MuiInputAdornment-root': {
        position: 'absolute',
        top: '25px',
        right: '24px',
        '& .MuiSvgIcon-root': {
          color: colors.accentBlue,
        },
      },
      '& input::placeholder': {
        opacity: '1',
        color: colors.darkGrey,
      },
    },
  } as SystemCssProperties,

  styleInput: {
    input: {
      textTransform: 'uppercase',
      color: colors.accentBlue,
    },
  } as SystemCssProperties,
};

import * as React from 'react';
import { memo, useRef } from 'react';

import Add from 'shared/components/Icons/Add';
import { Project } from 'shared/store/project/interface';

import { styles } from './styles';
import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { ActionAdd } from 'shared/store/snackbar/interface';
import { useDispatch } from 'react-redux';
import { useCheckPermissions } from 'shared/hooks/useCheckPermissions';
import { Roles } from 'shared/constants/enum';

export interface Props {
  name: string;
  nameField: string;
  accept: string[];
}

const Download = (props: Props): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<Project>();
  const dispatch = useDispatch();
  const refInput = useRef<HTMLInputElement | null>(null);
  const { name, nameField, accept } = props;
  const isValidLengthFiles = 5;

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const addFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Object.values(event.currentTarget.files !== null ? event.currentTarget.files : []);
    const filesList = [...values?.files[nameField], ...files];

    if (files && values?.files[nameField].length <= isValidLengthFiles) {
      setFieldValue(name, filesList);
      reset();
    } else {
      dispatch(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: 'Kan inte bifoga fler än 6 filer',
        } as ActionAdd),
      );
    }

    if (!accept.includes(files[0].type)) {
      setFieldValue(name, []);
      dispatch(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: 'Ogiltig filtyp',
        } as ActionAdd),
      );
    }
  };

  const reset = () => {
    refInput.current!.value = '';
  };

  return (
    <>
      {!isMU && (
        <label htmlFor={name} style={styles.root}>
          <input
            ref={refInput}
            onChange={addFiles}
            accept={accept.join(', ')}
            style={{ display: 'none' }}
            id={name}
            name={name}
            type="file"/>
          <Add />
          <Typography variant="subtitle2" component="span">
            Ladda upp filer
          </Typography>
        </label>
      )}
    </>
  );
};

export default memo(Download);

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  NavLink: {
    width: '196px',
    textTransform: 'none',
    '&.MuiButton-root': {
      padding: '0px',
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.white,
      textTransform: 'none',
      width: '100%',
      height: '100%',
      padding: '10px 16px',
    },
  } as SystemCssProperties,

  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  } as SystemCssProperties,

  tableContainerBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    flexWrap: 'wrap',
    height: '100%',

    marginBottom: {
      xl: '36px',
    },
  } as SystemCssProperties,

  titleContainer: {
    flexBasis: '75%',
    height: '410px',
    position: 'relative',
    top: 0,
    left: 0,
  } as SystemCssProperties,

  titleContainerTypo: {
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    color: colors.white,
    flexGrow: 1,
    position: 'absolute',
    fontSize: '24px',
    fontWeight: '600',
    top: '-100px',
  } as SystemCssProperties,

  SubTitle: {
    justifyContent: 'flex-start',
    color: colors.white,
    fontSize: '14px',
    marginTop: '5px',
  } as SystemCssProperties,

  flxCenter: {
    display: 'flex',
    justifyContent: 'center',
  } as SystemCssProperties,

  RightSectionTypo: {
    justifyContent: 'flex-start',
    color: colors.white,
  } as SystemCssProperties,

  table: {
    background: 'ActiveBorder',
    height: '100%',
    borderRadius: '8px',
  } as SystemCssProperties,

  IconContainer: {
    flexBasis: '25%',
    paddingLeft: '50px',
  } as SystemCssProperties,

  IconContainerBox: {
    display: 'flex',
    marginBottom: '24px',
  } as SystemCssProperties,

  NavLinkInstruct: {
    background: colors.white,
    '&.MuiButton-root': {
      padding: '0px',
    },
    height: '72px',
    width: '210px',

    '&:hover': {
      background: colors.white,
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.accentBlue,
      textTransform: 'none',
      width: '100%',
      height: '100%',
      padding: '10px 16px',
    },
  } as SystemCssProperties,
};

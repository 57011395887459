import React from 'react';

const ProductLine = (): JSX.Element => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3988 6.1875H7.78824C7.31397 6.1875 6.85912 6.3759 6.52376 6.71126C6.1884 7.04662
        6 7.50147 6 7.97574V31.5022H21.187V7.97574C21.187 7.50147 20.9986 7.04662 20.6632 6.71126C20.3279 6.3759 19.873 6.1875 19.3988
        6.1875ZM19.5006 29.8124H16.9715V27.281H10.2189V29.8124H7.68757V7.97574C7.68757 7.94874 7.6983 7.92285 7.71739 7.90376C7.73648
        7.88466 7.76237 7.87394 7.78937 7.87394H19.3988C19.4258 7.87394 19.4517 7.88466 19.4707 7.90376C19.4898 7.92285 19.5006 7.94874
        19.5006 7.97574V29.8124Z"
        fill="#40DBE4"/>
      <path d="M9.37524 10.4067H11.0628V12.0943H9.37524V10.4067Z" fill="#40DBE4" />
      <path d="M12.7505 10.4067H14.4381V12.0943H12.7505V10.4067Z" fill="#40DBE4" />
      <path d="M16.1255 10.4067H17.8131V12.0943H16.1255V10.4067Z" fill="#40DBE4" />
      <path d="M9.37524 14.6255H11.0628V16.3131H9.37524V14.6255Z" fill="#40DBE4" />
      <path d="M12.7505 14.6255H14.4381V16.3131H12.7505V14.6255Z" fill="#40DBE4" />
      <path d="M16.1255 14.6255H17.8131V16.3131H16.1255V14.6255Z" fill="#40DBE4" />
      <path d="M9.37524 18.8433H11.0628V20.5308H9.37524V18.8433Z" fill="#40DBE4" />
      <path d="M12.7505 18.8433H14.4381V20.5308H12.7505V18.8433Z" fill="#40DBE4" />
      <path d="M16.1255 18.8433H17.8131V20.5308H16.1255V18.8433Z" fill="#40DBE4" />
      <path d="M9.37524 23.062H11.0628V24.7496H9.37524V23.062Z" fill="#40DBE4" />
      <path d="M12.7505 23.062H14.4381V24.7496H12.7505V23.062Z" fill="#40DBE4" />
      <path d="M16.1255 23.062H17.8131V24.7496H16.1255V23.062Z" fill="#40DBE4" />
      <path d="M22.0308 14.6255H23.7183V16.3131H22.0308V14.6255Z" fill="#40DBE4" />
      <path d="M25.4058 14.6255H27.0933V16.3131H25.4058V14.6255Z" fill="#40DBE4" />
      <path d="M22.0308 18.8433H23.7183V20.5308H22.0308V18.8433Z" fill="#40DBE4" />
      <path d="M25.4058 18.8433H27.0933V20.5308H25.4058V18.8433Z" fill="#40DBE4" />
      <path d="M22.0308 23.062H23.7183V24.7496H22.0308V23.062Z" fill="#40DBE4" />
      <path d="M25.4058 23.062H27.0933V24.7496H25.4058V23.062Z" fill="#40DBE4" />
      <path d="M28.781 14.9884V29.8123H22.0308V31.4999H30.4686V14.5156C29.9309 14.7513 29.3629 14.9104 28.781 14.9884Z" fill="#40DBE4" />
      <path
        d="M32.1561 8.71893C32.1561 9.55335 31.9087 10.369 31.4451 11.0628C30.9815 11.7566 30.3226 12.2974 29.5517
        12.6167C28.7808 12.936 27.9325 13.0196 27.1141 12.8568C26.2957 12.694 25.544 12.2922 24.954 11.7022C24.3639 11.1121 23.9621 10.3604
        23.7993 9.542C23.6365 8.72361 23.7201 7.87532 24.0394 7.10441C24.3587 6.33351 24.8995 5.6746 25.5933 5.21102C26.2871 4.74744
        27.1028 4.5 27.9372 4.5C28.4912 4.5 29.0398 4.60913 29.5517 4.82115C30.0636 5.03317 30.5287 5.34393 30.9204 5.73569C31.3122
         6.12746 31.6229 6.59255 31.835 7.10441C32.047 7.61628 32.1561 8.16489 32.1561 8.71893Z"
        fill="#40DBE4"/>
    </svg>
  );
};

export default ProductLine;

import { createSlice } from '@reduxjs/toolkit';
import { StateInterface } from 'shared/interfaces';

const initialState: StateInterface = {
  isLoading: false,
  error: {
    message: [],
    stackErrors: '',
  },
};

const logout = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
    },
    end(state) {
      state.isLoading = false;
    },
    error(state, action: any) {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
  },
});

export const logoutActions = logout.actions;
export default logout.reducer;

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { styles } from './styles';

interface Props {
  [key: string]: any;
}
const Help = (props: Props): JSX.Element => {
  const { handleClose } = props;
  return (
    <Box sx={styles.modal}>
      <Typography id="modal-modal-title" variant="h6" component="div" sx={styles.modalHeader}>
        <Typography variant="subtitle1" component="p" color="inherit">Zoom</Typography>

        <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <Typography variant="subtitle2" component="p" color="inherit" sx={{ letterSpacing: 'initial' }}>
          Svårt att läsa texter eller få överblick? <br />
          Använd din webbläsares kortkommandon:
        </Typography>

        <Typography variant="subtitle1" component="p" sx={styles.headline}>Windows:</Typography>

        <Typography variant="subtitle2" component="p" color="inherit">
          <b>ctrl +</b> för att förstora bilder och text <br />
          <b>ctrl -</b> för att förminska bilder och text
        </Typography>

        <Typography variant="subtitle1" component="p" sx={styles.headline}>Mac:</Typography>

        <Typography variant="subtitle2" component="p" color="inherit">
          <b>cmd +</b> för att förstora bilder och text <br />
          <b>cmd -</b> för att förminska bilder och text
        </Typography>
      </Typography>
    </Box>
  );
};

export default Help;

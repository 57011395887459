import React from 'react';

const Participants = (): JSX.Element => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_243_3464)">
        <path d="M22.5 6H31.5V15H22.5V6Z" fill="#46B9EB" />
        <path
          d="M4.5 18.0005C4.53358 21.5588 5.97069 24.96 8.49878 27.4644C11.0269 29.9687 14.4415 31.3737
              18 31.3737C21.5585 31.3737 24.9731 29.9687 27.5012 27.4644C30.0293 24.96 31.4664 21.5588 31.5
              18.0005H25.5004C25.5004 19.9897 24.7102 21.8975 23.3036 23.3041C21.897 24.7107 19.9892 25.5009
              18 25.5009C16.0108 25.5009 14.103 24.7107 12.6964 23.3041C11.2898 21.8975 10.4996 19.9897
              10.4996 18.0005H4.5Z"
          fill="#46B9EB"/>
        <path
          d="M9.0006 15.0002C9.89079 15.0002 10.761 14.7362 11.5011 14.2417C12.2413 13.7471 12.8181
               13.0441 13.1587 12.2217C13.4993 11.3992 13.5884 10.4942 13.4146 9.62116C13.2409 8.7481
                12.8121 7.94617 12.1826 7.3168C11.553 6.68742 10.751 6.25888 9.87789 6.08536C9.00478
                 5.91184 8.09982 6.00113 7.27746 6.34196C6.45511 6.68278 5.75229 7.25982 5.2579
                  8.0001C4.76352 8.74038 4.49976 9.61064 4.5 10.5008C4.50032 11.6943 4.97463 12.8387
                   5.81862 13.6825C6.66261 14.5262 7.80718 15.0002 9.0006 15.0002Z"
          fill="#46B9EB"/>
      </g>
      <defs>
        <clipPath id="clip0_243_3464">
          <rect width="27" height="25.4998" fill="white" transform="translate(4.5 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Participants;

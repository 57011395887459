import { combineReducers } from '@reduxjs/toolkit';
import getProject from 'shared/store/project/ducks/reducers/getProject';
import createProject from 'shared/store/project/ducks/reducers/createProject';
import updateProject from 'shared/store/project/ducks/reducers/updateProject';
import completionProject from 'shared/store/project/ducks/reducers/completionProject';
import getSharedProject from 'shared/store/project/ducks/reducers/getSharedProject';
import updateTimer from 'shared/store/project/ducks/reducers/updateTimer';

export default combineReducers({
  getProject,
  createProject,
  updateProject,
  completionProject,
  getSharedProject,
  updateTimer,
});

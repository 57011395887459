import * as yup from 'yup';

export const validationSchemaSwedish = yup.object({
  email: yup.string().email('Använd formatet email@example.com').required('E-post är ett obligatoriskt fält'),
  password: yup.string().required('Lösenord är ett obligatoriskt fält').min(8, 'Error'),
});

export const validationSchemaEng = yup.object({
  email: yup.string().email('Please use format email@example.com').required('Email is a required field'),

  password: yup.string().required('Password is a required field').min(8, 'Error'),
});

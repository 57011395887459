import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  timers: Record<string, string>;
  error: {
    message: string[];
    stackErrors: string;
  };
}

const initialState: InitialState = {
  timers: {},
  error: {
    message: [],
    stackErrors: '',
  },
};

const updateTimer = createSlice({
  name: 'updateTimer',
  initialState,
  reducers: {
    start(state, action) {
      state.timers = action.payload.timers;
    },
    error(state, action: any) {
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
  },
});

export const updateTimerActions = updateTimer.actions;
export default updateTimer.reducer;

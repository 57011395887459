import React from 'react';

const DateI = (): JSX.Element => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0007 31.5C15.3094 31.5001 12.6794 30.6958 10.4484 29.1903C8.21749 27.6849 6.48741 25.547 5.48032 23.0511C4.47323
        20.5553 4.23509 17.8154 4.79648 15.1832C5.35787 12.551 6.69315 10.1467 8.63091 8.27894C8.83777 8.07938 9.11544 7.97017 9.40282
        7.97533C9.69021 7.98049 9.96377 8.0996 10.1633 8.30646C10.3629 8.51333 10.4721 8.79099 10.467 9.07838C10.4618 9.36576 10.3427
        9.63933 10.1358 9.83889C8.27493 11.6315 7.08268 14.0063 6.7568 16.5695C6.43092 19.1327 6.99098 21.7303 8.3441 23.9315C9.69722
        26.1327 11.7621 27.8053 14.1963 28.6719C16.6305 29.5384 19.2878 29.5468 21.7275 28.6958C24.1671 27.8448 26.2426 26.1854
        27.6097 23.9928C28.9768 21.8002 29.5534 19.2063 29.2438 16.641C28.9342 14.0758 27.7571 11.6934 25.9077 9.88905C24.0582
        8.08466 21.6476 6.96666 19.0755 6.72044V10.9469C19.0755 11.235 18.961 11.5114 18.7573 11.7151C18.5536 11.9188 18.2773
        12.0332 17.9892 12.0332C17.701 12.0332 17.4247 11.9188 17.221 11.7151C17.0173 11.5114 16.9028 11.235 16.9028
        10.9469V5.58778C16.9028 5.29967 17.0173 5.02336 17.221 4.81964C17.4247 4.61592 17.701 4.50146 17.9892 4.50146C21.5696
        4.50146 25.0034 5.92378 27.5351 8.45552C30.0668 10.9873 31.4891 14.421 31.4891 18.0015C31.4891 21.5819 30.0668 25.0157
        27.5351 27.5474C25.0034 30.0791 21.5696 31.5015 17.9892 31.5015L18.0007 31.5Z"
        fill="#46B9EB"/>
      <path
        d="M12.5938 11.6478L19.3101 16.4739C19.7488 16.7868 20.0452 17.2612 20.1342 17.7926C20.2231 18.3241 20.0973 18.8691
        19.7844 19.3078C19.4715 19.7465 18.9972 20.0429 18.4658 20.1319C17.9343 20.2208 17.3893 20.095 16.9506 19.7821C16.7673
        19.653 16.6075 19.4933 16.4784 19.3099L11.6494 12.5936C11.5568 12.463 11.5135 12.3038 11.5271 12.1443C11.5407 11.9848
        11.6103 11.8352 11.7236 11.7221C11.8369 11.609 11.9865 11.5396 12.1461 11.5263C12.3056 11.5129 12.4647 11.5565 12.5952
        11.6493L12.5938 11.6478Z"
        fill="#46B9EB"/>
    </svg>
  );
};

export default DateI;

export enum SnackBarType {
  Success = 'success',
  Error = 'error',
}

export enum CompareParametersId {
  Root = 'ROOT',
  Eliminera = 'increase',
  Oka = 'decrease',
  Reducera = 'eliminate',
  Reduce = 'reduce',
  Kraeera = 'kraeera',
}

export enum Roles {
  USER = 'user',
  ADMIN = 'admin',
  MU = 'mu',
}

export enum Statuses {
  ONGOING = 'Pågående',
  FINISHED = 'Avslutade',
}

export enum CompareParametersNames {
  Eliminera = 'Eliminera',
  Oka = 'Öka',
  Reducera = 'Reducera',
  Kraeera = 'Kraeera',
}

export enum FieldsNameValidation {
  Parameter = 'Parameter',
  Beskrivning = 'Beskrivning (valfritt)',
}

export enum BoardPermissionKeys {
  CalculateValue = 'calculateValue',
  OverriddenValue = 'overriddenValue',
  Values = 'values',
}

export enum Explore {
  BTN1 = 'Taxibolag',
  BTN2 = 'Frisör',
  BTN3 = 'Livsmedelsbutik',
  BTN4 = 'Bensinmack',
}

export enum TableHeaderArchive {
  'Projektnamn',
  'Plats',
  'Region',
  'Datum',
  'Status',
}

export enum TableHeaderVideos {
  'Projektsteg',
  'Videons namn',
  'Längd',
}

export enum TableHeaderActiveUsers {
  'First name',
  'Last name',
  'Email',
}

export enum TableHeaderInvitedUsers {
  'Email',
  'Expire in',
}

export const enum StatusProjectArchive {
  Finished = 'Avslutade',
  Progress = 'Pågående',
}

export enum TableBody {
  Date = 'date',
  ValidUntil = 'validUntil',
}

export enum Exercises {
  BTN1 = 'Trender',
  BTN2 = 'Kundresan',
  BTN3 = 'Pinor & plågor',
  BTN4 = 'Utöka marknaden',
}

export enum Options {
  STEP3EXERCISE = 'step3Exercise',
  STEP3EXPLORE = 'step3Explore',
}

export enum DiagramStep5 {
  GAMMALSTRATEGI = 'Gammal strategi',
  RIKSBYGGEN = 'Riksbyggen',
}

export enum VideosKeys {
  Timecodes = 'timecodes',
}

import * as React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { stylesText } from './styles';
import { SystemCssProperties } from '@mui/system';

export interface Props {
  isShowInfo: boolean;
  textInfo: string | undefined;
  stylePopup?: SystemCssProperties;
}

const TextInfo = ({ isShowInfo, textInfo, stylePopup }: Props): JSX.Element => {
  return (
    <Box sx={{ ...stylesText(isShowInfo).info, ...stylePopup }}>
      <Typography variant="body2">{textInfo}</Typography>
    </Box>
  );
};

export default TextInfo;

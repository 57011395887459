import {SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  tabItems: {
    width: '100%',
    display: 'flex',
    marginBottom: '24px',
    borderRadius: '20px',
    overflow: 'hidden',
    position: 'sticky',
    bottom: '24px',
    zIndex: 90,
    backgroundColor: colors.accentBlue,
  } as SystemCssProperties,
};

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  upload: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: '416px',
    color: colors.white,
    marginLeft: '60px',
    mb: 3,
    '.MuiTypography-root': {
      color: colors.white,
    },
  } as SystemCssProperties,

  uploadWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& .IconField': {
      marginBottom: '0',
    },
  } as SystemCssProperties,

  lists: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  } as SystemCssProperties,

  fileUploaderWrapper: {
    display: 'flex',
    alignItems: 'center',
  } as SystemCssProperties,

  fileUploader: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    mr: '10px',
  } as SystemCssProperties,
};

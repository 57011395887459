import React, { useState } from 'react';
import { Box } from '@mui/system';
import MuiModal from 'shared/components/Modal';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_6/Instruction/Help/styles';
import Help from 'components/WebTools/ProjectProcess/Common/Help';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';
import { useGetProject } from 'shared/hooks/useGetProjects';
import Loader from 'shared/components/Loader';

type CallbackFunctionModal = (...args: any[]) => void;

const HelpLine = (): JSX.Element => {
  const [isOpenHelp, modalToggleHelp] = useState(false);
  const handleClose = (callback: CallbackFunctionModal) => callback(false);
  const { projectData } = useGetProject();

  if (projectData === null) {
    return (
      <Box sx={styles.Loader}>
        <Loader />
      </Box>
    );
  }

  const instruction = projectData.data.stepsNotes['6'];

  return (
    <Box sx={styles.modalButtons}>
      <Instruction stepNote={instruction} data={projectData.data} isShowOnlyZoom={true} noteKey={6} />

      <MuiModal isOpen={isOpenHelp} handleClose={() => handleClose(modalToggleHelp)}>
        <Help handleClose={() => handleClose(modalToggleHelp)} />
      </MuiModal>
    </Box>
  );
};

export default HelpLine;

import * as React from 'react';
import HeaderWeb from 'shared/components/HeaderWeb';
import LayoutContainer from './LayoutContainer';
import ProjectForm from 'components/WebTools/MainForm/ProjectForm';
import colors from 'styles/_Variables';

const MainForm = (): JSX.Element => {
  return (
    <LayoutContainer>
      <HeaderWeb background={colors.accentBlue} isStartScreen={false} />
      <ProjectForm />
    </LayoutContainer>
  );
};

export default MainForm;

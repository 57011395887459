import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  clearBorder: {
    border: 'none',
    borderRadius: '8px',
  } as SystemCssProperties,

  tableRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  } as SystemCssProperties,

  tableCell: {
    display: 'flex',
    width: '100%',
    maxWidth: '291px',
  } as SystemCssProperties,

  tableCellHeader: {
    '&:last-child': {
      borderRadius: '0 8px 0 0',
    },
    '&:first-of-type': {
      borderRadius: '8px 0 0 0',
    },
    zIndex: 1,
    background: colors.white,
    fontWeight: '500',
    fontSize: '14px',
    color: colors.darkGrey,
    border: 'none',
    position: 'sticky',
    left: 0,
  } as SystemCssProperties,

  tableCellWithShadow: {
    zIndex: 2,
    boxShadow: '0 0 5px',
  } as SystemCssProperties,
};

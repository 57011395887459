import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions as actionSnackbar } from 'shared/store/snackbar';
import { formService } from 'core/services/form';
import { actions } from 'shared/store/files';
import { CreateFilesPayload } from 'shared/store/files/interface';

interface Props {
  payload: CreateFilesPayload;
}

export function* addGeneralFiles(props: Props): SagaIterator {
  const { id, data } = props.payload;
  try {
    yield call(formService.addFiles, `projects/${id}/files/general`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    yield put(actions.addGeneralFilesActions.end());
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.addGeneralFilesActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* addGeneralFilesSaga(): SagaIterator {
  yield takeLatest(actions.addGeneralFilesActions.start, addGeneralFiles);
}

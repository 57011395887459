import HTTP from 'core/services/api';
import { Settings, User, InvitedUser } from 'shared/store/admin/interface';

export const adminService = {
  getUsers: async (url: string, config?: any): Promise<User[]> => {
    const response = await HTTP.get(url, config);
    return response.data;
  },
  deleteUser: async (url: string) => {
    const response = await HTTP.delete(url);
    return response.data;
  },
  getSettings: async (): Promise<Settings> => {
    const response = await HTTP.get('settings');
    return response.data;
  },
  putSettings: async (payload: Settings): Promise<Settings> => {
    const response = await HTTP.put('settings', payload);
    return response.data;
  },
  sendInvite: async (url: string, config?: any) => {
    const response = await HTTP.post(url, config);
    return response.data;
  },

  getInvites: async (url: string, config?: any): Promise<InvitedUser[]> => {
    const response = await HTTP.get(url, config);
    return response.data;
  },

  resendInvite: async (url: string, config?: any) => {
    const response = await HTTP.post(url, config);
    return response.data;
  },
};

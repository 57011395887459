import * as React from 'react';
import {TableCell} from '@mui/material';
import {styles as stylesTable} from '../../../Table/styles';
import {ellipsString} from 'shared/helpers';
import {StepVideo} from 'shared/store/ListVideos/interface';

interface Props {
  row: StepVideo;
}

const RowVideo = (props: Props): any => {
  const {
    row: {
      displayProperties: {step, name, duration},
    },
  } = props;
  return [step, name, duration].map((item: string, index: number) => {
    return <TableCell sx={stylesTable.td} key={index}>{ellipsString(item, 65)}</TableCell>;
  });
};

export default RowVideo;

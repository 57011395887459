export const setRowsTable = (rowsLength, newCompetitionParameters) => {
  const valuesRows: string[] = [];
  for (let i = 0; i < rowsLength; i++) {
    valuesRows.push('');
  }
  if (newCompetitionParameters.length > 0) {
    return valuesRows.map((item, index) => ({
      name: newCompetitionParameters[index]?.name ?? '',
      description: newCompetitionParameters[index]?.description ?? '',
      overriddenValue: 8,
    }));
  }
  return valuesRows.map(() => ({
    name: '',
    description: '',
    overriddenValue: 8,
  }));
};

import React, {useRef} from 'react';
import { Box } from '@mui/material';
import TimeCodes from 'shared/components/TimeCodes';
import VideoPlayer from 'shared/components/VideoPlayer';
import {StepDisplay} from 'shared/store/project/interface';
import {styles} from './styles';
import StepInstructions from '../../StepInstructions';
import { StepNumber } from 'shared/interfaces';

interface Props {
  url: string;
  pathUrl: string;
  previewUrl: string;
  displayProperties: StepDisplay;
  currentStep: StepNumber;
}

const Video = (props: Props): JSX.Element => {
  const { displayProperties, pathUrl, url, previewUrl, currentStep } = props;
  const playerRef = useRef();
  return (
    <Box sx={styles.Container}>
      <StepInstructions displayProperties={displayProperties} pathUrl={pathUrl} currentStep={currentStep}/>

      <Box sx={styles.Content}>
        <VideoPlayer
          ref={playerRef}
          url={url}
          previewUrl={previewUrl}
          width="680px"
          height="400px"
        />
        <TimeCodes playerRef={playerRef} timeCodes={displayProperties} />
      </Box>
    </Box>
  );
};

export default Video;

import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import Box from '@mui/material/Box';
import MuiButton from 'shared/components/UI/MuiButton';
import Actions from 'components/WebTools/Archive/TableArchive/ArchiveCollapse/Actions';
import {styles} from './styles';
import {Archive} from 'shared/store/archive/interface';
import {StatusProjectArchive} from 'shared/constants/enum';
import {actions} from 'shared/store/archive';
import {RoutesPage} from 'shared/routes';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useDispatch} from 'react-redux';

interface Props {
  onHandleOpenPopup: Dispatch<SetStateAction<boolean>>;
  archive: Archive;
}

const ArchiveCollapse = (props: Props): JSX.Element => {
  const {archive, onHandleOpenPopup} = props;

  const dispatch = useDispatch();
  const {push} = useHistory();

  const currentRoute = useRouteMatch();

  const openProject = (archiveItem: Archive) => {
    const currentStep = +archiveItem?.currentStep || 1;

    if (archiveItem?.id) {
      push(`${RoutesPage.project.page}${archiveItem?.id}${RoutesPage.steps.step}${currentStep}${RoutesPage.steps.transition}`, {
        from: currentRoute.path,
      });
    }
  };

  const openProjectForm = (): void => {
    push(`/forms/${archive.id}`);
  };

  const copyProject = () => {
    dispatch(actions.copyArchiveActions.start({id: archive.id}));
  };

  const copyLinkPdf = async (sharingLink: string) => {
    await navigator.clipboard.writeText(sharingLink || '');
  };

  return (
    <Box sx={styles.archiveCollapse}>
      {archive?.status !== StatusProjectArchive.Finished && (
        <Box sx={styles.buttons}>
          <MuiButton onClick={() => openProject(archive)} type="button" variant="contained" sx={styles.buttonCollapse}>
            Öppna projektet
          </MuiButton>

          <MuiButton onClick={() => openProjectForm()} type="button" variant="contained" sx={styles.buttonCollapse}>
            Se projektinfo
          </MuiButton>
        </Box>
      )}
      <Actions
        onHandleOpenPopup={onHandleOpenPopup}
        onHandleCopyLinkPdf={copyLinkPdf}
        onHandleCopyProject={copyProject}
        archive={archive}/>
    </Box>
  );
};

export default ArchiveCollapse;

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const stylesProps = (index: number) => {
  return {
    actions: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      cursor: 'pointer',
      pr: 2,
      mb: 2,
      '&:hover': {
        background: 'none',
      },
      '& .MuiSvgIcon-root, & .MuiTypography-root': {
        color: index === 0 ? colors.disableBlue : colors.white,
      },
      '& .MuiPaper-root-MuiAccordion-root .MuiButtonBase-root': {
        padding: '0px',
      },
    } as SystemCssProperties,
  };
};

export const styles = {
  generalBox: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 'calc(33.33% - 32px)',
    color: colors.white,
    mb: 3,
    marginRight: 4,
    '.MuiTypography-root': {
      color: colors.white,
    },
  } as SystemCssProperties,

  competitorsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    maxWidth: '1440px',
  } as SystemCssProperties,

  fieldWrapper: {
    width: '100%',
    mb: 2,
  } as SystemCssProperties,
};

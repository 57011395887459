import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { adminService } from 'core/services/admin';

export function* getUsers(): SagaIterator {
  try {
    const response = yield call(adminService.getUsers, 'users', {
      params: {
        limit: 999,
        offset: 0,
      },
    });
    yield put(actions.getUsersActions.end(response));
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.getUsersActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* getUsersSaga(): SagaIterator {
  yield takeLatest(actions.getUsersActions.start, getUsers);
}

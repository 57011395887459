import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiTextarea from 'shared/components/UI/MuiTextarea';
import MuiButton from 'shared/components/UI/MuiButton';
import { styles } from './styles';
import { useFormik } from 'formik';
import { ProjectWithoutFiles } from 'shared/store/project/interface';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions } from 'shared/store/project';
import InfoPopup from 'components/WebTools/ProjectProcess/Common/InfoPopup';
import { useCheckPermissions } from '../../../../../shared/hooks/useCheckPermissions';
import { Roles } from '../../../../../shared/constants/enum';

interface Props {
  [key: string]: any;

  data: ProjectWithoutFiles;
  stepNote: string;
  noteKey: string | number;
}

const Notes = (props: Props): JSX.Element => {
  const { handleClose, stepNote, data, noteKey } = props;
  let { id } = useParams<{ id: string }>();
  id = id ?? localStorage.getItem('projectId');
  const dispatch = useDispatch();

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const formik = useFormik({
    initialValues: {
      notes: stepNote,
    },
    onSubmit: (values) => {
      const newData = { ...data };
      delete newData.files;
      const updateProjectWithoutFiles = {
        ...newData,
        stepsNotes: {
          ...newData.stepsNotes,
          [noteKey]: values.notes,
        },
      };
      dispatch(actions.updateProjectActions.start({ id, updateProjectWithoutFiles }));
      handleClose(false);
    },
  });

  return (
    <Box sx={styles.modal}>
      <Typography variant="h6" component="div" sx={styles.modalHeader}>
        <Box sx={styles.popupInfo}>
          Anteckningar
          <InfoPopup maxLength={500} stylePopup={styles.stylePopup} />
        </Box>
        <IconButton color="inherit" size="small" disableRipple>
          <CloseIcon onClick={handleClose} />
        </IconButton>
      </Typography>
      <Typography sx={{ mt: 2 }}>
        <MuiTextarea
          rows={15}
          name="notes"
          placeholder={'Skriv din kommentar här'}
          maxLength={500}
          value={formik.values.notes}
          onChange={formik.handleChange}
          sx={styles.modalTextArea}
          disabled={isMU}
        />
      </Typography>
      <Box sx={styles.modalButtons}>
        <MuiButton disabled={isMU} variant="text" type="button" onClick={handleClose} sx={styles.muiButton}>
          Avbryt
        </MuiButton>
        <MuiButton disabled={isMU} variant="contained" type="button" onClick={formik.handleSubmit} sx={styles.muiButtonRight}>
          Spara
        </MuiButton>
      </Box>
    </Box>
  );
};

export default Notes;

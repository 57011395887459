import * as React from 'react';
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { styles } from './styles';
import { DetailsType } from 'shared/store/project/interface';
import { useCheckPermissions } from '../../../hooks/useCheckPermissions';
import { Roles } from '../../../constants/enum';

export interface Props {
  formik?: any;
  stateValues?: DetailsType;
  isChecked?: boolean;
  onChange?: (...arg: any) => any;
  onBlur?: (...arg: any) => any;
  name?: string;
  label?: any;
  disabled?: boolean;
}

const MuiCheckbox = (props: Props): JSX.Element => {
  const {isChecked, ...rest} = props;
  const roles = useCheckPermissions();
  const isMU = roles === Roles.MU;
  return <FormControlLabel sx={styles.root}
                           {...rest}
                           disabled={props.disabled || isMU}
                           control={<Checkbox checked={isChecked} value={isChecked} />} label={props.label} />;
};

export default MuiCheckbox;

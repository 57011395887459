import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import {styles} from 'shared/components/UI/MuiSelect/styles';
import * as React from 'react';

type PlaceholderT = string | undefined;

interface PlaceholderType {
  placeholderText: PlaceholderT;
}

const PlaceholderSelect = (props: PlaceholderType): JSX.Element => {
  const {placeholderText} = props;
  return (
    <Box>
      <Typography sx={styles.placeholderStyle} variant="subtitle2">
        {placeholderText}
      </Typography>
    </Box>
  );
};

export default PlaceholderSelect;

import {SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  timeCode: {
    color: colors.white,
    marginBottom: '40px',
  } as SystemCssProperties,

  timeCodeItem: {
    textAlign: 'start',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: 2,
    cursor: 'pointer',
    whiteSpace: 'pre-wrap',
  } as SystemCssProperties,
};

import React, {useState} from 'react';
import {Box} from '@mui/system';
import MuiModal from 'shared/components/Modal';
import {IconButton, Typography} from '@mui/material';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import {styles} from 'components/WebTools/ProjectProcess/Common/Instruction/styles';
import Notes from 'components/WebTools/ProjectProcess/Common/Notes';
import Help from 'components/WebTools/ProjectProcess/Common/Help';
import {Project} from 'shared/store/project/interface';
import Anteckningar from 'shared/components/Icons/Instructions/Anteckningar';

interface Props {
  stepNote: string;
  data: Project;
  noteKey: number | string;
  isShowOnlyZoom?: boolean;
}

type CallbackFunctionModal = (...args: any[]) => void;

const Instruction = (props: Props): JSX.Element => {
  const {stepNote, data, noteKey, isShowOnlyZoom} = props;
  const [isOpenNotes, modalToggleNotes] = useState(false);
  const [isOpenHelp, modalToggleHelp] = useState(false);
  const handleOpen = (callback: CallbackFunctionModal) => callback(true);
  const handleClose = (callback: CallbackFunctionModal) => callback(false);

  return (
    <Box sx={styles.modalButtons}>
      {
        !isShowOnlyZoom &&
        <IconButton
          onClick={() => handleOpen(modalToggleNotes)}
          edge="start"
          color="inherit"
          size="small"
          disableRipple
          sx={styles.modalButtonsItem}
        >
          <Anteckningar/>
          <Typography variant="subtitle2" component="p" color="inherit">
            Anteckningar
          </Typography>
        </IconButton>
      }

      <IconButton
        onClick={() => handleOpen(modalToggleHelp)}
        edge="start"
        color="inherit"
        size="small"
        disableRipple
        sx={styles.modalButtonsItem}
      >
        <ZoomOutMapIcon />
        <Typography variant="subtitle2" component="p" color="inherit">
          Zoom
        </Typography>
      </IconButton>
      <MuiModal isOpen={isOpenNotes} handleClose={() => handleClose(modalToggleNotes)}>
        <Notes handleClose={() => handleClose(modalToggleNotes)} stepNote={stepNote} data={data} noteKey={noteKey}/>
      </MuiModal>
      <MuiModal isOpen={isOpenHelp} handleClose={() => handleClose(modalToggleHelp)}>
        <Help handleClose={() => handleClose(modalToggleHelp)}/>
      </MuiModal>
    </Box>
  );
};

export default Instruction;

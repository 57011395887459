import * as React from 'react';
import {Box, Link, Typography} from '@mui/material';
import {Formik, FormikProps} from 'formik';
import MuiField from 'shared/components/UI/MuiField';
import MuiButton from 'shared/components/UI/MuiButton';
import {validationSchemaSwedish, validationSchemaEng} from 'components/Authorization/Login/validationSchema';
import {useDispatch} from 'react-redux';
import {RequestUser} from 'shared/store/auth/interface';
import {actions} from 'shared/store/auth';
import {useHistory} from 'react-router-dom';
import ErrorMessage from 'components/Authorization/ErrorMessage';
import {styles} from './styles';
import {RoutesPage} from 'shared/routes';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useState} from 'react';

const language = {
  eng: {
    title: 'Log in as admin',
    placeholder: {
      email: 'Email',
      pass: 'Password',
    },
    link: 'Restore password',
    btn: 'Log in',
  },
  sverige: {
    title: 'Logga in',
    placeholder: {
      email: 'E-post',
      pass: 'Lösenord',
    },
    link: 'Återställ lösenord',
    btn: 'Logga in',
  },
};

const Login = (): JSX.Element => {
  const [isShowPassword, setShowPassword] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isAdminLogin = history.location.pathname.includes(RoutesPage.admin);
  const currentLang = isAdminLogin ? language.eng : language.sverige;

  const transformLoginToLowerCase = (event, formik: FormikProps<RequestUser>) => {
    formik.setFieldValue('email', (formik.values.email as string).toLowerCase());
    formik.handleBlur(event);
  };

  const eyeClick = () => {
    setShowPassword(!isShowPassword);
  };

  const iconEye = (
    <Box sx={styles.eye}>{isShowPassword ? <VisibilityIcon onClick={eyeClick}/> : <VisibilityOffIcon onClick={eyeClick}/>}</Box>
  );

  return (
    <>
      <Typography sx={styles.typoHeader} variant="subtitle1" component="p">
        {currentLang.title}
      </Typography>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={isAdminLogin ? validationSchemaEng : validationSchemaSwedish}
        onSubmit={(values: RequestUser) => {
          let body;
          if (isAdminLogin) {
            body = {...values, history, roles: ['admin']};
          } else {
            body = {...values, history};
          }
          dispatch(actions.loginActions.start(body));
        }}
      >
        {(formik) => (
          <Box component="form" onSubmit={formik.handleSubmit} sx={styles.fields}>
            <Box sx={styles.styleFiled}>
              <MuiField
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={(event) => transformLoginToLowerCase(event, formik)}
                type="text"
                placeholder={currentLang.placeholder.email}
                isDisabledIcon={true}
                error={formik.touched.email && Boolean(formik.errors.email)}/>
              <ErrorMessage
                isError={formik.touched.email && Boolean(formik.errors.email)}
                message={formik.touched.email && formik.errors.email}/>
            </Box>

            <Box sx={styles.styleFiled}>
              <MuiField
                id="password"
                name="password"
                maxLength={32}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type={isShowPassword ? 'text' : 'password'}
                iconEye={iconEye}
                placeholder={currentLang.placeholder.pass}
                isDisabledIcon={true}
                error={formik.touched.password && Boolean(formik.errors.password)}/>
            </Box>
            <Box sx={styles.btn}>
              <MuiButton variant="contained" type="submit" disabled={!formik.isValid || !formik.dirty}>
                <Typography sx={styles.btnTxt} variant="subtitle2" component="p">
                  {currentLang.btn}
                </Typography>
              </MuiButton>
            </Box>
            <Link
              sx={styles.linkReset}
              onClick={() => history.push(isAdminLogin ? `${RoutesPage.admin}${RoutesPage.reset}` : RoutesPage.reset)}
            >
              <Typography sx={styles.linkReset} variant="subtitle2" component="p">
                {currentLang.link}
              </Typography>
            </Link>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default Login;

import { combineReducers } from '@reduxjs/toolkit';
import login from 'shared/store/auth/ducks/reducers/login';
import logout from 'shared/store/auth/ducks/reducers/logout';
import registration from 'shared/store/auth/ducks/reducers/registration';
import getMe from 'shared/store/auth/ducks/reducers/getMe';
import resetPassword from 'shared/store/auth/ducks/reducers/resetPassword';
import updatePassword from 'shared/store/auth/ducks/reducers/updatePassword';

export default combineReducers({
  login,
  logout,
  registration,
  getMe,
  resetPassword,
  updatePassword,
});

import React, { ReactElement } from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import { styles } from './styles';
import { RoutesPage, SubRoutes } from '../../routes';
import PencilIcon from 'assets/icons/ic_pencil.svg';
import CapIcon from 'assets/icons/ic_cap.svg';
import HeaderWeb from '../HeaderWeb';
import NavigationBar from '../NavigationBar';

interface PropsInterface {
  svg: ReactElement;
  stepNumber: number;
  stepName: string;
  stepIcons?: ('pencil' | 'greenCap' | 'blackCap')[];
}

const StepTransition = ({ svg, stepNumber, stepName, stepIcons }: PropsInterface): JSX.Element => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const renderIcons = () => {
    if (!stepIcons) {
      return null;
    }
    return stepIcons.map((icon, index) => {
      const icons = {
        pencil: PencilIcon,
        greenCap: CapIcon,
        blackCap: CapIcon,
      };

      return (
        <Box sx={styles.stepIcon} key={index} className={icon}>
          <img src={icons[icon]} alt="" />
        </Box>
      );
    });
  };

  const goForward = () => {
    push(`${RoutesPage.project.page}${id}${RoutesPage.steps[`step${stepNumber}`]}${RoutesPage.steps.video}${location.search}`);
  };

  const goBack = () => {
    if (stepNumber === 1) {
      return push(`${RoutesPage.project.page}${id}${RoutesPage.steps.step1}${RoutesPage.steps.welcome}${location.search}`);
    }

    const previousMaxOrder = Math.max(...SubRoutes[`step=${stepNumber - 1}`].map((route) => route.order || 0));
    const previousPath = SubRoutes[`step=${stepNumber - 1}`].find((route) => route.order === previousMaxOrder).path;

    push(`${RoutesPage.project.page}${id}${RoutesPage.steps[`step${stepNumber - 1}`]}${previousPath}${location.search}`);
  };

  return (
    <Box sx={styles.viewTransitionContainer(stepNumber)}>
      <HeaderWeb isStartScreen={false} background={'transparent'} isTop={true} />
      {svg}

      <Box sx={styles.stepInfo} onClick={goForward}>
        <Box sx={styles.circle}>
          <Typography variant="h3" component="span" color="inherit">
            {stepNumber}
          </Typography>
          <Box sx={styles.stepIconsWrapper}>{renderIcons()}</Box>
        </Box>
        <Typography sx={styles.stepTitle} variant="h5" component="span" color="white">
          {stepName}
        </Typography>
      </Box>

      <Box sx={ styles.navigationBar }>
        <NavigationBar
          navigateBack={() => goBack()}
          navigateForward={() => goForward()}
        />
      </Box>
    </Box>
  );
};

export default StepTransition;

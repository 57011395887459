import React from 'react';
import MuiButton from 'shared/components/UI/MuiButton';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';
import {RoutesPage} from 'shared/routes';
import {Box, Typography} from '@mui/material';
import { Link, useHistory, useParams } from 'react-router-dom';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_2/Instruction/styles';
import Loader from 'shared/components/Loader';
import {useGetProject} from 'shared/hooks/useGetProjects';
import { instructions } from './instructions';
import instructionImage from 'assets/images/instruction-step2.png';
import NavigationBar from 'shared/components/NavigationBar';

const InstructionStep2 = (): JSX.Element => {
  const id = useParams<{ id: string }>().id ?? localStorage.getItem('projectId');
  const pathUrl = `${RoutesPage.project.page}${id}${RoutesPage.steps.step2}${RoutesPage.steps.table}${location.search}`;
  const history = useHistory();
  const { projectData } = useGetProject();

  if (!projectData) {
    return (
      <Box sx={styles.Loader}>
        <Loader/>
      </Box>
    );
  }
  const instruction = projectData.data.stepsNotes['2'];

  const navigateForward = (): void => {
    history.push(pathUrl);
  };

  const navigateBack = (): void => {
    const previousPath = `${RoutesPage.project.page}${id}${RoutesPage.steps.step2}${RoutesPage.steps.video}${location.search}`;

    history.push(previousPath);
  };

  return (
    <Box sx={ styles.container }>
      <Box sx={ styles.header }>
        <Typography variant="subtitle1" component="h1" color="inherit" sx={ styles.name }>Nuvarande position</Typography>

        <Instruction stepNote={instruction} data={projectData.data} noteKey={2}/>
      </Box>

      <Box sx={ styles.content }>
        <Box sx={ styles.wrapper }>
          <Box sx={ styles.instructions }>
            {
              instructions.map((item, index) =>
                <Typography variant="subtitle2" component="p" color="inherit">{ index + 1 }. { item }</Typography>)
            }
          </Box>

          <Typography variant="subtitle1" component="p" sx={ styles.stepTime }>
            Tidsåtgång: ca 30 minuter
          </Typography>

          <MuiButton type="button" variant="contained" sx={styles.NavLink}>
            <Link to={pathUrl}>Fortsätt</Link>
          </MuiButton>
        </Box>

        <img height="510px" width="680px" src={instructionImage} alt="Instruction image"/>
      </Box>

      <NavigationBar navigateBack={() => navigateBack()} navigateForward={() => navigateForward()} />
    </Box>
  );
};

export default InstructionStep2;

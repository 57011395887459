import React, { lazy } from 'react';
import StartScreen from 'components/WebTools/StartScreen';
import ProjectProcess from 'components/WebTools/ProjectProcess';
import MainForm from 'components/WebTools/MainForm';
import InstructionStep1 from 'components/WebTools/ProjectProcess/Steps/Step_1/Instruction';
import InstructionStep2 from 'components/WebTools/ProjectProcess/Steps/Step_2/Instruction';
import InstructionStep3 from 'components/WebTools/ProjectProcess/Steps/Step_3/Instruction';
import InstructionStep6 from 'components/WebTools/ProjectProcess/Steps/Step_6/Instruction/index';
import AdditionalInfoContainer from 'components/WebTools/ProjectProcess/Steps/Step_3/AdditionalInfoContainer';
import VideoContainer from 'components/WebTools/ProjectProcess/Common/VideoContainer';
import TableContainer from 'components/WebTools/ProjectProcess/Steps/Step_3/TableContainer';
import TableContainer4 from 'components/WebTools/ProjectProcess/Steps/Step_4/AdditionalInfoContainer/index';
import TableContainer5 from 'components/WebTools/ProjectProcess/Steps/Step_5/TableContainer';
import TableContainer2 from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer';
import CommentContainer from 'components/WebTools/ProjectProcess/Steps/Step_6/CommentContainer';
import Test from 'components/WebTools/ProjectProcess/NotFound';
import InstructionStep5 from 'components/WebTools/ProjectProcess/Steps/Step_5/Instruction';
import DiagramContainer from 'components/WebTools/ProjectProcess/Steps/Step_2/DiagramContainer';
import DiagramContainer5 from 'components/WebTools/ProjectProcess/Steps/Step_5/DiagramContainer';
import Archive from 'components/WebTools/Archive';
import Login from 'components/Authorization/Login';
import AuthContainer from 'components/Authorization';
import Registration from 'components/Authorization/Registration';
import ResetPassword from 'components/Authorization/ResetPassword';
import NewPassword from 'components/Authorization/ResetPassword/NewPassword';

import TransitionStep1 from 'components/WebTools/ProjectProcess/Steps/Step_1/Transition';
import TransitionStep2 from 'components/WebTools/ProjectProcess/Steps/Step_2/Transition';
import TransitionStep3 from 'components/WebTools/ProjectProcess/Steps/Step_3/Transition';
import TransitionStep4 from 'components/WebTools/ProjectProcess/Steps/Step_4/Transition';
import TransitionStep5 from 'components/WebTools/ProjectProcess/Steps/Step_5/Transition';
import TransitionStep6 from 'components/WebTools/ProjectProcess/Steps/Step_6/Transition';

const AdminPanel = lazy(() => import('components/AdminPanel'));
import ListVideos from 'components/WebTools/ListVideos';
import AdditionalInstructionStep3 from 'components/WebTools/ProjectProcess/Steps/Step_3/AdditionalInfo';
import WelcomeScreen from 'components/WebTools/ProjectProcess/WelcomeScreen';

export interface Router {
  path: string;
  component: React.ComponentType;
  exact?: boolean;
  order?: number;
}

export const RoutesPage = {
  home: '/',
  admin: '/admin',
  login: '/login',
  archive: '/archive',
  videos: '/videos',
  reset: '/reset-password',
  registration: '/accept-invite',
  newPassword: '/auth/reset-password/user',
  newPasswordAdmin: '/auth/reset-password/admin',
  v1: '/reset-password/v1',
  notFound: '*',
  adminPanel: {
    invitedUsers: '/admin/invited-users',
    activeUsers: '/admin/active-users',
    settings: '/admin/settings',
  },
  form: {
    page: '/forms/',
    id: '/forms/:id/',
  },
  mainForm: {
    page: '/main-form/',
    id: '/main-form/:id',
  },
  steps: {
    step: '/step=',
    step1: '/step=1',
    step2: '/step=2',
    step3: '/step=3',
    step4: '/step=4',
    step5: '/step=5',
    step6: '/step=6',
    instruction: '/instruction',
    additionalInstruction: '/additional-instruction',
    video: '/video',
    table: '/table',
    diagram: '/diagram',
    comment: '/comment',
    finish: '/finish',
    transition: '/transition',
    welcome: '/welcome',
  },
  step3AdditionalInfo: {
    exercise: '/Exercise',
    explore: '/Explore',
  },
  project: {
    page: '/project-process/',
    id: '/project-process/:id/',
  },
  sharedProject: {
    page: '/shared-project/:token/project-process/:id/',
  },
};

export const publicRoutes: Router[] = [
  { path: RoutesPage.login, component: AuthContainer, exact: true },
  { path: RoutesPage.registration, component: AuthContainer, exact: false },
  { path: RoutesPage.reset, component: AuthContainer, exact: false },
  { path: RoutesPage.newPassword, component: AuthContainer, exact: true },

  { path: `${RoutesPage.admin}${RoutesPage.login}`, component: AuthContainer, exact: true },
  { path: `${RoutesPage.admin}${RoutesPage.reset}`, component: AuthContainer, exact: false },
  { path: RoutesPage.newPasswordAdmin, component: AuthContainer, exact: true },
];

export const privateRoutes: Router[] = [
  { path: RoutesPage.home, component: StartScreen, exact: true },
  { path: RoutesPage.archive, component: Archive, exact: true },
  { path: RoutesPage.videos, component: ListVideos, exact: true },
  { path: RoutesPage.project.id, component: ProjectProcess, exact: false },
  { path: RoutesPage.sharedProject.page, component: ProjectProcess, exact: false },
  { path: RoutesPage.form.id, component: MainForm, exact: true },
  { path: RoutesPage.mainForm.id, component: MainForm, exact: true },
  { path: RoutesPage.admin, component: AdminPanel, exact: false },
];

export const authRoutes: Router[] = [
  { path: RoutesPage.login, component: Login, exact: true },
  { path: RoutesPage.registration, component: Registration, exact: false },
  { path: RoutesPage.reset, component: ResetPassword, exact: false },
  { path: RoutesPage.newPassword, component: NewPassword, exact: false },

  { path: `${RoutesPage.admin}${RoutesPage.login}`, component: Login, exact: true },
  { path: `${RoutesPage.admin}${RoutesPage.reset}`, component: ResetPassword, exact: false },
  { path: RoutesPage.newPasswordAdmin, component: NewPassword, exact: false },
];

export const step1: Router[] = [
  { path: RoutesPage.steps.welcome, component: WelcomeScreen, exact: true, order: 0 },
  { path: RoutesPage.steps.transition, component: TransitionStep1, exact: true, order: 1 },
  { path: RoutesPage.steps.instruction, component: InstructionStep1, exact: true, order: 3 },
  { path: RoutesPage.steps.video, component: VideoContainer, exact: true, order: 2 },
];

export const step2: Router[] = [
  { path: RoutesPage.steps.transition, component: TransitionStep2, exact: true, order: 0 },
  { path: RoutesPage.steps.instruction, component: InstructionStep2, exact: true, order: 2 },
  { path: RoutesPage.steps.table, component: TableContainer2, exact: true, order: 3 },
  { path: RoutesPage.steps.video, component: VideoContainer, exact: true, order: 1 },
  { path: RoutesPage.steps.diagram, component: DiagramContainer, exact: true, order: 4 },
];

export const step3: Router[] = [
  { path: RoutesPage.steps.transition, component: TransitionStep3, exact: true, order: 0 },
  { path: RoutesPage.steps.instruction, component: InstructionStep3, exact: true, order: 2 },
  { path: RoutesPage.steps.video, component: VideoContainer, exact: true, order: 1 },
  { path: RoutesPage.steps.additionalInstruction, component: AdditionalInstructionStep3, exact: true, order: 3 },
  { path: RoutesPage.steps.table, component: TableContainer, exact: true, order: 4 },
  { path: RoutesPage.step3AdditionalInfo.exercise, component: AdditionalInfoContainer, exact: true },
  { path: RoutesPage.step3AdditionalInfo.explore, component: AdditionalInfoContainer, exact: true },
];

export const step4: Router[] = [
  { path: RoutesPage.steps.transition, component: TransitionStep4, exact: true, order: 0 },
  { path: RoutesPage.steps.instruction, component: TableContainer4, exact: true, order: 2 },
  { path: RoutesPage.steps.video, component: VideoContainer, exact: true, order: 1 },
  { path: RoutesPage.steps.diagram, component: DiagramContainer, exact: true },
];

export const step5: Router[] = [
  { path: RoutesPage.steps.transition, component: TransitionStep5, exact: true, order: 0 },
  { path: RoutesPage.steps.instruction, component: InstructionStep5, exact: true, order: 3 },
  { path: RoutesPage.steps.video, component: VideoContainer, exact: true, order: 1 },
  { path: RoutesPage.steps.table, component: TableContainer5, exact: true, order: 2 },
  { path: RoutesPage.steps.diagram, component: DiagramContainer5, exact: true },
];

export const step6: Router[] = [
  { path: RoutesPage.steps.transition, component: TransitionStep6, exact: true, order: 0 },
  { path: RoutesPage.steps.finish, component: InstructionStep6, exact: true, order: 3 },
  { path: RoutesPage.steps.video, component: VideoContainer, exact: true, order: 1 },
  { path: RoutesPage.steps.comment, component: CommentContainer, exact: true, order: 2 },
];

export const notFound = { path: RoutesPage.notFound, component: Test, exact: true };

export const SubRoutes = {
  'step=1': step1,
  'step=2': step2,
  'step=3': step3,
  'step=4': step4,
  'step=5': step5,
  'step=6': step6,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Settings, SettingsState } from '../../interface';

const initialState: SettingsState = {
  isLoading: false,
  settings: {
    timers: {},
  },
  error: {
    message: [],
    stackErrors: '',
  },
};

const updateSettings = createSlice({
  name: 'updateSettings',
  initialState,
  reducers: {
    start: {
      reducer(state) {
        state.isLoading = true;
      },
      prepare(payload: Settings) {
        return { payload };
      },
    },

    end(state, action: PayloadAction<Settings>) {
      state.isLoading = false;
      state.settings.timers = action.payload.timers;
    },
    error(state, action: any) {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
    reset() {
      return initialState;
    },
  },
});

export const updateSettingsActions = updateSettings.actions;
export default updateSettings.reducer;

import { styles, stylesProps } from './styles';
import { Box, Typography } from '@mui/material';
import colors from 'styles/_Variables';
import { StepVideo } from 'shared/store/project/interface';
import MuiButton from 'shared/components/UI/MuiButton';
import { Link, useParams } from 'react-router-dom';
import VideoModal from '../AdditionalInfoContainer/VideoModal';
import React, { useState } from 'react';
import { Exercises } from 'shared/constants/enum';
import { RoutesPage } from 'shared/routes';
import { videoInfo } from './videoInfo';

export interface ActiveVideo extends StepVideo {
  videoHeader: string;
}

interface Props {
  videos: StepVideo[];
}

const ExerciseInfo = (props: Props): JSX.Element => {
  const { videos } = props;
  const id = useParams<{ id: string }>().id ?? localStorage.getItem('projectId');
  const [activeButtonIndex, setActiveButtonIndex] = useState<number | null>(null);
  const previousUrl = `${RoutesPage.project.page}${id}${RoutesPage.steps.step3}${RoutesPage.steps.instruction}${location.search}`;
  const [isShowVideo, setShowVideo] = useState<boolean>(false);
  const [activeVideo, setActiveVideo] = useState<ActiveVideo | null>(null);

  const btnClick = (index: number, videoHeader: string): void => {
    const videoItem: ActiveVideo = {
      ...videos[index],
      videoHeader,
    };

    openVideo(videoItem);
    setActiveButtonIndex(index);
  };

  const closeVideo = (): void => {
    setShowVideo(false);
    setActiveVideo(null);
    setActiveButtonIndex(null);
  };

  const openVideo = (videoItem: ActiveVideo): void => {
    setShowVideo(true);
    setActiveVideo(videoItem);
  };

  return (
    <Box sx={styles.additionalInfoContainer}>
      <Box sx={styles.additionalInfoLeft}>
        <Box sx={{ width: '210px', flex: 'none' }}>
          <Typography variant="subtitle2" component="p" color={colors.white}>
            Repetera videos och teori genom att klicka på knapparna nedan.
          </Typography>

          <Box sx={styles.buttons}>
            {
              Object.values(Exercises).map((buttonName, index) =>
                <MuiButton
                  type="button"
                  variant="contained"
                  onClick={() => btnClick(index, buttonName)}
                  sx={{...stylesProps(true).additionalInfoLeftBtnItem}}
                >
                  {buttonName}
                </MuiButton>)
            }
          </Box>

          <MuiButton type="button" variant="contained" sx={styles.NavLink}>
            <Link to={previousUrl}>Tillbaka till Instruktion</Link>
          </MuiButton>
        </Box>

        <Box>
          <Typography variant="h1" component="h1" color={colors.white} sx={{ fontSize: '18px', marginBottom: '16px' }}>
            { videoInfo[activeButtonIndex as number]?.headline }
          </Typography>

          {
            videoInfo[activeButtonIndex as number]?.paragraphs.map((paragraph) =>
              <Typography variant="subtitle2" component="p" color={colors.white}>{paragraph}</Typography>)
          }
        </Box>
      </Box>

      <Box sx={styles.additionalInfoRight}>
        {isShowVideo && <VideoModal handleClose={() => closeVideo()} videoInfo={activeVideo} isPlaying={true} />}
      </Box>
    </Box>
  );
};

export default ExerciseInfo;

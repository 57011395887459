import React from 'react';
import StepTransition from 'shared/components/StepTransition';
import styles from './styles';

const TransitionStep4 = (): JSX.Element => {
  return (
    <StepTransition
      svg={
        <svg id="svg4" viewBox="0 0 380 168" style={styles.path}>
          <defs>
            <mask id="msk4">
              <rect width="120%" height="120%" fill="white" />
              <path
                stroke="black"
                strokeWidth="11"
                fill="none"
                strokeDashoffset="700"
                strokeDasharray="700"
                strokeLinecap="round"
                d="M 2.91 22.701 C 2.91 22.701 39.09 4.369 80.908 4.657 C 167.814 5.256 185.76 84.88 247.963 101.281 C
          269.352 106.92 293.064 94.577 314.319 100.698 C 377.496 118.892 383.004 172.875 383.004 172.875"
              >
                <animate attributeName="stroke-dashoffset" dur="4s" begin="0s" values="0;700;" fill="freeze" />
              </path>
            </mask>
          </defs>
          <path
            className="dashed"
            mask="url(#msk4)"
            d="M 2.91 22.701 C 2.91 22.701 39.09 4.369 80.908 4.657 C 167.814 5.256 185.76 84.88 247.963 101.281 C
          269.352 106.92 293.064 94.577 314.319 100.698 C 377.496 118.892 383.004 172.875 383.004 172.875"/>
        </svg>
      }
      stepName="Utvärdera och välj"
      stepNumber={4}
      stepIcons={['pencil', 'blackCap']}/>
  );
};

export default TransitionStep4;

import React, { useState } from 'react';
import ReactPlayer from 'react-player/file';
import { Box } from '@mui/system';
import { config as configVideo } from './config';
import { stylesProps } from './styles';

interface Props {
  url: string;
  ref?: any;
  previewUrl: string;
  height?: string;
  width?: string;
  isPlaying?: boolean;
}

const VideoPlayer = React.forwardRef((props: Props, ref: any): JSX.Element => {
  const { url, previewUrl, height, width, isPlaying: isPlayingAuto } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const customWidth = width ?? '100%';
  const customHeight = height ?? '100%';
  const isAuto: boolean | undefined = isPlayingAuto;

  const onClickPreview = (event) => {
    event.stopPropagation();
    setIsPlaying(true);
  };
  return (
    <Box sx={stylesProps(customWidth, customHeight).factor}>
      <ReactPlayer
        url={url}
        playing={isAuto ? isAuto : isPlaying}
        ref={ref}
        width={'100%'}
        height={'100%'}
        muted={false}
        volume={0.4}
        controls
        config={configVideo}
        light={isAuto ? undefined : previewUrl}
        onClickPreview={isAuto ? undefined : onClickPreview}/>
    </Box>
  );
});

export default VideoPlayer;

import * as yup from 'yup';
const message = 'Parametervärdet måste ligga i intervallet 0 till 10';
const validationSchema = yup.object().shape({
  newCompetitionParameters: yup.array().of(
    yup.object().shape({
      overriddenValue: yup.number().min(0, message).max(10, message).typeError(message).required('Obligatoriskt fält'),
    }),
  ),
});

export default validationSchema;

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  wrapperField: {
    display: 'flex',
    maxWidth: '100%',
    '& input': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      color: colors.accentBlue,
      top: '0',
      left: '0',
      padding: '10px 16px',
      border: 'none',
      boxShadow: '0px 2px 4px rgb(0 0 0 / 14%), 0px 3px 4px rgb(0 0 0 / 12%), 0px 1px 5px rgb(0 0 0 / 20%)',
      fontFamily: 'Poppins,sans-serif',
      fontSize: '14px',
    },
    '& input:focus': {
      outline: '1px solid transparent',
    },
  } as SystemCssProperties,
};

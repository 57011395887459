import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { RequestUser } from 'shared/store/auth/interface';
import { authService } from 'core/services/auth';
import { RoutesPage } from 'shared/routes';

interface Props {
  payload: RequestUser;
}

export function* getLogin(props: Props): SagaIterator {
  const {
    payload: { email, password, history, roles },
  } = props;
  let body;
  if (roles) {
    body = { email, password, roles };
  } else {
    body = { email, password };
  }
  try {
    const response = yield call(authService.login, 'auth/login', body);
    yield put(actions.loginActions.end(response));
    localStorage.setItem('accessToken', response.accessToken);
    if (roles) {
      history.push(RoutesPage.adminPanel.invitedUsers, { from: `${RoutesPage.admin}${RoutesPage.login}` });
    } else {
      history.push(RoutesPage.home, { from: RoutesPage.login });
    }
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.loginActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* getLoginSaga(): SagaIterator {
  yield takeLatest(actions.loginActions.start, getLogin);
}

import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { AcceptInvite } from 'shared/store/auth/interface';
import { authService } from 'core/services/auth';
import { RoutesPage } from 'shared/routes';

interface Props {
  payload: AcceptInvite & { history: any; token: string };
}

export function* getRegistration(props: Props): SagaIterator {
  const { history, token, ...userData } = props.payload;
  try {
    const response = yield call(authService.registration, 'invites/accept', { ...userData, token });
    localStorage.setItem('accessToken', response.accessToken);
    yield put(actions.loginActions.end(response));
    history.push(RoutesPage.home);
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.loginActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* getRegistrationSaga(): SagaIterator {
  yield takeLatest(actions.registrationActions.start, getRegistration);
}

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 2,
  } as SystemCssProperties,

  textTitle: {
    color: colors.white,
  } as SystemCssProperties,
};

import * as React from 'react';
import Header from 'components/WebTools/MainForm/ProjectForm/Header';
import ContainerAccordion from 'components/WebTools/MainForm/ContainerAccordion';

const ProjectForm = (): JSX.Element => {
  return (
    <>
      <Header />
      <ContainerAccordion />
    </>
  );
};

export default ProjectForm;

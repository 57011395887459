export const descriptions: string[] = [
  'Er uppgift idag är att ta er fram till en BLÅ OCEAN.',
  'Till er hjälp får ni instruktioner i text- eller videoform.',
  'Ni ska ta er igenom totalt 6 olika faser:',
];

export const steps: Array<{ name: string; time: number }> = [
  {
    name: 'Inledning & teori',
    time: 30,
  },
  {
    name: 'Nuvarande position',
    time: 60,
  },
  {
    name: 'Skapa nytt',
    time: 75,
  },
  {
    name: 'Utvärdera och välj',
    time: 30,
  },
  {
    name: 'Den nya positionen',
    time: 30,
  },
  {
    name: 'Eftertanke och avslut',
    time: 15,
  },
];

import * as React from 'react';

const Survays = (): JSX.Element => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 17.5C32 24.9558 25.9558 31 18.5 31C11.0442 31 5 24.9558 5 17.5C5 10.0442 11.0442 4 18.5 4C25.9558
              4 32 10.0442 32 17.5ZM12.875 10.257C11.943 10.257 11.1875 11.0126 11.1875 11.9445V22.0695C11.1875 23.0015
              11.943 23.757 12.875 23.757C13.807 23.757 14.5625 23.0015 14.5625 22.0695V11.9445C14.5625 11.0126 13.807
              10.257 12.875 10.257ZM16.8125 17.5696C16.8125 16.6376 17.568 15.8821 18.5 15.8821C19.432 15.8821 20.1875
              16.6376 20.1875 17.5696V22.0696C20.1875 23.0015 19.432 23.7571 18.5 23.7571C17.568 23.7571 16.8125 23.0015
              16.8125 22.0696V17.5696ZM24.125 13.6321C23.193 13.6321 22.4375 14.3876 22.4375 15.3196V22.0696C22.4375
              23.0015 23.193 23.7571 24.125 23.7571C25.057 23.7571 25.8125 23.0015 25.8125 22.0696V15.3196C25.8125
              14.3876 25.057 13.6321 24.125 13.6321Z"
        fill="#46B9EB"/>
    </svg>
  );
};

export default Survays;

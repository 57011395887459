import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTypedSelector} from 'shared/hooks/useTypedSelector';
import {actions} from 'shared/store/ListVideos';
import Loader from 'shared/components/Loader';
import {useDispatch} from 'react-redux';
import CustomTable from '../Table';
import {TableHeaderVideos} from 'shared/constants/enum';

const TableVideos = (): JSX.Element => {
  const dispatch = useDispatch();
  const {videos, isLoading} = useTypedSelector((state) => state.listVideos.getVideos);
  const [, setIdRow] = useState<number | string>(0);

  useEffect(() => {
    dispatch(actions.getVideosActions.start());
  }, []);

  if (isLoading || !videos.length) {
    return <Loader/>;
  }

  return (<CustomTable header={TableHeaderVideos} isDeleteIcon={false} rows={videos} setIdRow={setIdRow}/>) as JSX.Element;
};

export default TableVideos;

import colors from 'styles/_Variables';
import { SystemCssProperties } from '@mui/system';

export const styles = {
  typoHeader: {
    marginBottom: '32px',
    color: colors.accentBlue,
    textAlign: 'center',
  } as SystemCssProperties,

  eye: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    cursor: 'pointer',
    right: '15px',
    top: '13px',
  } as SystemCssProperties,

  fields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as SystemCssProperties,

  styleFiled: {
    width: '100%',
    marginBottom: '32px',
    maxWidth: '296px',
    position: 'relative',
  } as SystemCssProperties,

  btn: {
    width: '100%',
    height: '48px',
    maxWidth: '196px',
  } as SystemCssProperties,

  btnTxt: {
    color: colors.white,
  } as SystemCssProperties,

  linkReset: {
    marginTop: '47px',
    marginBottom: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
  } as SystemCssProperties,

  linkResetTxt: {
    color: colors.accentBlue,
  } as SystemCssProperties,
};

import * as React from 'react';
import {styles} from './styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiButton from 'shared/components/UI/MuiButton';
import MuiModal from 'shared/components/Modal';
import {useLogout} from 'shared/hooks/useLogout';

interface Props {
  [key: string]: any;

  isOpenLogoutConfirm: boolean;
  handleClose: () => void;
}

const Logout = (props: Props): JSX.Element => {
  const {isOpenLogoutConfirm, handleClose} = props;
  const logout = useLogout();

  return (
    <MuiModal isOpen={isOpenLogoutConfirm} handleClose={handleClose}>
      <Box sx={styles.modal}>
        <Typography id="modal-modal-title" variant="h6" component="div" sx={styles.modalHeader} onClick={handleClose}>
          <Box>Exit</Box>
          <IconButton color="inherit" size="small" disableRipple>
            <CloseIcon/>
          </IconButton>
        </Typography>
        <Typography id="modal-modal-description" className="modal__body" component={'p'} sx={styles.body}>
          Är du säker på att du vill logga ut
        </Typography>
        <Box sx={styles.modalButtons}>
          <MuiButton variant="text" type="button" onClick={handleClose} sx={styles.muiButton}>
            Avbryt
          </MuiButton>
          <MuiButton variant="contained" type="button" onClick={logout} sx={styles.muiButtonAccent}>
            Logga ut
          </MuiButton>
        </Box>
      </Box>
    </MuiModal>
  );
};

export default Logout;

import * as React from 'react';
import {Dispatch, SetStateAction, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

import Row from 'components/WebTools/Archive/TableArchive/Row';
import {TableHeaderArchive} from 'shared/constants/enum';

import {styles} from './styles';
import {Archive} from 'shared/store/archive/interface';
import ArchiveCollapse from 'components/WebTools/Archive/TableArchive/ArchiveCollapse';

export interface Props {
  header: typeof TableHeaderArchive;
  rows: Archive[];
  setIdRow: Dispatch<SetStateAction<string | number>>;
  onHandleOpenPopup: Dispatch<SetStateAction<boolean>>;
}

const CustomTable = (props: Props): JSX.Element => {
  const {header, rows, setIdRow, onHandleOpenPopup} = props;
  const tableHeader: string[] = Object.keys(header).filter((x) => !(parseInt(x) >= 0));
  const [isOpenCollapseOnId, setIsOpenCollapseOnId] = useState<number | boolean>(0);

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <Table>
        <TableHead sx={styles.tableHead}>
          <TableRow sx={styles.tableRow}>
            {tableHeader.map((headerItem: string) => (
              <TableCell key={headerItem} sx={styles.td}>{headerItem}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 &&
            rows.map((row: Archive) => (
              <Row
                key={row.id}
                row={row}
                isOpenCollapseOnId={isOpenCollapseOnId}
                setIsOpenCollapseOnId={setIsOpenCollapseOnId}
                setIdRow={setIdRow}
                renderCollapse={() => <><ArchiveCollapse onHandleOpenPopup={onHandleOpenPopup} archive={row}/></>}/>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;

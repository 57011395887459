import {createTheme} from '@mui/material/styles';
import {MuiButton, MuiCssBaseline, MuiInputBase, MuiPaper} from 'shared/theme/overrides';
import {Body2, Header1, Subtitle1, Subtitle2} from 'shared/theme/typography';

export const theme = createTheme({
  palette: {
    secondary: {
      main: '#72C509',
    },
    success: {
      contrastText: '#fff',
      main: '#72C509',
      dark: '#72C509',
      light: '#72C509',
    },
    error: {
      main: '#D51C29',
      dark: '#D51C29',
      light: '#D51C29',
    },
    info: {
      contrastText: '#fff',
      dark: '#01579b',
      light: '#03a9f4',
      main: '#0288d1',
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.38)',
      primary: '#20262D',
      secondary: '#46505A',
    },
  },
  components: {
    MuiCssBaseline,
    MuiButton,
    MuiInputBase,
    MuiPaper,
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    subtitle1: Subtitle1,
    subtitle2: Subtitle2,
    body2: Body2,
    h1: Header1,
    caption: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: 'center',
      color: '#FFF',
    },
    fontSize: 14,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
  },
});

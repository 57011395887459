import React from 'react';
import { Box } from '@mui/material';
import { styles } from './styles';
import MuiButton from 'shared/components/UI/MuiButton';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';

interface Props {
  isLeftButtonDisabled?: boolean;
  isRightButtonDisabled?: boolean;
  navigateBack: (event: React.MouseEvent<HTMLInputElement>) => void;
  navigateForward: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const NavigationBar = (props: Props): JSX.Element => {
  const { isLeftButtonDisabled, isRightButtonDisabled } = props;

  return (
    <Box sx={styles.container}>
      <MuiButton
        type="button"
        variant="contained"
        sx={ styles.button }
        disabled={isLeftButtonDisabled}
        onClick={(event) => props.navigateBack(event)}
      >
        <ArrowLeft width="20px" height="14px" />
      </MuiButton>

      <MuiButton
        type="button"
        variant="contained"
        sx={ styles.button }
        disabled={isRightButtonDisabled}
        onClick={(event) => props.navigateForward(event)}
      >
        <ArrowRight width="20px" height="14px" />
      </MuiButton>
    </Box>
  );
};

export default NavigationBar;

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  archiveCollapse: {
    padding: '30px 0 37px 24px',
    background: colors.selectedLink,
  } as SystemCssProperties,

  buttonCollapse: {
    background: colors.white,
    color: colors.accentBlue,
    textTransform: 'none',
    '&:hover': {
      background: colors.white,
    },
  } as SystemCssProperties,

  buttons: {
    display: 'flex',
    gap: '40px',
  } as SystemCssProperties,
};

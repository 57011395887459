import { combineReducers } from '@reduxjs/toolkit';
import { reducers as auth } from 'shared/store/auth';
import { reducers as snackbar } from 'shared/store/snackbar';
import { reducers as project } from 'shared/store/project';
import { reducers as files } from 'shared/store/files';
import { reducers as archives } from 'shared/store/archive';
import { reducers as popup } from 'shared/store/popup';
import { reducers as admin } from 'shared/store/admin';
import { reducers as listVideos } from 'shared/store/ListVideos';

const rootReducer = combineReducers({
  auth,
  snackbar,
  project,
  files,
  archives,
  popup,
  admin,
  listVideos,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Snackbar as SnackbarType } from 'shared/store/snackbar/interface';
import { actions } from 'shared/store/snackbar';
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { Snackbar } from '@mui/material';
import { SnackBarType } from 'shared/constants/enum';
import { useTypedSelector } from 'shared/hooks/useTypedSelector';
import { stylesSnackbar } from 'shared/components/SnackBar/styles';
import { styles } from './styles';

const MuiSnackbar = (): JSX.Element => {
  const dispatch = useDispatch();
  const { isSnackbar } = useTypedSelector((state) => state.snackbar.snackbar);

  const handleClose = (): void => {
    dispatch(actions.snackbarActions.setSnackbar(false));
  };

  const timeClosed = (): void => {
    setTimeout(() => {
      dispatch(actions.snackbarActions.setSnackbar(false));
    }, 6000);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    timeClosed();
  }, [isSnackbar]);

  const { snackErrors } = useTypedSelector((state) => state.snackbar.snackbar);

  return (
    <Box>
      {snackErrors.map((snackbar: SnackbarType) => (
        <Box key={snackbar.id} sx={stylesSnackbar(snackbar.type === SnackBarType.Success).root}>
          <Snackbar
            message={snackbar.text}
            open={isSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            action={action}
            sx={styles.action}/>
        </Box>
      ))}
    </Box>
  );
};

export default MuiSnackbar;

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  Loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as SystemCssProperties,

  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: colors.white,
  } as SystemCssProperties,

  typography: {
    fontSize: '24px',
    textAlign: 'center',
    lineHeight: '32px',
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
  } as SystemCssProperties,

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: colors.white,
  } as SystemCssProperties,

  name: {
    fontSize: '24px',
    fontFamily: 'Poppins',
    marginBottom: '24px',
    fontWeight: 600,
  } as SystemCssProperties,

  instructions: {
    color: colors.white,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  } as SystemCssProperties,

  wrapper: {
    width: '50%',
  } as SystemCssProperties,

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '66px',
  } as SystemCssProperties,

  stepTime: {
    fontFamily: 'Poppins',
    marginTop: '24px',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '16px',
    color: colors.white,
  } as SystemCssProperties,

  NavLink: {
    marginTop: '40px',

    '&.MuiButton-root': {
      padding: '0px',
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.white,
      textTransform: 'none',
      width: '100%',
      height: '100%',
      padding: '10px 16px',
    },
  } as SystemCssProperties,
};

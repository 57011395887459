import HTTP from 'core/services/api';

export const formService = {
  getProject: async (url: string) => {
    const response = await HTTP.get(url);
    return response.data;
  },

  sharedProject: async (token: string) => {
    const response = await HTTP.get(token);
    return response.data;
  },

  getSharedProject: async (token: string) => {
    const response = await HTTP.get(token);
    return response.data;
  },
  createProject: async (url: string) => {
    const response = await HTTP.post(url);
    return response.data;
  },
  completionProject: async (url: string, formData: any, config?: any) => {
    const response = await HTTP.post(url, formData, config);
    return response.data;
  },
  addFiles: async (url: string, formData: any, config: any) => {
    const response = await HTTP.post(url, formData, config);
    return response.data;
  },
  removeFiles: async (url: string) => {
    const response = await HTTP.delete(url);
    return response.data;
  },
  updateProject: async (url: string, body: any) => {
    const response = await HTTP.patch(url, body);
    return response.data;
  },
  updateTimer: async  (url: string, body: any) => {
    const response = await HTTP.patch(url, body);
    return response.data;
  },
};

import React from 'react';
import StepTransition from 'shared/components/StepTransition';
import styles from './styles';

const TransitionStep1 = (): JSX.Element => {
  return (
    <StepTransition
      svg={
        <svg id="svg1" viewBox="0 0 401.63 119.86" style={styles.path}>
          <defs>
            <mask id="msk1">
              <rect width="120%" height="120%" fill="black" />
              <path
                stroke="white"
                strokeWidth="11"
                fill="none"
                strokeDashoffset="700"
                strokeDasharray="700"
                strokeLinecap="round"
                d="M 0 21.587 C 0 21.587 85.701 119.517 160.07 120.54 C 296.767 122.421 251.902 16.214 315.483 1.797 C 344.095
           -4.691 401.63 19.841 401.63 19.841"
              >
                <animate attributeName="stroke-dashoffset" dur="4s" begin="0s" values="700;0;" fill="freeze" />
              </path>
            </mask>
          </defs>
          <path
            className="dashed"
            mask="url(#msk1)"
            d="M 0 21.587 C 0 21.587 85.701 119.517 160.07 120.54 C 296.767 122.421 251.902 16.214 315.483 1.797 C 344.095
           -4.691 401.63 19.841 401.63 19.841"/>
        </svg>
      }
      stepName="Inledning & teori"
      stepNumber={1}/>
  );
};

export default TransitionStep1;

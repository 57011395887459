import * as React from 'react';
import {useState} from 'react';
import {TextField} from '@mui/material';
import {styles} from './styles';
import colors from 'styles/_Variables';
import Box from '@mui/material/Box';
import InfoComponent from 'shared/components/UI/Info';
import TextInfo from 'shared/components/UI/Info/Text';
import {useCheckPermissions} from '../../../hooks/useCheckPermissions';
import {Roles} from '../../../constants/enum';
import {competitorName} from 'components/WebTools/MainForm/ProjectForm/Forms/CompetitorsLocal/helpers';

const MuiField = (props): JSX.Element => {
  const [isShowInfo, setIsShowInfo] = useState<boolean>(false);
  const {maxLength, isDisabledIcon, iconEye, sx, ...rest} = props;

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const requiredFiledText = `Obligatoriskt, max ${maxLength ?? 100} tecken`;
  const IsNotRequiredFiledText = `max ${maxLength ?? 100} tecken`;

  const textInfo = (nameField) => {
    if (nameField === 'general.name' || competitorName.includes(nameField)) {
      return requiredFiledText;
    }
    return IsNotRequiredFiledText;
  };

  return (
    <Box sx={styles.wrapperText}>
      <TextField
        {...rest}
        disabled={props.disabled || isMU}
        sx={{...styles.textField, ...sx}}
        InputLabelProps={{
          style: {
            color: colors.accentBlue,
          },
        }}
        inputProps={{maxLength: maxLength ?? 200}}
      />
      {!isDisabledIcon && (
        <>
          <InfoComponent onSetIsShowInfo={setIsShowInfo}/>
          <Box sx={styles.textInfo}>
            <TextInfo textInfo={textInfo(props.name)} isShowInfo={isShowInfo}/>
          </Box>

        </>
      )}
      {iconEye && iconEye}
    </Box>
  );
};

export default React.memo(MuiField);

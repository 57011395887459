import { StepNumber } from 'shared/interfaces';

interface StepInstructions {
  step: StepNumber;
  instructions: string[];
  time: number;
}

const stepInstructions: StepInstructions[] = [
  {
    step: 1,
    instructions: ['Titta på instruktionsvideon.'],
    time: 10,
  },
  {
    step: 2,
    instructions: [
      'Titta på instruktionsvideon.',
      'Klicka på fortsätt för att diskutera och ta fram era konkurrensparametrar.',
    ],
    time: 6,
  },
  {
    step: 3,
    instructions: ['Titta på instruktionsvideon.', 'Klicka på Fortsätt när ni är klara.'],
    time: 17,
  },
  {
    step: 4,
    instructions: ['Titta på instruktionsvideon.', 'Klicka sedan på fortsätt.'],
    time: 15,
  },
  {
    step: 5,
    instructions: ['Titta på instruktionsvideon.', 'Klicka sedan på Fortsätt för att se er nya position.'],
    time: 3,
  },
  {
    step: 6,
    instructions: ['Titta på instruktionsvideon.', 'Klicka sedan på ”Fortsätt”.'],
    time: 0,
  },
];

export const getInstructionsByStep = (step: StepInstructions['step']): StepInstructions => {
  return stepInstructions.find((instructions) => instructions.step === step || 1) as StepInstructions;
};

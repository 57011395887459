import colors from 'styles/_Variables';
import { SystemCssProperties } from '@mui/system';

export const styles = {
  wrapperText: {
    position: 'relative',
  } as SystemCssProperties,
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      background: colors.white,
      padding: 0,
      color: colors.accentBlue,
      '&.Mui-disabled': {
        borderRadius: '4px',
        background: '#8A96AD',
        '& input::placeholder': {
          opacity: 1,
          WebkitTextFillColor: '#D6DAE1',
          borderRadius: '4px',
        },
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#D6DAE1',
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.focusFiled,
      },
      '&.Mui-error fieldset': {
        borderWidth: '2px',
      },
      '& input::placeholder, & textarea::placeholder': {
        opacity: 1,
        color: colors.darkGrey,
      },
      '& .MuiOutlinedInput-input': {
        padding: '12.5px 45px 12.5px 12.5px',
      },
    },
    '& .MuiFormHelperText-root ': {
      position: 'absolute',
      bottom: '-20px',
    },
  } as SystemCssProperties,

  propsField: {
    fontFamily: 'Poppins, sans-serif',
    color: colors.accentBlue,
    fontSize: 16,
  } as SystemCssProperties,

  textInfo: {
    position: 'absolute',
    left: 0,
    top: '50px',
    right: '-50px',
  } as SystemCssProperties,
};

import backgroundBoxGame from 'assets/images/backgroundBoxGame.svg';
import { SystemCssProperties } from '@mui/system';

export const styles = {
  root: {
    background: `url(${backgroundBoxGame}) no-repeat center/cover`,
    minHeight: {
      lg: '100vh',
      lx: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    padding: {
      md: '0',
      lg: '0 40px 0 40px',
    },
  } as SystemCssProperties,
};

import * as React from 'react';
import { useRef } from 'react';
import { styles as stylesTable, stylesRow } from '../../Table/styles';
import { TableCell, TableRow } from '@mui/material';
import VideoPlayer from 'shared/components/VideoPlayer';
import { StepVideo } from 'shared/store/ListVideos/interface';
import RowVideo from './RowVideo';

interface Props {
  row: StepVideo;
  isOpenCollapseOnId: any;
  setIsOpenCollapseOnId: any;
  setIdRow: any;
}

const VideoCollapse = (props: Props): JSX.Element => {
  const { row, isOpenCollapseOnId, setIsOpenCollapseOnId, setIdRow } = props;

  const playerRef = useRef();
  const handleRow = (ev) => {
    ev.stopPropagation();
    if (row.id === isOpenCollapseOnId && ev.target.tagName !== 'VIDEO') {
      setIsOpenCollapseOnId(0);
      setIdRow(0);
    }
  };

  return (
    <TableRow sx={stylesRow(isOpenCollapseOnId, row.id).tableBodyRow} onClick={handleRow}>
      <RowVideo row={row} />
      {row.url && (
        <TableCell sx={stylesTable.td}>
          <VideoPlayer ref={playerRef} url={row.url} previewUrl={row.previewUrl} width="382px" height="250px" />
        </TableCell>
      )}
    </TableRow>
  );
};

export default VideoCollapse;

import { Properties } from 'csstype';

const styles = {
  path: {
    position: 'absolute',
    left: '0px',
    top: '50%',
    transform: 'translateY(-50%)',
  } as Properties,
};

export default styles;

import {SystemCssProperties} from '@mui/system';

export const styles = {
  errorMessage: {
    color: 'red',
    fontSize: '12px',
    marginTop: '2px',
    marginLeft: '12px',
    position: 'absolute',
  } as SystemCssProperties,
};

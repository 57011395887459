import React, {Dispatch, ForwardedRef, SetStateAction} from 'react';
import {Typography} from '@mui/material';
import {Label, LabelProps, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import {useCurrentPng} from 'recharts-to-png';
import {Box} from '@mui/system';

import {DiagramData} from '../index';
import {
  styles,
  stylesDiagram,
  stylesProps,
} from 'components/WebTools/ProjectProcess/Steps/Step_5/DiagramContainer/DiagramCompetitors/styles';

import {Competitors} from 'shared/store/project/interface';
import {colorsCompetitors} from 'components/WebTools/ProjectProcess/Steps/Step_5/DiagramContainer/utils';

import FullWidth from 'shared/components/Icons/FullWidth';
import CloseIcon from '@mui/icons-material/Close';

import {DiagramStep5} from 'shared/constants/enum';
import IconButton from '@mui/material/IconButton/IconButton';
import colors from 'styles/_Variables';
import {sep} from 'shared/helpers';

export interface Props {
  dataDiagram: DiagramData[] | any;
  competitors: Competitors[];
  setFillSize: Dispatch<SetStateAction<boolean>>;
  isFullSize: boolean;
}

const DiagramCompetitors = React.forwardRef((props: Props, ref?: ForwardedRef<any>): JSX.Element => {
  const {dataDiagram, competitors, setFillSize, isFullSize} = props;
  const [getPng, {ref: defaultRef}] = useCurrentPng();

  const generateColor = (name, inx) => {
    if (name === DiagramStep5.GAMMALSTRATEGI) {
      return colorsCompetitors[colorsCompetitors.length - 1];
    }
    if (name === DiagramStep5.RIKSBYGGEN) {
      return colorsCompetitors[0];
    }
    return colorsCompetitors[inx];
  };

  const closeButton = (
    <IconButton size="small" aria-label="close" color="inherit" disableRipple sx={styles.closeButton}>
      <CloseIcon sx={styles.closeSvg}/>
      Stäng
    </IconButton>
  );

  const renderLegend = (property) => {
    const {payload} = property;
    return (
      <Box>
        {payload.map((item, index) => {
          return (
            <Box sx={styles.wrapperColorsCompetitors} key={item.dataKey}>
              <Box sx={stylesProps(index, generateColor, item.dataKey, isFullSize).stylesCircle}/>
              <Box sx={item.dataKey.length > 20 ? styles.wrapperPopup : null}>
                <Typography sx={stylesDiagram(isFullSize).colorText} variant="subtitle2">
                  {item.dataKey}
                </Typography>
                <Box sx={stylesDiagram(isFullSize).popupCompetitor} className="showPopupKey">
                  {item.dataKey}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderText = (data) => {
    const text = sep(data.payload?.value, 20);
    return text.map((txt: string) => (
      <tspan x={data.x} dy="1.2em">
        {txt}
      </tspan>
    ));
  };

  const renderAxisArrow = (axis: 'x' | 'y', data: any) => {
    const x = data.width + 60;
    const y = data.y - 14;
    switch (axis) {
      case 'y':
        return <path d="M 59 9 L 65 3 L 71 9" fill="none" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>;
      case 'x':
      default:
        return (
          <>
            <text y={isFullSize ? -5 : -24}>Konkurrensvärde</text>

            <path
              d={`M ${x} ${y} L ${x + 6} ${y + 6} L ${x} ${y + 12}`}
              fill="none"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"/>
            <text
              fontSize={data.fontSize}
              strokeWidth={data.strokeWidth}
              stroke={data.stroke}
              orientation="bottom"
              width={300}
              height={data.height}
              type={data.type}
              x={data.x}
              y={data.y}
              fill={colors.black}
              transform={`rotate(${-45}, ${data.x}, ${data.y})`}
              textAnchor="end"
              className="recharts-text recharts-cartesian-axis-tick-value"
            >
              <tspan x={data.x} dy="0.72em">
                {renderText(data)}
              </tspan>
            </text>
          </>
        );
    }
  };

  const renderLabel = (textLabel: string, position: LabelProps['position']): JSX.Element => {
    const fill = {fill: colors.black};
    return (
      <Label angle={360} position={position} style={fill}>
        {textLabel}
      </Label>
    );
  };

  const renderChart = () => {
    return (
      <LineChart data={dataDiagram} ref={ref || defaultRef}>
        <XAxis
          tick={(data) => renderAxisArrow('x', data)}
          dataKey="name"
          interval={0}
          padding={{left: isFullSize ? 60 : 40, right: 55}}
          fontSize={isFullSize ? '16px' : '13px'}
          strokeWidth="2px"/>
        <YAxis
          tick={(data) => renderAxisArrow('y', data)}
          fontSize={isFullSize ? '16px' : '13px'}
          strokeWidth="2px"
          stroke="black">
          {renderLabel('Hög', isFullSize ? 'insideTop' : {x: 44, y: 20})}
          {renderLabel('Låg', 'bottom')}
        </YAxis>
        <Legend align={'right'}
                verticalAlign={'middle'}
                width={140}
                content={renderLegend}
                iconType="circle"
                wrapperStyle={stylesDiagram(isFullSize).wrapperStyleLegend}
        />
        {competitors.map((item: Competitors, index: number) => {
          const prop = item.name === DiagramStep5.GAMMALSTRATEGI ? '5 5' : '';
          const colorLine = generateColor(item.name, index);
          if (item.hasAllOldCompetitionValues) {
            return (
              <Line
                key={item.name}
                style={{
                  width: '8px',
                  height: '8px',
                }}
                r={isFullSize ? 7 : 5}
                isAnimationActive={false}
                type="linear"
                dataKey={item.name}
                stroke={colorLine}
                dot={{fill: colorLine}}
                activeDot={{fill: colorLine}}
                strokeDasharray={prop}/>
            );
          }
        })}
      </LineChart>
    );
  };

  return (
    <Box sx={stylesDiagram(isFullSize).wrapperDiagram}>
      {
        isFullSize &&
        <Typography sx={stylesDiagram(isFullSize).titleDiagram} variant="subtitle2" component="p">
          Ny strategi
        </Typography>
      }
      <Box
        onClick={() => {
          setFillSize((prevState) => !prevState);
        }}
        sx={stylesDiagram(isFullSize).iconFullWidth}
      >
        {isFullSize ? closeButton : <FullWidth/>}
      </Box>
      <ResponsiveContainer height={!isFullSize ? 320 : '75%'} width={!isFullSize ? '80%' : '100%'}>
        {
          renderChart()
        }
      </ResponsiveContainer>
    </Box>
  );
});

export default DiagramCompetitors;

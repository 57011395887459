import { Competitors, OldCompetitionParameters } from 'shared/store/project/interface';

const listIncludesNames = ['doings', 'priorities'];
export const transformDataDiagram = (allCompetitors: Competitors[], oldListsCompetitionParameters: OldCompetitionParameters[]) => {
  const namesTransformCompetitors = allCompetitors
    .map((item: Competitors) => Object.entries(item).filter(([key]: any) => !listIncludesNames.includes(key)))
    .map((item) => Object.fromEntries(item));
  return oldListsCompetitionParameters.reduce((total: any, item: OldCompetitionParameters, index: number) => {
    total[index] = item.values.reduce((accum, value, indexVal) => {
      accum[namesTransformCompetitors[indexVal].name] = value;
      return accum;
    }, {});
    total[index].name = item.name;
    total[index].values = item.values;
    return total;
  }, []);
};

export const colorsCompetitors = ['#D51C29', '#0063AA', '#00B8F1', '#123967', '#05A64F', '#274C26'];

import * as React from 'react';
import { styles } from './styles';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Image } from '@mui/icons-material';
import { Box } from '@mui/system';

import Close from 'shared/components/Icons/Close';
import Loader from 'shared/components/Loader';

import { RootState } from 'shared/store/rootReducer';
import { FileType, Project, ProjectWithoutFiles } from 'shared/store/project/interface';

import { actions } from 'shared/store/files';
import { useFormikContext } from 'formik';
import { memo } from 'react';

import { DOC, PDF } from 'shared/components/Icons/index';
import {useCheckPermissions} from '../../../../../../shared/hooks/useCheckPermissions';
import {Roles} from '../../../../../../shared/constants/enum';

export interface Props {
  name: string;
  nameField: string;
}

const formatTypesImages = ['image/png', 'image/jpeg'];
const formatTypesPdf = ['application/pdf'];
const formatTypesDocs = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

const findFile = (arrayTypeFiles: string[], mimeType: string) => arrayTypeFiles.find((element: string) => element === mimeType);

const renderIcons = (mimeType: string): React.ReactNode => {
  switch (mimeType) {
    case findFile(formatTypesImages, mimeType): {
      return <Image />;
    }
    case findFile(formatTypesPdf, mimeType): {
      return <PDF />;
    }
    case findFile(formatTypesDocs, mimeType): {
      return <DOC />;
    }
  }
};

const Upload = (props: Props): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<Project>();
  const { name, nameField } = props;
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { isLoading: isLoadingDelete } = useSelector((state: RootState) => state.files.deleteFile);

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const removeFile = (key, compareValue) => {
    const filteredFiles = values.files[nameField].filter((item: FileType) => item[key] !== compareValue);
    setFieldValue(name, filteredFiles);
  };

  const removeFileToId = (fileId: number) => {
    const updateProjectWithoutFiles: ProjectWithoutFiles = {
      ...values,
    };
    delete updateProjectWithoutFiles.files;
    dispatch(
      actions.deleteFileActions.start({
        idProject: id,
        fileId,
      }),
    );
    removeFile('id', fileId);
  };

  if (isLoadingDelete) {
    return <Loader />;
  }

  return (
    <Box sx={styles.lists}>
      <List sx={styles.listsUl}>
        {values.files[nameField].map((file: FileType, index: number) => {
          if (file.id) {
            return (
              <ListItem sx={styles.listItem} key={index}>
                <ListItemIcon sx={styles.listItemIcon}>{renderIcons(file.mimeType as string)}</ListItemIcon>
                <ListItemText sx={styles.listItemText} primary={file.name} />
                {
                  !isMU &&
                  <Box onClick={() => removeFileToId(file.id)}>
                    <Close style={styles.closeIcon} />
                  </Box>
                }
              </ListItem>
            );
          } else {
            return (
              <ListItem sx={styles.listItem} key={index}>
                <ListItemIcon sx={styles.listItemIcon}>{renderIcons(file.type as string)}</ListItemIcon>
                <ListItemText sx={styles.listItemText} primary={file.name} />
                {
                  !isMU &&
                  <Box onClick={() => removeFile('name', file.name)}>
                    <Close style={styles.closeIcon} />
                  </Box>
                }
              </ListItem>
            );
          }
        })}
      </List>
    </Box>
  );
};

export default memo(Upload);

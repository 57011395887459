import { createSlice } from '@reduxjs/toolkit';
import { StateInterface } from 'shared/interfaces';
import { CreateFilesPayload } from 'shared/store/files/interface';

const initialState: StateInterface = {
  isLoading: false,
  error: {
    message: [],
    stackErrors: '',
  },
};

const addScheduleFiles = createSlice({
  name: 'addScheduleFiles',
  initialState,
  reducers: {
    start: {
      reducer(state) {
        state.isLoading = true;
        state.error = initialState.error;
      },
      prepare(payload: CreateFilesPayload) {
        return { payload };
      },
    },
    end(state) {
      state.isLoading = false;
    },
    error(state, action: any) {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
    clear() {
      return initialState;
    },
  },
});

export const addScheduleFilesActions = addScheduleFiles.actions;
export default addScheduleFiles.reducer;

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  Loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  } as SystemCssProperties,

  instructionStep: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '24px',
    color: colors.white,
    height: '100%',
    position: 'relative',
    top: '25%',
    '& .MuiTypography-root': {
      margin: '40px 0',
    },
  } as SystemCssProperties,

  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  } as SystemCssProperties,

  Title: {
    display: 'flex',
    justifyContent: 'space-between',
  } as SystemCssProperties,

  typography: {
    fontSize: '24px',
    textAlign: 'center',
    lineHeight: '32px',
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
  } as SystemCssProperties,

  NavLink: {
    width: 'auto',

    '&.MuiButton-root': {
      padding: '0px',
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.white,
      textTransform: 'none',
      width: '100%',
      height: '100%',
      padding: '10px 16px',
    },
  } as SystemCssProperties,

  NavLinkInstruct: {
    background: colors.white,
    '&.MuiButton-root': {
      padding: '0px',
    },
    height: '72px',
    width: '210px',

    '&:hover': {
      background: colors.white,
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.accentBlue,
      textTransform: 'none',
      width: '100%',
      height: '100%',
      padding: '10px 16px',
    },
  } as SystemCssProperties,

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
    columnGap: '66px',
  } as SystemCssProperties,

  paragraphs: {
    display: 'flex',
    flexDirection: 'column',
    color: colors.white,
    rowGap: '16px',
    width: '50%',
  } as SystemCssProperties,
};

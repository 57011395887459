import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const stylesProps = (isActive: boolean) => {
  return {
    additionalInfoLeftBtnItem: {
      width: '164px',
      height: '56px',
      color: colors.accentBlue,
      background: isActive ? colors.grey : colors.white,
      textTransform: 'none',
      boxShadow: 'none',
      padding: 0,
      '&:hover': {
        background: isActive ? colors.grey : colors.white,
        boxShadow: 'none',
      },
    } as SystemCssProperties,
  };
};

export const styles = {
  additionalInfoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    gap: '66px',
  } as SystemCssProperties,

  additionalInfoLeft: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '50%',
    gap: '24px',
    flexDirection: 'column',
  } as SystemCssProperties,

  instructions: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  } as SystemCssProperties,

  stepTime: {
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '16px',
    color: colors.white,
  } as SystemCssProperties,

  buttons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
  } as SystemCssProperties,

  NavLink: {
    width: '196px',
    marginTop: '16px',

    '&.MuiButton-root': {
      padding: '0px',
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.white,
      textTransform: 'none',
      width: '100%',
    },
  } as SystemCssProperties,

  additionalInfoRight: {
    height: '100%',
    marginBottom: {
      xl: '0',
      lg: '90px',
    },
  } as SystemCssProperties,
};

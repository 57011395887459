import * as React from 'react';
import { styles } from './styles';
import { Box } from '@mui/system';

interface Props {
  children?: React.ReactNode;
}

const LayoutContainer = (props: Props): JSX.Element => {
  const { children } = props;
  return <Box sx={styles.container}>{children}</Box>;
};

export default LayoutContainer;

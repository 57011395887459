import {SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';
import {StylesCSS} from 'shared/interfaces';

export const stylesProps = (isError?: boolean, isTouched?: boolean): StylesCSS => {
  return {
    td: {
      height: '42px',
      wordBreak: 'break-word',
      position: 'relative',
      color: colors.accentBlue,
      borderBottom: 'none',
      border: isError && isTouched ? '1.1px solid #D51C29' : '1px solid #D6DAE1',
      padding: '10px 16px',
      lineHeight: 'inherit',
    },
  };
};

export const styles: StylesCSS = {
  flxCenter: {
    marginTop: '36px',
    position: 'relative',
    left: '165px',
    display: 'flex',
    justifyContent: 'center',
  },

  tableCont: {
    borderRadius: '8px',
    boxShadow: 'none',
    borderCollapse: 'separate',
  },

  tableHidden: {
    height: '100%',
    minWidth: '805px',
    overflow: 'initial',
  },

  tableW: {
    width: '100%',
    minWidth: 650,
    borderRadius: '8px',
  },

  NavLink: {
    width: '196px',
    textDecoration: 'none',
    color: colors.white,
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&.MuiButton-root': {
      padding: '0px',
    },
  } as SystemCssProperties,

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  tableRow: {
    border: 'none',
  },

  tableContainer: {
    borderRadius: '8px',
    boxShadow: 'none',
  },

  buttonForm: {
    mt: 3,
    background: colors.lightGreyDisable,
  },

  textButtonForm: {
    color: colors.white,
  },

  tableTd: {
    background: 'inherit',
    width: '100%',
    textDecoration: 'none',
    color: colors.white,
    textTransform: 'none',
  },

  errorWrapper: {
    width: '100%',
    minWidth: '309px',
    padding: '4px 8px',
    background: colors.error,
    position: 'absolute',
    bottom: '-37px',
    left: 0,
    zIndex: 1000,
  },

  errorText: {
    color: colors.white,
    fontSize: '12px',
    lineHeight: '22px',
  },
};

export const stylesForm = {
  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'center',
  'align-items': 'center',
  width: '100%',
  'max-width': '1360px',
  margin: '0 auto',
};

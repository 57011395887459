import React from 'react';
import HelpLine from 'components/WebTools/ProjectProcess/Steps/Step_6/Instruction/Help';
import {Box} from '@mui/material';
import {styles} from './styles';
import Loader from 'shared/components/Loader';
import {useTypedSelector} from 'shared/hooks/useTypedSelector';
import Comment from 'components/WebTools/ProjectProcess/Steps/Step_6/CommentContainer/CommentSection';
import {TitleAndSubTitle} from '../../../Common/TittleAndSubTitile';
import {useGetProject} from 'shared/hooks/useGetProjects';

const CommentContainer = (): JSX.Element => {
  const { projectData } = useGetProject();
  const { isLoading: isLocationCompleteProject } = useTypedSelector((state) => state.project.completionProject);

  if (projectData === null) {
    return (
      <Box sx={styles.Loader}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {isLocationCompleteProject && (
        <Box sx={styles.Loader}>
          <Loader />
        </Box>
      )}
      <Box sx={styles.Title}>
        <TitleAndSubTitle
          Title={() => (
            <>
              Hur gick det för er? Är ni nöjda? <br /> Skriv dina kommentarer och synpunkter i detta fält
            </>
          )}/>
        <HelpLine />
      </Box>
      <Comment data={projectData.data} />
    </Box>
  );
};

export default CommentContainer;

import * as React from 'react';
import {Field} from 'formik';
import MuiField from 'shared/components/UI/MuiField';
import {SxProps} from '@mui/system';

export interface MuiFieldProps {
  formik?: any;
  multiline?: boolean;
  type?: string;
  disabled?: boolean;
  name?: string;
  label?: string;
  validate?: any;
  value?: string | number | null;
  placeholder?: string;
  onChange?: (...arg: any) => any;
  onBlur?: (...arg: any) => any;
  onClick?: (...arg: any) => any;
  error?: any;
  id?: string;
  helperText?: any;
  errorText?: string | undefined | boolean;
  rows?: number;
  autoFocus?: boolean;
  isDisabledIcon?: boolean;
  iconEye?: React.ReactNode;
  sx?: SxProps;
  className?: string;
  maxLength?: number;
}

const FormikField = (props: MuiFieldProps): JSX.Element => {
  const {validate, ...rest} = props;
  return (
    <Field
      {...props}
      validate={validate}
      render={() => <MuiField {...rest} />}
    />
  );
};

export default React.memo(FormikField);

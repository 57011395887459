import {call, put, takeLatest} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';

import {actions} from '../../index';
import {actions as actionSnackbar} from 'shared/store/snackbar';
import {formService} from 'core/services/form';
import {Competitors, ProjectWithoutFiles} from 'shared/store/project/interface';

interface Props {
  payload: {
    id: number | string;
    stepUpdate?: string;
    updateProjectWithoutFiles: ProjectWithoutFiles;
  };
}

export function* updateProject(props: Props): SagaIterator {
  const {id, updateProjectWithoutFiles, stepUpdate} = props.payload;
  const sharedToken = location.search.substring(1);
  const filteredCompetitor = updateProjectWithoutFiles.competitors?.map((competitor: Competitors) => {
    const {hasAllOldCompetitionValues, ...propsCompetitor} = competitor;
    return {
      ...propsCompetitor,
    };
  });

  try {
    if (!sharedToken) {
      yield call(formService.updateProject, `projects/${id}/`, {
        data: {
          ...updateProjectWithoutFiles,
          competitors: filteredCompetitor || undefined,
        },
      });

      if (!stepUpdate) {
        const response = yield call(formService.getProject, `projects/${id}`);
        yield put(actions.getProjectActions.end(response));
      }

    } else {
      const response = yield call(formService.getSharedProject, `projects/shared/${sharedToken}`);
      yield put(actions.getProjectActions.end(response));
    }

    yield put(actions.updateProjectActions.end());
    yield put(actions.updateProjectActions.finish());

  } catch (error: any) {
    if (error.response) {
      const {message, errorCode: stackErrors} = error.response.data;
      yield put(actions.updateProjectActions.error({message, stackErrors}));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* updateProjectSaga(): SagaIterator {
  yield takeLatest(actions.updateProjectActions.start, updateProject);
}

import {SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';
import {StylesCSS} from 'shared/interfaces';

export const stylesPropsTd = (width: number | string, isError?: boolean, isTouched?: boolean, isDisabled?: boolean) => {
  return {
    td: {
      width,
      height: '42px',
      wordBreak: 'break-word',
      position: 'relative',
      color: isDisabled ? colors.disabled : colors.accentBlue,
      minHeight: '40px',
      border: isError && isTouched ? '1.1px solid #D51C29' : '1px solid #D6DAE1',
      borderBottom: isError && isTouched ? '1.1px solid #D51C29' : 0,
      borderRight: isError && isTouched ? '1.1px solid #D51C29' : 0,
      padding: '10px 16px',
      lineHeight: 'inherit',
      verticalAlign: 'top',
      background: isDisabled && colors.lightGreyDisableTable,
    } as SystemCssProperties,
    stickyColumn(offset: number = 0): SystemCssProperties {
      return {
        ...this.td,
        position: 'sticky',
        left: offset,
        zIndex: 2,
        boxShadow: '0 3px 5px 0px',
        borderRight: 0,
        borderBottom: 0,
      } as SystemCssProperties;
    },
  };
};

export const stylesProps = (isError: boolean): StylesCSS => {
  return {
    tableRow: {
      display: 'block',
      maxWidth: '291px',
      minHeight: '42px',
      wordWrap: 'break-word',
      padding: '10px 0 10px 17px',
      position: 'relative',
      fontFamily: 'Poppins,sans-serif',
      color: colors.accentBlue,
      fontSize: '14px,',
      border: isError ? `1px solid ${colors.error}` : `1px solid ${colors.lightGrey}`,
    },
  };
};

export const styles: StylesCSS = {
  errorMessageWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '111%',
    minHeight: '32px',
    position: 'absolute',
    bottom: '-37px',
    left: '-1px',
    background: colors.error,
    padding: '4px 8px 4px 8px',
    zIndex: '9999',
  },

  errorWrapper: {
    width: 'max-content',
    minWidth: '309px',
    padding: '4px 8px',
    background: colors.error,
    position: 'absolute',
    bottom: '-32px',
    left: 0,
    zIndex: 1000,
  },

  errorText: {
    color: colors.white,
    fontSize: '12px',
    lineHeight: '22px',
  },

  errorMessageText: {
    fontSize: '12px',
    color: colors.white,
  },
};

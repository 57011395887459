import * as React from 'react';
import {Box} from '@mui/material';
import DragIcon from 'shared/components/Icons/DragIcon';

const DragIconTableCell = (): JSX.Element => {
  return (
    <Box component="span" className="dragIcon" sx={{display: 'none'}}>
      <DragIcon/>
    </Box>
  );
};

export default DragIconTableCell;

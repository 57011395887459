import * as React from 'react';
import { ReactNode } from 'react';
import { Box } from '@mui/material';
import HeaderWeb from 'shared/components/HeaderWeb';

import { styles, stylesProps } from './styles';

interface Props {
  children?: ReactNode;
  isStartScreen: boolean;
}

const LayoutBackgroundStart = (props: Props): JSX.Element => {
  const { children, isStartScreen } = props;
  return (
    <Box sx={stylesProps(isStartScreen).root}>
      <Box sx={{ paddingRight: '40px' }}>
        <HeaderWeb background="none" isStartScreen={isStartScreen} />
      </Box>
      <Box sx={stylesProps(isStartScreen).dots}>
        <Box sx={styles.wrapperBigLogo}>
          <Box sx={styles.bigLogo} />
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutBackgroundStart;

import {SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';
import {StylesCSS} from 'shared/interfaces';

export const stylesSnackbar = (isSuccess?: boolean): StylesCSS => {
  return {
    root: {
      '.MuiPaper-root': {
        maxWidth: '620px',
        borderRadius: '8px',
        padding: '16px 24px 16px 24px',
        backgroundColor: isSuccess ? colors.success : colors.error,
        '& > .MuiSnackbarContent-message ': {
          padding: 0,
          textAlign: 'left',
          fontSize: '16px',
          lineHeight: '22px',
          maxWidth: '520px',
        },
        '& > .MuiSnackbarContent-action': {
          position: 'static',
          right: '24px',
          top: '13px',
          padding: 0,
          margin: 0,
        },
        '& > .MuiButtonBase-root': {
          margin: 0,
          padding: 0,
        },
      },
    } as SystemCssProperties,
    success: {
      '.MuiPaper-root': {
        backgroundColor: colors.success,
      },
    } as SystemCssProperties,
    error: {
      '.MuiPaper-root': {
        backgroundColor: colors.error,
      },
    } as SystemCssProperties,
  };
};

export const styles = {
  action: {
    '.MuiPaper-root>.MuiSnackbarContent-action': {
      position: 'static',
      height: '22px',
    },
  } as SystemCssProperties,
};

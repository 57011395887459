import * as React from 'react';
import Box from '@mui/material/Box';
import TabItems from 'components/WebTools/ProjectProcess/NavProgressMap/Tabs';
import { styles } from './styles';

const NavProgressMap = (): JSX.Element => {
  return (
    <Box sx={styles.tabItems}>
      <TabItems />
    </Box>
  );
};

export default NavProgressMap;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PopupState } from 'shared/store/popup/interface';

const initialState: PopupState = {
  isShowPopup: false,
};

const popup = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    toggle(state, action: PayloadAction<boolean>) {
      state.isShowPopup = action.payload;
    },
  },
});

export const popupActions = popup.actions;
export default popup.reducer;

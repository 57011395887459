import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  IconField: {
    display: 'flex',
    color: colors.white,
    alignItems: 'center',
    mb: 3,
    '.MuiTypography-root': {
      color: colors.white,
    },
  } as SystemCssProperties,

  icon: {
    display: 'flex',
    alignItems: 'center',
    mr: '20px',
  } as SystemCssProperties,
};

import { SelectType } from 'components/WebTools/MainForm/ProjectForm/interface';

export const regions: SelectType[] = [
  {
    id: 1,
    value: 'Region Syd',
  },
  {
    id: 2,
    value: 'Region Väst',
  },
  {
    id: 3,
    value: 'Region Öst',
  },
  {
    id: 4,
    value: 'Region Stockholm/Uppland',
  },
  {
    id: 5,
    value: 'Region Riks',
  },
  {
    id: 6,
    value: 'Region Norr',
  },
];

export const productsTypes: SelectType[] = [
  {
    id: 1,
    value: 'Färdiga hustyper',
  },
  {
    id: 2,
    value: 'Upprepning av tidigare RB-project',
  },
  {
    id: 3,
    value: 'Tänk nytt stort och fritt',
  },
];

import {Competitors} from 'shared/store/project/interface';

export const clearTokens = (): void => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const convertTime = (time) => {
  const [m, s] = time.split(':');
  const seconds = m * 60 + parseInt(s);
  return seconds;
};

export const ellipsString = (str: string, maxLength: number): string => {
  return str.length >= maxLength ? str.substring(0, maxLength) + '...' : str;
};

export const sep = (str, colSymbol) => str.length ? [str.slice(0, colSymbol), ...sep(str.slice(colSymbol), colSymbol)] : [];

export const validateValue = (val: number): number => {
  let value = val;
  if (value > 10) {
    value = 10;
  } else if (val <= 0) {
    value = 1;
  }
  return value;
};

export const applyQuadrantToOldParameterValue = (originalValue: number, quadrant: string) => {
  switch (quadrant) {
    case 'increase':
      return validateValue(originalValue + 2);
    case 'reduce':
      return validateValue(originalValue - 2);
    case 'eliminate':
      return 0;
  }
  return originalValue;
};

export const filteredEmptyStepTwoDataFromTable = (parametersTable: any) =>
  parametersTable
    .filter((item) => item.name !== '')
    .map((item) => ({
      ...item,
      values: item.values.map((val: number) => parseInt(String(val))),
    }));

export const filteredCompetitors = (values) => {
  return values.reduce((accum: Competitors[], currentItem: Competitors) => {
    const {hasAllOldCompetitionValues, ...propsItem} = currentItem;
    if (propsItem.name !== '') {
      accum.push(propsItem);
    }
    return accum;
  }, []);
};

import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { adminService } from 'core/services/admin';

export function* getSettings(): SagaIterator {
  try {
    const response = yield call(adminService.getSettings);
    yield put(actions.getSettingsActions.end(response));
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.getSettingsActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* getSettingsSaga(): SagaIterator {
  yield takeLatest(actions.getSettingsActions.start, getSettings);
}

import React from 'react';
import Video from './Video';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {RoutesPage} from 'shared/routes';
import {Box} from '@mui/material';
import {styles} from './Video/styles';
import Loader from 'shared/components/Loader';
import {useGetProject} from 'shared/hooks/useGetProjects';
import { StepNumber } from 'shared/interfaces';
import NavigationBar from 'shared/components/NavigationBar';

const stepsKeysFromRedux = {
  'step=1': 'step1',
  'step=2': 'step2',
  'step=3': 'step3',
  'step=4': 'step4',
  'step=5': 'step5',
  'step=6': 'step6',
};

const VideoContainer = (): JSX.Element => {
  const history = useHistory();
  let {id} = useParams<{ id: string }>();
  id = id ?? localStorage.getItem('projectId');
  const urlStep = useLocation()
    .pathname.split('/')
    .find((item) => item.includes('step='));
  let pathUrl = `${RoutesPage.project.page}${id}/${urlStep}${RoutesPage.steps.instruction}${location.search}`;
  const {projectData} = useGetProject();
  let step: any = null;

  if (projectData === null) {
    return (
      <Box sx={styles.Loader}>
        <Loader/>
      </Box>
    );
  }
  step = projectData.config.videos[stepsKeysFromRedux[urlStep ?? '']];

  if (pathUrl.includes('step=5')) {
    pathUrl = `${RoutesPage.project.page}${id}/${urlStep}${RoutesPage.steps.table}${location.search}`;
  }
  if (pathUrl.includes('step=6')) {
    pathUrl = `${RoutesPage.project.page}${id}/${urlStep}${RoutesPage.steps.comment}${location.search}`;
  }

  const {displayProperties, previewUrl, url} = step;

  const navigateForward = (): void => {
    history.push(pathUrl);
  };

  const navigateBack = (): void => {
    const previousPath = `${RoutesPage.project.page}${id}/${urlStep}${RoutesPage.steps.transition}${location.search}`;

    history.push(previousPath);
  };

  return (
    <Box sx={ styles.VideoContainer }>
        <Video
          displayProperties={displayProperties}
          previewUrl={previewUrl}
          url={url}
          pathUrl={pathUrl}
          currentStep={+projectData.data.currentStep as StepNumber}
        />

        <NavigationBar  navigateBack={() => navigateBack()} navigateForward={() => navigateForward()} />
    </Box>
  );
};

export default VideoContainer;

const LogOutIcon = (): JSX.Element => {
  return (
    <svg width="25" height="23" style={{marginRight: '11px'}} viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.698 7.45391C14.9136 6.39045 13.8132 5.60209 12.5539
             5.20148C11.2947 4.80087 9.94102 4.80849 8.68636 5.22325C7.43169 5.63802 6.34021 6.43871 5.56785
             7.51093C4.79549 8.58315 4.38176 9.87205 4.38577 11.1935C4.38978 12.5149 4.81132 13.8013 5.59018
             14.8688C6.36903 15.9363 7.46535 16.7304 8.72251 17.1375C9.97967 17.5446 11.3333 17.5441 12.5901
              17.1358C13.8469 16.7276 14.9426 15.9325 15.7205 14.8643L17.3372 16.0417C16.3111 17.4508 14.8658
               18.4995 13.208 19.038C11.5502 19.5765 9.76459 19.5773 8.1063 19.0402C6.44801 18.5032 5.00187 17.4557
                3.97449 16.0476C2.94712 14.6395 2.39106 12.9426 2.38577 11.1996C2.38049 9.45647 2.92623 7.75631 3.94504
                 6.34196C4.96385 4.92761 6.40361 3.87143 8.05861 3.32432C9.71361 2.77721 11.4992 2.76716 13.1603
                 3.2956C14.8213 3.82405 16.2729 4.86395 17.3075 6.26674L15.698 7.45391Z"
        fill="white"/>
      <path
        d="M24.2813 10.3696L19.8186 5.89999C19.8186 5.89999 19.5881 6.13043 19.3033 6.42319C19.0185 6.71594
             18.7881 6.96079 18.7881 6.96079L21.9773 10.1551H11.4188C11.4188 10.1551 11.4188 10.488 11.4188 10.9C11.4188
              11.3119 11.4188 11.9 11.4188 11.9H21.9773L18.7881 14.8392C18.7881 14.8392 19.1577 15.2323 19.4417
               15.5251C19.5856 15.6726 19.8186 15.9 19.8186 15.9L24.2813 11.4304C24.4208 11.2903 24.5 11.0996 24.5
                10.9C24.5 10.7004 24.4215 10.5104 24.2813 10.3696Z"
        fill="white"/>
    </svg>
  );
};

export default LogOutIcon;

import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useTypedSelector} from 'shared/hooks/useTypedSelector';
import {actions} from 'shared/store/project';
import {InitialStateProject} from '../store/project/interface';

interface ReturnedUseGetProject {
  projectData: InitialStateProject | null;
  isLoadingGetForm: boolean;
}

export const useGetProject = (): ReturnedUseGetProject => {
  const dispatch = useDispatch();
  let {id} = useParams<{ id: string }>();

  id = id ?? localStorage.getItem('projectId');
  const {projectData, isLoading: isLoadingGetForm} = useTypedSelector((state) => state.project.getProject);

  useEffect(() => {
    const token = location.search.substring(1);
    if (token) {
      dispatch(actions.getSharedProjectActions.start({token}));
      return;
    }
    dispatch(actions.getProjectActions.start({id}));
  }, []);

  return {
    projectData,
    isLoadingGetForm,
  };
};

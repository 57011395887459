import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { adminService } from 'core/services/admin';
import { User } from 'shared/store/admin/interface';

interface Props {
  payload: Pick<User, 'email'>;
}

export function* sendInvite(props: Props): SagaIterator {
  const {
    payload: { email },
  } = props;
  try {
    yield call(adminService.sendInvite, 'invites/send', { email });
    yield put(
      actionSnackbar.snackbarActions.add({
        type: 'success',
        text: 'Invite has been successfully sent',
      }),
    );
    yield put(actions.getInvitesActions.start());
    yield put(actions.sendInviteActions.end());
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.sendInviteActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* sendInviteSaga(): SagaIterator {
  yield takeLatest(actions.sendInviteActions.start, sendInvite);
}

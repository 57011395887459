import colors from 'styles/_Variables';
import { SystemCssProperties } from '@mui/system';

export const styles = {
  modal: {
    position: 'absolute',
    zIndex: '999999',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '369px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    outline: 'none',
    p: 3,
  } as SystemCssProperties,

  body: {
    marginTop: '24px',
    marginBottom: '40px',
  } as SystemCssProperties,

  muiButton: {
    color: colors.accentBlue,
    background: colors.white,
    width: 'auto',
    textTransform: 'none',
    marginRight: '38px',
    '&:hover': {
      background: colors.white,
      boxShadow: 'none',
      color: colors.accentBlue,
    },
    '&:active': {
      background: 'none',
      color: colors.accentBlue,
    },
  } as SystemCssProperties,

  Loader: {
    width: '45px',
    display: 'flex',
    position: 'relative',
    left: '50%',
  } as SystemCssProperties,

  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  } as SystemCssProperties,

  muiButtonAccent: {
    background: colors.accentBlue,
    width: 'auto',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  } as SystemCssProperties,

  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '18px',
    alignItems: 'center',
  } as SystemCssProperties,
};

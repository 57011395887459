import React, { createContext, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_3/TableContainer/styles';
import Loader from 'shared/components/Loader';
import TableSection from 'components/WebTools/ProjectProcess/Steps/Step_3/TableContainer/Table';
import {TitleAndSubTitle} from 'components/WebTools/ProjectProcess/Common/TittleAndSubTitile';
import {useGetProject} from 'shared/hooks/useGetProjects';
import { instructions } from './instructions';
import MuiButton from 'shared/components/UI/MuiButton';
import colors from 'styles/_Variables';
import NavigationBar from 'shared/components/NavigationBar';
import { RoutesPage } from 'shared/routes';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

export const TableContext = createContext({
  tableEvent: null,
});

const TableContainer = (): JSX.Element => {
  const { projectData, isLoadingGetForm } = useGetProject();
  const [tableEvent, setTableEvent] = useState(null);
  const history = useHistory();
  const id = useParams<{ id: string }>().id ?? localStorage.getItem('projectId');
  const [navigateForwardEvent, setNavigateForwardEvent] = useState<React.MouseEvent<HTMLInputElement> | null>(null);
  let instruction: any = null;

  if (projectData === null || isLoadingGetForm) {
    return (
      <Box sx={styles.Loader}>
        <Loader />
      </Box>
    );
  }
  instruction = projectData.data.stepsNotes['3'];

  const navigateBack = (): void => {
    const previousPath =
      `${RoutesPage.project.page}${id}${RoutesPage.steps.step3}${RoutesPage.steps.additionalInstruction}${location.search}`;

    history.push(previousPath);
  };

  return (
    <Box sx={styles.Container}>
      <Box sx={styles.Title}>
        <TitleAndSubTitle
          Title={() => <>Lista era utvalda NYA konkurrensparametrar</>}
          Subtitle={() => <>(1 är lågt och 10 är högt)</>}
        />
        <Instruction stepNote={instruction} data={projectData.data} noteKey={3} />
      </Box>
      <Box sx={ styles.Content }>
        <Box sx={ styles.Instructions }>
          {
            instructions.map((instructionItem, index) =>
              <Typography variant="subtitle1" component="p" sx={ styles.Instruction } key={index}>
                { index + 1 }. { instructionItem }
              </Typography>)
          }

          <Typography variant="subtitle1" component="p" sx={ styles.StepTime }>Tidsåtgång: ca 10 minuter</Typography>

          <MuiButton  type="button" variant="contained" onClick={(event) => setTableEvent(event)}>
            <Typography variant="subtitle2" component="p" color={colors.white}>Fortsätt</Typography>
          </MuiButton>
        </Box>

        <TableContext.Provider value={{tableEvent}}>
          <TableSection
            data={projectData.data}
            newCompetitionParameters={projectData.data.newCompetitionParameters}
            navigateForwardEvent={navigateForwardEvent}
          />
        </TableContext.Provider>
      </Box>

      <NavigationBar
        navigateBack={() => navigateBack()}
        navigateForward={(event: React.MouseEvent<HTMLInputElement>) => setNavigateForwardEvent(event)}
      />
    </Box>
  );
};

export default TableContainer;

import React from 'react';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';
import {Box, Typography} from '@mui/material';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_4/AdditionalInfoContainer/styles';
import Loader from 'shared/components/Loader';
import {useTypedSelector} from 'shared/hooks/useTypedSelector';
import TableSection from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection';
import InstructionTreeIcon from '../../../../../../shared/components/Icons/Instructions/InstructionTree';
import color from '../../../../../../styles/_Variables';

const AdditionalInfoContainer = (): JSX.Element => {
  const { projectData } = useTypedSelector((state) => state.project.getProject);
  let instruction: any = null;

  if (projectData === null) {
    return (
      <Box sx={styles.Loader}>
        <Loader />
      </Box>
    );
  }

  instruction = projectData.data.stepsNotes['4'];

  return (
    <Box sx={styles.container}>
      <Box sx={styles.containerAdditional}>
        <Box sx={styles.headerTitle}>
          <InstructionTreeIcon width={48} height={48} background={color.black}/>
          <Typography variant="h1">Välj ut och sortera konkurrensparametrar</Typography>
        </Box>
        <Instruction stepNote={instruction} data={projectData.data} noteKey={4} />
      </Box>
      <TableSection />
    </Box>
  );
};

export default AdditionalInfoContainer;

import {SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';
import {StylesCSS} from '../../interfaces';

export const styles: StylesCSS = {
  tableContainer: {
    boxShadow: 'none',
    height: '720px',
    maxHeight: '720px',
    marginBottom: '20px',
    background: colors.lightBlueDark,
    position: 'relative',
  },

  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    background: colors.lightBlue,
  },

  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 9999,
  },

  td: {
    display: 'flex',
    width: '33.33%',
    color: colors.white,
    fontWeight: 500,
    fontSize: 16,
    border: 'none',
    paddingLeft: '24px',
    wordBreak: 'break-word',
  },

  tdBody: {
    display: 'flex',
    width: '19%',
    color: colors.white,
    fontWeight: 400,
    fontSize: 16,
    borderBottom: 'none',
    paddingLeft: '24px',
  },
};

export const stylesRow = (isOpenCollapse: number | boolean, id: number): StylesCSS => {
  return {
    tableBodyRow: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      background: isOpenCollapse === id ? colors.selectedLink : colors.lightBlueDark,
      cursor: 'pointer',
      '&:hover': {
        background: colors.selectedLink,
        '.deleteIcon': {
          display: 'flex',
        },
      },
      '&::after': {
        content: '" "',
        position: 'absolute',
        display: 'block',
        width: '98%',
        left: '50%',
        marginRight: '-50% ',
        transform: 'translate(-50%, -50%)',
        height: '1px',
        background: colors.selectedLink,
        borderRadius: '4px',
      },
    } as SystemCssProperties,
  };
};

import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VideoPlayer from 'shared/components/VideoPlayer';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_3/AdditionalInfoContainer/VideoModal/styles';

interface Props {
  [key: string]: any;
}

const VideoModal = (props: Props): JSX.Element => {
  const { handleClose, videoInfo, isPlaying } = props;
  const { url, previewUrl, videoHeader } = videoInfo;

  return (
    <Box sx={styles.VideoModalContainer}>
      <Box sx={styles.VideoModalHeader}>
        <Typography variant="subtitle2" color="inherit" sx={styles.VideoModalHeaderTypography} component="h4">
          {videoHeader}
        </Typography>
        <IconButton color="inherit" size="small" disableRipple>
          <CloseIcon onClick={handleClose} />
        </IconButton>
      </Box>
      <Typography variant="subtitle1" color="inherit" component="h2" sx={styles.VideoModalTypography}>
        {videoInfo.displayProperties.name}
      </Typography>
      <VideoPlayer url={url} previewUrl={previewUrl} height="355px" isPlaying={isPlaying} />
    </Box>
  );
};

export default VideoModal;

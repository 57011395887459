import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StepVideo, VideosState } from 'shared/store/ListVideos/interface';

const initialState: VideosState = {
  isLoading: false,
  videos: [],
  error: {
    message: [],
    stackErrors: '',
  },
};

const getVideos = createSlice({
  name: 'getVideos',
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
      state.error = initialState.error;
    },
    end(state, action: PayloadAction<StepVideo[]>) {
      state.isLoading = false;
      state.videos = action.payload;
    },
    error(state, action: any) {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
    clear() {
      return initialState;
    },
  },
});

export const getVideosActions = getVideos.actions;
export default getVideos.reducer;

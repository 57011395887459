import React from 'react';
import { useParams } from 'react-router-dom';
import { RoutesPage } from 'shared/routes';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_3/TableContainer/Table/styles';
import { Box } from '@mui/material';
import { NewCompetitionParameters, Project } from 'shared/store/project/interface';
import TableMyCompany from 'components/WebTools/ProjectProcess/Steps/Step_3/Table';

interface Props {
  newCompetitionParameters: NewCompetitionParameters[];
  data: Project;
  navigateForwardEvent: React.MouseEvent<HTMLInputElement> | null;
}

const TableSection = (props: Props): JSX.Element => {
  const { newCompetitionParameters, data, navigateForwardEvent } = props;
  let { id } = useParams<{ id: string }>();
  id = id ?? localStorage.getItem('projectId');
  const pathUrlNextStep = `${RoutesPage.project.page}${id}${RoutesPage.steps.step4}${RoutesPage.steps.transition}${location.search}`;

  return (
    <Box sx={styles.tableContainer}>
      <Box sx={styles.tableContainerBox}>
        <Box sx={styles.titleContainer}>
          <Box sx={styles.table}>
            <TableMyCompany
              newCompetitionParameters={newCompetitionParameters}
              data={data}
              rowsLength={9}
              pathUrlNextStep={pathUrlNextStep}
              navigateForwardEvent={navigateForwardEvent}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TableSection;

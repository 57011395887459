import React, { useEffect } from 'react';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {Box} from '@mui/material';
import {RoutesPage} from 'shared/routes';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_5/TableContainer/Table/styles';
import MuiButton from 'shared/components/UI/MuiButton';
import TableStep5 from 'components/WebTools/ProjectProcess/Steps/Step_5/Table';
import {NewCompetitionParameters, Project, ProjectWithoutFiles} from 'shared/store/project/interface';
import {FormikValues, useFormik} from 'formik';
import validationSchema from 'components/WebTools/ProjectProcess/Steps/Step_5/Table/validationSchema';
import {useDispatch} from 'react-redux';
import {actions} from 'shared/store/project';
import {actions as actionsPopup} from 'shared/store/popup';

interface Props {
  [key: string]: any;

  combineData: NewCompetitionParameters[];
  data: Project;
  nextStepEvent: null | React.MouseEvent<HTMLInputElement>;
}

const TableSection5 = (props: Props): JSX.Element => {
  const {combineData, data, nextStepEvent} = props;
  let {id} = useParams<{ id: string }>();
  const currentRoute = useRouteMatch();
  id = id ?? localStorage.getItem('projectId');
  const dispatch = useDispatch();
  const {push} = useHistory();
  const pathUrl = `${RoutesPage.project.page}${id}${RoutesPage.steps.step5}${RoutesPage.steps.instruction}${location.search}`;
  const pathUrlDiagram = `${RoutesPage.project.page}${id}${RoutesPage.steps.step5}${RoutesPage.steps.diagram}${location.search}`;

  const formik = useFormik({
    initialValues: {newCompetitionParameters: combineData},
    validationSchema,
    onSubmit: (values: FormikValues) => {
      const {oldCompetitionParameters, newCompetitionParameters} = values.newCompetitionParameters.reduce(
        (accum, item) => {
          const {riksbyggen, isEdited, ...filteredItem} = item;
          filteredItem.overriddenValue = parseInt(filteredItem.overriddenValue);
          if (filteredItem.values) {
            accum.oldCompetitionParameters.push(filteredItem);
          } else {
            accum.newCompetitionParameters.push(filteredItem);
          }
          return accum;
        },
        {
          oldCompetitionParameters: [],
          newCompetitionParameters: [],
        },
      );

      const newData: ProjectWithoutFiles = {...data};
      delete newData.files;
      const updateProjectWithoutFiles = {
        ...newData,
        newCompetitionParameters: [...newCompetitionParameters],
        oldCompetitionParameters: [...oldCompetitionParameters],
      };
      dispatch(actionsPopup.popupActions.toggle(false));
      dispatch(
        actions.updateProjectActions.start({
          id,
          stepUpdate: 'stepUpdate',
          updateProjectWithoutFiles,
        }),
      );
    },
  });

  const handleUpdate = (event) => {
    formik.handleSubmit(event);
    dispatch(actionsPopup.popupActions.toggle(false));
  };

  const handleNextStep = (event) => {
    formik.handleSubmit(event);
    localStorage.setItem('isUpdateForStep4', 'false');
    push(pathUrl);
  };

  const showDiagram = (event) => {
    formik.handleSubmit(event);
    localStorage.setItem('isUpdateForStep4', 'false');
    push(pathUrlDiagram, {from: currentRoute.path});
    dispatch(actionsPopup.popupActions.toggle(false));
  };

  useEffect(() => {
    if (nextStepEvent) {
      handleNextStep(nextStepEvent);
    }
  }, [nextStepEvent]);

  return (
    <Box sx={styles.additionalInfoContainer}>
      <Box sx={styles.table}>
        <TableStep5 rows={combineData} rowsLength={combineData.length} formik={formik} onHandleUpdate={handleUpdate}/>
      </Box>
      <Box sx={styles.BtnSection}>
        <MuiButton
          onClick={showDiagram}
          type="button"
          variant="contained"
          sx={{
            ...styles.NavLink,
            ...styles.BtnWhite,
          }}
        >
          Visa diagram
        </MuiButton>
      </Box>
    </Box>
  );
};

export default TableSection5;

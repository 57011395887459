import React, {
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';

import AccordionSummary from 'components/WebTools/MainForm/ProjectForm/Accordion/AccordionSummary';
import GeneralInformation from 'components/WebTools/MainForm/ProjectForm/Forms/GeneralInformation';
import { validate, validationSchema } from 'components/WebTools/MainForm/ProjectForm/Forms/validationSchema';
import { FileType, Project, ProjectWithoutFiles } from 'shared/store/project/interface';

import Add from 'shared/components/Icons/Add';
import CompetitorsLocal from 'components/WebTools/MainForm/ProjectForm/Forms/CompetitorsLocal';
import Details from 'components/WebTools/MainForm/ProjectForm/Forms/Details';
import InformationPlans from 'components/WebTools/MainForm/ProjectForm/Forms/InformationPlans';
import MuiButton from 'shared/components/UI/MuiButton';
import { styled } from '@mui/material/styles';

import colors from 'styles/_Variables';
import { styles } from './styles';
import { actions } from 'shared/store/project';
import { actions as actionsFiles } from 'shared/store/files';

import { RoutesPage } from 'shared/routes';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { ActionAdd } from 'shared/store/snackbar/interface';
import { filteredCompetitors } from 'shared/helpers';
import { RootState } from 'shared/store/rootReducer';
import { useCheckPermissions } from 'shared/hooks/useCheckPermissions';
import { Roles } from 'shared/constants/enum';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const countBlockForm = 6;

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(() => ({
  '& .MuiButtonBase-root': {
    padding: '32px 0 24px 0',
  },
  background: colors.accentBlue,
  borderBottom: '1px solid #354B71',
  '&:before': {
    display: 'none',
  },
}));

interface Props {
  dataForm: Project;
}

const Accordions = (props: Props): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const fromArchive = location?.state?.from;
  const isPathToForm = history.location.pathname.startsWith(RoutesPage.form.page);

  const [expanded, setExpanded] = React.useState<string | boolean>('');
  const isFinished = useSelector((state: RootState) => state.project.updateProject.isFinished);

  const isLoading = useSelector((state: RootState) => state.project.updateProject.isLoading);

  const [isBack, setIsBack] = useState<boolean>(false);
  const [generalFiles, setGeneralFiles] = useState<FileType[]>();
  const [scheduleFiles, setScheduleFiles] = useState<FileType[]>();

  const { dataForm } = props;

  const redirectToSteps = () => {
    if ((isPathToForm || fromArchive === RoutesPage.archive) && !isBack) {
      history.push(`${RoutesPage.project.page}${id}${RoutesPage.steps.step1}${RoutesPage.steps.welcome}${history.location.search}`);
    } else {
      history.go(-1);
    }
    setIsBack(false);
  };

  useEffect(() => {
    if (isFinished) {
      sendDispatchToFiles(actionsFiles.addGeneralFilesActions.start, generalFiles);
      sendDispatchToFiles(actionsFiles.addScheduleFilesActions.start, scheduleFiles);
      redirectToSteps();
    }
  }, [isFinished]);

  const saveUpdatedValues = (values) => {
    setGeneralFiles(values.files.general);
    setScheduleFiles(values.files.schedule);
    const updateProjectWithoutFiles: ProjectWithoutFiles = {
      ...values,
      competitors: filteredCompetitors(values.competitors),
    };
    delete updateProjectWithoutFiles.files;
    dispatch(
      actions.updateProjectActions.start({
        id,
        updateProjectWithoutFiles,
      }),
    );
  };

  const sendDispatchToFiles = (action, files) => {
    files.forEach((file) => {
      if (!file.id) {
        const formData = new FormData();
        formData.append('file', file);
        dispatch(action({ id, data: formData }));
      }
    });
  };

  const sendToStep = (values, isValid, errors, validateForm, isDirty) => {
    if (isMU || !isDirty) {
      return redirectToSteps();
    }
    if (isValid) {
      saveUpdatedValues(values);
    } else {
      validateForm();
      if (errors?.general?.name) {
        dispatch(
          actionSnackbar.snackbarActions.add({
            type: 'error',
            text: errors?.general?.name,
          } as ActionAdd),
        );
      }
    }
  };

  const addCompetitor = (event: SyntheticEvent, values, setFieldValue) => {
    event.stopPropagation();
    const newPartners = [
      ...values.competitors,
      {
        name: '',
        doings: '',
        priorities: '',
      },
    ];

    if (values.competitors.length !== countBlockForm) {
      setFieldValue('competitors', newPartners);
      values.oldCompetitionParameters.forEach((value, index) => {
        setFieldValue(`oldCompetitionParameters[${index}].values`, [...value.values, undefined]);
      });
    }
  };

  const handleExpandedChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={dataForm}
      validate={validate}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {({ values, isValid, dirty,  errors, validateForm, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <IconButton
            edge="start"
            color="inherit"
            size="small"
            disableRipple
            sx={styles.stylesHeaderItems}
            onClick={() => {
              if (isLoading) {
                return;
              }
              setIsBack(true);
              sendToStep(values, isValid, errors, validateForm, dirty);
            }}
          >
            <ArrowBackIosNewIcon sx={styles.arrowIcon}/>
            <Typography variant="subtitle2" component="p" color="inherit">
              Tillbaka
            </Typography>
          </IconButton>
          <Box sx={{ pt: 5 }}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleExpandedChange('panel1')}>
              <AccordionSummary expanded={expanded} isOpenExpanded="panel1">
                <Typography variant="subtitle1" sx={styles.stylesTitleAccordion} component="p">
                  Allmän information
                </Typography>
              </AccordionSummary>
              <MuiAccordionDetails sx={styles.removePadding}>
                <GeneralInformation />
              </MuiAccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleExpandedChange('panel2')}>
              <AccordionSummary expanded={expanded} isOpenExpanded="panel2">
                <Box sx={styles.accordionSummary}>
                  <Typography variant="subtitle1" sx={styles.stylesTitleAccordion} component="p">
                    Konkurrenter i närområdet
                  </Typography>
                  {expanded === 'panel2' && !isMU && (
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={(event) => addCompetitor(event, values, setFieldValue)}
                      sx={styles.iconBtn}
                      disableRipple
                    >
                      <Add />
                      <Typography sx={styles.textForBtn} variant="subtitle2">
                        Lägg till fler
                      </Typography>
                    </IconButton>
                  )}
                </Box>
              </AccordionSummary>
              <MuiAccordionDetails sx={styles.removePadding}>
                <CompetitorsLocal />
              </MuiAccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleExpandedChange('panel3')}>
              <AccordionSummary expanded={expanded} isOpenExpanded="panel3">
                <Typography variant="subtitle1" sx={styles.stylesTitleAccordion} component="p">
                  Projektinformation
                </Typography>
              </AccordionSummary>
              <MuiAccordionDetails sx={styles.removePadding}>
                <Details />
              </MuiAccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleExpandedChange('panel4')}>
              <AccordionSummary expanded={expanded} isOpenExpanded="panel4">
                <Typography variant="subtitle1" sx={styles.stylesTitleAccordion} component="p">
                  Information enligt RB-planer
                </Typography>
              </AccordionSummary>
              <MuiAccordionDetails sx={styles.removePadding}>
                <InformationPlans />
              </MuiAccordionDetails>
            </Accordion>

            <MuiButton
              disabled={isLoading}
              onClick={() => sendToStep(values, isValid, errors, validateForm, dirty)}
              sx={styles.button}
              type="submit"
              variant="text">
              <Typography variant="subtitle2" component="p" sx={styles.text}>
                Klar
              </Typography>
            </MuiButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default Accordions;

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  Loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as SystemCssProperties,

  instructionStep: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
    color: colors.white,
    height: '100%',
    position: 'relative',
    '& .MuiTypography-root': {
      margin: '40px 0',
    },
  } as SystemCssProperties,

  container: {
    width: '100%',
  } as SystemCssProperties,

  Icon: {
    width: '80px',
    height: '72px',
  } as SystemCssProperties,

  TypoText: {
    fontSize: '24px',
    fontWeight: '600',
    fontStyle: 'normal',
    textAlign: 'center',
  } as SystemCssProperties,

  BtnSection: {
    display: 'flex',
    height: '48px',
  } as SystemCssProperties,

  BtnWhite: {
    background: colors.white,
    color: colors.accentBlue,
    marginRight: '24px',
    '&:hover': {
      background: colors.white,
      boxShadow: 'none',
    },
  } as SystemCssProperties,

  typography: {
    fontSize: '24px',
    textAlign: 'center',
    lineHeight: '32px',
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
  } as SystemCssProperties,

  NavLink: {
    width: '196px',
    textDecoration: 'none',
    color: colors.white,
    textTransform: 'none',
    height: '100%',
    padding: '10px 16px',
    boxShadow: 'none',
    '&:hover': {
      background: colors.accentBlue,
      boxShadow: 'none',
    },
    '&.MuiButton-root': {
      padding: '0px',
    },
  } as SystemCssProperties,
};

export const styles = {
  stylePopupFirstRow: {
    maxWidth: '156px',
    right: 0,
    left: '104px',
  },

  stylePopupSecondRow: {
    maxWidth: '156px',
    left: '175px',
    bottom: '-25px',
    right: 0,
  },
};

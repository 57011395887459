import React, { ReactFragment } from 'react';
import { Box } from '@mui/system';

import { styles } from './styles';

interface Props {
  children: NonNullable<React.ReactNode> | ReactFragment | JSX.Element;
}

const LayoutBackgroundGame = (props: Props): JSX.Element => {
  const { children } = props;
  return <Box sx={styles.root}>{children}</Box>;
};

export default LayoutBackgroundGame;

import * as React from 'react';
import { Box } from '@mui/material';
import { styles } from './styles';

interface Props {
  isError: any;
  message: string | undefined | boolean;
}

const ErrorMessage = (props: Props): JSX.Element => {
  const { message, isError } = props;

  return <>{isError && <Box sx={styles.errorMessage}>{message}</Box>}</>;
};

export default ErrorMessage;

import React from 'react';
import {Box, Typography} from '@mui/material';
import { styles } from './styles';
import {convertTime} from 'shared/helpers';
import {StepDisplay, TimeCode} from 'shared/store/project/interface';

interface Props {
    timeCodes: StepDisplay;
    playerRef: any;
}

const TimeCodes = (props: Props): JSX.Element => {
    const { timeCodes, playerRef} = props;
    const { timecodes } = timeCodes;

    const handleClick = (time) => {
        playerRef.current.seekTo(time);
    };
    return (
        <Box sx={ styles.timeCode }>
            {
                timecodes.map( ({description, time}: TimeCode, index) =>
                  (<Typography
                      key={`${index}${description}`}
                      variant="subtitle2"
                      color="inherit"
                      component="p"
                      sx={ styles.timeCodeItem }
                      onClick={ () => handleClick(convertTime(time))}
                   >
                      {`${time} ${description}`}
                   </Typography>),
              )
            }
        </Box>
    );
};

export default TimeCodes;

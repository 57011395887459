import { SystemCssProperties } from '@mui/system';

export const style = {
  accordionSummary: {
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
      mt: 0,
      mb: 0,
      ml: 2,
    },
  } as SystemCssProperties,

  accordion: {
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
      mt: 0,
      mb: 0,
      ml: 2,
    },
  } as SystemCssProperties,
};

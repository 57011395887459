import React from 'react';
import {Box} from '@mui/system';
import {Typography} from '@mui/material';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {BoardPermissionKeys, CompareParametersId} from 'shared/constants/enum';

import {
  styles,
  stylesProps,
  stylesPropsBoard,
  stylesPropsInnerBoard,
} from 'components/WebTools/ProjectProcess/Steps/Step_4/CompareValuesContainer/styles';
import {InitialDataI, ItemBoard} from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/initialStateData';
import DragIconTableCell from 'components/WebTools/ProjectProcess/Steps/Step_4/DragIconTableCell';
import {applyQuadrantToOldParameterValue} from 'shared/helpers';

interface Props {
  boards: InitialDataI[];
}

const defaultValueKraera = 8;

const setValueTable = (item) => {
  let values;
  if (item.quadrant) {
    values = applyQuadrantToOldParameterValue(item.values[0], item.quadrant);
  } else {
    values = defaultValueKraera;
  }
  return values;
};

const CompareValuesContainer = (props: Props) => {
  const {boards} = props;

  const renderBoard = (board: InitialDataI) => {
    return board.items.map((item: ItemBoard, index: number) => {
      let value;
      const keys = Object.keys(item);
      if (keys.includes(BoardPermissionKeys.Values)) {
        value = setValueTable(item);
      } else {
        value = setValueTable(item);
      }
      return (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {(draggableProvided, snapshotDraggable) => {
            return (
              <Box
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
                sx={stylesProps(snapshotDraggable, draggableProvided).block}
              >
                <Typography sx={styles.itemBox}>{item.name}</Typography>
                <Typography sx={styles.itemBox} className="itemBoxName">{value}</Typography>
                {board.id !== CompareParametersId.Kraeera && <DragIconTableCell/>}
              </Box>
            );
          }}
        </Draggable>
      );
    });
  };

  return (
    <Box sx={styles.compareValuesContainer}>
      {boards.map((board: InitialDataI) => {
        if (board.id !== CompareParametersId.Root) {
          return (
            <Box sx={stylesPropsBoard(board.id).board} key={board.id}>
              <Box sx={styles.iconInfoWrapper}>
                <Typography sx={styles.boardTitle}>{board.name}</Typography>
              </Box>
              <Droppable droppableId={board.id} isDropDisabled={board.id === CompareParametersId.Kraeera}>
                {(provided, snapshotDroppable) => {
                  return (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      sx={stylesPropsInnerBoard(board.id, snapshotDroppable).innerBoard}
                    >
                      {
                        board.items.length > 0 &&
                        renderBoard(board)
                      }
                      {provided.placeholder}
                    </Box>
                  );
                }}
              </Droppable>
            </Box>
          );
        }
      })}
    </Box>
  );
};
export default CompareValuesContainer;

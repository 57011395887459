import { Competitors, OldCompetitionParameters } from 'shared/store/project/interface';

export const setRowsTable = (dataHeader: Competitors[], rowsLength: number, oldCompetitionParameters): OldCompetitionParameters[] => {
  const rows: OldCompetitionParameters[] = [];
  const rowValues = dataHeader.map(() => '');

  for (let i = 0; i < rowsLength; i++) {
    rows.push({
      name: oldCompetitionParameters[i]?.name ?? '',
      description: oldCompetitionParameters[i]?.description ?? '',
      values: oldCompetitionParameters[i]?.values ?? rowValues,
      quadrant: oldCompetitionParameters[i]?.quadrant ?? null,
      overriddenValue: oldCompetitionParameters[i]?.overriddenValue ?? null,
    });
  }

  return rows;
};

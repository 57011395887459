import React from 'react';
import StepTransition from 'shared/components/StepTransition';
import styles from './styles';

const TransitionStep2 = (): JSX.Element => {
  return (
    <StepTransition
      svg={
        <svg id="svg2" viewBox="0 0 427 191" style={styles.path}>
          <defs>
            <mask id="msk2">
              <rect width="120%" height="120%" fill="white" />
              <path
                stroke="black"
                strokeWidth="11"
                fill="none"
                strokeDashoffset="700"
                strokeDasharray="700"
                strokeLinecap="round"
                d="M 0.582 173.458 C 0.582 173.458 58.444 193.46 88.475 193.248 C 182.175 192.586 222.697 94.392 272.992
          83.819 C 295.021 79.188 313.951 87.174 336.438 86.147 C 417.675 82.435 427.823 1.746 427.823 1.746"
              >
                <animate attributeName="stroke-dashoffset" dur="4s" begin="0s" values="0;700;" fill="freeze" />
              </path>
            </mask>
          </defs>
          <path
            className="dashed"
            mask="url(#msk2)"
            d="M 0.582 173.458 C 0.582 173.458 58.444 193.46 88.475 193.248 C 182.175 192.586 222.697 94.392 272.992
          83.819 C 295.021 79.188 313.951 87.174 336.438 86.147 C 417.675 82.435 427.823 1.746 427.823 1.746"/>
        </svg>
      }
      stepName="Nuvarande position"
      stepNumber={2}
      stepIcons={['pencil']}/>
  );
};

export default TransitionStep2;

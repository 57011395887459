import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  clearBorder: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    border: 'none',
    borderBottom: '1px solid #9BA1AB',
  } as SystemCssProperties,

  tableRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  } as SystemCssProperties,

  tableCell: {
    display: 'flex',
    width: '100%',
    maxWidth: '291px',
  } as SystemCssProperties,

  tableCellHeader: {
    padding: '0',
    fontWeight: '500',
    fontSize: '14px',
    color: colors.darkGrey,
    border: 'none',
  } as SystemCssProperties,

  cellHeader: {
    minWidth: '145px',
    padding: '8px 16px',
    fontWeight: '500',
    fontSize: '14px',
    color: colors.darkGrey,
    border: 'none',
  } as SystemCssProperties,
};

import * as React from 'react';
import {Route, Switch} from 'react-router';
import {privateRoutes, publicRoutes, Router, RoutesPage} from 'shared/routes';
import {useTypedSelector} from 'shared/hooks/useTypedSelector';
import {Redirect} from 'react-router-dom';
import Loader from 'shared/components/Loader';
import {Suspense} from 'react';
import {useCheckPermissions} from '../../hooks/useCheckPermissions';
import {Roles} from '../../constants/enum';
import {styles} from './styles';

export const forbiddenRoutes = [
  RoutesPage.login,
  RoutesPage.registration,
  RoutesPage.newPassword,
  RoutesPage.reset,
  `${RoutesPage.admin}${RoutesPage.login}`,
  `${RoutesPage.admin}${RoutesPage.reset}`,
  RoutesPage.newPasswordAdmin,
];

const AppRouter = () => {
  const {isAuth} = useTypedSelector((state) => state.auth.login);
  const role = useCheckPermissions();

  return (
    <>
      <Suspense fallback={<Loader />}>
        <fieldset style={styles.fieldset}>
          {isAuth || role === Roles.MU ? (
            <Switch>
              {privateRoutes.map((route: Router) => (
                <Route exact={route.exact} path={route.path} component={route.component} key={route.path}/>
              ))}
              {forbiddenRoutes.map((route: string, index: number) => (
                <Redirect from={route} to={{
                  path: RoutesPage.home,
                  search: location.search,
                }} exact key={index}/>
              ))}
            </Switch>
          ) : (
            <Switch>
              {publicRoutes.map((route: Router) => (
                <Route exact={route.exact} path={route.path} component={route.component} key={route.path}/>
              ))}
              <Redirect from={RoutesPage.home} to={{
                path: RoutesPage.login,
                search: location.search,
              }} exact/>
            </Switch>
          )}
        </fieldset>
      </Suspense>
    </>
  );
};

export default AppRouter;

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const stylesArchive = {
  titleArchive: {
    marginTop: 5,
    marginBottom: 5,
  } as SystemCssProperties,

  icon: {
    color: colors.white,
    marginRight: '0px',
  } as SystemCssProperties,
};

import React from 'react';
import MuiButton from 'shared/components/UI/MuiButton';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';
import {RoutesPage} from 'shared/routes';
import {Box, Typography} from '@mui/material';
import {Link, useHistory, useParams} from 'react-router-dom';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_3/Instruction/styles';
import Loader from 'shared/components/Loader';
import InstructionTreeIcon from 'shared/components/Icons/Instructions/InstructionTree';
import color from 'styles/_Variables';

import {useGetProject} from 'shared/hooks/useGetProjects';
import { instructions } from './instructions';
import instructionImage from 'assets/images/instruction-step3-1.png';
import NavigationBar from 'shared/components/NavigationBar';

const AdditionalInstructionStep3 = (): JSX.Element => {
  let { id } = useParams<{ id: string }>();
  id = id ?? localStorage.getItem('projectId');
  const { push } = useHistory();
  const { project, steps } = RoutesPage;
  const pathNextTable = `${project.page}${id}${steps.step3}${steps.table}${location.search}`;
  const { projectData } = useGetProject();
  let instruction: any = null;

  if (projectData === null) {
    return (
      <Box sx={styles.Loader}>
        <Loader />
      </Box>
    );
  }
  instruction = projectData.data.stepsNotes['3'];

  const navigateBack = (): void => {
    const previousPath = `${RoutesPage.project.page}${id}${RoutesPage.steps.step3}${RoutesPage.steps.instruction}${location.search}`;

    push(previousPath);
  };

  return (
    <Box sx={styles.container}>

      <Box sx={ styles.header }>
        <Box sx={ styles.headerTitle }>
          <InstructionTreeIcon width={48} height={48} background={color.black}/>

          <Typography variant="subtitle1" component="h1" color="inherit" sx={ styles.name }>
            Tung last! Släng några ideer överbord!
          </Typography>
        </Box>

        <Instruction stepNote={instruction} data={projectData.data} noteKey={3}/>
      </Box>

      <Box sx={ styles.content }>
        <Box sx={ styles.wrapper }>
          <Box sx={ styles.instructions }>
            {
              instructions.map((item, index) =>
                <Typography variant="subtitle2" component="p" color="inherit">{ index + 1 }. { item }</Typography>)
            }
          </Box>

          <Typography variant="subtitle1" component="p" sx={ styles.stepTime }>
            Tidsåtgång: ca 10 minuter
          </Typography>

          <MuiButton type="button" variant="contained" sx={styles.NavLink}>
            <Link to={pathNextTable}>Fortsätt</Link>
          </MuiButton>
        </Box>

        <img height="510px" width="680px" src={instructionImage} alt="Instruction image"/>
      </Box>

      <NavigationBar  navigateBack={() => navigateBack()} navigateForward={() => push(pathNextTable)} />
    </Box>
  );
};

export default AdditionalInstructionStep3;

import colors from 'styles/_Variables';
import { SystemCssProperties } from '@mui/system';

export const styles = {
  form: {
    width: '376px',
    padding: '32px 40px',
    minHeight: '332px',
    backgroundColor: colors.white,
    borderRadius: '12px',
  } as SystemCssProperties,

  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  } as SystemCssProperties,

  logo: {
    marginBottom: '35px',
  } as SystemCssProperties,
};

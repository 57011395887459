import React from 'react';
import StepTransition from 'shared/components/StepTransition';
import styles from './styles';

const TransitionStep3 = (): JSX.Element => {
  return (
    <StepTransition
      svg={
        <svg id="svg3" viewBox="0 0 427 191" style={styles.path}>
          <defs>
            <mask id="msk3">
              <rect width="120%" height="120%" fill="black" />
              <path
                stroke="white"
                strokeWidth="11"
                fill="none"
                strokeDashoffset="700"
                strokeDasharray="700"
                strokeLinecap="round"
                d="M 4.075 103.609 C 4.075 103.609 78.231 3.692 175.786 4.075 C 296.423 4.548 259.32 123.496 333.527 122.817
          C 379.183 122.399 427.823 98.952 427.823 98.952"
              >
                <animate attributeName="stroke-dashoffset" dur="4s" begin="0s" values="700;0;" fill="freeze" />
              </path>
            </mask>
          </defs>
          <path
            className="dashed"
            mask="url(#msk3)"
            d="M 4.075 103.609 C 4.075 103.609 78.231 3.692 175.786 4.075 C 296.423 4.548 259.32 123.496 333.527 122.817
          C 379.183 122.399 427.823 98.952 427.823 98.952"/>
        </svg>
      }
      stepName="Skapa nytt"
      stepNumber={3}
      stepIcons={['pencil', 'greenCap']}/>
  );
};

export default TransitionStep3;

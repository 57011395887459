import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { DeleteArchivePayload } from 'shared/store/archive/interface';
import { adminService } from 'core/services/admin';

interface Props {
  payload: DeleteArchivePayload;
}

export function* deleteUser(props: Props): SagaIterator {
  const { id } = props.payload;
  try {
    yield call(adminService.deleteUser, `users/${id}`);
    yield put(
      actionSnackbar.snackbarActions.add({
        type: 'success',
        text: 'You have deleted user successfully',
      }),
    );
    yield put(actions.getUsersActions.start());
    yield put(actions.deleteUserActions.end());
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.getUsersActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* deleteUserSaga(): SagaIterator {
  yield takeLatest(actions.deleteUserActions.start, deleteUser);
}

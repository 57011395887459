import * as React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { styles } from 'components/WebTools/MainForm/ProjectForm/Forms/IconBlock/styles';

export interface Props {
  title: string;
  icon: JSX.Element;
}

const IconBlock = (props: Props): JSX.Element => {
  const { title, icon } = props;
  return (
    <Box sx={styles.IconField} className="IconField">
      <Box sx={styles.icon}>{icon}</Box>
      <Typography variant="subtitle2" component="span">
        {title}
      </Typography>
    </Box>
  );
};

export default IconBlock;

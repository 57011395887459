import { getUsersActions } from 'shared/store/admin/ducks/reducers/getUsers';
import { deleteUserActions } from 'shared/store/admin/ducks/reducers/deleteArchive';
import { getSettingsActions } from './reducers/getSettings';
import { updateSettingsActions } from './reducers/updateSettings';
import { sendInviteActions } from 'shared/store/admin/ducks/reducers/sendInvite';
import { getInvitesActions } from 'shared/store/admin/ducks/reducers/getInvites';

export default {
  getUsersActions,
  deleteUserActions,
  getSettingsActions,
  updateSettingsActions,
  sendInviteActions,
  getInvitesActions,
};

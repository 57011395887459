import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px',
  } as SystemCssProperties,

  content: {
    maxWidth: '480px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as SystemCssProperties,

  descriptions: {
    marginTop: '24px',
    fontSize: '18px',
    color: colors.white,
    textAlign: 'center',
  } as SystemCssProperties,

  steps: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
    paddingRight: '40px',
    gap: '16px',
  } as SystemCssProperties,

  step: {
    display: 'flex',
    justifyContent: 'space-between',
    color: colors.white,
  } as SystemCssProperties,

  navigationBar: {
    width: '100%',
    marginTop: '35px',
    justifyContent: 'center',
  } as SystemCssProperties,

  NavLink: {
    width: '196px',
    marginTop: '40px',
    textTransform: 'none',
  } as SystemCssProperties,
};

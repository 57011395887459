import React, {useCallback, useMemo} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import { RoutesPage } from 'shared/routes';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_6/CommentContainer/CommentSection/styles';
import MuiButton from 'shared/components/UI/MuiButton';
import Comment from 'components/WebTools/ProjectProcess/Steps/Step_6/CommentContainer/CommentSection/Comment';
import { useFormik } from 'formik';
import { actions } from 'shared/store/project';
import { snackbarActions } from 'shared/store/snackbar/ducks/reducers/snackbar';
import { useDispatch } from 'react-redux';
import { Project, ProjectWithoutFiles } from 'shared/store/project/interface';
import { actions as actionsPopup } from 'shared/store/popup';
import { useCheckPermissions } from 'shared/hooks/useCheckPermissions';
import { Roles } from 'shared/constants/enum';
import NavigationBar from 'shared/components/NavigationBar';
import DiagramCompetitors from '../../../Step_2/DiagramContainer/DiagramCompetitors';
import DiagramCompetitors5 from '../../../Step_5/DiagramContainer/DiagramCompetitors';
import {useCurrentPng} from 'recharts-to-png';
import {transformDataDiagram} from '../../../Step_2/DiagramContainer/utils';
import {transformDataDiagram as transformDataDiagram5} from '../../../Step_5/DiagramContainer/utils';
import {paragraphs} from './paragraphs';

interface Props {
  data: Project;
}

const CommentSection = (props: Props): JSX.Element => {
  const { data } = props;
  let { id } = useParams<{ id: string }>();
  const history = useHistory();
  id = id ?? localStorage.getItem('projectId');
  const pathUrl = `${RoutesPage.project.page}${id}${RoutesPage.steps.step6}${RoutesPage.steps.finish}${location.search}`;
  const dispatch = useDispatch();
  const [getInitialPng, { ref: initialRef }] = useCurrentPng();
  const [getNewPng, { ref: newRef }] = useCurrentPng();
  const formik = useFormik({
    initialValues: {
      comment: data.comment,
    },
    onSubmit: async (values) => {
      const formData = await handleComposedDownload();
      const newData: ProjectWithoutFiles = { ...data };
      delete newData.files;
      const updateProjectWithoutFiles = {
        ...newData,
        comment: values.comment,
      };
      dispatch(actionsPopup.popupActions.toggle(false));
      validateCommentAndFinishProject(values, data, updateProjectWithoutFiles, formData);
    },
  });


  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const dataDiagram = useMemo(() => {
    if (data !== null) {
      return transformDataDiagram(data.competitors, data.oldCompetitionParameters);
    }
  }, [data]);

  const newDataDiagram = useMemo(() => {
    if (data !== null) {
      return transformDataDiagram5(
        data.competitors,
        data.oldCompetitionParameters,
        data.newCompetitionParameters,
      );
    }
  }, [data]);

  const handleComposedDownload = useCallback(async () => {
    const formData = new FormData();

    const svgOne = document.querySelectorAll('.recharts-surface')[0] as HTMLElement;
    const svgTwo = document.querySelectorAll('.recharts-surface')[1] as HTMLElement;
    svgOne.setAttribute('height', '400');
    svgOne.setAttribute('viewBox', '0 0 679 400');
    svgTwo.setAttribute('height', '400');
    svgTwo.setAttribute('viewBox', '0 0 679 400');

    const chartOne = document.querySelectorAll('.recharts-wrapper')[0] as HTMLElement;
    const chartTwo = document.querySelectorAll('.recharts-wrapper')[1] as HTMLElement;
    chartOne.style.cssText = `${chartOne.style.cssText} height: 400px;`;
    chartTwo.style.cssText = `${chartOne.style.cssText} height: 400px;`;

    const diagramStep2 = await (await fetch(await getInitialPng() as string)).blob();
    const diagramStep5 = await (await fetch(await getNewPng() as string)).blob();
    formData.append('plotInitial', diagramStep2);
    formData.append('plotNew', diagramStep5);
    return formData;
  }, [getInitialPng, getNewPng]);

  const validateCommentAndFinishProject = (values, prevValues, updateProjectWithoutFiles, formData) => {
    if (data.oldCompetitionParameters.length) {
      if (values.comment !== prevValues.comment) {
        if (values.comment.length < 500) {
          dispatch(
            actions.updateProjectActions.start({
              id,
              updateProjectWithoutFiles,
            }),
          );

          dispatch(actions.completionProjectActions.start({ id, history, diagrams: formData, pathUrl }));
        } else {
          dispatch(snackbarActions.add({ text: 'Kommentarslängden måste vara upp till 500 tecken', type: 'error' }));
        }
      } else {
        dispatch(actions.completionProjectActions.start({ id, history, diagrams: formData, pathUrl }));
      }
    } else {
      dispatch(
        snackbarActions.add({
          text: `Alla steg måste slutföras för att avsluta projektet.
        Kontrollera om du har angett alla parametervärden i steg 2 och 5.`,
          type: 'error',
        }),
      );
    }
  };

  const saveProject = () => {
    const newData: ProjectWithoutFiles = { ...data };
    delete newData.files;
    const updateProjectWithoutFiles = {
      ...newData,
      comment: formik.values.comment ?? '',
    };
    dispatch(actions.updateProjectActions.start({ id, updateProjectWithoutFiles }));
  };

  const navigateBack = (): void => {
    const previousPath = `${RoutesPage.project.page}${id}${RoutesPage.steps.step6}${RoutesPage.steps.video}${location.search}`;

    history.push(previousPath);
  };

  return (
    <Box sx={styles.additionalInfoContainer}>
      <Box sx={styles.hidden}>
        <DiagramCompetitors
          isFullSize={false}
          isStep4={false}
          competitors={data.competitors}
          dataDiagram={dataDiagram}
          setFillSize={() => {}}
          ref={initialRef}/>

        {
          newDataDiagram &&
          <DiagramCompetitors5 isFullSize={false}
                               competitors={newDataDiagram.competitorsTemp}
                               dataDiagram={newDataDiagram.dataDiagramTemp}
                               setFillSize={() => {}}
                               ref={newRef}
          />
        }
      </Box>

      <Box sx={styles.content}>
        <Box sx={styles.additionalInfoLeft}>
          <Box sx={styles.Left}>
            <Typography variant="subtitle2" component="p" color="inherit">
              Nu är ni framme vid slutet av denna workshop. <br />
              Skriv gärna ner kommentarer och synpunkter i fältet till höger.
              Dessa kommer att finnas med i den slutliga projektrapporten. Ni har nu två valmöjligheter:
            </Typography>

            {
              paragraphs.map(({ headline, text }, index) =>
                <Box sx={ styles.paragraph }>
                  <Typography variant="subtitle1" component="p" color="inherit">
                    { index + 1 }. { headline }
                  </Typography>

                  <Typography variant="subtitle2" component="p" color="inherit">
                    { text }
                  </Typography>
                </Box>,
              )
            }
          </Box>
          <Box
            sx={{
              display: 'flex',
            }}>
            <MuiButton disabled={isMU}
                       type="button"
                       onClick={formik.handleSubmit}
                       variant="contained"
                       sx={{ ...styles.NavLink, marginRight: '20px' }}>
              Avsluta & Lås projekt
            </MuiButton>
            <Box sx={styles.save}>
              <Link to={RoutesPage.home}>
                <MuiButton disabled={isMU}
                           type="button"
                           onClick={() => saveProject()}
                           variant="contained"
                           sx={{ ...styles.NavLink }}>
                  Spara projekt
                </MuiButton>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.additionalInfoRight}>
          <Comment data={data} formik={formik} />
        </Box>
      </Box>

      <NavigationBar
        isRightButtonDisabled={true}
        navigateBack={() => navigateBack()}
        navigateForward={() => {}}
      />
    </Box>
  );
};

export default CommentSection;

import * as React from 'react';
import {ReactNode} from 'react';
import {Box} from '@mui/material';

import {stylesProps} from './styles';

interface Props {
  children?: ReactNode;
  isStartScreen: boolean;
}

const LayoutBackgroundAuth = (props: Props): JSX.Element => {
  const {children} = props;
  return <Box sx={stylesProps().root}>{children}</Box>;
};

export default LayoutBackgroundAuth;

import * as yup from 'yup';

export const validationSchemaSwedish = yup.object({
  password: yup.string().required('Lösenord är ett obligatoriskt fält').min(8, ' '),

  confirmPassword: yup
    .string()
    .required('Upprepa lösenord är ett obligatoriskt fält')
    .oneOf([yup.ref('password'), null], 'Upprepa lösenord och lösenord stämmer inte överens')
    .min(8, ' '),
});

export const validationSchemaEng = yup.object({
  password: yup.string().required('Password is a required field').min(8, ' '),

  confirmPassword: yup
    .string()
    .required('Confirmation password is a required field.')
    .oneOf([yup.ref('password'), null], 'Confirm password and password don’t match'),
});

import { CompareParametersId } from 'shared/constants/enum';

export type ItemI = {
  id: string;
  name: string;
  value: number;
};

export interface ColumnI {
  id: string;
  name: string;
  items: ItemI[];
}

export interface OldCompetitionParametersToTableI {
  id: string;
  calculateValue: number;
  description: string;
  name: string;
  overriddenValue: number;
  quadrant: string;
  values: number[];
}

export interface NewCompetitionParametersToTableI {
  id: string;
  name: string;
  description: string;
  value: number;
}

export type ItemBoard = OldCompetitionParametersToTableI | NewCompetitionParametersToTableI;

export interface InitialDataI {
  id: string;
  name: string;
  items: OldCompetitionParametersToTableI[] | NewCompetitionParametersToTableI[];
}

export const initialData = (oldCompetitionParameters, newCompetitionParameters): InitialDataI[] => {
  const initialBoard = {
    ROOT: [],
    eliminate: [],
    increase: [],
    reduce: [],
  };

  const transform = oldCompetitionParameters.reduce((accum, current) => {
    switch (current.quadrant) {
      case null: {
        accum.ROOT.push(current);
        break;
      }
      case CompareParametersId.Reducera: {
        accum.eliminate.push(current);
        break;
      }
      case CompareParametersId.Eliminera: {
        accum.increase.push(current);
        break;
      }
      case CompareParametersId.Reduce: {
        accum.reduce.push(current);
        break;
      }
    }
    return accum;
  }, initialBoard);

  const filteredEmptyParameters = transform.ROOT.filter((item) => item.values[0] !== null);

  return [
    {
      id: 'ROOT',
      name: 'table',
      items: [...filteredEmptyParameters],
    },
    {
      id: 'eliminate',
      name: 'Eliminera',
      items: [...transform.eliminate],
    },
    {
      id: 'increase',
      name: 'Öka',
      items: [...transform.increase],
    },
    {
      id: 'reduce',
      name: 'Reducera',
      items: [...transform.reduce],
    },
    {
      id: 'kraeera',
      name: 'Kreera',
      items: newCompetitionParameters,
    },
  ];
};

import * as React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import HelpIcon from '@mui/icons-material/Help';

import { styles } from './styles';

const Header = (): JSX.Element => {
  return (
    <Box sx={styles.header}>
      <Typography sx={styles.titleForm}>Projektinfo</Typography>

      <Box sx={styles.textInfo}>
        <HelpIcon />
        <Typography variant="subtitle2" sx={styles.textInfoText}>
          Info
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;

import { AcceptInvite, RequestUser, User, UserData } from 'shared/store/auth/interface';
import { clearTokens } from 'shared/helpers';
import HTTP from 'core/services/api';

export const authService: any = {
  login: async (url: string, body: RequestUser): Promise<UserData> => {
    const response = await HTTP.post(url, body);
    return response.data;
  },

  registration: async (url: string, body: AcceptInvite & { token: string }): Promise<User> => {
    const response = await HTTP.post(url, body);
    return response.data;
  },

  getMe: async (url: string): Promise<User> => {
    const response = await HTTP.get(url);
    return response.data;
  },

  sendPasswordReset: async (url: string, body: Pick<User, 'email'>): Promise<unknown> => {
    const response = await HTTP.post(url, body);
    return response.data;
  },

  resetPassword: async (url: string, body: Pick<AcceptInvite, 'token' | 'password'>): Promise<unknown> => {
    const response = await HTTP.post(url, body);
    return response.data;
  },

  sendPasswordResetAdmin: async (url: string, body: Pick<User, 'email'>): Promise<unknown> => {
    const response = await HTTP.post(url, body);
    return response.data;
  },

  resetPasswordAdmin: async (url: string, body: Pick<AcceptInvite, 'token' | 'password'>): Promise<unknown> => {
    const response = await HTTP.post(url, body);
    return response.data;
  },

  refreshToken: async (url: string) => {
    try {
      const response = await HTTP.put(url, {
        refresh: localStorage.getItem('refreshToken'),
      });
      return response.data;
    } catch (error) {
      clearTokens();
    }
  },
};

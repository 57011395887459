import colors from 'styles/_Variables';
import { SystemCssProperties } from '@mui/system';

export const styles = {
  timeCodeTitle: {
    textAlign: 'start',
    fontWeight: '600',
    lineHeight: '22px',
    fontSize: '24px',
    marginBottom: '24px',
    color: colors.white,
  } as SystemCssProperties,

  NavLink: {
    marginTop: '40px',

    '&.MuiButton-root': {
      padding: '0px',
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.white,
      textTransform: 'none',
      width: '100%',
      height: '100%',
      padding: '10px 0px',
    },
  } as SystemCssProperties,

  Container: {
    display: 'flex',
    flexDirection: 'column',
  } as SystemCssProperties,

  Instructions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  } as SystemCssProperties,

  Instruction: {
    color: colors.white,
    fontWeight: 400,
    fontSize: '16px',
  } as SystemCssProperties,

  StepTime: {
    fontFamily: 'Poppins',
    marginTop: '24px',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '16px',
    color: colors.white,
  } as SystemCssProperties,
};

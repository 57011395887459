import {SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';
import {StylesCSS} from 'shared/interfaces';

export const styles: StylesCSS = {
  Tab: {
    display: 'flex',
    width: '100%',
    borderRadius: '20px',
    height: '100%',
    'MuiTabs-flexContainer': {
      height: '100%',
    },
  } as SystemCssProperties,

  TabItem: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',

    '& a': {
      width: '100%',
      height: '100%',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
    },

    '& a:not(.active):after': {
      content: '""',
      display: 'inline-block',
      right: 0,
      top: 'calc(50% - 30px)',
      position: 'absolute',
      height: '56px',
      width: '0.5px',
      background: colors.selectedLink,
    },

    '&:last-of-type a:after': {
      display: 'none',
    },

    '& a:active': {
      textDecoration: 'none',
    },

    'a.active': {
      background: colors.selectedStepLink,
      pointerEvents: 'none',
      boxShadow: `0 0 0 1px ${colors.selectedStepLink}`,
      position: 'relative',
      zIndex: 1,
    },

    '& a > .MuiBox-root': {
      color: colors.white,
      textDecoration: 'none',

      '& > .MuiButtonBase-root': {
        width: '100%',
        height: '100%',
      },
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      padding: '24px',
      gap: '16px',
    },
  } as SystemCssProperties,

  paddingNull: {
    padding: '0',
  },

  TabIcon: {
    display: 'flex',
    alignItems: 'center',
  },

  GroupIcon: {
    position: 'absolute',
    top: '13px',
    right: '9px',
  },

  Typography: {
    fontSize: '14px',
    textAlign: 'center',
  },
};

import * as yup from 'yup';
import moment from 'moment';

export const validationSchema = yup.object({
  general: yup.object({
    name: yup.string().required('Projektnamn är ett obligatoriskt fält'),
  }),
  rbPlans: yup.object({
    dates: yup.object({
      constructionStart: yup.date().test('validateDate', 'Required', validateDate),
      design: yup.date().test('validateDate', 'Required', validateDate),
      movedIn: yup.date().test('validateDate', 'Required', validateDate),
      preselection: yup.date().test('validateDate', 'Required', validateDate),
      purchase: yup.date().test('validateDate', 'Required', validateDate),
      salesStart: yup.date().test('validateDate', 'Required', validateDate),
    }),
  }),
});

export const validate = (values) => {
  let errors = {};
  values.competitors.forEach((item) => {
    if (!item.name) {
      errors = 'Name är ett obligatoriskt fält';
    }
  });
  return errors;
};

function validateDate(val, context) {
  const datesValues = Object.values(context.parent).filter((item) => item !== undefined);
  if (!datesValues.length) {
    return true;
  }
  return moment(val, 'YYYY-MM-DD').isValid();
}

import React from 'react';
import { Router } from 'shared/routes';
import { Route } from 'react-router-dom';
import { Box } from '@mui/material';
import { styles } from './styles';

interface Props {
  stepRoutes: Router[];
}

const SubStepperRoutes = (props: Props): JSX.Element => {
  const { stepRoutes } = props;
  return (
    <Box sx={styles.stepOneContainer}>
      <Route
        render={(prop) =>
          stepRoutes.map((route) => {
            return <Route path={`${prop.match.path}${route.path}`} component={route.component} exact={route.exact} key={route.path} />;
          })
        }/>
    </Box>
  );
};

export default SubStepperRoutes;

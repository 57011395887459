import {SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';
import {StylesCSS} from 'shared/interfaces';

export const stylesProps = (isError?: boolean, isTouched?: boolean): StylesCSS => {
  return {
    td: {
      height: '42px',
      wordBreak: 'break-word',
      position: 'relative',
      color: colors.accentBlue,
      borderBottom: 'none',
      border: isError && isTouched ? `1.1px solid ${colors.error}` : `1.1px solid ${colors.lightGrey}`,
      padding: '10px 16px',
      lineHeight: 'inherit',
    } as SystemCssProperties,
  };
};

export const stylesDragProps = (name, isDragging: boolean): StylesCSS => {
  return {
    clTable: {
      minHeight: '41px',
      padding: 0,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      borderBottom: isDragging ? 'none' : `1px solid ${colors.darkGrey}`,
      '&:hover': {
        backgroundColor: name ? colors.lightGreyDisableTable : '',
        '& .dragIcon': {
          display: name ? 'flex' : 'none',
        },
      },
      '& .dragIcon': {
        display: isDragging ? 'flex' : 'none',
      },
      cursor: name ? 'grab' : 'default',
    } as SystemCssProperties,
  };
};

export const styles: StylesCSS = {
  flxCenter: {
    marginTop: '36px',
    position: 'relative',
    left: '165px',
    display: 'flex',
    justifyContent: 'center',
  },

  tableCont: {
    overflowX: 'hidden',
  },

  tableW: {
    background: colors.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: '8px',
  },

  NavLink: {
    width: '196px',
    textDecoration: 'none',
    color: colors.white,
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&.MuiButton-root': {
      padding: '0px',
    },
  } as SystemCssProperties,

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  droppableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  textValue: {
    display: 'flex',
    justifyContent: 'space-between',
    borderLeft: '1px solid #9BA1AB',
    width: '145px',
    padding: '8px 16px',
    color: colors.accentBlue,
  },

  textName: {
    maxWidth: '417px',
    wordBreak: 'break-word',
    padding: '8px 16px',
    color: colors.accentBlue,
  },

  tableRow: {
    border: 'none',
  },

  tableContainer: {
    borderRadius: '8px',
    boxShadow: 'none',
  },

  buttonForm: {
    mt: 3,
    background: colors.lightGreyDisable,
  },

  textButtonForm: {
    color: colors.white,
  },

  tableTd: {
    background: 'inherit',
    width: '100%',
    textDecoration: 'none',
    color: colors.white,
    textTransform: 'none',
  },

  errorWrapper: {
    width: '100%',
    minWidth: '309px',
    padding: '4px 8px',
    background: colors.error,
    position: 'absolute',
    bottom: '-37px',
    left: 0,
    zIndex: 1000,
  },

  errorText: {
    color: colors.white,
    fontSize: '12px',
    lineHeight: '22px',
  },
};

export const stylesForm = {
  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'center',
  'align-items': 'center',
  width: '100%',
  'max-width': '1360px',
  margin: '0 auto',
};

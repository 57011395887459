import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { formService } from 'core/services/form';
import { actions } from '../../index';
import {actions as actionSnackbar} from 'shared/store/snackbar';

export function* updateTimer(props): SagaIterator {
  const { id, timers } = props.payload;

  try {
    yield call(formService.updateTimer, `projects/${id}/`, {
      data: { timers },
    });
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;

      if (error.response.status !== 401) {
        yield put(actions.updateTimerActions.error({ message, stackErrors }));
        yield put(actionSnackbar.snackbarActions.add({ type: 'error', text: message }));
      }
    }
  }
}

export default function* updateTimerSaga(): SagaIterator {
  yield takeLatest(actions.updateTimerActions.start, updateTimer);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginState, RequestUser, UserData } from 'shared/store/auth/interface';

const initialState: LoginState = {
  isLoading: false,
  isAuth: false,
  accessToken: null,
  user: null,
  error: {
    message: [],
    stackErrors: '',
  },
};

const login = createSlice({
  name: 'login',
  initialState,
  reducers: {
    start: {
      reducer(state) {
        state.isLoading = true;
        state.isAuth = false;
        state.user = null;
      },
      prepare(payload: RequestUser) {
        return { payload };
      },
    },

    end(state, action: PayloadAction<UserData>) {
      state.isLoading = false;
      state.isAuth = true;
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
    },
    error(state, action: any) {
      state.isLoading = false;
      state.isAuth = false;
      state.user = null;
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
    reset() {
      return initialState;
    },
  },
});

export const loginActions = login.actions;
export default login.reducer;

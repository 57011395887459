import React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {TableCell, TableRow, Typography} from '@mui/material';
import {styles, stylesDragProps} from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/Table/styles';
import {BoardPermissionKeys} from 'shared/constants/enum';
import DragIconTableCell from 'components/WebTools/ProjectProcess/Steps/Step_4/DragIconTableCell';
import {InitialDataI, ItemBoard} from 'components/WebTools/ProjectProcess/Steps/Step_4/TableSection/initialStateData';
import {stylesDraggable} from './styles';

interface Props {
  item: ItemBoard;
  index: number;
  board: InitialDataI;
}

const ListDraggableTable = React.memo((props: Props) => {
  const {item, index} = props;
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(draggableRows, snapshot) => (
        <TableRow
          key={item.id}
          {...draggableRows.draggableProps}
          {...draggableRows.dragHandleProps}
          ref={draggableRows.innerRef}
          sx={stylesDraggable(snapshot).draggableElement}
        >
          <TableCell sx={stylesDragProps(item.name, snapshot.isDragging).clTable}>
            <Typography sx={styles.textName}>{item.name}</Typography>
            <Typography sx={styles.textValue}>
              {BoardPermissionKeys.Values in item ? item.values[0] : 0}
              <DragIconTableCell/>
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
});

export default ListDraggableTable;

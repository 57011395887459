import * as React from 'react';
import { useCallback, useState } from 'react';
import {Box} from '@mui/system';
import {Typography} from '@mui/material';

import {useGetProject} from 'shared/hooks/useGetProjects';
import Loader from 'shared/components/Loader';

import {transformDataDiagram} from 'components/WebTools/ProjectProcess/Steps/Step_5/DiagramContainer/utils';
import DiagramCompetitors from 'components/WebTools/ProjectProcess/Steps/Step_5/DiagramContainer/DiagramCompetitors';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';

import {styles, stylesDiagramContainer} from 'components/WebTools/ProjectProcess/Steps/Step_5/DiagramContainer/styles';
import MuiButton from 'shared/components/UI/MuiButton';
import { useCurrentPng } from 'recharts-to-png';
import { useHistory, useParams } from 'react-router-dom';
import { RoutesPage } from 'shared/routes';

export interface DiagramData {
  [key: string]: string | number;
}

const DiagramContainer = (): JSX.Element => {
  const {projectData, isLoadingGetForm} = useGetProject();
  const [isFullSize, setFillSize] = useState<boolean>(false);
  const [getPng] = useCurrentPng();
  const { push, location } = useHistory();
  const id = useParams<{ id: string }>().id ?? localStorage.getItem('projectId');
  const routeFrom = location.state?.from.includes(RoutesPage.steps.table) ? RoutesPage.steps.table : RoutesPage.steps.instruction;
  const nextStep = `${RoutesPage.project.page}${id}${RoutesPage.steps.step5}${routeFrom}${location.search}`;

  const handleComposedDownload = useCallback(async () => {
    const png = await getPng();
    if (png) {
      localStorage.setItem('plotNew', png);
    }
    push(nextStep);
  }, [getPng]);

  let instruction: any = null;

  let dataDiagram;
  let competitors;
  if (projectData !== null) {
    const {dataDiagramTemp, competitorsTemp}: any = transformDataDiagram(
      projectData.data.competitors,
      projectData.data.oldCompetitionParameters,
      projectData.data.newCompetitionParameters,
    );
    dataDiagram = [...dataDiagramTemp];
    competitors = [...competitorsTemp];
  }

  if (projectData === null || isLoadingGetForm) {
    return <Loader/>;
  }

  instruction = projectData.data.stepsNotes['5'];

  return (
    <Box sx={isFullSize ? stylesDiagramContainer().root : styles.container}>
      <Box sx={styles.diagramInnerContainer}>
        <Typography sx={styles.diagramInnerContainerText}>Ny konkurrensposition</Typography>
        <Instruction stepNote={instruction} data={projectData.data} noteKey={5}/>
      </Box>
      <DiagramCompetitors isFullSize={isFullSize} competitors={competitors} dataDiagram={dataDiagram} setFillSize={setFillSize}/>
      {!isFullSize && (
        <MuiButton sx={styles.buttonDiagram} onClick={handleComposedDownload} type="submit" variant="outlined">
          <Typography sx={styles.buttonTextDiagram} variant="subtitle2">
            Tillbaka
          </Typography>
        </MuiButton>
      )}
    </Box>
  );
};

export default DiagramContainer;

import {SystemCssProperties} from '@mui/system';
import {StylesCSS} from 'shared/interfaces';
import colors from 'styles/_Variables';
import {Properties} from 'csstype';

export const stylesProps = (width: number | string, isError?: boolean, isTouched?: boolean): StylesCSS | any => {
  return {
    td: {
      width,
      verticalAlign: 'top',
      height: '42px',
      wordBreak: 'break-word',
      position: 'relative',
      color: colors.accentBlue,
      borderBottom: 'none',
      border: isError && isTouched ? '1.1px solid #D51C29' : '1px solid #D6DAE1',
      padding: '10px 16px',
      lineHeight: 'inherit',
      backgroundColor: colors.white,
      '& textarea': {
        zIndex: 2,
        resize: 'none',
        overflow: 'hidden',
        '&:focus': {
          outline: '1px solid transparent',
        },
      },
    },
    buttonForm: {
      mt: 3,
      background: colors.lightGreyDisable,
    },
  };
};

export const stylesButtonForm = (isValid?: boolean): StylesCSS => {
  return {
    buttonForm: {
      flexShrink: 0,
      mt: 3,
      background: isValid ? colors.accentBlue : colors.lightGreyDisable,
    } as SystemCssProperties,
  };
};

export const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  tableRow: {
    border: 'none',
  },

  tableContainer: {
    borderRadius: '8px',
    boxShadow: 'none',
    height: '409px',
    overflowY: 'auto',
  } as SystemCssProperties,

  buttonForm: {
    mt: 3,
    background: colors.lightGreyDisable,
  },

  navLink: {
    textDecoration: 'none',
  },

  textButtonForm: {
    color: colors.white,
  },

  tableTd: {
    background: 'inherit',
    width: '100%',
    textDecoration: 'none',
    color: colors.white,
    textTransform: 'none',
  },

  errorWrapper: {
    width: '100%',
    minWidth: '309px',
    padding: '4px 8px',
    background: colors.error,
    position: 'absolute',
    bottom: '-37px',
    right: 0,
    zIndex: 1000,
  } as SystemCssProperties,

  errorText: {
    color: colors.white,
    fontSize: '12px',
    lineHeight: '22px',
  },
};

export const stylesForm = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '1360px',
  margin: '0 auto',
} as Properties<string | number, string & {}>;

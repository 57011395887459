import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { CompletionProjectPayload } from 'shared/store/project/interface';
import { formService } from 'core/services/form';

interface Props {
  payload: CompletionProjectPayload;
}

export function* completionProject(props: Props): SagaIterator {
  const { history, id, diagrams, pathUrl } = props.payload;

  try {
    yield call(formService.completionProject, `/projects/${id}/finish`, diagrams, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    yield put(actions.completionProjectActions.end());
    if (history) {
      history.push(pathUrl);
    }
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.completionProjectActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* completionProjectSaga(): SagaIterator {
  yield takeLatest(actions.completionProjectActions.start, completionProject);
}

import { loginActions } from 'shared/store/auth/ducks/reducers/login';
import { logoutActions } from 'shared/store/auth/ducks/reducers/logout';
import { registrationActions } from 'shared/store/auth/ducks/reducers/registration';
import { getMeActions } from 'shared/store/auth/ducks/reducers/getMe';
import { resetPasswordActions } from 'shared/store/auth/ducks/reducers/resetPassword';
import { updatePasswordActions } from 'shared/store/auth/ducks/reducers/updatePassword';

export default {
  loginActions,
  logoutActions,
  registrationActions,
  getMeActions,
  resetPasswordActions,
  updatePasswordActions,
};

import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { actions } from '../../index';
import { actions as actionSnackbar } from 'shared/store/snackbar';
import { CreateProjectPayload } from 'shared/store/project/interface';
import { RoutesPage } from 'shared/routes';
import { formService } from 'core/services/form';

interface Props {
  payload: CreateProjectPayload;
}

export function* createProject(props: Props): SagaIterator {
  const { history } = props.payload;
  try {
    const response = yield call(formService.createProject, 'projects');
    localStorage.setItem('projectId', response.id);
    yield put(actions.createProjectActions.end());
    yield put(actions.getProjectActions.end(response));
    history.push(`${RoutesPage.form.page}${response.id}`);
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.createProjectActions.error({ message, stackErrors }));
      yield put(
        actionSnackbar.snackbarActions.add({
          type: 'error',
          text: message,
        }),
      );
    }
  }
}

export default function* createProjectSaga(): SagaIterator {
  yield takeLatest(actions.createProjectActions.start, createProject);
}

import * as React from 'react';
import { Box, TableCell, Typography } from '@mui/material';
import { setWidthTd } from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors/helpers';
import { styles, stylesProps } from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors/styles';

export interface Props {
  indexR: string | number;
  indexC: number;
  activateEditMode: any;
  value: any;
  errors: any;
  touched: any;
  keyRow: string;
}

const TdWithoutValues = (props: Props): JSX.Element => {
  const { value, indexR, activateEditMode, errors, touched, keyRow, indexC } = props;
  const isError = errors?.oldCompetitionParameters?.[indexR]?.[keyRow];
  const isTouched = touched?.oldCompetitionParameters?.[indexR]?.[keyRow];
  const widthTableTd = setWidthTd(keyRow);

  return (
    <TableCell
      sx={stylesProps(widthTableTd, isError, isTouched).td}
      onClick={activateEditMode}
      style={{ minWidth: widthTableTd }}
    >
      {value}
      {isError && isTouched && (
        <Box sx={styles.errorWrapper}>
          <Typography sx={styles.errorText}>{errors?.oldCompetitionParameters?.[indexR]?.[keyRow]}</Typography>
        </Box>
      )}
    </TableCell>
  );
};

export default TdWithoutValues;

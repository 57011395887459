import * as React from 'react';
import InfoComponent from 'shared/components/UI/Info';
import { stylesHeaderTable } from 'components/WebTools/ProjectProcess/Steps/styles';
import TextInfo from 'shared/components/UI/Info/Text';
import { useState } from 'react';
import { SystemCssProperties } from '@mui/system';

interface Props {
  maxLength: number;
  stylePopup?: SystemCssProperties;
}

const InfoPopup = (props: Props): JSX.Element => {
  const { maxLength, stylePopup } = props;
  const [isShowInfo, setIsShowInfo] = useState<boolean>(false);
  return (
    <div>
      <InfoComponent onSetIsShowInfo={setIsShowInfo} sx={stylesHeaderTable.info} />
      <TextInfo textInfo={`max ${maxLength} tecken`} isShowInfo={isShowInfo} stylePopup={stylePopup} />
    </div>
  );
};

export default InfoPopup;

import {SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';
import {StylesCSS} from 'shared/interfaces';

export const stylesDiagramContainer = (isFullSize?: boolean): StylesCSS => {
  return {
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      border: 0,
      zIndex: '9999',
      overflowY: 'hidden',
      background: colors.white,
      width: '100%',
      height: '100vh',
      margin: 0,
    },

    diagramInnerContainerText: {
      fontSize: '24px',
      color: colors.white,
      fontWeight: 600,
      '.MuiTypography-root': {
        marginBottom: isFullSize && '0',
      },
    } as SystemCssProperties,
  };
};

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  } as SystemCssProperties,

  diagramInnerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },

  buttonDiagram: {
    display: 'flex',
    marginTop: '24px',
    marginBottom: '36px',
    flex: 'none',
  },

  buttonTextDiagram: {
    color: colors.white,
  },

  diagramInnerContainerText: {
    fontSize: '24px',
    color: colors.white,
    fontWeight: 600,
    marginBottom: '0px!important',
  },
};

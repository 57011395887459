import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  root: {
    marginTop: '77px',
    marginBottom: '32px',
  } as SystemCssProperties,

  colorText: {
    color: colors.white,
  } as SystemCssProperties,

  actionContent: {
    position: 'absolute',
    left: 35,
    bottom: 20,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
  } as SystemCssProperties,

  actionBtns: {
    alignItems: 'start',
    position: 'relative',
    top: '3px',
  } as SystemCssProperties,

  snackBar: {
    '.MuiSnackbarContent-root': {
      alignItems: 'flex-start',
      borderRadius: '8px',
      minHeight: '140px',
      fontSize: '16px',
      '&.MuiSnackbarContent-message': {
        alignSelf: 'flex-start',
      },
    },
  } as SystemCssProperties,
};

import * as React from 'react';
import LayoutBackgroundStart from 'shared/components/Layout/LayoutBackgroundStart';
import { Typography } from '@mui/material';
import MuiButton from 'shared/components/UI/MuiButton';
import { Box } from '@mui/system';

import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions as actionArchive } from 'shared/store/archive';
import { RootState } from 'shared/store/rootReducer';
import { actions } from 'shared/store/project';
import Loader from 'shared/components/Loader';
import { Redirect, useHistory } from 'react-router-dom';
import { RoutesPage } from 'shared/routes';
import { useTypedSelector } from 'shared/hooks/useTypedSelector';
import Snackbar from '@material-ui/core/Snackbar';
import { stylesSnackbar } from 'shared/components/SnackBar/styles';
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Roles, Statuses } from 'shared/constants/enum';
import jwt_decode from 'jwt-decode';
import { useCheckPermissions } from 'shared/hooks/useCheckPermissions';
import { useEffect } from 'react';

const StartScreen = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isSnackBar, setSnackBar] = React.useState<boolean>(false);
  const { isLoading: isLoadingCreateProject } = useSelector((state: RootState) => state.project.createProject);
  const { projectData } = useSelector((state: RootState) => state.project.getSharedProject);
  const { archives } = useTypedSelector((state) => state.archives.getArchive);
  const history = useHistory();
  const isLogin = localStorage.getItem('accessToken');
  const role = useCheckPermissions();

  const isFromLogin = (): boolean => !!history.location.state?.from?.includes(RoutesPage.login);

  useEffect(() => {
    let decoded;
    const token = localStorage.getItem('accessToken') as string;
    if (token) {
      decoded = jwt_decode(token);
    } else if (!token && !role) {
      return;
    }

    if (role === Roles.MU) {
      history.push(
        `${RoutesPage.project.page}${projectData?.id}${RoutesPage.steps.step1}${RoutesPage.steps.transition}${history.location.search}`,
      );
      return;
    }

    if (!archives.data.length && decoded.roles[0] !== Roles.ADMIN) {
      dispatch(actionArchive.getArchiveActions.start());
    }

    setSnackBar(hasAnyOngoingProjectStatus() && isFromLogin());
  }, [role, archives]);

  function hasAnyOngoingProjectStatus() {
    return !!(archives && archives.data.some((project) => project.status === Statuses.ONGOING));
  }

  const renderButtons = () => {
    if (isLogin) {
      return (
        <MuiButton
          onClick={() => {
            history.push(`${RoutesPage.archive}`);
          }}
          variant="contained"
          type="button"
        >
          <Typography sx={styles.colorText} variant="subtitle2" component="p">
            Arkiv
          </Typography>
        </MuiButton>
      );
    }
  };

  const handleCloseSnackBar = () => setSnackBar(false);

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" disableRipple onClick={handleCloseSnackBar} sx={styles.actionBtns}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <IconButton sx={styles.actionContent} disableRipple size="small" color="inherit" onClick={() => history.push(RoutesPage.archive)}>
        Ja
      </IconButton>
    </>
  );

  if (isLogin) {
    const decoded: any = jwt_decode(localStorage.getItem('accessToken') as string);
    if (decoded.roles[0] === Roles.ADMIN) {
      return <Redirect to={RoutesPage.admin} exact />;
    }
  }

  return (
    <>
      {isSnackBar && (
        <Box sx={{ ...stylesSnackbar().success, ...styles.snackBar }}>
          <Snackbar
            message={'Du har pågående projekt. Vill du jobba vidare med ett påbörjat projektet?'}
            open={isSnackBar}
            onClose={handleCloseSnackBar}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            action={action}
          />
        </Box>
      )}
      <LayoutBackgroundStart isStartScreen={true}>
        <Box sx={{ mt: 9, mb: 4 }}>
          <MuiButton
            onClick={() => {
              dispatch(actions.createProjectActions.start({ history }));
            }}
            variant="contained"
            type="button"
          >
            <Typography sx={styles.colorText} variant="subtitle2" component="span">
              Skapa ny strategi
            </Typography>
          </MuiButton>
        </Box>
        {renderButtons()}
        <Box sx={{ mt: 4 }}>{isLoadingCreateProject && <Loader />}</Box>
      </LayoutBackgroundStart>
    </>
  );
};

export default StartScreen;

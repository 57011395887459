import {call, put, takeLatest} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {actions} from '../../index';
import {authService} from 'core/services/auth';

export function* getMe(): SagaIterator {
  try {
    const response = yield call(authService.getMe, 'users/me');
    yield put(actions.loginActions.end(response));
    yield put(actions.getMeActions.end());
  } catch (error: any) {
    if (error.response) {
      const { message, errorCode: stackErrors } = error.response.data;
      yield put(actions.getMeActions.error({ message, stackErrors }));
      yield put(actions.loginActions.error({ message, stackErrors }));
    }
  }
}

export default function* getLoginSaga(): SagaIterator {
  yield takeLatest(actions.getMeActions.start, getMe);
}

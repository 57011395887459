import * as React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { Formik } from 'formik';
import MuiField from 'shared/components/UI/MuiField';
import MuiButton from 'shared/components/UI/MuiButton';
import { validationSchemaSwedish, validationSchemaEng } from './validationSchema';
import { useDispatch } from 'react-redux';
import { actions } from 'shared/store/auth';
import { useHistory } from 'react-router-dom';
import ErrorMessage from 'components/Authorization/ErrorMessage';
import { styles } from './styles';
import { RoutesPage } from 'shared/routes';
import queryString from 'query-string';

const language = {
  eng: {
    title: 'Restore password',
    placeholder: 'Email',
    before: {
      body: 'We’ll send you a link to your email to confirm that this account is yours',
      btn: 'Reset password',
    },
    after: {
      body: 'Link has been sent. Please check your email and create new password',
      btn: 'Resend password',
      link: 'Back to Main page',
    },
  },
  sverige: {
    title: 'Återställ lösenord',
    placeholder: 'E-post',
    before: {
      body: 'Vi skickar en länk till din e-post för att bekräfta att detta konto är ditt',
      btn: 'Återställ lösenord',
    },
    after: {
      body: 'Link has been sent. Please check your email and create new password',
      btn: 'Skicka länken igen',
      link: 'Tillbaka till huvudsidan',
    },
  },
};

const ResetPassword = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSentLink = history.location.search.includes('?email');
  const emailQuery = queryString.parse(history.location.search).email as string | null;
  const isAdminReset = history.location.pathname.includes(RoutesPage.admin);
  const currentLang = isAdminReset ? language.eng : language.sverige;

  return (
    <>
      <Typography sx={styles.typoHeader} variant="subtitle1" component="p">
        {currentLang.title}
      </Typography>
      <Typography sx={styles.typoTxt} variant="subtitle1" component="p">
        {isSentLink ? currentLang.after.body : currentLang.before.body}
      </Typography>
      <Formik
        initialValues={{
          email: emailQuery ? emailQuery : '',
        }}
        validationSchema={isAdminReset ? validationSchemaEng : validationSchemaSwedish}
        onSubmit={(values) => {
          const body = { ...values, history };
          dispatch(actions.resetPasswordActions.start(body));
        }}
      >
        {(formik) => (
          <Box component="form" onSubmit={formik.handleSubmit} sx={styles.fields}>
            {!isSentLink && (
              <Box sx={styles.styleFiled}>
                <MuiField
                  id="email"
                  name="email"
                  maxLength={128}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  placeholder={currentLang.placeholder}
                  isDisabledIcon={true}
                  error={formik.touched.email && Boolean(formik.errors.email)}/>
                <ErrorMessage
                  isError={formik.touched.email && Boolean(formik.errors.email)}
                  message={formik.touched.email && formik.errors.email}/>
              </Box>
            )}
            <Box sx={isSentLink ? { ...styles.btn, ...styles.margin } : { ...styles.btn }}>
              <MuiButton variant="contained" type="submit">
                <Typography sx={styles.btnTxt} variant="subtitle2" component="p">
                  {!isSentLink ? currentLang.before.btn : currentLang.after.btn}
                </Typography>
              </MuiButton>
            </Box>
            {isSentLink && (
              <Link
                sx={styles.linkReset}
                onClick={() => history.push(isAdminReset ? `${RoutesPage.admin}${RoutesPage.login}` : RoutesPage.login)}
              >
                <Typography sx={styles.linkReset} variant="subtitle2" component="a">
                  {currentLang.after.link}
                </Typography>
              </Link>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;

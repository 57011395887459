import * as React from 'react';

const Pdf = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <rect x="5.80798" y="1.2393" width="16.692" height="21.7593" fill="url(#pattern0)" />
        <path d="M6.32947 1.5407H15.8112C17.9159 3.03065 20.3194 5.25618 21.9644 7.68246V22.2179H6.32947V1.5407Z" fill="#152E5B" />
        <path
          d="M6.32947 1.5407H15.8112C17.9159 3.03065 20.3194 5.25618 21.9644 7.68246V22.2179H6.32947V1.5407V1.5407Z"
          stroke="#152E5B"
          strokeWidth="0.0186548"/>
        <rect x="15.2781" y="1.00169" width="7.22181" height="7.19653" fill="url(#pattern1)" />
        <path
          d="M15.8112 1.5407C18.1008 3.20109 20.2809 5.24637 21.9644 7.68246C20.874 6.55393 19.3789 5.95156 17.6047 6.02889C17.8143
              3.93636 17.0045 2.58497 15.8112 1.5407Z"
          fill="#152E5B"/>
        <path
          d="M15.8112 1.5407C18.1008 3.20109 20.2809 5.24637 21.9644 7.68246C20.874 6.55393 19.3789 5.95156 17.6047 6.02889C17.8143
              3.93636 17.0045 2.58497 15.8112 1.5407Z"
          stroke="#152E5B"
          strokeWidth="0.0186548"/>
        <path d="M1.9906 2.99334H16.1052V7.72068H1.9906V2.99334Z" stroke="#152E5B" strokeWidth="0.224044" />
        <path
          d="M11.8522 15.1407C12.2334 14.3963 12.6665 13.5576 13.0132 12.7156L13.1502 12.3832C12.6974 10.666 12.4259 9.2877 12.6685
              8.39645C12.7338 8.16409 13.0039 8.02311 13.2926 8.02311L13.4685 8.02569H13.5008C13.8961 8.01965 14.0822 8.52072 14.1034
              8.71554C14.1382 9.03999 13.9876 9.58907 13.9876 9.58907C13.9876 9.36733 13.9963 9.00903 13.8559 8.69986C13.6924 8.34213
              13.5362 8.12838 13.3962 8.0945C13.3256 8.14158 13.2568 8.23901 13.2333 8.42639C13.1845 8.68916 13.17 9.02085 13.17
              9.19183C13.17 9.79593 13.2893 10.5933 13.5239 11.4153C13.5682 11.288 13.6071 11.1657 13.6381 11.0512C13.6862 10.8706
              13.992 9.6737 13.992 9.6737C13.992 9.6737 13.9149 11.2669 13.8073 11.749C13.7842 11.8509 13.7588 11.9517 13.7323
              12.0548C14.1191 13.1318 14.7424 14.093 15.4859 14.7849C15.7791 15.0579 16.1493 15.278 16.4998 15.4787C17.2651
              15.3697 17.9697 15.3182 18.5575 15.3247C19.3374 15.335 19.9098 15.4499 20.1417 15.6775C20.2552 15.7881 20.3012
              15.9216 20.3155 16.0714C20.319 16.1296 20.2905 16.2665 20.2821 16.3009C20.2905 16.2593 20.2905 16.0548 19.6644
              15.8557C19.1713 15.6986 18.2486 15.7035 17.1413 15.821C18.422 16.4454 19.6698 16.7556 20.0652 16.5696C20.1619
              16.5227 20.2792 16.3626 20.2792 16.3626C20.2792 16.3626 20.2095 16.6781 20.1594 16.757C20.0955 16.8427 19.9702
              16.9356 19.8514 16.9669C19.2269 17.1329 17.6014 16.7487 16.1843 15.9418C14.601 16.1742 12.8622 16.6034 11.4684
              17.059C10.0987 19.4509 9.06897 20.5494 8.23131 20.1315L7.92332 19.9772C7.7981 19.9058 7.77902 19.7321 7.80795
              19.5907C7.90561 19.1145 8.50479 18.3974 9.70835 17.6812C9.83793 17.6031 10.415 17.299 10.415 17.299C10.415 17.299
              9.98775 17.7111 9.88767 17.7919C8.92702 18.5764 8.21799 19.5633 8.2357 19.9459L8.23918 19.9792C9.05521 19.8634 10.279
              18.2082 11.8522 15.1407ZM12.3508 15.3951C12.088 15.8882 11.8312 16.3455 11.5941 16.7653C12.9058 16.2177 14.3176 15.8672
              15.6615 15.6183C15.4808 15.494 15.3055 15.3624 15.1405 15.2233C14.4005 14.5989 13.8362 13.8199 13.4273 13.0003C13.168
              13.6966 12.8595 14.4365 12.3508 15.3951Z"
          fill="white"/>
        <path
          d="M20.6028 17.2895H20.6534C20.7096 17.2895 20.7575 17.2726 20.7575 17.2242C20.7575 17.1856 20.7331 17.1589 20.6608
              17.1589C20.6318 17.1589 20.6148 17.1615 20.6028 17.1641V17.2895ZM20.6028 17.5158H20.5277V17.113C20.5742 17.1053 20.6212
              17.1014 20.6683 17.1016C20.7359 17.1016 20.7696 17.113 20.796 17.1325C20.8203 17.1493 20.8373 17.181 20.8373
              17.2173C20.8373 17.268 20.8015 17.2988 20.7575 17.3137V17.3184C20.794 17.3327 20.8136 17.3643 20.8258 17.4148C20.8373
              17.4774 20.8448 17.5017 20.8548 17.5159H20.7771C20.765 17.5017 20.7554 17.4653 20.7453 17.4101C20.736 17.3663 20.7096
              17.3474 20.6514 17.3474H20.6028V17.5158ZM20.6807 17.6922C20.4669 17.6922 20.2935 17.5194 20.2935 17.3064C20.2935 17.0933
              20.4669 16.9204 20.6807 16.9204C20.8945 16.9204 21.068 17.0933 21.068 17.3064C21.068 17.5195 20.8945 17.6922 20.6807
              17.6922ZM20.9904 17.3063C20.9904 17.1358 20.8517 16.9976 20.6806 16.9976C20.5993 16.9988 20.5216 17.0318 20.4645
              17.0896C20.4074 17.1474 20.3754 17.2252 20.3754 17.3063C20.3754 17.3874 20.4074 17.4653 20.4645 17.523C20.5216 17.5808
              20.5993 17.6139 20.6806 17.6151C20.8517 17.6151 20.9904 17.4768 20.9904 17.3063Z"
          fill="white"/>
        <path
          d="M13.6198 20.2701L13.5149 19.5942C13.4847 19.3998 13.4467 19.0959 13.4171 18.8853H13.4058C13.3748 19.0974 13.3321
              19.4103 13.3011 19.5959L13.1879 20.2701H13.6198ZM13.131 20.7098L12.9943 21.459H12.4895L13.1189 18.3417H13.7384L14.31
              21.459H13.8028L13.6684 20.7098H13.131ZM15.459 20.0666C15.459 20.0247 15.459 19.976 15.4515 19.9321C15.4309 19.7777
              15.3552 19.6391 15.2344 19.6391C15.0257 19.6391 14.9489 19.9651 14.9489 20.3456C14.9489 20.7826 15.0583 21.032 15.2268
              21.032C15.3041 21.032 15.3921 20.9889 15.4408 20.8058C15.4521 20.7691 15.459 20.72 15.459 20.663L15.459 20.0666ZM15.9768
              18.259V20.8123C15.9768 21.0152 15.9866 21.2643 15.9943 21.459H15.5465L15.5177 21.1988H15.5062C15.4318 21.359 15.2678
              21.4953 15.0727 21.4953C14.6438 21.4953 14.4313 20.9826 14.4313 20.3353C14.4313 19.5464 14.7486 19.1625 15.094
              19.1625C15.2746 19.1625 15.3908 19.2588 15.4499 19.3974H15.4591V18.259H15.9768ZM17.0554 21.0641C17.2537 21.0641
              17.3019 20.6603 17.3019 20.3256C17.3019 20.0073 17.2537 19.5934 17.0471 19.5934C16.8283 19.5934 16.7822 20.0073
              16.7822 20.3256C16.7822 20.7094 16.8407 21.0641 17.0516 21.0641H17.0554ZM17.0462 21.4953C16.5688 21.4953 16.269
              21.0793 16.269 20.3358C16.269 19.5239 16.653 19.1625 17.0576 19.1625C17.5283 19.1625 17.8242 19.599 17.8242 20.3223C17.8242
              21.2523 17.3613 21.4954 17.0501 21.4954L17.0462 21.4953ZM18.632 20.6523C18.632 20.7082 18.6412 20.7609 18.6525
              20.7963C18.7042 20.9855 18.7929 21.0319 18.8635 21.0319C19.0631 21.0319 19.1444 20.7567 19.1444 20.3164C19.1444 19.9155
              19.0587 19.6391 18.8612 19.6391C18.7627 19.6391 18.6798 19.7552 18.6517 19.8793C18.6411 19.918 18.6321 19.9794 18.6321
              20.0214L18.632 20.6523ZM18.1144 18.259H18.632V19.4358H18.6403C18.7429 19.2623 18.8787 19.1625 19.0752 19.1625C19.4662
              19.1625 19.6621 19.6443 19.6621 20.2736C19.6621 21.0696 19.3698 21.4953 18.9911 21.4953C18.8104 21.4953 18.6898
              21.4085 18.5812 21.1883H18.5706L18.541 21.459H18.0968C18.1038 21.2712 18.1145 21.0151 18.1145
              20.8123V18.259H18.1144V18.259ZM20.9039 20.1136C20.9061 19.7963 20.8204 19.5746 20.6564 19.5746C20.469
              19.5746 20.3892 19.8722 20.3831 20.1136H20.9039ZM20.3817 20.511C20.3878 20.9194 20.5858 21.0528 20.8318
              21.0528C20.9873 21.0528 21.1218 21.0152 21.2204 20.9745L21.2895 21.3785C21.1529 21.443 20.9403 21.4831
              20.7367 21.4831C20.1811 21.4831 19.8775 21.0716 19.8775 20.3678C19.8775 19.613 20.2297 19.1592 20.6898
               19.1592C21.1338 19.1592 21.3805 19.5601 21.3805 20.2142C21.3805 20.364 21.3743 20.448 21.3653 20.5138L20.3817 20.511Z"
          fill="white"/>
        <rect x="1.5" y="2.58126" width="15.1257" height="5.77108" fill="url(#pattern2)" />
        <path d="M1.9845 3.01178H16.0991V7.73919H1.9845V3.01178Z" fill="white" />
        <path d="M16.3686 8.02287H1.72913V2.72826H16.3686V8.02287ZM15.842 3.25309H2.25537V7.49694H15.842V3.25309Z" fill="white" />
        <path
          d="M5.67358 3.70576H6.70065C7.00598 3.70576 7.24011 3.79555 7.40298 3.97509C7.567 4.15331 7.64896 4.40777 7.64896
              4.73867C7.64896 5.071 7.56704 5.32692 7.40298 5.50649C7.24011 5.68471 7.00598 5.77382 6.70065
              5.77372H6.29238V6.87241H5.67358V3.70576ZM6.29245 4.29748V5.18197H6.63487C6.75482 5.18197 6.84754 5.14382 6.91291
              5.06747C6.97829 4.98967 7.01095 4.88006 7.01095 4.73864C7.01095 4.59722 6.97832 4.48832 6.91291 4.41198C6.84758
              4.33563 6.75482 4.29744 6.63487 4.29744L6.29245 4.29748ZM8.70339 4.3229V6.25524H8.92512C9.17805 6.25524 9.37096
              6.17251 9.50375 6.00706C9.6377 5.84161 9.7047 5.6012 9.7047 5.28591C9.7047 4.97195 9.63821 4.73307 9.50532
              4.56894C9.37253 4.40491 9.17904 4.3229 8.92508 4.3229H8.70339ZM8.08456 3.70576H8.73708C9.10144 3.70576 9.37257
              3.74039 9.55035 3.80964C9.72933 3.8776 9.88256 3.99349 10.0101 4.15755C10.1226 4.30033 10.2061 4.4651 10.2608
              4.6517C10.3154 4.83844 10.3427 5.04979 10.3427 5.28595C10.3427 5.52489 10.3154 5.73848 10.2608 5.92654C10.2061
              6.11314 10.1226 6.27785 10.01 6.42069C9.88147 6.58468 9.72715 6.70139 9.54718 6.77067C9.36715 6.83853 9.09715 6.87244
              8.73708 6.87244H8.08456V3.70576ZM10.8169
              3.70576H12.4869V4.32294H11.4357V4.91264H12.4242V5.52995H11.4357V6.87248H10.8169V3.70576Z"
          fill="#152E5B"/>
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_39_1461" />
        </pattern>
        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image1_39_1461" />
        </pattern>
        <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image2_39_1461" />
        </pattern>
        <clipPath id="clip0_39_1461">
          <rect width="21" height="22" fill="white" transform="translate(1.5 1)" />
        </clipPath>
        <image
          id="image0_39_1461"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeoAAAKBCAYAAACYtug+AAAACXBIWXMAAAsSAAALEgHS3X78AAAA"/>
        <image
          id="image1_39_1461"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANUAAADUCAYAAAALHGYmAAAACXBIWXMAAAsSAAALEgHS3X78AAAA"/>
        <image
          id="image2_39_1461"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAb0AAACrCAYAAADy1kJ2AAAACXBIWXMAAAsSAAALEgHS3X78AAAA"/>
      </defs>
    </svg>
  );
};

export default Pdf;

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useParams} from 'react-router';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TimerIcon from '@mui/icons-material/Timer';
import PauseIcon from '@mui/icons-material/PauseCircleFilledRounded';
import LinkIcon from '@mui/icons-material/Link';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogOutIcon from '../Icons/LogOutIcon';

import {RoutesPage} from 'shared/routes';
import {useGetProject} from 'shared/hooks/useGetProjects';

import LogoutConfirm from 'components/Authorization/Logout';
import ModalRedirect from 'components/WebTools/ProjectProcess/Common/ModalRedirect';

import HeaderItem from 'shared/components/HeaderProjectProcess/HeaderItem';
import {findStepToRedirect} from 'shared/components/HeaderProjectProcess/helpers';

import {styles} from './styles';
import {useTypedSelector} from 'shared/hooks/useTypedSelector';
import moment, {Moment} from 'moment';
import {useCheckPermissions} from '../../hooks/useCheckPermissions';
import {Roles} from '../../constants/enum';
import {actions} from '../../store/project';
import {useDispatch} from 'react-redux';
import {useCurrentStep} from '../../hooks/useCurrentStep';
import { Box } from '@mui/system';

export type CallbackFunctionModal = (...args) => void;

const HeaderProjectProcess = (): JSX.Element | null => {
  const {isShowPopup} = useTypedSelector((state) => state.popup.popup);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams<{ id: string }>();
  const {pathname} = useLocation();
  const step = useCurrentStep();
  const {projectData, isLoadingGetForm} = useGetProject();
  const [isLoaded, setLoaded] = useState(false);
  const [isConfirmLogout, modalConfirmLogout] = useState(false);
  const [pathRedirect, setPathRedirect] = useState<string>('');
  const [currentTime, setCurrentTime] = useState<string | null>(null);
  const [isLittleTimeLeft, setLittleTimeLeft] = useState<boolean>(false);
  const [isTimePaused, pauseTime] = useState<boolean>(false);
  const [isTimeEnded, endTime] = useState<boolean>(false);
  const isStep6 = step === 6;
  const pathToMainForm = RoutesPage.mainForm.page + id;
  const [isOpenPopup, setOpenPopup] = useState<boolean>(false);
  const handleOpen = (callback: CallbackFunctionModal) => callback(true);
  const handleClose = (callback: CallbackFunctionModal) => callback(false);
  const closeRedirectPopup = () => setOpenPopup(false);
  const ref = useRef(false);
  ref.current = isTimePaused;

  const role = useCheckPermissions();
  const isWelcome = location.pathname.includes('welcome');

  const prevStepNavigation = (stepNum) =>
    `${RoutesPage.project.page}${id}${RoutesPage.steps.step}${stepNum - 1}${RoutesPage.steps.transition}`;

  const timerFunction = (momentTimeStart: Moment, momentTime: Moment) => {
    const timer = setInterval(() => {
      if (ref.current) {
        return;
      }

      if (momentTime.get('hours') + momentTime.get('minutes') + momentTime.get('seconds') === 0) {
        clearInterval(timer);
        endTime(true);
        return;
      }

      momentTime = momentTime.subtract(1, 'second');
      const timeString = momentTime.format('HH:mm:ss');
      setCurrentTime(timeString);

      if (momentTime.valueOf() / 1000 % 5 === 0) {
        updateServerTime(timeString);
      }

      if (momentTimeStart.valueOf() * 0.15 > momentTime.valueOf()) {
        setLittleTimeLeft(true);
      }

      if (momentTime.get('hours') + momentTime.get('minutes') + momentTime.get('seconds') === 0) {
        clearInterval(timer);
        endTime(true);
      }
    }, 1000);
    return timer;
  };

  const startTimer = (timerString: string, timerStringTotal: string) => {
    const [hours, minutes, seconds] = timerString.split(':');
    const [hoursTotal, minutesTotal, secondsTotal] = timerStringTotal.split(':');

    const momentTimeStart = moment(0)
      .utc()
      .set('hours', +hoursTotal)
      .set('minutes', +minutesTotal)
      .set('seconds', +secondsTotal);
    const momentTime = moment(0)
      .utc()
      .set('hours', +hours)
      .set('minutes', +minutes)
      .set('seconds', +seconds);

    return timerFunction(momentTimeStart, momentTime);
  };

  const updateServerTime = (time: string | null) => {
    if (projectData && time) {
      dispatch(actions.updateTimerActions.start({
        id,
        timers: {
          ...projectData.data.timers,
          [`step${step}`]: time,
        },
      }));
    }
  };

  useEffect(() => {
    setLittleTimeLeft(false);
    pauseTime(false);
    endTime(false);

    if (role === Roles.MU) {
      return;
    }

    const timerString = projectData?.data.timers[`step${step}`];
    const timerStringTotal = projectData?.config.timers[`step${step}`];
    if (!timerString) {
      return;
    }

    const timer = !isWelcome && !isLoadingGetForm ? startTimer(timerString, timerStringTotal): null;

    return () => {
      if (timer) {
        clearInterval(timer);
      }
      updateServerTime(currentTime);
    };
  }, [isLoaded, step, isLoadingGetForm]);

  useEffect(() => {
    if (isLoaded || !projectData) {
      return;
    }
    setLoaded(true);
  }, [projectData]);

  const isFinish = pathname.includes(RoutesPage.steps.finish);

  const openRedirectPopup = useCallback((url: string) => {
    const isMathUrl = findStepToRedirect(window.location.pathname);
    if (isMathUrl && url === RoutesPage.home) {
      history.push(RoutesPage.home, {from: pathname});
    } else {
      history.push(`${RoutesPage.mainForm.page}${id}`, {from: pathname});
    }
  }, []);

  const nextValStep = React.useCallback((location: string) => {
    const urls: string[] = location.split('/');
    for (let i = 0; i <= urls.length; i++) {
      if (urls[i].includes('step=')) {
        const val = parseInt(urls[i][urls[i].length - 1]) + 1;
        return urls[i].slice(0, -1) + val;
      }
    }
    return urls.find((item: string) => item.includes('step='));
  }, []);

  const skipStep = `${RoutesPage.project.page}${id}/${nextValStep(pathname)}${RoutesPage.steps.transition}${location.search}`;

  const redirectTo = (url) => {
    if (step === 1 && url !== pathToMainForm) {
      history.push(RoutesPage.home);
    } else if (step && !isStep6 && url !== pathToMainForm) {
      history.push(prevStepNavigation(step));
    } else if (isStep6 && !isFinish && url !== pathToMainForm) {
      history.push(RoutesPage.home);
    } else {
      history.push(url + window.location.search, {from: pathname});
    }
  };

  const handleClickToForm = (url) => {
    const isMathUrl = findStepToRedirect(window.location.pathname);
    if (isMathUrl && isShowPopup) {
      setOpenPopup(true);
    } else {
      closeRedirectPopup();
      redirectTo(url);
    }
  };

  const copySharedLink = async () => {
    const regex = /((^https:\/\/[a-z.-]*)|([a-z0-9]*$))/gi;
    const search = projectData?.sharingLink.match(regex);
    const sharedLink = `${search?.[0]}/?${search?.[1]}`;
    await navigator.clipboard.writeText(sharedLink);
  };

  const headerElements = useMemo(() => ({
      emptyItem: <Box></Box>,
      back: role !== Roles.MU ?
        <HeaderItem text="Tillbaka"
                    handleClick={() => {
                      handleClickToForm(RoutesPage.home);
                      setPathRedirect(RoutesPage.home);
                    }}
                    Icon={<ArrowBackIosNewIcon sx={styles.ArrowBack}/>}
                    name="back"
                    key="back"
        /> :
        <HeaderItem text="" Icon={<ArrowBackIosNewIcon sx={styles.ArrowBack} className="hidden"/>} name="back"/>,
      timer: role !== Roles.MU ?
        <HeaderItem text={currentTime || (projectData?.data.timers[`step${step}`] || '')}
                    Icon={isTimePaused ? <PauseIcon sx={styles.TimerIcon}/> : <TimerIcon sx={styles.TimerIcon}/>}
                    name="timer"
                    key="timer"
                    handleClick={() => {
                      if (isTimeEnded) {
                        return;
                      }
                      pauseTime(!isTimePaused);
                    }}
                    sx={{
                      ...styles.TimerButton,
                      ...(isLittleTimeLeft && styles.TimerButtonLittleTimeLeft),
                      ...(isTimeEnded && styles.TimerButtonEnded),
                    }}
        /> :
        <HeaderItem text="" Icon={<ArrowBackIosNewIcon sx={styles.ArrowBack} className="hidden"/>} name="timer" key="timer"/>,
      skip: <HeaderItem text="Hoppa över"
                        handleClick={() => history.push(skipStep)}
                        Icon={<ArrowForwardIcon sx={styles.ArrowForward}/>}
                        name="skip"
                        key="skip"
      />,
      copyLink: <HeaderItem text="Kopiera länk"
                            handleClick={copySharedLink}
                            Icon={<LinkIcon sx={styles.LinkIcon}/>}
                            name="copyLink"
                            key="copyLink"
      />,
      listOfVideos: <HeaderItem text="Lista över videor"
                                Icon={<VideoLibraryIcon sx={styles.VideoLibrary}/>}
                                name="listOfVideos"
                                key="listOfVideos"
                                handleClick={() => {
                                  updateServerTime(currentTime);
                                  history.push(`${RoutesPage.videos}${location.search}`, {from: pathname});
                                }}
      />,
      watchProjectForm: <HeaderItem text="Se projektinfo"
                                    handleClick={() => {
                                      updateServerTime(currentTime);
                                      handleClickToForm(pathToMainForm);
                                      setPathRedirect(`${RoutesPage.mainForm.page}${id}${location.search}`);
                                    }}
                                    Icon={<DashboardIcon sx={styles.Dashboard}/>}
                                    name="watchProjectForm"
                                    key="watchProjectForm"
      />,
      logOut: role !== Roles.MU ?
        <HeaderItem text="Exit"
                    handleClick={() => handleOpen(modalConfirmLogout)}
                    Icon={<LogOutIcon/>}
                    name="logOut"
                    key="logOut"
        /> :
        <HeaderItem text="" Icon={<ArrowBackIosNewIcon sx={styles.ArrowBack} className="hidden"/>} name="logOut" key="logOut"/>,
    }
  ), [skipStep, isShowPopup, currentTime, isTimePaused, isLittleTimeLeft, isTimeEnded, role, projectData]);

  const renderHeaderElements = (items?: string[]) => {
    if (!items) {
      return Object.keys(headerElements).map((item) => headerElements[item]);
    }
    return Object.keys(headerElements).map((item) => (items.includes(item) ? headerElements[item] : null));
  };

  const renderHeaders = () => {
    if (isStep6 && !isFinish) {
      return renderHeaderElements(['emptyItem', 'copyLink', 'listOfVideos', 'watchProjectForm', 'logOut']);
    } else if (isFinish) {
      return renderHeaderElements(['emptyItem', 'logOut']);
    }

    if (isWelcome) {
      return renderHeaderElements(['copyLink', 'listOfVideos', 'watchProjectForm', 'logOut']);
    }

    return renderHeaderElements(['timer', 'copyLink', 'listOfVideos', 'watchProjectForm', 'logOut']);
  };

  if (projectData === null || isLoadingGetForm) {
    return null;
  }

  return (
    <AppBar position="static" sx={styles.AppBar}>
      <Toolbar sx={styles.Toolbar}>{renderHeaders()}</Toolbar>
      <ModalRedirect closePopup={closeRedirectPopup} onHandleAction={() => openRedirectPopup(pathRedirect)} isOpenPopup={isOpenPopup}/>
      <LogoutConfirm isOpenLogoutConfirm={isConfirmLogout} handleClose={() => handleClose(modalConfirmLogout)}/>
    </AppBar>
  );
};

export default HeaderProjectProcess;

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const stylesPropsTd = (width: number | string) => {
  return {
    td: {
      width,
      height: '42px',
      wordBreak: 'break-word',
      position: 'relative',
      color: colors.accentBlue,
      borderBottom: 'none',
      border: `1px solid ${colors.lightGrey}`,
      verticalAlign: 'top',
      padding: '10px 16px',
      lineHeight: 'inherit',
      boxShadow: 'none',
    } as SystemCssProperties,
  };
};

export const stylesProps = () => {
  return {
    tableRow: {
      display: 'block',
      maxWidth: '291px',
      minHeight: '42px',
      wordWrap: 'break-word',
      padding: '10px 0 10px 17px',
      position: 'relative',
      fontFamily: 'Poppins,sans-serif',
      color: colors.accentBlue,
      fontSize: '14px,',
      border: `1px solid ${colors.lightGrey}`,
    } as SystemCssProperties,
  };
};

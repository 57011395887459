import { getProjectActions } from 'shared/store/project/ducks/reducers/getProject';
import { createProjectActions } from 'shared/store/project/ducks/reducers/createProject';
import { completionProjectActions } from 'shared/store/project/ducks/reducers/completionProject';
import { updateProjectActions } from 'shared/store/project/ducks/reducers/updateProject';
import { getSharedProjectActions } from 'shared/store/project/ducks/reducers/getSharedProject';
import { updateTimerActions } from 'shared/store/project/ducks/reducers/updateTimer';

export default {
  getProjectActions,
  createProjectActions,
  updateProjectActions,
  completionProjectActions,
  getSharedProjectActions,
  updateTimerActions,
};

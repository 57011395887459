import React from 'react';

const Doc = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <rect x="5.80798" y="1.2393" width="16.692" height="21.7593" fill="url(#pattern0)" />
        <path d="M6.32947 1.5407H15.8112C17.9159 3.03065 20.3194 5.25618 21.9644 7.68246V22.2179H6.32947V1.5407Z" fill="#152E5B" />
        <path
          d="M6.32947 1.5407H15.8112C17.9159 3.03065 20.3194 5.25618 21.9644
        7.68246V22.2179H6.32947V1.5407V1.5407Z"
          stroke="#152E5B"
          strokeWidth="0.0186548"/>
        <rect x="15.2781" y="1.00169" width="7.22181" height="7.19653" fill="url(#pattern1)" />
        <path
          d="M15.8112 1.5407C18.1008 3.20109 20.2809 5.24637 21.9644 7.68246C20.874 6.55393 19.3789 5.95156 17.6047 6.02889C17.8143
         3.93636 17.0045 2.58498 15.8112 1.5407Z"
          fill="#152E5B"/>
        <path
          d="M15.8112 1.5407C18.1008 3.20109 20.2809 5.24637 21.9644 7.68246C20.874 6.55393 19.3789 5.95156 17.6047 6.02889C17.8143
        3.93636 17.0045 2.58498 15.8112 1.5407Z"
          stroke="#152E5B"
          strokeWidth="0.0186548"/>
        <path d="M1.9906 2.99334H16.1052V7.72068H1.9906V2.99334Z" stroke="#152E5B" strokeWidth="0.224044" />
        <rect x="1.5" y="2.58126" width="15.1257" height="5.77108" fill="url(#pattern2)" />
        <path d="M1.9845 3.01177H16.0991V7.73919H1.9845V3.01177Z" fill="white" />
        <path d="M16.3686 8.02286H1.72913V2.72825H16.3686V8.02286ZM15.842 3.25309H2.25537V7.49694H15.842V3.25309Z" fill="white" />
        <path
          d="M6 3.5232H6.83393C7.12034 3.5232 7.34356 3.56111 7.50361 3.63692C7.66647 3.70992 7.78159 3.82364 7.84898
        3.97808C7.91917 4.13251 7.95427 4.33748 7.95427 4.593V5.83548C7.95427 6.09661 7.92058 6.3072 7.85319 6.46724C7.7858 6.62448
        7.67208 6.74242 7.51203 6.82104C7.35199 6.89685 7.13157 6.93475 6.85078 6.93475H6V3.5232ZM6.84236 6.4504C6.9631 6.4504
         7.05154 6.43215 7.1077 6.39564C7.16667 6.35633 7.20317 6.30018 7.21721 6.22717C7.23406 6.15417 7.24248 6.04887 7.24248
         5.91129V4.50034C7.24248 4.37118 7.23265 4.2743 7.213 4.20972C7.19615 4.14234 7.15965 4.0932 7.10349 4.06231C7.04733
         4.02862 6.95888 4.01177 6.83815 4.01177H6.69073V6.4504H6.84236Z"
          fill="#152E5B"/>
        <path
          d="M9.37045 6.98108C9.00824 6.98108 8.74992 6.88702 8.59548 6.69889C8.44105 6.50796 8.36384 6.22858 8.36384
        5.86075V4.58878C8.36384 4.22096 8.44105 3.94438 8.59548 3.75906C8.74992 3.57374 9.00824 3.48108 9.37045 3.48108C9.72986
        3.48108 9.98678 3.57515 10.1412 3.76327C10.2985 3.94859 10.3771 4.22376 10.3771 4.58878V5.86496C10.3771 6.22998 10.2985
        6.50796 10.1412 6.69889C9.98678 6.88702 9.72986 6.98108 9.37045 6.98108ZM9.37045 6.48409C9.48838 6.48409 9.567 6.44478
        9.60631 6.36616C9.64843 6.28473 9.66949 6.17382 9.66949 6.03343V4.42031C9.66949 4.27992 9.64843 4.17182 9.60631 4.09601C9.567
        4.01739 9.48838 3.97808 9.37045 3.97808C9.25252 3.97808 9.1725 4.01739 9.13038 4.09601C9.09107 4.17182 9.07142 4.27992 9.07142
        4.42031V6.03343C9.07142 6.17663 9.09107 6.28754 9.13038 6.36616C9.16969 6.44478 9.24972 6.48409 9.37045 6.48409Z"
          fill="#152E5B"/>
        <path
          d="M11.7887 6.98108C11.4265 6.98108 11.1696 6.88421 11.018 6.69047C10.8664 6.49392 10.7906 6.21173 10.7906
        5.8439V4.62248C10.7906 4.24623 10.865 3.96263 11.0138 3.7717C11.1654 3.57795 11.4237 3.48108 11.7887 3.48108C12.1369
        3.48108 12.3826 3.56672 12.5258 3.738C12.6718 3.90647 12.7448 4.14935 12.7448 4.46664V4.75305H12.0709V4.43716C12.0709
        4.33608 12.0653 4.25465 12.0541 4.19288C12.0457 4.1311 12.0204 4.08056 11.9783 4.04125C11.939 3.99913 11.8772 3.97808
         11.793 3.97808C11.6666 3.97808 11.5852 4.01879 11.5487 4.10022C11.5122 4.18165 11.4939 4.30098 11.4939
         4.45822V6.00816C11.4939 6.16821 11.515 6.28754 11.5571 6.36616C11.5992 6.44478 11.6778 6.48409 11.793
         6.48409C11.9109 6.48409 11.9867 6.44478 12.0204 6.36616C12.0541 6.28473 12.0709 6.1654 12.0709
         6.00816V5.67543H12.7448V5.95762C12.7448 6.28333 12.6732 6.53604 12.53 6.71574C12.3868 6.89264
         12.1397 6.98108 11.7887 6.98108Z"
          fill="#152E5B"/>
        <rect x="9" y="10" width="10" height="1" fill="white" />
        <rect x="9" y="13" width="10" height="1" fill="white" />
        <rect x="9" y="16" width="10" height="1" fill="white" />
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2656_4488" />
        </pattern>
        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image1_2656_4488" />
        </pattern>
        <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image2_2656_4488" />
        </pattern>
        <clipPath id="clip0_2656_4488">
          <rect width="21" height="22" fill="white" transform="translate(1.5 1)" />
        </clipPath>
        <image
          id="image0_2656_4488"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeoAAAKBCAYAAACYtug+AAAACXBIWXMAAAsSAAALEgHS3X78AAAA"/>
        <image
          id="image1_2656_4488"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANUAAADUCAYAAAALHGYmAAAACXBIWXMAAAsSAAALEgHS3X78AAAA"/>
        <image
          id="image2_2656_4488"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAb0AAACrCAYAAADy1kJ2AAAACXBIWXMAAAsSAAALEgHS3X78AAAA"/>
      </defs>
    </svg>
  );
};

export default Doc;

import React from 'react';
import { Box } from '@mui/material';
import { styles } from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/Table/styles';
import TableCompetitors from 'components/WebTools/ProjectProcess/Steps/Step_2/TableContainer/TableCompetitors';
import { InitialStateProject } from 'shared/store/project/interface';

interface Props {
  projectData: InitialStateProject;
  isUpdateTable: boolean;
}

const TableSection2 = (props: Props): JSX.Element => {
  const { projectData, isUpdateTable } = props;

  return (
    <Box sx={styles.additionalInfoContainer}>
      <Box sx={styles.table}>
        <TableCompetitors
          dataHeader={projectData.data.competitors}
          data={projectData.data}
          isUpdateTable={isUpdateTable}
          oldCompetitionParameters={projectData.data.oldCompetitionParameters}/>
      </Box>
    </Box>
  );
};

export default TableSection2;

import colors from 'styles/_Variables';
import {StylesCSS} from 'shared/interfaces';

export const stylesText = (isShowInfo: boolean): StylesCSS => {
  return {
    info: {
      display: isShowInfo ? 'block' : 'none',
      position: 'absolute',
      right: 0,
      maxWidth: '255px',
      zIndex: '9999',
      padding: '8px 12px',
      borderRadius: '4px',
      background: colors.lightGrey,
      boxShadow: 3,
    },
  };
};

export const paragraphs: Array<{ preLine: boolean; text: string }> = [
  {
    preLine: false,
    text: 'Fyll i de nya konkurrensparametrarna. Beskriv dem gärna lite mer utförligt i mittkolumnen.',
  },
  {
    preLine: false,
    text: `Rekommenderat antal parametrar är 5-6 st, dock max 9.
    Har ni fler än så får ni hjälpas åt att välja de som bäst beskriver nuvarande konkurrenssituation.`,
  },
  {
    preLine: false,
    text: `Parametrarna ”Pris” och ”Läge” är redan ifyllda då dessa ofta bör finnas med i de flesta projekt.
    Det går dock att redigera även dessa.`,
  },
  {
    preLine: true,
    text: `Sätt Riksbyggens och konkurrenternas nivåer på alla parametrar. \n 1 = lågt konkurrensvärde. \n 10 = högt konkurrensvärde.
    Observera att ett högt pris på bostaden alltså oftast innebär ett lågt konkurrensvärde.`,
  },
  {
    preLine: false,
    text: 'Klicka på Generera diagram (och helskärms-ikonen) för att se ert nuvarande konkurrensdiagram.',
  },
];

import { addGeneralFilesActions } from 'shared/store/files/ducks/reducers/addGeneralFiles';
import { addScheduleFilesActions } from 'shared/store/files/ducks/reducers/addScheduleFiles';
import { deleteFileActions } from 'shared/store/files/ducks/reducers/deleteFile';
import { getFilesActions } from 'shared/store/files/ducks/reducers/getFiles';

export default {
  getFilesActions,
  addGeneralFilesActions,
  addScheduleFilesActions,
  deleteFileActions,
};

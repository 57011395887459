import * as yup from 'yup';

const errorTextToNumber: string = 'Parametervärdet måste ligga i intervallet 1 till 10';

const errorTextToLength = (val: number) => `Maximal parameterlängd ${val} tecken`;

const validationSchema = yup.object().shape({
  oldCompetitionParameters: yup.array(
    yup.object().shape({
      name: yup.string().notRequired().max(50, errorTextToLength(50)),
      description: yup.string().notRequired().max(200, errorTextToLength(200)),
      values: yup
        .array()
        .of(
          yup.number().nullable(true).min(1, errorTextToNumber).max(10, errorTextToNumber).typeError('De uppgifter du angav är felaktiga'),
        ),
    }),
  ),
});

export default validationSchema;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserPayload, UsersState} from 'shared/store/admin/interface';

const initialState: UsersState = {
  isLoading: false,
  users: {
    data: [],
    limit: 0,
    offset: 0,
    total: 0,
  },
  error: {
    message: [],
    stackErrors: '',
  },
};

const getUsers = createSlice({
  name: 'getUsers',
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
      state.error = initialState.error;
    },
    end(state, action: PayloadAction<UserPayload>) {
      state.isLoading = false;
      state.users = action.payload;
    },
    error(state, action: any) {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
    clear() {
      return initialState;
    },
  },
});

export const getUsersActions = getUsers.actions;
export default getUsers.reducer;

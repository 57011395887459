import * as React from 'react';
import {useState} from 'react';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import {Box} from '@mui/system';

import {styles, stylesProps} from './styles';
import LogoutConfirm from 'components/Authorization/Logout';
import {CallbackFunctionModal} from '../HeaderProjectProcess';
import {useCheckPermissions} from '../../hooks/useCheckPermissions';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {Roles} from '../../constants/enum';

interface Props {
  background: string;
  isStartScreen: boolean;
  isTop?: boolean;
}

const HeaderWeb = (props: Props): JSX.Element => {
  const {background, isStartScreen, isTop} = props;
  const [isConfirmLogout, modalConfirmLogout] = useState(false);
  const handleOpen = (callback: CallbackFunctionModal) => callback(true);
  const handleClose = (callback: CallbackFunctionModal) => callback(false);
  const isTransition = location.pathname.includes('transition');
  const isProjectForm = location.pathname.includes('forms') || location.pathname.includes('main-form');
  const role = useCheckPermissions();
  const isShowBackArrow = (): boolean => {
    if (isTransition || isProjectForm) {
      return false;
    }

    return !isStartScreen;
  };
  return (
    <AppBar position="static" sx={stylesProps(background, isTop).header}>
      <Toolbar sx={styles.toolbar} variant="dense">
        <Box>
          { isShowBackArrow() && (
            <IconButton
              edge="start"
              color="inherit"
              size="small"
              disableRipple
              sx={styles.stylesHeaderItems}
              onClick={() => history.go(-1)}
            >
              <ArrowBackIosNewIcon sx={styles.arrowIcon}/>
              <Typography variant="subtitle2" component="p" color="inherit">
                Tillbaka
              </Typography>
            </IconButton>
          )}
        </Box>
        {role !== Roles.MU && (
          <Box sx={styles.wrapperLogo} onClick={() => handleOpen(modalConfirmLogout)}>
            <Box sx={styles.logoutBgc}/>
            <Typography variant="subtitle2" component="p" color="inherit">
              Exit
            </Typography>
          </Box>
        )}
        <LogoutConfirm isOpenLogoutConfirm={isConfirmLogout} handleClose={() => handleClose(modalConfirmLogout)}/>
        {isStartScreen ? null : <Box sx={styles.logoSmall}/>}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderWeb;

import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  ArrowBack: {
    width: '17px',
    height: '20px',
    marginRight: '16px',

    '&.hidden': {
      display: 'none',
    },
  } as SystemCssProperties,

  TimerButton: {
    width: '140px',
    borderRadius: '4px',
    height: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px',
  } as SystemCssProperties,

  TimerButtonLittleTimeLeft: {
    backgroundColor: colors.orange,
  } as SystemCssProperties,

  TimerButtonEnded: {
    backgroundColor: colors.error,
    pointerEvents: 'none',
  } as SystemCssProperties,

  TimerIcon: {
    verticalAlign: 'middle',
    marginRight: '11px',
  } as SystemCssProperties,

  ArrowForward: {
    width: '1em',
    height: '1em',
    marginRight: '12px',
  } as SystemCssProperties,

  LinkIcon: {
    width: '1em',
    height: '1em',
    marginRight: '10px',
  } as SystemCssProperties,

  VideoLibrary: {
    width: '1em',
    height: '1em',
    marginRight: '8px',
  } as SystemCssProperties,

  Dashboard: {
    width: '1em',
    height: '1em',
    marginRight: '11px',
  } as SystemCssProperties,

  LogOut: {
    width: '25px',
    height: '25px',
    marginRight: '11px',
  } as SystemCssProperties,

  AppBar: {
    background: 'none',
    boxShadow: 'none',
    height: '80px',
  } as SystemCssProperties,

  Toolbar: {
    justifyContent: 'space-between',
    padding: {
      md: '0',
    },
  } as SystemCssProperties,
};

import StartScreenBgc from 'assets/images/background-scale.png';
import logo from 'assets/images/logo.png';
import {StylesCSS} from 'shared/interfaces';

export const stylesProps = (): StylesCSS => {
  return {
    root: {
      width: '100%',
      margin: '0 auto',
      minHeight: '100vh',
      height: '100%',
      padding: '0 40px 0 40px',
      position: 'relative',
      background: `url(${StartScreenBgc}) center / cover no-repeat`,
    },
  };
};

export const styles: StylesCSS = {
  wrapperBigLogo: {
    width: '100%',
    maxWidth: '282px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  bigLogo: {
    width: '224px',
    height: '203px',
    background: `url(${logo}) no-repeat`,
  },
};

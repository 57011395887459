import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTypedSelector} from 'shared/hooks/useTypedSelector';
import {actions} from 'shared/store/archive';
import Loader from 'shared/components/Loader';
import {useDispatch} from 'react-redux';
import DeleteModal from 'components/WebTools/Archive/TableArchive/DeleteModal';
import CustomTable from 'shared/components/Table';
import {TableHeaderArchive} from 'shared/constants/enum';

const TableArchive = (): JSX.Element => {
  const dispatch = useDispatch();
  const {archives, isLoading} = useTypedSelector((state) => state.archives.getArchive);
  const {isLoading: isLoadingCopy} = useTypedSelector((state) => state.archives.copyArchive);
  const [isOpenPopup, setOpenPopup] = useState<boolean>(false);
  const [idRow, setIdRow] = useState<number | string>(0);

  const closePopup = () => setOpenPopup(false);
  const openPopup = () => setOpenPopup(true);

  const deleteProject = () => {
    dispatch(actions.deleteArchiveActions.start({id: idRow}));
    closePopup();
  };

  useEffect(() => {
    dispatch(actions.getArchiveActions.start());
  }, []);

  if (isLoading || isLoadingCopy) {
    return <Loader/>;
  }

  return (
    <>
      <CustomTable
        header={TableHeaderArchive}
        onHandleOpenPopup={openPopup}
        rows={archives.data}
        setIdRow={setIdRow}/>
      <DeleteModal closePopup={closePopup} isOpenPopup={isOpenPopup} deleteProject={deleteProject}/>
    </>
  );
};

export default TableArchive;

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import MuiField from 'shared/components/UI/MuiField';
import MuiButton from 'shared/components/UI/MuiButton';
import { validationSchemaEng, validationSchemaSwedish } from './validationSchema';
import { useDispatch } from 'react-redux';
import { actions } from 'shared/store/auth';
import { useHistory } from 'react-router-dom';
import ErrorMessage from 'components/Authorization/ErrorMessage';
import { styles } from './styles';
import queryString from 'query-string';
import { RoutesPage } from 'shared/routes';

const language = {
  eng: {
    title: 'New password',
    placeholder: {
      pass: 'New password',
      confirm: 'Repeat password',
    },
    body: 'Please, create a new password',
    btn: 'Confirm',
  },
  sverige: {
    title: 'Nytt lösenord',
    placeholder: {
      pass: 'Nytt lösenord',
      confirm: 'Repetera lösenord',
    },
    body: 'Vänligen skapa ett nytt lösenord',
    btn: 'Bekräfta',
  },
};

const NewPassword = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAdminNewPassword = history.location.pathname.includes(RoutesPage.admin);
  const currentLang = isAdminNewPassword ? language.eng : language.sverige;

  return (
    <>
      <Typography sx={styles.typoHeader} variant="subtitle1" component="p">
        {currentLang.title}
      </Typography>
      <Typography sx={styles.typoTxt} variant="subtitle1" component="p">
        {currentLang.body}
      </Typography>
      <Formik
        initialValues={{
          confirmPassword: '',
          password: '',
        }}
        validationSchema={isAdminNewPassword ? validationSchemaEng : validationSchemaSwedish}
        onSubmit={(values) => {
          const token = queryString.parse(history.location.search).token as string;
          const body = { password: values.password, history, token };
          dispatch(actions.updatePasswordActions.start(body));
        }}
      >
        {(formik) => (
          <Box component="form" onSubmit={formik.handleSubmit} sx={styles.fields}>
            <Box sx={styles.styleFiled}>
              <MuiField
                id="password"
                name="password"
                maxLength={32}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                placeholder={currentLang.placeholder.pass}
                isDisabledIcon={true}
                error={formik.touched.password && Boolean(formik.errors.password)}/>
              <ErrorMessage
                isError={formik.touched.password && Boolean(formik.errors.password)}
                message={formik.touched.password && formik.errors.password}/>
            </Box>
            <Box sx={styles.styleFiled}>
              <MuiField
                id="confirmPassword"
                name="confirmPassword"
                maxLength={32}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                placeholder={currentLang.placeholder.confirm}
                isDisabledIcon={true}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}/>
              <ErrorMessage
                isError={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                message={formik.touched.confirmPassword && formik.errors.confirmPassword}/>
            </Box>
            <Box sx={styles.btn}>
              <MuiButton variant="contained" type="submit">
                <Typography sx={styles.btnTxt} variant="subtitle2" component="p">
                  {currentLang.btn}
                </Typography>
              </MuiButton>
            </Box>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default NewPassword;

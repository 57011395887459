import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  select: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      background: colors.white,
      color: colors.accentBlue,
      padding: 0,
      '&.Mui-focused fieldset': {
        borderColor: colors.focusFiled,
      },
      '& .MuiOutlinedInput-input': {
        padding: '12.5px',
      },
      '& .MuiSvgIcon-root': {
        color: colors.accentBlue,
      },
    },
  } as SystemCssProperties,

  placeholderStyle: {
    color: '#9BA1AB',
    textAlign: 'left',
    lineHeight: 'inherit',
  } as SystemCssProperties,
};

import { createSlice } from '@reduxjs/toolkit';
import { RequestUser } from 'shared/store/auth/interface';
import { StateInterface } from 'shared/interfaces';

const initialState: StateInterface = {
  isLoading: false,
  error: {
    message: [],
    stackErrors: '',
  },
};

const resetPassword = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    start: {
      reducer(state) {
        state.isLoading = true;
      },
      prepare(payload: Pick<RequestUser, 'history' | 'email'>) {
        return { payload };
      },
    },

    end(state) {
      state.isLoading = false;
    },
    error(state, action: any) {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
    reset() {
      return initialState;
    },
  },
});

export const resetPasswordActions = resetPassword.actions;
export default resetPassword.reducer;

import * as React from 'react';
import {Dispatch, SetStateAction, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import Row from '../TableVideos/Row';
import {TableHeaderVideos} from 'shared/constants/enum';
import VideoCollapse from '../TableVideos/VideoCollapse';
import {StepVideo} from 'shared/store/ListVideos/interface';
import {styles} from './styles';

export interface Props {
  header: typeof TableHeaderVideos;
  rows: StepVideo[];
  setIdRow: Dispatch<SetStateAction<string | number>>;
  isDeleteIcon: boolean;
}

const CustomTable = (props: Props): JSX.Element => {
  const {header, rows, setIdRow, isDeleteIcon} = props;
  const tableHeader: string[] = Object.keys(header).filter((x) => !(parseInt(x) >= 0));

  const [isOpenCollapseOnId, setIsOpenCollapseOnId] = useState<number | boolean>(0);

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <Table>
        <TableHead sx={styles.tableHead}>
          <TableRow sx={styles.tableRow}>
            {tableHeader.map((headerItem: string) => (
              <TableCell key={headerItem} sx={styles.td}>{headerItem}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={styles.tableBody}>
          {rows.map((row: StepVideo) => {
            return (
              <Row
                key={row.id}
                row={row}
                isOpenCollapseOnId={isOpenCollapseOnId}
                setIsOpenCollapseOnId={setIsOpenCollapseOnId}
                isDeleteIcon={isDeleteIcon}
                setIdRow={setIdRow}
                renderCollapse={() => (
                  <VideoCollapse
                    row={row}
                    isOpenCollapseOnId={isOpenCollapseOnId}
                    setIsOpenCollapseOnId={setIsOpenCollapseOnId}
                    setIdRow={setIdRow}/>
                )}/>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;

import React, {Dispatch, memo, SetStateAction} from 'react';
import {Collapse, TableRow} from '@mui/material';
import {stylesRow} from '../../Table/styles';
import {StepVideo} from 'shared/store/ListVideos/interface';
import RowVideo from '../VideoCollapse/RowVideo';

interface Props {
  row: StepVideo;
  renderCollapse?: any;
  setIdRow: Dispatch<SetStateAction<string | number>>;
  setIsOpenCollapseOnId: Dispatch<SetStateAction<number | boolean>>;
  isOpenCollapseOnId: number | boolean;
  isDeleteIcon: boolean;
}

const Row = (props: Props): JSX.Element => {
  const {row, renderCollapse, setIdRow, isOpenCollapseOnId, setIsOpenCollapseOnId} = props;

  const handleRow = (ev) => {
    ev.stopPropagation();
    if (renderCollapse) {
      setIdRow(row.id);
      setIsOpenCollapseOnId(row.id);

      if (row.id === isOpenCollapseOnId) {
        setIsOpenCollapseOnId(0);
        setIdRow(0);
      }
    }
  };

  return (
    <React.Fragment>
      {isOpenCollapseOnId !== row.id ? (
        <TableRow sx={stylesRow(isOpenCollapseOnId, row.id).tableBodyRow} onClick={handleRow}>
          <RowVideo row={row}/>
        </TableRow>
      ) : (
        <TableRow>
          <Collapse in={isOpenCollapseOnId === row.id} timeout={600}>
            {renderCollapse && renderCollapse()}
          </Collapse>
        </TableRow>
      )}
    </React.Fragment>
  );
};
export default memo(Row);

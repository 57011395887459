import * as React from 'react';

const SimilarProject = (): JSX.Element => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.6777 24.3218H20.8921C20.1249 24.3218 19.3891 24.6266 18.8466 25.1691C18.304 25.7116 17.9993 26.4474 17.9993
            27.2146V29.1426H19.9273V27.2146C19.9273 26.9587 20.029 26.7133 20.2099 26.5324C20.3908 26.3515 20.6362 26.2498 20.8921
            26.2498H26.6777C26.9336 26.2498 27.1789 26.3515 27.3599 26.5324C27.5408 26.7133 27.6424 26.9587 27.6424
            27.2146V29.1426H29.5705V27.2146C29.5705 26.4474 29.2657 25.7116 28.7232 25.1691C28.1807 24.6266 27.4449 24.3218
            26.6777 24.3218Z"
        fill="#40DBE4"/>
      <path
        d="M19.9288 19.5006C19.9288 20.2636 20.1551 21.0094 20.5789 21.6438C21.0028 22.2781 21.6053 22.7726 22.3102
            23.0645C23.015 23.3565 23.7907 23.4329 24.539 23.2841C25.2873 23.1352 25.9746 22.7678 26.5141 22.2283C27.0536
            21.6888 27.421 21.0015 27.5698 20.2532C27.7187 19.5049 27.6423 18.7293 27.3503 18.0244C27.0583 17.3195 26.5639
            16.7171 25.9295 16.2932C25.2952 15.8693 24.5493 15.6431 23.7864 15.6431C22.7633 15.6431 21.7821 16.0495 21.0587
            16.7729C20.3353 17.4964 19.9288 18.4775 19.9288 19.5006V19.5006ZM25.7144 19.5006C25.7144 19.882 25.6014 20.2547
            25.3895 20.5718C25.1776 20.8889 24.8765 21.136 24.5242 21.2819C24.1719 21.4278 23.7842 21.466 23.4102 21.3916C23.0362
            21.3172 22.6927 21.1336 22.423 20.864C22.1534 20.5943 21.9698 20.2508 21.8954 19.8768C21.821 19.5028 21.8592 19.1151
            22.0051 18.7628C22.151 18.4105 22.3982 18.1094 22.7152 17.8975C23.0323 17.6856 23.4051 17.5726 23.7864 17.5726C24.2977 17.5726
            24.7881 17.7757 25.1497 18.1373C25.5113 18.4989 25.7144 18.9893 25.7144 19.5006Z"
        fill="#40DBE4"/>
      <path
        d="M12.215 20.069L9.71704 17.571L8.35742 18.9306L12.215 22.7882L18.9653 16.0378L17.6057 14.6782L12.215 20.069Z"
        fill="#40DBE4"/>
      <path
        d="M16.0712 27.2144H6.42805V7.92806H13.3448L16.6368 11.2201L17.2023 11.7856H29.5719V19.4993H31.5V11.7856C31.5 11.2743
            31.2969 10.7839 30.9353 10.4223C30.5737 10.0607 30.0833 9.85756 29.5719 9.85756H18.0007L14.7073 6.56554C14.346 6.20386
            13.8559 6.00044 13.3448 6H6.42805C5.9167 6 5.42629 6.20313 5.06471 6.56471C4.70313 6.92629 4.5 7.4167 4.5 7.92806V27.2144C4.5
            27.7257 4.70313 28.2161 5.06471 28.5777C5.42629 28.9393 5.9167 29.1424 6.42805 29.1424H16.0712V27.2144Z"
        fill="#40DBE4"/>
    </svg>
  );
};

export default SimilarProject;

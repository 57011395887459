import { ProjectWithoutFiles } from 'shared/store/project/interface';
import { filteredEmptyStepTwoDataFromTable } from 'shared/helpers';

export const setWidthTd = (keyRow: string) => {
  let widthTd;
  if (keyRow === 'name') {
    widthTd = '291px';
  } else if (keyRow === 'description') {
    widthTd = '337px';
  } else {
    widthTd = '170px';
  }
  return widthTd;
};

export const updatedTable = (values, currentData) => {
  const filteredValues = filteredEmptyStepTwoDataFromTable(values.oldCompetitionParameters);
  const newData: ProjectWithoutFiles = { ...currentData };
  delete newData.files;
  return {
    ...newData,
    oldCompetitionParameters: [...filteredValues],
  };
};

export const getMaxLengthByRow = (keyRow: string): number => {
  if (keyRow === 'name') {
    return 25;
  }

  if (keyRow === 'description') {
    return 200;
  }

  if (keyRow === 'values') {
    return 2;
  }

  return 100;
};

import * as yup from 'yup';

export const validationSchema = yup.object({
  email: yup
    .string()
    .email('Använd formatet email@example.com')
    .required('E-post är ett obligatoriskt fält')
    .min(3, 'E-post eller lösenordet du angav är felaktigt')
    .max(128, 'E-post eller lösenordet du angav är felaktigt'),

  firstName: yup.string().required('Förnamn är ett obligatoriskt fält'),

  lastName: yup.string().required('Efternamn är ett obligatoriskt fält'),

  password: yup.string().required('Lösenord är ett obligatoriskt fält').min(8, ' '),

  confirmPassword: yup
    .string()
    .required('Upprepa lösenord är ett obligatoriskt fält')
    .oneOf([yup.ref('password'), null], 'Upprepa lösenord och lösenord stämmer inte överens'),
});

import React from 'react';
import color from 'styles/_Variables';

const InstructionTreeIcon = ({width = 96, height = 96, background = color.green}): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 96 96" fill="none">
      <circle cx="48" cy="48" r="46" fill={background} stroke="white" strokeWidth="4" />
      <path
        d="M69.9419 42.2757C69.664 42.7532 68.3095 43.361 65.939 43.9341C65.6004 42.6762 65.1319 41.4569 64.541 40.2959C68.431
           41.06 69.7508 41.9023 69.9419 42.2583V42.2757ZM67.0591 56.238C67.0502 56.2868 67.0502 56.3368 67.0591 56.3856C67.0542 56.4085
           67.0542 56.4321 67.0591 56.455C66.7986 57.6099 60.0346 59.772 47.9565 59.772C35.8784 59.772 29.149 57.6099 28.8972
           56.4637C28.9015
            56.4117 28.9015 56.3594 28.8972 56.3074C28.9013 56.2903 28.9013 56.2724 28.8972 56.2553L26.5615 44.9674C28.1675 45.5952 29.8352
             46.0522 31.5369 46.3306C33.4124 46.6866 35.5658 46.9645 37.8842 47.1989H38.0231C41.0969 47.4768 44.4746 47.6244 47.9738
              47.6244C51.4731 47.6244 54.8508 47.4768 57.9333 47.1989H58.0809C60.3992 46.9905 62.544 46.7127 64.4282 46.3306C66.1299
               46.0522 67.7975 45.5952 69.4035 44.9674L67.0591 56.238ZM25.9971 42.2757C26.2142 41.9283 27.5166 41.0774 31.4066
                40.3133C30.8158 41.4743 30.3473 42.6936 30.0087 43.9515C27.6382 43.3784 26.275 42.7706 26.0058 42.293L25.9971
                 42.2757ZM37.4066 36.9269C39.4139 36.0797 41.6348 35.8764 43.7626 36.3452C41.3053 37.3524 38.4659 39.6968 37.2243
                  45.089C35.2185 44.8893 33.4732 44.6375 32.0144 44.3596C33.0738 40.6259 34.8885 38.1165 37.4326 36.9269H37.4066ZM47.8523
                   37.6303H48.1128C48.3906 37.6303 54.6511 36.9269 56.7176 45.2713C54.1562 45.471 51.2387 45.5926 47.9825 45.5926C44.7264
                    45.5926 41.8176 45.471 39.2474 45.2713C41.314 36.9443 47.557 37.5868 47.8523 37.6303ZM58.5497 36.9269C61.0939 38.1078
                     62.8913 40.6172 63.9766 44.3596C62.4918 44.6375 60.7466 44.8893 58.7668 45.089C57.5165 39.6881 54.6771 37.3437 52.2112
                     36.3452C54.3416 35.8729 56.5663 36.0762 58.5758 36.9269H58.5497ZM63.091 38.0123C62.1067 36.7731 60.8498 35.7777 59.418
                      35.1035C57.0551 34.0841 54.4347 33.8202 51.9159 34.348C49.3317 33.884 46.6855 33.884 44.1012 34.348C41.5796 33.8201
                       38.9564 34.0839 36.5904 35.1035C35.1587 35.7777 33.9018 36.7731 32.9175 38.0123C24.9465 39.2713 24 41.0427 24
                        42.2757C24.0002 42.5096 24.0414 42.7418 24.1216 42.9616L26.9522 56.6634C27.369 60.701 40.3067 61.7864 47.9999
                         61.7864C55.693 61.7864 68.6394 60.701 69.0562 56.6634L71.8782 42.9616C71.9624 42.7429 72.0037 42.51 71.9997
                         42.2757C71.9997 41.0427 71.0533 39.2713 63.0823 38.0123"
        fill="white"/>
      <path
        d="M50.2576 55.7602C49.8172 56.194 49.2635 56.4949 48.66 56.6285V54.1278H49.9711C50.1106 54.1408 50.2514 54.1246 50.3843
           54.0801C50.5172 54.0356 50.6393 53.9639 50.7429 53.8695C50.8465 53.7751 50.9293 53.6601 50.9859 53.5319C51.0425 53.4037 51.0718
            53.2651 51.0718 53.1249C51.0718 52.9848 51.0425 52.8462 50.9859 52.718C50.9293 52.5898 50.8465 52.4748 50.7429 52.3804C50.6393
             52.286 50.5172 52.2143 50.3843 52.1698C50.2514 52.1253 50.1106 52.109 49.9711 52.122H48.66V50.5157C48.673 50.3762 48.6567
              50.2354 48.6122 50.1025C48.5677 49.9696 48.496 49.8475 48.4016 49.7439C48.3072 49.6403 48.1922 49.5575 48.064 49.5009C47.9358
               49.4443 47.7972 49.415 47.6571 49.415C47.5169 49.415 47.3783 49.4443 47.2501 49.5009C47.1219 49.5575 47.007 49.6403 46.9126
                49.7439C46.8182 49.8475 46.7464 49.9696 46.702 50.1025C46.6575 50.2354 46.6412 50.3762 46.6542
                50.5157V52.122H45.3431C45.2035
                 52.109 45.0628 52.1253 44.9299 52.1698C44.797 52.2143 44.6748 52.286 44.5712 52.3804C44.4676 52.4748 44.3849 52.5898
                 44.3283
                  52.718C44.2716 52.8462 44.2424 52.9848 44.2424 53.1249C44.2424 53.2651 44.2716 53.4037 44.3283 53.5319C44.3849 53.6601
                   44.4676 53.7751 44.5712 53.8695C44.6748 53.9639 44.797 54.0356 44.9299 54.0801C45.0628 54.1246 45.2035 54.1408 45.3431
                    54.1278H46.6542V56.6285C46.0507 56.4949 45.4969 56.194 45.0565 55.7602C44.9691 55.6524 44.8604 55.5637 44.7372
                     55.4999C44.6139 55.436 44.4788 55.3984 44.3403 55.3892C44.2018 55.38 44.0629 55.3996 43.9323 55.4466C43.8017 55.4937
                      43.6823 55.5673 43.5815 55.6627C43.4807 55.7581 43.4007 55.8733 43.3465 56.0011C43.2923 56.1289 43.2651 56.2665
                       43.2667 56.4053C43.2682 56.5441 43.2984 56.6811 43.3554 56.8077C43.4124 56.9342 43.4949 57.0477 43.5978
                        57.1408C44.1275 57.6714 44.759 58.0896 45.4543 58.3701C46.1496 58.6506 46.8944 58.7877 47.6441 58.7732C48.3937
                         58.7877 49.1385 58.6506 49.8338 58.3701C50.5291 58.0896 51.1606 57.6714 51.6903 57.1408C51.8713 56.9452 51.9682
                          56.6863 51.9601 56.4199C51.9519 56.1536 51.8395 55.9011 51.6469 55.7168C51.5542 55.6236 51.4439 55.5497 51.3224
                           55.4992C51.201 55.4488 51.0708 55.4228 50.9393 55.4228C50.8078 55.4228 50.6775 55.4488 50.5561 55.4992C50.4347
                            55.5497 50.3244 55.6236 50.2316 55.7168"
        fill="white"/>
    </svg>
  );
};

export default InstructionTreeIcon;

import React, { useState } from 'react';
import { Box } from '@mui/system';
import { StepVideo } from 'shared/store/project/interface';
import { styles, stylesProps } from './styles';
import { Typography } from '@mui/material';
import colors from 'styles/_Variables';
import { Explore } from 'shared/constants/enum';
import MuiButton from 'shared/components/UI/MuiButton';
import { Link, useParams } from 'react-router-dom';
import VideoModal from '../AdditionalInfoContainer/VideoModal';
import { RoutesPage } from 'shared/routes';
import { ActiveVideo } from '../ExerciseInfo';
import { instructions } from './instructions';

interface Props {
  videos: StepVideo[];
}

const ExploreInfo = (props: Props): JSX.Element => {
  const { videos } = props;
  const id = useParams<{ id: string }>().id ?? localStorage.getItem('projectId');
  const previousUrl = `${RoutesPage.project.page}${id}${RoutesPage.steps.step3}${RoutesPage.steps.instruction}${location.search}`;
  const [isShowVideo, setShowVideo] = useState<boolean>(false);
  const [activeVideo, setActiveVideo] = useState<ActiveVideo | null>(null);

  const btnClick = (video: StepVideo, videoHeader: string): void => {
    const videoItem: ActiveVideo = {
      ...video,
      videoHeader,
    };

    openVideo(videoItem);
  };

  const closeVideo = (): void => {
    setShowVideo(false);
    setActiveVideo(null);
  };
  const openVideo = (videoItem: ActiveVideo): void => {
    setShowVideo(true);
    setActiveVideo(videoItem);
  };

  return (
    <Box sx={styles.additionalInfoContainer}>
      <Box sx={styles.additionalInfoLeft}>
        <Box sx={styles.instructions}>
          <Typography variant="subtitle2" component="p" color={colors.white}>
            Här kan ni träna er i att tänka kreativt i en annan bransch.
          </Typography>

          {
            instructions.map((instruction, index) =>
                <Typography variant="subtitle2" component="p" color={colors.white}>{ index + 1 }. { instruction }</Typography>)
          }

          <Typography variant="subtitle1" component="p" sx={ styles.stepTime }>
            Tidsåtgång: ca 45 minuter
          </Typography>
        </Box>

        <Box sx={styles.buttons}>
          {
            Object.values(Explore).map((buttonName, index) =>
              <MuiButton
                type="button"
                variant="contained"
                onClick={() => btnClick(videos[index], buttonName)}
                sx={{...stylesProps(true).additionalInfoLeftBtnItem}}
              >
                {buttonName}
              </MuiButton>)
          }
        </Box>

        <MuiButton type="button" variant="contained" sx={styles.NavLink}>
          <Link to={previousUrl}>Tillbaka till Instruktion</Link>
        </MuiButton>
      </Box>

      <Box sx={styles.additionalInfoRight}>
        {isShowVideo && <VideoModal handleClose={() => closeVideo()} videoInfo={activeVideo} isPlaying={true} />}
      </Box>
    </Box>
  );
};

export default ExploreInfo;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArchivePayload, ArchiveState } from 'shared/store/archive/interface';

const initialState: ArchiveState = {
  isLoading: false,
  archives: {
    data: [],
    limit: 0,
    offset: 0,
    total: 0,
  },
  error: {
    message: [],
    stackErrors: '',
  },
};

const getArchive = createSlice({
  name: 'getArchive',
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true;
      state.error = initialState.error;
    },
    end(state, action: PayloadAction<ArchivePayload>) {
      state.isLoading = false;
      state.archives = action.payload;
    },
    error(state, action: any) {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.stackErrors = action.payload.stackErrors;
    },
    clear() {
      return initialState;
    },
  },
});

export const getArchiveActions = getArchive.actions;
export default getArchive.reducer;

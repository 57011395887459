import React from 'react';
import Instruction from 'components/WebTools/ProjectProcess/Common/Instruction';
import {Box} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_3/AdditionalInfoContainer/styles';
import Loader from 'shared/components/Loader';
import {Options} from 'shared/constants/enum';
import {TitleAndSubTitle} from 'components/WebTools/ProjectProcess/Common/TittleAndSubTitile';
import {useGetProject} from 'shared/hooks/useGetProjects';
import ExerciseInfo from '../ExerciseInfo';
import ExploreInfo from '../ExploreInfo';
import { StepVideo } from 'shared/store/project/interface';
import InstructionTreeIcon from 'shared/components/Icons/Instructions/InstructionTree';

const videoSplit = (objVideos): [StepVideo[], StepVideo[]] => {
  const explore: StepVideo[] = [];
  const exercise: StepVideo[] = [];

  Object.keys(objVideos).forEach((item: string) => {
    if (item.includes(Options.STEP3EXERCISE)) {
      exercise.push(objVideos[item]);
    }
    if (item.includes(Options.STEP3EXPLORE)) {
      explore.push(objVideos[item]);
    }
  });
  return [explore, exercise];
};

enum InstructionBtnText {
  EXPLORE = 'Övning i annan bransch',
  EXERCISES = 'Tankeverktyg',
}

const AdditionalInfoContainer = (): JSX.Element => {
  const location = useLocation();
  const { projectData } = useGetProject();
  const pathUrl = location.pathname.split('/').find((item) => item === 'Exercise' || item === 'Explore');

  if (projectData === null) {
    return (
      <Box sx={styles.Loader}>
        <Loader />
      </Box>
    );
  }

  const instruction = projectData.data.stepsNotes['3'];
  const [videoExplore, videoExercise] = videoSplit(projectData.config.videos);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.Title}>
        {
          pathUrl === 'Explore'
            ? <Box sx={styles.header}>
                <InstructionTreeIcon width={48} height={48} />
                <TitleAndSubTitle Title={() => <>{InstructionBtnText.EXPLORE}</>} />
              </Box>
            : <Box>
                <TitleAndSubTitle Title={() => <>{InstructionBtnText.EXERCISES}</>} />
              </Box>
        }

        <Instruction stepNote={instruction} data={projectData.data} noteKey={3} />
      </Box>
      {
        pathUrl === 'Exercise' ? <ExerciseInfo videos={videoExplore} /> : <ExploreInfo videos={videoExercise} />
      }
    </Box>
  );
};

export default AdditionalInfoContainer;

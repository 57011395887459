import * as React from 'react';
import queryString from 'query-string';
import {Box, Typography} from '@mui/material';
import {Formik} from 'formik';
import MuiField from 'shared/components/UI/MuiField';
import MuiButton from 'shared/components/UI/MuiButton';
import {validationSchema} from './validationSchema';
import {useDispatch} from 'react-redux';
import {actions} from 'shared/store/auth';
import {useHistory} from 'react-router-dom';
import ErrorMessage from 'components/Authorization/ErrorMessage';
import {styles} from './styles';

const Registration = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const email = queryString.parse(history.location.search).email as string;

  return (
    <>
      <Typography sx={styles.typoHeader} variant="subtitle1" component="p">
        Registrering
      </Typography>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email,
          password: '',
          confirmPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const {confirmPassword, ...userData} = values;
          const token = queryString.parse(history.location.search).token;
          const body = {...userData, history, token};
          dispatch(actions.registrationActions.start(body));
        }}
      >
        {(formik) => (
          <Box component="form" onSubmit={formik.handleSubmit} sx={styles.fields}>
            <Box sx={styles.styleFiled}>
              <MuiField
                id="firstName"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                maxLength={25}
                placeholder="Förnamn"
                isDisabledIcon={true}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}/>
              <ErrorMessage
                isError={formik.touched.firstName && Boolean(formik.errors.firstName)}
                message={formik.touched.firstName && formik.errors.firstName}/>
            </Box>
            <Box sx={styles.styleFiled}>
              <MuiField
                id="lastName"
                name="lastName"
                maxLength={25}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                placeholder="Efternamn"
                isDisabledIcon={true}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}/>
              <ErrorMessage
                isError={formik.touched.lastName && Boolean(formik.errors.lastName)}
                message={formik.touched.lastName && formik.errors.lastName}/>
            </Box>
            <Box sx={styles.styleFiled}>
              <MuiField
                id="email"
                name="email"
                className="email"
                value={email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={styles.disabledField}
                disabled
                type="text"
                placeholder="mail@email.com"
                isDisabledIcon={true}
                error={formik.touched.email && Boolean(formik.errors.email)}/>
              <ErrorMessage
                isError={formik.touched.email && Boolean(formik.errors.email)}
                message={formik.touched.email && formik.errors.email}/>
            </Box>
            <Box sx={styles.styleFiled}>
              <MuiField
                id="password"
                name="password"
                maxLength={32}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                placeholder="Lösenord"
                isDisabledIcon={true}
                error={formik.touched.password && Boolean(formik.errors.password)}/>
              <ErrorMessage
                isError={formik.touched.password && Boolean(formik.errors.password)}
                message={formik.touched.password && formik.errors.password}/>
            </Box>
            <Box sx={styles.styleFiled}>
              <MuiField
                id="confirmPassword"
                name="confirmPassword"
                maxLength={32}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                placeholder="Bekräfta lösenord"
                isDisabledIcon={true}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}/>
              <ErrorMessage
                isError={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                message={formik.touched.confirmPassword && formik.errors.confirmPassword}/>
            </Box>
            <Box sx={styles.btn}>
              <MuiButton variant="contained" type="submit">
                <Typography sx={styles.btnTxt} variant="subtitle2" component="p">
                  Registrering
                </Typography>
              </MuiButton>
            </Box>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default Registration;

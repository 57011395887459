import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  wrapperDialog: {
    '& > .MuiPaper-root': {
      margin: '0',
    },
  } as SystemCssProperties,

  dialog: {
    maxWidth: '369px',
    margin: '0 auto',
    borderRadius: '8px',
    '.MuiPaper-root': {
      margin: '0',
      borderRadius: '8px',
    },
  } as SystemCssProperties,

  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    '& > p': {
      fontSize: '18px',
      color: colors.accentBlue,
      fontWeight: '500',
    },
  } as SystemCssProperties,

  dialogContentText: {
    '& > p': {
      fontSize: '16px',
      color: colors.accentBlue,
      fontWeight: '400',
    },
  } as SystemCssProperties,

  dialogActions: {
    padding: '24px',
  } as SystemCssProperties,

  dialogFirstButtonAction: {
    maxWidth: '112px',
    textTransform: 'capitalize',
    background: colors.white,
    color: colors.accentBlue,
    '&:hover': {
      background: colors.white,
      boxShadow: 'none',
    },
  } as SystemCssProperties,

  dialogSecondButtonAction: {
    maxWidth: '112px',
    textTransform: 'capitalize',
  } as SystemCssProperties,

  closeIcon: {
    cursor: 'pointer',
  } as SystemCssProperties,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionAdd, SnackbarStack } from 'shared/store/snackbar/interface';
import { v4 as uuidv4 } from 'uuid';

const initialState: SnackbarStack = {
  snackErrors: [],
  isSnackbar: false,
};

const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    add(state, action: PayloadAction<ActionAdd>) {
      state.snackErrors = [...state.snackErrors, { id: uuidv4(), ...action.payload }];
      state.isSnackbar = true;
    },
    setSnackbar(state, action: PayloadAction<boolean>) {
      state.isSnackbar = action.payload;
      state.snackErrors = initialState.snackErrors;
    },
  },
});

export const snackbarActions = snackbar.actions;
export default snackbar.reducer;

import * as React from 'react';
import {TableCell, TableRow, Typography} from '@mui/material';
import {styles} from 'components/WebTools/ProjectProcess/Steps/Step_5/Table/header/styles';

interface Props {
  dataHeader: { name: string }[];
}

const TableHeader = (props: Props): JSX.Element => {
  const { dataHeader } = props;

  return (
    <TableRow sx={styles.clearBorder}>
      {dataHeader &&
        dataHeader.map((column: any, index: number) => (
          <TableCell
            sx={{ ...styles.tableCellHeader, ...column.name === 'Riksbyggens parametrar' && styles.tableCellWithShadow }}
            key={index}
          >
            <Typography sx={styles.tableCellHeader}>{column.name}</Typography>
          </TableCell>
        ))}
    </TableRow>
  );
};

export default TableHeader;

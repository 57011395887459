import React, {Dispatch, memo, SetStateAction} from 'react';
import moment from 'moment';
import {Collapse, TableCell, TableRow} from '@mui/material';
import {TableBody} from 'shared/constants/enum';
import {Archive} from 'shared/store/archive/interface';

import {styles as stylesTable, stylesRow} from 'shared/components/Table/styles';

interface Props {
  row: Archive;
  renderCollapse?: any;
  setIdRow: Dispatch<SetStateAction<string | number>>;
  setIsOpenCollapseOnId: Dispatch<SetStateAction<number | boolean>>;
  isOpenCollapseOnId: number | boolean;
}

const excludeKeys = ['id', 'pdfUrl', 'sharingLink', 'currentStep', 'reportUrl'];

const Row = (props: Props): JSX.Element => {
  const {row, renderCollapse, setIdRow, isOpenCollapseOnId, setIsOpenCollapseOnId} = props;
  const keyRows = Object.keys(row).filter((rowKey: string) => !excludeKeys.includes(rowKey));

  const handleRow = (ev) => {
    ev.stopPropagation();
    if (renderCollapse) {
      setIdRow(row.id);
      setIsOpenCollapseOnId(row.id);

      if (row.id === isOpenCollapseOnId) {
        setIsOpenCollapseOnId(0);
        setIdRow(0);
      }
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={stylesRow(isOpenCollapseOnId, row.id).tableBodyRow} onClick={handleRow}>
        {keyRows.map((rowKey: string, index: number) => (
          <TableCell key={index} sx={stylesTable.td}>
            {rowKey === TableBody.Date ? moment(row[rowKey]).format('YYYY-MM-DD') : row[rowKey]}
          </TableCell>
        ))}
      </TableRow>
      {renderCollapse ?
        <TableRow sx={{ display: 'flex' }}>
          <Collapse sx={{ width: '100%' }} in={isOpenCollapseOnId === row.id}>{renderCollapse && renderCollapse()}</Collapse>
        </TableRow> : null}
    </React.Fragment>
  );
};

export default memo(Row);

import StartScreenBgc from 'assets/images/background-scale.png';
import backgroundBox from 'assets/images/background.png';
import dotsBgc from 'assets/images/dots-bgc.svg';
import logo from 'assets/images/logo.png';
import {StylesCSS} from 'shared/interfaces';

export const stylesProps = (isStartScreen: boolean): StylesCSS => {
  return {
    root: {
      width: '100%',
      margin: '0 auto',
      height: '100vh',
      padding: '0 0 0 40px',
      background: `url(${isStartScreen ? StartScreenBgc : backgroundBox}) center / cover no-repeat`,
    },
    dots: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100vh',
      background: `url(${isStartScreen ? dotsBgc : backgroundBox}) no-repeat  center`,
    },
  };
};

export const styles: StylesCSS = {
  wrapperBigLogo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '282px',
  },
  rootDots: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  },
  bigLogo: {
    width: '224px',
    height: '203px',
    background: `url(${logo}) no-repeat`,
  },
};

import * as React from 'react';
import {Box} from '@mui/system';
import {styles, stylesProps} from './styles';

import DeleteIcon from '@mui/icons-material/Delete';
import {Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import {Competitors, OldCompetitionParameters} from 'shared/store/project/interface';
import FormikField from 'shared/components/UI/FormikField';
import {useFormikContext} from 'formik';
import {validateName} from 'components/WebTools/MainForm/ProjectForm/Forms/CompetitorsLocal/helpers';
import {useCheckPermissions} from 'shared/hooks/useCheckPermissions';
import {Roles} from 'shared/constants/enum';

const CompetitorsLocal = (): JSX.Element => {
  const {values, touched, errors, handleChange, handleBlur, setFieldValue} = useFormikContext<any>();
  const stateValues: Competitors[] = values.competitors;
  const removeItem = (indexForm: number) => {
    const filteredItemsForm = stateValues.filter((el: Competitors, idx: number) => idx !== indexForm);
    const result = values.oldCompetitionParameters.map((item: OldCompetitionParameters) => {
      return {...item, values: item.values.filter((elem, index) => indexForm !== index)};
    });
    setFieldValue('competitors', filteredItemsForm);
    setFieldValue('oldCompetitionParameters', result);
  };

  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  return (
    <Box sx={styles.competitorsWrapper}>
      {stateValues?.map((item: Competitors, index: number) => {
        return (
          <Box key={index} sx={styles.generalBox}>
            <Box sx={styles.fieldWrapper}>
              <FormikField
                type="text"
                maxLength={100}
                disabled={index === 0}
                name={`competitors[${index}].name`}
                value={index !== 0 ? item.name : ''}
                placeholder={index === 0 ? 'Riksbyggen' : 'Namn'}
                onBlur={handleBlur}
                onChange={handleChange}
                validate={validateName}
                error={touched.competitors?.[index]?.name && !!errors.competitors?.[index]?.name}/>
            </Box>

            <Box sx={styles.fieldWrapper}>
              <FormikField
                type="text"
                maxLength={100}
                name={`competitors[${index}].doings`}
                placeholder="Projektnamn"
                value={item.doings}
                onChange={handleChange}/>
            </Box>

            <Box sx={styles.fieldWrapper}>
              <FormikField
                type="text"
                maxLength={500}
                multiline
                rows={3}
                name={`competitors[${index}].priorities`}
                placeholder="Prioritering/Fördelar"
                value={item.priorities}
                onChange={handleChange}/>
            </Box>
            <Box sx={styles.fieldWrapper}>
              {!isMU && (
                <IconButton
                  style={{padding: 0}}
                  onClick={() => {
                    removeItem(index);
                  }}
                  disabled={index === 0}
                  sx={stylesProps(index).actions}
                  disableRipple
                >
                  <DeleteIcon/>
                  <Typography variant="subtitle2">Eliminera</Typography>
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CompetitorsLocal;

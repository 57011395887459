import React from 'react';

const Region = (): JSX.Element => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7.5" y="3" width="3" height="31.5" rx="1.5" fill="#40DBE4" />
      <path
        d="M6 6C6 5.17157 6.67157 4.5 7.5 4.5H30.1051C31.3213 4.5 32.0319 5.8714 31.3306 6.86503L27.2577
            12.635C26.8916 13.1536 26.8916 13.8464 27.2577 14.365L31.3306 20.135C32.032 21.1286 31.3213 22.5
             30.1051 22.5H7.5C6.67157 22.5 6 21.8284 6 21V6Z"
        fill="#40DBE4"/>
    </svg>
  );
};

export default Region;

import { SystemCssProperties } from '@mui/system';

export const styles = {
  Loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as SystemCssProperties,

  Container: {
    display: 'flex',
    justifyContent: 'space-between',
  } as SystemCssProperties,

  Content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '680px',
  } as SystemCssProperties,

  VideoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
  } as SystemCssProperties,
};

import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { styles } from './styles';

interface Props {
  children: ReactNode;
}

const Steps = (props: Props): JSX.Element => {
  const { children } = props;

  return <Box sx={ styles.container }>{children}</Box>;
};

export default Steps;

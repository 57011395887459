import * as React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { stylesArchive } from './styles';
import TableArchive from 'components/WebTools/Archive/TableArchive';
import HeaderWeb from 'shared/components/HeaderWeb';
import colors from 'styles/_Variables';
import LayoutContainer from 'components/WebTools/MainForm/LayoutContainer';

const Archive = (): JSX.Element => {
  return (
    <LayoutContainer>
      <HeaderWeb background={colors.accentBlue} isStartScreen={false} />
      <Box sx={stylesArchive.titleArchive}>
        <Typography variant="h1">Arkiv</Typography>
      </Box>
      <TableArchive />
    </LayoutContainer>
  );
};

export default Archive;

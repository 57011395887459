import { SystemCssProperties } from '@mui/system';
import colors from 'styles/_Variables';

export const styles = {
  navLink: {
    textDecoration: 'none',
    color: colors.white,
    textTransform: 'none',
    padding: '10px 16px',
    '&.MuiButton-root': {
      padding: '0px',
      marginRight: '24px',
    },
  } as SystemCssProperties,

  LeftTable: {
    width: '100%',
    maxWidth: '562px',
  } as SystemCssProperties,

  center: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  } as SystemCssProperties,

  centerIcon: {
    width: '32px',
    height: '32px',
    marginBottom: '21px',
  } as SystemCssProperties,

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  } as SystemCssProperties,

  rightDragAndDropSection: {
    width: '562px',
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'flex-end',
    justifyContent: 'center',
  } as SystemCssProperties,

  additionalInfoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  } as SystemCssProperties,

  footer: {
    flexBasis: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '50px',
    marginTop: '40px',
  } as SystemCssProperties,

  instructions: {
    color: colors.white,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    whiteSpace: 'pre-line',
    maxWidth: '562px',
  } as SystemCssProperties,

  stepTime: {
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '16px',
    color: colors.white,
    marginTop: '24px',
    marginBottom: '40px',
  } as SystemCssProperties,

  footerLeft: {
    justifyContent: 'flex-start',
    color: 'white',
    display: 'flex',
  } as SystemCssProperties,

  footerRight: {
    display: 'flex',
    justifyContent: 'end',
    width: '562px',
  } as SystemCssProperties,

  footerRightTypo: {
    width: '60%',
    color: colors.white,
  } as SystemCssProperties,

  additionalInfoLeft: {
    width: '100%',
    maxWidth: '562px',
    height: '407px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '8px',
  } as SystemCssProperties,

  additionalInfoRight: {
    height: '100%',
  } as SystemCssProperties,

  additionalInfoLeftTypo: {
    fontSize: '24px',
    fontWeight: 600,
    color: colors.white,
  } as SystemCssProperties,

  additionalInfoLeftBtnRow: {
    marginBottom: '16px',
  } as SystemCssProperties,

  typo: {
    width: '228px',
  } as SystemCssProperties,

  additionalInfoLeftBtnItem: {
    width: '164px',
    height: '56px',
    marginRight: '16px',
    color: colors.accentBlue,
    background: 'white',
    textTransform: 'none',
    '&:hover': {
      background: 'white',
    },
  } as SystemCssProperties,

  helpInfo: {
    width: '100%',
    maxWidth: '196px',
    textAlign: 'center',
    marginTop: 2,
  } as SystemCssProperties,

  navLinkInstruct: {
    background: colors.white,
    '&.MuiButton-root': {
      padding: '0px',
    },
    height: '72px',
    width: '210px',

    '&:hover': {
      background: colors.white,
    },

    '&  a': {
      textDecoration: 'none',
      color: colors.accentBlue,
      textTransform: 'none',
      width: '100%',
      height: '100%',
      padding: '10px 16px',
    },
  } as SystemCssProperties,
};

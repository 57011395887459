const white = '#ffffff';
const accentBlue = '#152E5B';
const disableBlue = '#44587C';
const focusFiled = '#FFB800';
const black = '#000';
const hoverDatePicker = '#EEEEEE';
const selectedLink = '#234176';
const darkGrey = '#9BA1AB';
const grey = '#EEEEEE';
const lightGrey = '#D6DAE1';
const lightBlue = '#2A4B86';
const lighterBlue = '#2C446F';
const lightBlue2 = '#46B9EB';
const lightBlueDark = '#1C3768';
const darkBlue = '#061A40';
const darkMoreBlue = '#0C2450';
const lightGreyDisable = '#C4C4C4';
const lightGreyDisableTable = '#F8F8F8';
const success = '#72C509';
const error = '#D51C29';
const silver = '#C4C4C4';
const disabled = '#9BA1AB';
const green = '#009640';
const purple = '#1D3868';
const orange = '#F2CC66';
const selectedStepLink = '#365DA3';

export default {
  white,
  black,
  error,
  accentBlue,
  disableBlue,
  selectedLink,
  focusFiled,
  hoverDatePicker,
  darkGrey,
  lightGrey,
  grey,
  lightGreyDisable,
  lightGreyDisableTable,
  lightBlue,
  lighterBlue,
  lightBlue2,
  lightBlueDark,
  success,
  darkBlue,
  darkMoreBlue,
  silver,
  disabled,
  green,
  purple,
  orange,
  selectedStepLink,
};

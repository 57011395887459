import {SystemCssProperties} from '@mui/system';

interface ReturnStylesProps {
  factor: SystemCssProperties;
}

export const stylesProps = (width: string, height: string): ReturnStylesProps => {
  return {
    factor: {
      width,
      height,
      position: 'relative',
    } as SystemCssProperties,
  };
};

export const styles = {
  videoPlayer: {
    position: 'relative',
    width: '680px',
    height: '400px',
  } as SystemCssProperties,
};

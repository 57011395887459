import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  children: JSX.Element;
}

const MuiModal = React.forwardRef((props: Props, ref: any): JSX.Element => {
  const {isOpen, children, handleClose} = props;
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box ref={ref}>
        {children}
      </Box>
    </Modal>
  );
});

export default MuiModal;

import React from 'react';
import Ellipse from 'shared/components/Icons/Ellipse';
import {StepsTab} from 'components/WebTools/ProjectProcess/NavProgressMap/_mock_';
import {NavLink} from 'react-router-dom';
import {RoutesPage} from 'shared/routes';
import {Box, Typography} from '@mui/material';
import {styles} from 'components/WebTools/ProjectProcess/NavProgressMap/Tabs/styles';
import {useParams} from 'react-router';
import {Steps} from 'components/WebTools/ProjectProcess/NavProgressMap/interface';
import {actions as actionsPopup} from 'shared/store/popup';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'shared/store/rootReducer';
import {useCheckPermissions} from 'shared/hooks/useCheckPermissions';
import {Roles} from 'shared/constants/enum';
import GroupIcon from '@mui/icons-material/Group';

const TabItems = (): JSX.Element => {
  const {id: idParam} = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const {projectData} = useSelector((state: RootState) => state.project.getProject);
  const role = useCheckPermissions();
  const isMU = role === Roles.MU;

  const subActiveRoute = (location: any, routeLink: String) => {
    let newStr;
    let newArr;
    if (location) {
      newArr = location.pathname.split('/');
      newArr.splice(-1, 1);
      newStr = newArr.join('/');
    }
    return Boolean(routeLink.includes(newStr));
  };

  return (
    <>
      {StepsTab.map(({label, id, path}: Steps) => {
        const routeLink = `${RoutesPage.project.page}${idParam}/${path}${RoutesPage.steps.transition}${location.search}`;
        return (
          <Box key={id} sx={styles.TabItem}>
            <NavLink
              onClick={() => {
                dispatch(actionsPopup.popupActions.toggle(false));
              }}
              isActive={(match, location: any) => {
                return subActiveRoute(location, routeLink);
              }}
              to={routeLink}
            >
              <Box sx={styles.TabIcon}>
                {Number(projectData?.data.currentStep) === Number(id) + 1 && isMU && <GroupIcon sx={styles.GroupIcon}/>}
                <Ellipse counter={Number(id) + 1}/>
                <Typography variant="subtitle2" component="p" color="inherit" sx={styles.Typography}>
                  {label}
                </Typography>
              </Box>
            </NavLink>
          </Box>
        );
      })}
    </>
  );
};

export default TabItems;

import React from 'react';
import { Box, Typography } from '@mui/material';
import MuiButton from 'shared/components/UI/MuiButton';
import { Link } from 'react-router-dom';
import { styles } from './styles';
import { getInstructionsByStep } from './stepInstructions';
import { StepDisplay } from 'shared/store/project/interface';
import { StepNumber } from 'shared/interfaces';

interface Props {
  displayProperties: StepDisplay;
  pathUrl: string;
  currentStep: StepNumber;
}

const StepInstructions = (props: Props): JSX.Element => {
  const { displayProperties, pathUrl, currentStep } = props;
  const stepInstructions = getInstructionsByStep(currentStep);

  return (
    <Box sx={ styles.Container }>
      <Typography variant="subtitle1" component="p" sx={ styles.timeCodeTitle }>{ displayProperties.name }</Typography>

      <Box sx={ styles.Instructions }>
        {
          stepInstructions.instructions.map((instruction, index) =>
            <Typography variant="subtitle1" component="p" sx={ styles.Instruction } key={index}>
              { index + 1 }. { instruction }
            </Typography>)
        }
      </Box>

      {
        !!stepInstructions.time &&
        <Typography variant="subtitle1" component="p" sx={ styles.StepTime }>
          Tidsåtgång: ca { stepInstructions.time } minuter
        </Typography>
      }

      <MuiButton type="button" variant="contained" sx={ styles.NavLink }>
        <Link to={pathUrl}>Fortsätt</Link>
      </MuiButton>
    </Box>
  );
};

export default StepInstructions;

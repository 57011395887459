export const MuiButton = {
  styleOverrides: {
    root: {
      width: '196px',
      height: '48px',
      background: '#152E5B',
      boxShadow: 'none',
      fontSize: 16,
      textAlign: 'center',
      color: '#fff',
      '&:hover': {
        background: '#0C2450',
      },
      '&:active': {
        background: '#051533',
      },
    },
  },
  defaultProps: {
    disableRipple: true,
  },
} as any;

import colors from 'styles/_Variables';
import { SystemCssProperties } from '@mui/system';

export const styles = {
  container: {
    background: colors.accentBlue,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: {
      md: '0',
      lg: '0 40px 0 40px',
    },
  } as SystemCssProperties,
};

import {keyframes, SystemCssProperties} from '@mui/system';
import colors from 'styles/_Variables';
import step1 from 'assets/images/bg-step1.webp';
import step2 from 'assets/images/bg-step2.webp';
import step3 from 'assets/images/bg-step3.webp';
import step4 from 'assets/images/bg-step4.webp';
import step5 from 'assets/images/bg-step5.webp';
import step6 from 'assets/images/bg-step6.webp';

const backgrounds = {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
};

const animate = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const typeWriterAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

export const styles = {
  viewTransitionContainer: (stepNumber: number): SystemCssProperties => {
    return {
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 99,
      background: `url(${backgrounds[`step${stepNumber}`]}) center / cover no-repeat`,
      backgroundColor: colors.accentBlue,
      '& > svg': {
        width: 'calc(50% - 82px)',
        overflow: 'visible',
      },
      '& .dashed': {
        strokeDasharray: '0, 20',
        strokeLinecap: 'round',
        strokeWidth: '9px',
        fill: 'none',
        stroke: 'white',
      },
      '& rect': {
        transform: 'translate(-10%, -10%)',
      },
    } as SystemCssProperties;
  },
  circle: {
    position: 'relative',
    width: '164px',
    height: '164px',
    borderRadius: '50%',
    fontWeight: 500,
    fontSize: '50px',
    color: colors.white,
    backgroundColor: colors.accentBlue,
    border: `15px solid ${colors.white}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    animation: `300ms ease-in-out 3700ms forwards ${animate} `,
  } as SystemCssProperties,
  stepInfo: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
  } as SystemCssProperties,
  stepTitle: {
    textTransform: 'uppercase',
    fontSize: '1.7rem',
    marginTop: '40px',
    width: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    animation: `1000ms ease-in-out 4000ms forwards ${typeWriterAnimation} `,
  } as SystemCssProperties,
  stepIcon: {
    width: '72px',
    height: '72px',
    border: `3px solid ${colors.white}`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:not(:first-of-type)': {
      marginLeft: '-6px',
    },
    '&.pencil': {
      backgroundColor: colors.accentBlue,
    },
    '&.greenCap': {
      backgroundColor: colors.green,
    },
    '&.blackCap': {
      backgroundColor: colors.black,
    },
  } as SystemCssProperties,

  navigationBar: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '124px',
  } as SystemCssProperties,

  stepIconsWrapper: {
    position: 'absolute',
    left: 104,
    top: -32,
    display: 'flex',
    opacity: 0,
    animation: `300ms ease-in-out 3700ms forwards ${animate} `,
  } as SystemCssProperties,
};

import * as React from 'react';
import {IconButton, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {SystemCssProperties} from '@mui/system';

const StylesHeaderItems = {
  '&:hover': {
    background: 'none',
  },
};

export interface Props {
  text: string;
  handleClick?: any;
  Icon: JSX.Element;
  name: string;
  sx?: SystemCssProperties;
}

const HeaderItem = (props: Props): JSX.Element => {
  const {text, handleClick, Icon, name, sx = {}} = props;
  return (
    <Box onClick={handleClick || undefined} key={name}>
      <IconButton edge="start" color="inherit" size="small" disableRipple sx={{...StylesHeaderItems, ...sx}}>
        {Icon}
        <Typography variant="subtitle2" component="p" color="inherit">
          {text}
        </Typography>
      </IconButton>
    </Box>
  );
};

export default HeaderItem;

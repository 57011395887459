import colors from 'styles/_Variables';

export const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 7,
  },

  titleForm: {
    color: colors.white,
    fontWeight: 600,
    fontSize: 24,
  },

  textInfo: {
    display: 'flex',
    color: colors.white,
  },

  textInfoText: {
    color: colors.white,
    ml: '10px',
  },
};
